export default class TaskResult {
	static SUCCESS = 'SUCCESS';
	static FAIL = 'FAIL';
	static CANCELED = 'CANCELED';
	static TIME_OUT = 'TIME_OUT';

	static getAll() {
		return [
			this.SUCCESS,
			this.FAIL,
			this.CANCELED,
			this.TIME_OUT
		]
	}
}

export var TaskUtils = {

    stateOrder: function (state) {
        if (state == 'WAITING') return 0;
        if (state == 'RUNNING') return 1;
        if (state == 'DONE') return 2;
        throw "Unknow task state";
    },

    secondAfterFirst: function (state1, state2) {

        return taskUtils.stateOrder(state1) < taskUtils.stateOrder(state2);
    },

    percentage: function(progress){

      return (Math.round(1000 * (0.0 + progress)) / 10) + '%';
    }

};

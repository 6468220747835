/* @flow */

import ZoneId from './zoneId';
import ZoneRules from './zone/zoneRules';
import ChronoField from '../enums/chronoField';

import {
	SECONDS_PER_MINUTE,
	MINUTES_PER_HOUR,
	SECONDS_PER_HOUR
} from '../constants';

export default class ZoneOffset extends ZoneId {

	static UTC = ZoneOffset.ofTotalSeconds(0);

	seconds: number;

	constructor(seconds: number) {
		super(idFromSeconds(seconds));
		this.seconds = seconds;
	}

	static of(id: string): ZoneOffset {
		throw new Error('Not implemented');
	}

	static ofTotalSeconds(totalSeconds: number): ZoneOffset {
		return new ZoneOffset(totalSeconds);
	}

	static ofTotalMinutes(totalMinutes: number): ZoneOffset {
		return new ZoneOffset(totalMinutes * SECONDS_PER_MINUTE);
	}

	static ofHours(hours: number): ZoneOffset {
		return new ZoneOffset(hours * SECONDS_PER_HOUR);
	}

	static fromJSON(value: ?any): ?ZoneOffset {
		if (value == null) {
			return null;
		}
		return new ZoneOffset(value.seconds);
	}

	toJSON() {
		return {
			seconds: this.seconds
		};
	}

	toMoment(): moment$Moment {
		return global.moment.utc(0)
			.utcOffset(this.getTotalMinutes(), true);
	}

	fromMoment(moment: moment$Moment): ZoneOffset {
		return ZoneOffset.ofTotalMinutes(moment.utcOffset());
	}

	getTotalMinutes(): number {
		return this.seconds / SECONDS_PER_MINUTE;
	}

	getTotalSeconds(): number {
		return this.seconds;
	}

	getRules(): ZoneRules {
		return ZoneRules.of(this);
	}

	get(field: ChronoField): number {
		if (field == ChronoField.OFFSET_SECONDS) {
			return this.seconds;
		}
		throw new Error('Invalid field');
	}

	isSupported(field: ChronoField): boolean {
		if (field instanceof ChronoField) {
			return ZoneOffset.supportedFields.indexOf(field) > -1;
		}
		throw new Error('Not supported type of the argument');
	}

	static supportedFields = [
		ChronoField.OFFSET_SECONDS
	];

	static getMethodSupportedEnumValues = ZoneOffset.supportedFields;
}

function idFromSeconds(totalSeconds: number): string {
	const absTotalSeconds = Math.abs(totalSeconds);
	const seconds = absTotalSeconds % SECONDS_PER_MINUTE;
	const totalMinutes = Math.floor(absTotalSeconds / SECONDS_PER_MINUTE);
	const minutes = totalMinutes % MINUTES_PER_HOUR;
	const hours = Math.floor(totalMinutes / MINUTES_PER_HOUR);
	const hoursStr = hours <= 9 ? '0' + hours : hours;
	const minutesStr = minutes <= 9 ? '0' + minutes : minutes;
	const secondsStr = seconds <= 9 ? '0' + seconds : seconds;
	const sign = totalSeconds < 0 ? '-' : '+';
	return `${sign}${hoursStr}:${minutesStr}:${secondsStr}`;
}

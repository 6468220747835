/* @flow */

import BaseModel from '../../common/models/baseModel';
import SystemInstancesMergeTree from '../components/systemInstancesMergeTree';

export default class SystemInstancesMergeModal extends Backbone.View {

	initialize () {
		let $el = $('#system-instances-merge-modal');
		if ($el.length == 0) {
			$el = $(this.getHTML());
      $("body").append($el);
		}
		this.setElement($el);
    this.instancesTree = new SystemInstancesMergeTree({el: '#typesTree'});
	}

	getHTML () {
		return `<div class="modal general-stretchable-modal fade" id="system-instances-merge-modal">
				<div class="modal-dialog modal-lg">
					<div class="modal-content">
						<div class="modal-header create-modal-header">
							<h4>
							<span id="headerText"></span>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
							</button>
							</h4>
						</div>
						<div class="modal-body">

              <section class="content">
        				<div class="row">
        					<div class="col-md-2"></div>
        					<div id="table-col" class="col-md-12">
        						<div class="box box-primary">
        							<div class="box-body">
        								<div style="display: flex">
        									<div id="typesTree"></div>
        								</div>
        							</div>
        							<div class="col-md-1"></div>
        						</div>
        					</div>
        				</div>
        			</section>

						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default pull-left" data-action="cancel"
									data-dismiss="modal">${app.getResource('cancel')}
							</button>
							<button type="button" class="btn btn-danger pull-right okButton" data-action="create"
									data-dismiss="modal">${app.getResource('ok')}
							</button>
						</div>
					</div>
				</div>
			</div>`;
	}

	show (options: Object) {
		const that = this;

		this.$el.find('.modal-header h4 #headerText').html(options.headerResource);

		this.$el.find('.okButton').off('click').on('click', e => {
        let instancesToMerge = this.instancesTree.selectedInstances();
				options.onSubmit(instancesToMerge);
				that.$el.modal('hide');
				e.stopPropagation();
			});

    this.instancesTree.render();
    this.$el.modal({backdrop: 'static'})
	}
}

/* @flow */
import Instant from './models/instant';
import Duration from './models/duration';

class TimeSynchronizer {

	serverTimestamp: Instant;
	clientTimestamp: Instant;

	initialize () {
		this.serverTimestamp = Instant.fromJSON(app.serverTime);
		this.clientTimestamp = Instant.now();
	}

	getClientAge(): Duration {
		return Duration.between(this.clientTimestamp, Instant.now());
	}

	getServerEventAge(theEventTimestamp: Instant): Duration {
		return Duration.between(theEventTimestamp, this.serverTimestamp).plus(this.getClientAge());
	}

}

export let timeSynchronizer = new TimeSynchronizer();

export function checkValid(ceil: number, value: number) {
	if (value < 0 || value >= ceil) {
		throw new Error(`Value is out of range (0 - ${ceil - 1})`);
	}
}

export function checkValidInclusive(ceil: number, value: number) {
	if (value <= 0 || value > ceil) {
		throw new Error(`Value is out of range (1 - ${ceil})`);
	}
}

export function checkValidYear(value: number) {
	const min = Number.MIN_SAFE_INTEGER;
	const max = Number.MAX_SAFE_INTEGER;
	if (value < min || value > max) {
		throw new Error(`Year is out of range (${min} - ${max})`);
	}
}

export function checkValidDayOfMonth(month: number, day: number, year: ?number) {
	if (day < 1 || day > 31) {
		throw new Error('Day is out of range');
	}
	if (day > 28) {
		let maxDay;
		if (month === 2) {
			// if leap year or no year
			if (year == null || ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0)) {
				maxDay = 29;
			} else {
				maxDay = 28;
			}
		} else if ([1, 3, 5, 7, 8, 10, 12].indexOf(month) > -1) {
			maxDay = 31;
		} else {
			maxDay = 30;
		}
		if (day > maxDay) {
			throw new Error('Invalid date');
		}
	}
}

export function checkUnitIsSupported(instance, unit): boolean {
	if (!instance.isSupported(unit)) {
		throw new Error(`Unit kind ${unit.toString()} is not supported`);
	}
}

export function mod(n, m) {
	return ((n % m) + m) % m;
}
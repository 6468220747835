export default class ValidationMessage extends Backbone.Model {
	getFieldControl () {
		let path = '';
		const innerField = !_.isObject(this.get('path').innerField) ? this.get('path').innerField :
			app.fields.get(this.get('path').innerField.id).fieldName();
		const outerField = this.get('path').outerField;
		if ((_.isObject(outerField) && outerField.id) || outerField) {
			path += (!_.isObject(outerField) ? outerField : app.fields.get(outerField.id).fieldName()) + '.';
		}
		path += innerField;
		let elms = this.get('context').context.wizardView.$(`[data-field="${path}"], [data-field="${outerField}"] [data-field="${innerField}"]`).not('.value-element')
		return elms
	}

	getValidationMessage () {
		return this.get('message');
	}
}

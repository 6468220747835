import { getScript, getStyle } from './utils.js'

let callback = () => {}

function registerCallbackAfterLoad (func) {
	callback = func
}

let promise

function loadOrReturn () {
  promise = promise || Promise.all([
    getScript(app.urls.jstree),
    getStyle(app.urls.jsTreeCss)
  ]).then(callback)
  return promise
}

$.fn.jstree = function () {
	if (arguments.length == 1 && arguments[0] == true) {
		//that asks to return an existing instance of jstree for an element.
		//Of course it's null as jstree isn't loaded yet.
		//Decided not to return promise here, as we use this method a lot to check if the tree is allready initialized.
		//maybe this should be resolved in another way.
		return null;
	}
	return loadOrReturn().then(() => {
		return $.fn.jstree.apply(this, arguments);
	});
}

export { registerCallbackAfterLoad }

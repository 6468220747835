import Type from '../models/type';
import MultilingualString from '../models/multilingualString';

export default class Types extends Backbone.Collection<Type> {

	initialize() {
		this.model = Type;
	}

	typeName(typeId: any, language: any): string {
		let type: Type = this.get(typeId);
		return (type && ((type.get('isPredefined') && type.getShortSystemName()) || (new MultilingualString(type.get('name'))).getCurrentValue(language || null))) || app.messages.missingTypeError;
	}

	isPredefined(typeId: any): boolean  {
		return this.get(typeId) && this.get(typeId).isPredefined()
	}

	byPrimitiveEntityType(typeName: string): string {
		for (let t of this.models) {
			if (t.primitive() == typeName) {
				return t;
			}
		}
	}

	getTypesByDataSourceId(dataSourceId): Type[] {
		return this.filter(t => t.dataSourceId() == dataSourceId);
	}

	systemString(dataSourceId): Type {
		return this.find(t => t.get("primitiveEntityType") == "SYSTEM_STRING");
	}

}

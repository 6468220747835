import ReferrersTree from './referrersTree'
import ReferrersTreeForMergeTool from './referrersTreeForMergeTool'
import { multilingualStringService } from '../service/multilingualStringService';

export default class ErrorsModal extends Backbone.View {
		initialize () {
		this.setElement($('#errors-modal'));
	}

	show (options) {
		const resource = (options.resource && app.getResource(options.resource)) || '';
		this.$el.find('.modal-body p').html(resource);

		if(options.html) {
			this.$el.find('.modal-body #errorsAccordion').html(options.html);
			this.$el.find('#errorsAccordion').show();
		} else {
			this.$el.find('.modal-body #errorsAccordion').html('');
			this.$el.find('#errorsAccordion').hide();
		}

		if(options.referrersData) {
			if (options.referrersData.referrers != null){
				this.referrersTree = new ReferrersTree()
				this.referrersTree.render(options.referrersData.referrers)
				let displayedAmount = multilingualStringService.format(app.getResource('the.first.several.are.displayed.here'),
				[options.referrersData.referrers.length]).getCurrentValue();
				this.$el.find('.modal-body p').html(displayedAmount);
			} else {
				this.referrersTreeForMergeTool = new ReferrersTreeForMergeTool()
				this.referrersTreeForMergeTool.render(options.referrersData)
				this.$el.find('.modal-body p').html(app.getResource("delete.with.dependencies.merge.tool"));
			}


/* TODO: uncomment this after delete perfomance fix
			const referrersAmountText = multilingualStringService.format(app.getResource('referrers.on.delete'),
				[options.referrersData.totalAmount]).getCurrentValue();
			let displayedAmount = '';
			if (options.referrersData.totalAmount != options.referrersData.referrers.length) {
				displayedAmount = multilingualStringService.format(app.getResource('the.first.several.are.displayed.here'),
					[options.referrersData.referrers.length]).getCurrentValue();
			}
			this.$el.find('.modal-body p').html(referrersAmountText + '\n' + displayedAmount);
*/
			this.$el.find('#referrersData').show();
		}
		_.each(options.buttons, (func, btn) => {
			this.$el.find(`.modal-footer > button[data-action="${btn}"]`)
				.off('click')
				.on('click', e => {
					this.$el.modal('hide');
					func(e);
					if (options.then) {
						options.then();
					}
				})
				.show();
			});
			if (options.loginOrRegister) {
				this.$el.find('.modal-header').show();
				this.$el.find('.modal-body p').css({'font-size': '16px', 'color': '#56565a'})
				this.$el.find('.modal-header').html(`
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">×</span>
					</button>
					<img style="height: 40px; width: auto;" src="/resources/images/Codejig_logo.svg">
					`)
				this.$el.find('.modal-body .close').hide();
			 	this.$el.find(`.modal-footer`).html(`
					<div class="row" style="text-align: center;">
						<div class="col-md-6" style="padding-left:50px;">
							<a href="https://apps.codejig.com/new-app" class='btn-success btn' style="min-width: 180px;margin: 10px;">
								<span>${app.getResource('create.new.account')}</span>
							</a>
						</div>
						<div class="col-md-6" style="padding-right:50px;">
							<a href="https://apps.codejig.com/login" class='btn-primary btn btn-md' style="min-width: 180px;margin: 10px;">
								<span>${app.getResource('codejig.user.portal')}</span>
							</a>
						</div>
					</div>`)
		 	}
			this.$el.modal('show');
	}
}

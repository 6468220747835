/* @flow */

/**
 * original author: Serhiy Skytyba
 */

export default class ChronoUnit {

	static NANOS = new ChronoUnit('NANOS', 'Nanos', null, true);
	static MICROS = new ChronoUnit('MICROS', 'Micros', null, true);
	static MILLIS = new ChronoUnit('MILLIS', 'Millis', 'milliseconds', true);
	static SECONDS = new ChronoUnit('SECONDS', 'Seconds', 'seconds', true);
	static MINUTES = new ChronoUnit('MINUTES', 'Minutes', 'minutes', true);
	static HOURS = new ChronoUnit('HOURS', 'Hours', 'hours', true);
	static HALF_DAYS = new ChronoUnit('HALF_DAYS', 'HalfDays', null, true);
	static DAYS = new ChronoUnit('DAYS', 'Days', 'days', true);
	static WEEKS = new ChronoUnit('WEEKS', 'Weeks', 'weeks', true);
	static MONTHS = new ChronoUnit('MONTHS', 'Months', 'months', true);
	static YEARS = new ChronoUnit('YEARS', 'Years', 'years', true);
	static DECADES = new ChronoUnit('DECADES', 'Decades', null, true);
	static CENTURIES = new ChronoUnit('CENTURIES', 'Centuries', null, true);
	static MILLENNIA = new ChronoUnit('MILLENNIA', 'Millennia', null, false);
	static ERAS = new ChronoUnit('ERAS', 'Eras', null, false);
	static FOREVER = new ChronoUnit('FOREVER', 'Forever', null, false);

	value: string;
	_name: string;
	moment: string;
	blocklyVisible: boolean;

	constructor(value: string, _name: string, moment: String, blocklyVisible: boolean) {
		this.value = value;
		this._name = _name;
		this.moment = moment;
		this.blocklyVisible = blocklyVisible;
	}

	getDisplayName(): string {
		if (translations.hasOwnProperty(this.value)) {
			return translations[this.value];
		}
		return this._name;
	}

	static values(): Array<ChronoUnit> {
		return values || (values = _.values(ChronoUnit));
	}

	name(): string {
		return this.value;
	}

	toString(): string {
		return this._name;
	}

	toMoment(): string {
		if (this.moment == null) {
			throw new Error(`Unit kind ${this.value} is not supported`);
		}
		return this.moment;
	}
}

let values;

const translations = {
	'NANOS': 'nanos',
	'MICROS': 'micros',
	'MILLIS': 'millis',
	'SECONDS': 'seconds',
	'MINUTES': 'minutes',
	'HOURS': 'hours',
	'HALF_DAYS': 'half-days',
	'DAYS': 'days',
	'WEEKS': 'weeks',
	'MONTHS': 'months',
	'YEARS': 'years',
	'DECADES': 'decades',
	'CENTURIES': 'centuries',
	'MILLENNIA': 'millennia',
	'ERAS': 'eras',
	'FOREVER': 'forever'
};

import utils from '../../common/components/utils.js';

var BaseControl = Backbone.View.extend({

	events: {
		'change': 'changed'
	},

	initialize: function (options) {
		this.model = options.model;
		this.modelAttr = options.modelAttr;
		this.additionalPropertiesToSet = options.additionalPropertiesToSet || [];
		_.extend(this.events, BaseControl.prototype.events);
		this.delegateEvents();
		if (this.model) {
			this.listenTo(this.model, 'change:' + this.modelAttr, this.render);
		}
	},

	render: function () {
		this.setValue(this.getDataFromModel());
	},

	focus: function() {
		this.$el.focus();
	},
	changed: function () {
		if (this.additionalPropertiesToSet.length) {
			this.setDataToModel(this.getValueWithProperties(this.additionalPropertiesToSet));
		} else {
			this.setDataToModel(this.getValue());
		}
	},

	reset: function () {
		this.changed();
	}
});

export default BaseControl;

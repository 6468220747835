/* @flow */

import Block from '../models/block';
import EntitiesWithOwner from './entitiesWithOwner';

export default class Blocks extends EntitiesWithOwner<Block> {

	initialize() {
		super.initialize.apply(this, arguments);
		this.model = Block;
		this.comparator = false;
	}

	current(): Block {
		return this.get(app.blockId);
	}

	blockName(blockId: number): string {
		let block: Block = this.get(blockId);
		return (block && block.get('name')) || app.messages.missingBlockError;
	}
}

import { RadioGroup } from '@codejig/ui-components'

class RadioGroupInputs {

  constructor (option) {
    var that = this
    this.props = {
      skin: option.skin || 'blue',
      value: option.value || null,
      options: option.options
    }
    this.radioGroup = new Vue({
      el: option.el,
      data: {
        props: that.props
      },
      mixins: [option.mixin || {}],
      render: h => h(RadioGroup, {
        props: this.props,
        ref: 'radioGroup',
        on: {
          input: function (newValue) {
            that.props.value = newValue
            option.selectionChanged(newValue)
          }
        }
      })
    }).$refs.radioGroup
  }

  disable () {}

  enable () {}

}
export default RadioGroupInputs;

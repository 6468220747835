import { openPresentationUrl, showInstance } from '../../common/components/utils';
import MetaObject from '../../common/models/metaObject';

var linkManager = {
	openInCurrentTab(link) {
		window.location.href = link;
	},

	openInNewTab(link) {
		window.open(link);
	},

	open(link, mode, options) {
		openPresentationUrl(link, mode, options);
	},

	openInstance(options) {
		showInstance(options)
	},

	getHomeLink() {
		return app.urls.home;
	},

	constructParams(parameters) {
		let result = '';
		for(let key in parameters) {
			if (result) {
				result += '&';
			} else {
				result += '?'
			}
			result += `${key}=${parameters[key]}`
		}
		return result;
	},

	getIndexLink(typeId, parameters) {
		return app.urls.entity + typeId + this.constructParams(parameters);
	},

	getCreateLink(typeId, parameters) {
		let result = app.urls.entity + typeId + '/create';
		return result + this.constructParams(parameters);
	},

	getUpdateLink(instance, parameters) {
		let typeId;
		if (instance instanceof MetaObject) {
			typeId = app.blockUtils.getObjectType(instance).id;
		} else {
			typeId = instance.entityTypeId;
		}
		return app.urls.entity + typeId +
			'/update/' + instance.id + this.constructParams(parameters);
	},

	getCustomRelativeLink(link) {
		return app.urls.home + link;
	},

	getCustomAbsoluteLink(link) {
		if (link.startsWith('http://') || link.startsWith('https://')) {
			return link;
		} else {
			return '//' + link;
		}
	}
};

export default linkManager;

export class FieldService {

	constructor(context) {
		this.context = context;
	}

	getField(viewItemId) {
		return this.getItem(viewItemId) || this.context.wizardView.forms[0].findComponentObject(viewItemId)
	}

	disableField(field) {
		field.disabled = true
		field.disable()
	}

	enableField(field) {
		field.disabled = false
		if (this.context.updating) {
			field.enable()
		}
	}

	disableItem(viewItemId) {
		let field = this.getField(viewItemId)
		if (field) {
			this.disableField(field)
		} else {
			this.getViewItemIdsInsideObject(this.getElementByDataItemId(viewItemId)).forEach((viewSubItemId) => {
				field = this.getField(viewSubItemId)
				if (field) {
					this.disableField(field)
				}
			})
		}
	}

	enableItem(viewItemId) {
		let field = this.getField(viewItemId)
		if (field) {
			this.enableField(field)
		} else {
			this.getViewItemIdsInsideObject(this.getElementByDataItemId(viewItemId)).forEach((viewSubItemId) => {
				field = this.getField(viewSubItemId)
				if (field) {
					this.enableField(field)
				}
			})
		}
	}

	putFocusInField(viewItemId) {
		const field = this.getItem(viewItemId);
		if (field) {
			field.el.focus();
		} else {
			let tab = this.context.wizardView.forms[0].findSubTabByViewId(viewItemId)
			if (tab) {
				tab.activate()
			}
		}
	}

	setRequiredField(viewItemId, required) {
		const field = this.getItem(viewItemId);
		if (field) {
			const group = this.getGroupForField(field);
			if (group) {
				const name = group.querySelector('.field-name');
				if(name) {
					if (required) {
						name.classList.add('required-sign');
					} else {
						name.classList.remove('required-sign');
					}
				} else {
					if (required) {
						group.classList.add('no-label-required-sign');
					} else {
						group.classList.remove('no-label-required-sign');
					}
				}
				if (required) {
					field.el.setAttribute('data-required', 'true')
				} else {
					field.el.removeAttribute('data-required')
				}
			}
		}
	}

	hideItem(viewItemId) {
		this.getGroupAndPerformAction(viewItemId, (group) => {
			group.classList.add('hidden');
		})
	}

	showItem(viewItemId) {
		this.getGroupAndPerformAction(viewItemId, (group) => {
			group.classList.remove('hidden');
		})
	}

	setStyleItem(viewItemId, styles) {
		this.getGroupAndPerformAction(viewItemId, (group) => {
			group.style.cssText = styles;
		})
	}

	showMessageForField(viewItemId, message, kind, timeout) {
		const field = this.getItem(viewItemId);
		if (field) {
			let $el = $(field.el);
			let fgroup = $el.hasClass('form-group') ? $el : $el.closest('.form-group');

			fgroup.addClass('has-message');
			const $span = $(`<span class="help-block kind-${kind}"/>`).appendTo(fgroup);
			if (message) {
				$span.append(message);
			};
			if(timeout) {
				setTimeout(() => {
					$span.remove();
				}, timeout);
			}
		}
	}

	clearMessageForField(viewItemId) {
		const field = this.getItem(viewItemId);
		if (field) {
			let $el = $(field.el);
			let fgroup = $el.hasClass('form-group') ? $el : $el.closest('.form-group');
			fgroup.removeClass('has-message');
			fgroup.find(".help-block").remove(".help-block");
		}
	}

	clearAllMessages() {
		this.context.$el.find(".has-message").removeClass('has-message');
		this.context.$el.find(".help-block").remove();
	}

	getGroupAndPerformAction(viewItemId, callback) {
		const field = this.getItem(viewItemId);
		if (field) {
			const group = this.getGroupForField(field);
			if (group) {
				callback(group);
			}
		} else {
			const group = this.getElementByDataItemId(viewItemId)
			if (group) {
				if (group.classList.contains('tab-pane')){
					const tab = this.getElementByDataTarget(viewItemId)
					if (tab) {
						callback(tab.parentElement)
					}
				}
				callback(group);
			}
		}
	}

	getGroupForField(field) {
		return field.el.closest('.form-group');
	}

	getItem(viewItemId) {
		return this.context.wizardView.forms[0].findComponentObjectByViewId(viewItemId);
	}

	getElementByDataItemId(dataItemId) {
		return this.context.el[0].querySelector(`[data-item-id="${dataItemId}"]`);
	}

	getElementByDataTarget(dataItemId) {
		return this.context.el[0].querySelector(`[data-target="#${dataItemId}"]`)
	}

	getViewItemIdsInsideObject(element) {
		let arr = []
		element && element.querySelectorAll(`[data-item-id]`).forEach((el) => {arr.push(el.getAttribute('data-item-id'))})
		return arr
	}
}

/* @flow */

import ChronoField from '../enums/chronoField';
import ChronoUnit from '../enums/chronoUnit';
import DayOfWeek from './dayOfWeek';
import LocalDateTime from './localDateTime';

import type { Temporal } from '../declarations';
import type ZoneId from './zoneId';

import {
	MONTHS_PER_YEAR
} from '../constants';

import {
	checkValid,
	checkValidYear,
	checkValidInclusive,
	checkValidDayOfMonth,
	checkUnitIsSupported
} from '../utils';

/**
 * Serilized form:
 *   year: int
 *   month: short
 *   day: short
 */

export default class LocalDate {

	year: number;
	// starts from 1
	month: number;
	day: number;

	constructor(year: number, month: number, day: number) {
		checkValidYear(year);
		checkValidInclusive(MONTHS_PER_YEAR, month);
		checkValidDayOfMonth(month, day, year);
		this.year = year;
		this.month = month;
		this.day = day;
	}

	static of(year: number, month: number, dayOfMonth: number): LocalDate {
		return new LocalDate(year, month, dayOfMonth);
	}

	static ofEpochDay(epochDay: number): LocalDate {
		const moment: moment$Moment = global.moment.utc(0).add(epochDay, 'days');
		return LocalDate.of(moment.year(), moment.month() + 1, moment.date());
	}

	static fromJSON(value: ?any): ?LocalDate {
		if (value == null) {
			return null;
		}
		return new LocalDate(value.year, value.month, value.day);
	}

	static now(zone: ZoneId): LocalDate {
		if (zone) {
			return LocalDateTime.now(zone).toLocalDate();
		}
		const date = new Date();
		return new LocalDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
	}

	toJSON() {
		return {
			year: this.year,
			month: this.month,
			day: this.day
		};
	}

	toMoment(): moment$Moment{
		return global.moment.utc({
			year: this.year,
			month: this.month - 1,
			day: this.day
		});
	}

	static fromMoment(moment: moment$Moment): LocalDate {
		return LocalDate.of(moment.year(), moment.month() + 1, moment.date());
	}

	toEpochDay(): number {
		const startOfEpoch = global.moment.utc(0);
		return this.toMoment().diff(startOfEpoch, 'days');
	}

	getDayOfMonth(): number {
		return this.day;
	}

	getMonthValue(): number {
		return this.month;
	}

	getDayOfYear(): number {
		return this.toMoment().dayOfYear();
	}

	getDayOfWeek(): DayOfWeek {
		return DayOfWeek.of(this.get(ChronoField.DAY_OF_WEEK));
	}

	getYear(): number {
		return this.year;
	}

	get(field: ChronoField): number {
		switch (field) {
			case ChronoField.DAY_OF_WEEK: return this.toMoment().isoWeekday();
			case ChronoField.DAY_OF_MONTH: return this.day;
			case ChronoField.DAY_OF_YEAR: return this.getDayOfYear();
			case ChronoField.EPOCH_DAY: return this.toEpochDay();
			case ChronoField.MONTH_OF_YEAR: return this.month;
			case ChronoField.YEAR: return this.year;
			case ChronoField.ALIGNED_DAY_OF_WEEK_IN_MONTH:
			case ChronoField.ALIGNED_DAY_OF_WEEK_IN_YEAR:
			case ChronoField.ALIGNED_WEEK_OF_MONTH:
			case ChronoField.ALIGNED_WEEK_OF_YEAR:
			case ChronoField.PROLEPTIC_MONTH:
			case ChronoField.YEAR_OF_ERA:
			case ChronoField.ERA:
				throw new Error('not implemented');
		}
		throw new Error('Unsupported field');
	}

	plus(amount: number, unit: ChronoUnit): LocalDate {
		checkUnitIsSupported(this, unit);
		const moment = this.toMoment().add(amount, unit.toMoment());
		return LocalDate.fromMoment(moment);
	}

	until(endExclusive: Temporal, unit: ChronoUnit): number {
		throw new Error('Not implemented');
	}

	compareTo(other: LocalDate): number {
		const y = this.year - other.year;
		if (y !== 0) {
			return y;
		}
		const m = this.month - other.month;
		if (m !== 0) {
			return m;
		}
		return this.day - other.day;
	}

	equals(other: ?any): boolean {
		if (other == null) {
			return false;
		}
		if (!(other instanceof LocalDate)) {
			return false;
		}
		return this.year === other.year &&
				this.month === other.month &&
				this.day === other.day;
	}

	isAfter(other: LocalDate): boolean {
		return this.compareTo(other) > 0;
	}

	isBefore(other: LocalDate): boolean {
		return this.compareTo(other) < 0;
	}

	isSupported(field: ChronoField | ChronoUnit): boolean {
		if (field instanceof ChronoField) {
			return LocalDate.supportedFields.indexOf(field) > -1;
		}
		if (field instanceof ChronoUnit) {
			return LocalDate.supportedUnits.indexOf(field) > -1;
		}
		throw new Error('Not supported type of the argument');
	}

	static supportedFields = [
		ChronoField.DAY_OF_WEEK,
		ChronoField.ALIGNED_DAY_OF_WEEK_IN_MONTH,
		ChronoField.ALIGNED_DAY_OF_WEEK_IN_YEAR,
		ChronoField.DAY_OF_MONTH,
		ChronoField.DAY_OF_YEAR,
		ChronoField.EPOCH_DAY,
		ChronoField.ALIGNED_WEEK_OF_MONTH,
		ChronoField.ALIGNED_WEEK_OF_YEAR,
		ChronoField.MONTH_OF_YEAR,
		ChronoField.PROLEPTIC_MONTH,
		ChronoField.YEAR_OF_ERA,
		ChronoField.YEAR,
		ChronoField.ERA,
	];

	static supportedUnits = [
		ChronoUnit.DAYS,
		ChronoUnit.WEEKS,
		ChronoUnit.MONTHS,
		ChronoUnit.YEARS,
		ChronoUnit.DECADES,
		ChronoUnit.CENTURIES,
		ChronoUnit.MILLENNIA,
		ChronoUnit.ERAS,
	];

	static getMethodSupportedEnumValues = LocalDate.supportedFields;
}

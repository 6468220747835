import Formatter from '../components/formatter';
import Instant from '../../time/models/instant';
import PrimitiveEntityType from '../enums/primitiveEntityType';
import utils from '../components/utils';
import MultilingualString from '../models/multilingualString';
import Constants from "../models/constants";
import formattersAndStyles from '../components/formattersAndStyles';


var ChangeLog = Backbone.View.extend({

	$container:null,
	$el: null,

	$list: null,
	$filter: null,
	$filterContainer: null,
	$header: null,

	$btn: null,

	$optionS: null,
	$valueS: null,

	$itemCount: null,
	$filterCount: null,

	currentNum: 0,
	hasMore: false,

	data: [],
	currentData: [],

	filters: [],
	optionSList: [],
	valueSList: [],


	// INIT


	initialize: function (o) {
		this.ids = o.ids;

		this.$btn = o.$btn;
		this.$container = o.$container;

		this.initOpenBtn();
	},

	initOpenBtn: function () {
		if(this.$btn && this.$btn.length) {
			this.$btn.on("click", ()=>{this.toggleHistory();});
		}
	},


	initHistorySidebar: function () {
		if(!this.$container || !this.$container.length) {
			return;
		}

		this.$el = $(`<div id="history-sidebar" class="affix-top" data-spy="affix" data-offset-top="50"></div>`);

		this.$header = $(`<div class="history-header"></div>`);
		let $filterPullLeft = $(`
			<div class="pull-left">
				<span class="header-text">Change log</span>
			</div>`
		);
		this.$itemCount = $(`<span class="low-text"></span>`)
		let $filterPullRight = $(`<div class="flex pull-right"></div>`);
		let $showTargetsBtn = $(`<a class="btn btn-default">Show targets</a>`);
		let $addFilterBtn = $(`
			<a class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				<span class="glyphicon glyphicon-filter" style="font-size: 20px;"></span>
			</a>`
		);
		this.$filterCount = $(`<span class="label" style="padding: 4px 5px 4px 5px;">0</span>`);
		let $closedArrowBtn = $(`<a class="btn btn-default fa fa-angle-left mirror-in-rtl" style="font-size: 25px;"></a>`);


		$filterPullLeft.append(this.$itemCount);
		$filterPullRight.append($showTargetsBtn);
		$addFilterBtn.append(this.$filterCount);
		$filterPullRight.append($addFilterBtn);
		$filterPullRight.append($closedArrowBtn);
		this.$header.append($filterPullLeft);
		this.$header.append($filterPullRight);
		this.$el.append(this.$header);



		this.$filter = $(`<div class="history-filter hidden"></div>`);
		this.$optionS = $(`<select name="option"></select>`);
		let filterLabel = $(`<span class="label">=</span>`);
		this.$valueS = $(`<select name="value"></select>`);
		let $applyFilterBtn = $(`<a class="btn btn-default glyphicon glyphicon-plus" style="font-size: 12px;"></a>`);


		this.$filter.append(this.$optionS);
		this.$filter.append(filterLabel);
		this.$filter.append(this.$valueS);
		this.$filter.append($applyFilterBtn);
		this.$el.append(this.$filter);


		this.$filterContainer = $(`<div class="history-filter-container" style="display: none;"></div>`);
		this.$list = $(`
			<ul class="history-list scrollable">
				<div style="background-color: #cccccc2b;width: 100%;height: 100%;">
					<i class="fa fa-spinner fa-spin" style="position: absolute;font-size: 75px;top: calc(50% - 59px);left: calc(50% - 27px);color: #ccc;"></i>
				</div>
			</ul>
		`);
		this.$el.append(this.$filterContainer);
		this.$el.append(this.$list);
		this.$container.append(this.$el);



		this.$el.affix({offset:{top:50}});

		$addFilterBtn.on("click", ()=>{
			this.$filter.toggleClass('hidden');
			this.resizeContainer();
		});
		$applyFilterBtn.on("click", ()=>{
			this.$filter.toggleClass('hidden');
			this.addFilter();
		});
		$closedArrowBtn.on("click", ()=>{
			this.toggleHistory();
		});
		$showTargetsBtn.on("click", ()=>{
			$showTargetsBtn.toggleClass('clicked');
			let isClicked = $showTargetsBtn.hasClass('clicked');
			isClicked ? $showTargetsBtn.text("Hide targets") : $showTargetsBtn.text("Show targets");
			this.showAllTargets(isClicked);
		});


		this.initSelects(true);

		this.addMutationObserver();
		this.$el.classChange((el, newClass) => { this.resizeContainer(); });
	},

	initSelects: function (initOnSelect) {
		this.$optionS.empty();
		this.$valueS.empty();

		this.$optionS.select2({
			data: this.optionSList,
			placeholder: {
				id: '',
				text: 'none'
		}});
		this.$valueS.select2({
			data: this.valueSList,
			placeholder: {
				id: '',
				text: 'none'
		}});

		if(this.optionSList.length == 0 || this.valueSList.length == 0) {
			this.$optionS.attr('disabled','disabled');
			this.$valueS.attr('disabled','disabled');
		} else {
			this.$optionS.removeAttr('disabled');
			this.$valueS.removeAttr('disabled');
		}

		if(initOnSelect) {
			this.$optionS.on('select2:select', (e) => {	this.updateSelectData(e.params.data); });
		}
	},


	// SHOW


	toggleHistory: function () {
		if(this.$container && this.$container.length && this.$container.find("#history-sidebar").length == 0) {
			this.initHistorySidebar();
			this.currentData = [];
			this.loadHistory().then(res => { this.getFilteredData(this.data).then(data => this.renderHistory(data)); })
			this.$btn && this.$btn.find("span").text(app.getResource('hide.history'));
		} else {
			this.filters = [];
			this.data = [];
			this.currentData = [];
			this.currentNum = 0;
			this.hasMore = false;
			this.$el.remove();
			this.$btn && this.$btn.find("span").text(app.getResource('show.history'));
		}
	},


	// DATA


	getFilteredData: async function (data) {
		let newData = data;
		_.each(this.filters, (filter) => {
			newData = newData.filter((item => {
				if(filter[0] == "kind") {
					return item.kind == filter[1];
				} else if(filter[0] == "result") {
					return item.result == filter[1];
				} else if(filter[0] == "state") {
					return item.state == filter[1];
				} else if(filter[0] == "mid") {
					return item.mainTarget.targetId == filter[1];
				} else if(filter[0] == "id") {
					return item.targets.map(target => {return target.targetId}).includes(filter[1]);
				}
			}))
		});
		
		if(newData.length < 50 && this.hasMore) {
			var res = await this.loadHistory(this.currentNum, 50);
			if(!res || res.length<1) {
				throw "error loading more";
			}
			data = [...data, ...res];

			return await this.getFilteredData(data);
		} else {
			return newData;
		}
	},

	loadHistory: function (start, num) {
		return new Promise(async (resolve, reject) => {
			let result = (await new Promise((resolve, reject) => {
				utils.request(this.ids, app.urls.changeLog(start || 0, num || 50), 'post').then(response => {
					resolve(response);
				})
				.catch(() => {
					this.toggleHistory();
					app.notificationManager.addError("You have no permission to access change log!") 
				});
			})) || [];

			this.data = [...this.data , ...((result && result['one']) || [])];
			this.currentNum = this.data.length;
			this.hasMore = result && result['two'];

			resolve((result && result['one']) || []);
		});
	},


	// SELECT DATA


	getUniqueOptions: function(arr, mapF, multiple) {
		let unique=[];
		_.each(arr, el=> {
			if(!multiple) {
				unique.push(mapF(el));
			} else {
				unique = unique.concat(mapF(el));
			}
		})
		unique = _.unique(unique , _.iteratee(function(n) {return n.id;}))
		return unique;
	},

	getSelectValues: function () {
		let ids =  this.ids.filter(item => item != null);
		return {
			"kind": () => ["CREATE","MERGE","UPDATE", "DELETE", "CONSTRUCT"].map((item) => {return {id:item, text:item}}),
			"result": () => ["SUCCESS","FAIL","CANCELED", "TIME_OUT"].map((item) => {return {id:item, text:item}}),
			"state": () => ["DONE","RUNNING","WAITING"].map((item) => {return {id:item, text:item}}),
			"mid": () => ids.map((item) => {return {id:item, text:item}}),
			"id": () => ids.map((item) => {return {id:item, text:item}}),
		}
	},

	getSelectOptions: function () {
		return [
			{id:'kind',text:'Task kind'},
			{id:'result',text:'Task result'},
			{id:'state',text:'Task state'},
			{id:'mid',text:'M target id'},
			{id:'id',text:'Target id'},
		];
	},

	updateSelectData: function () {
		let usedOps = this.filters.map(filter=>filter[0]);
		this.optionSList = this.getSelectOptions().filter(op => !usedOps.includes(op.id)) || [];

		if(this.optionSList.length > 0) {
			let val = this.$optionS.select2('val') || this.optionSList[0].id;
			if(usedOps.includes(val)) {
				this.optionSList[0].selected = true;
				val = this.optionSList[0].id;
			} else {
				_.each(this.optionSList, item => {
					if(item.id == val) {
						item.selected = true;
					}
				})
			}
			
			this.valueSList = this.getSelectValues()[val]() || [];
		} else {
			this.valueSList = [];
		}
	
		this.$optionS.select2('destroy');
		this.$valueS.select2('destroy');

		this.initSelects();
		this.updateFiltersCountLabel();
	},

	updateFiltersCountLabel: function () {
		let filterLen = this.filters.length;

		filterLen==0 ? this.$filterContainer.hide() : this.$filterContainer.show();
		this.$filterCount.text(filterLen);

		this.resizeContainer();
	},


	// RENDER


	renderHistory: function (data) {
		data = data || [];
		if(this.currentData.length == 0) {
			this.$list.html('');
		}
		this.currentData = [...this.currentData, ...data];
		this.$itemCount.html(this.currentData.length + ' ' + app.getResource('modifications'));

		_.each(data, (item) => {
			this.renderItem(item);
		});
		if (this.hasMore) {
			this.renderMore();
		}

		this.updateSelectData();
	},

	renderItem: function (task) {
		let taskId = task.id;
		let time = Formatter.formatWithPredefinedFormat(Instant.fromJSON(task.timeDone), {
			primitiveType: PrimitiveEntityType.TIMESTAMP
		});
		let result = task.result;
		let state = task.state;
		let kind = task.kind;
		let user = task.userAccount ? MultilingualString.getCurrentValue(task.userAccount.firstName) + ' ' + MultilingualString.getCurrentValue(task.userAccount.lastName) : 'anonymous';

		let mainTarget = task.mainTarget;
		let mainTargetType = MultilingualString.getCurrentValue(app.types.get(mainTarget.objectTypeId).get('name')) + this.getItemName(mainTarget) + this.getViewName(mainTarget);
		let mainTargetAction = mainTarget.action;

		let targets = task.targets;
		let messages = task.messages;



		let $html = $(`
			<li class="historyItem" data-taskid=${taskId}>
				<div>
					<span class="pull-left subheader-text">${mainTargetType}
						${targets.length > 1 ? `<span class="label" style="margin-left: 3px;">${targets.length}</span>` : ''}
					</span>
					<span class="pull-right header-text ${result.toLowerCase()=="success" ? "regular" :result.toLowerCase()}">
						${mainTargetAction}
						<a href="${app.urls.open(Constants.ID_TYPE_TASK, taskId)}" class="fa fa-external-link-square" target="_blank"></a>
					</span>
				</div>
				<div>
					<span class="pull-left low-text">${time}</span>
					<span class="pull-right low-text">${user}</span>
				</div>
			</li>`);

		let $statsDiv = $(`
			<div class="stats-div">
				<span class="stats-span">${kind}</span>
				${state != "DONE" ? `
				<span class="stats-span ${state.toLowerCase()}">${state}</span>` : ''}
				${result != "SUCCESS" ? `
				<span class="stats-span ${result.toLowerCase()}">${result}</span>` : ''}
			</div>`);
		$html.append($statsDiv);
				

		if(targets.length > 0) {
			let $targetBtn = $(`
				<a class=" btn btn-default">Targets
					<span class="label tiny-label">${targets.length}</span>
				</a>
			`);
			let $targetList = $(`
				<div class="targets-list" style="display: none;" data-taskid=${taskId}></div>
			`);
			$targetBtn.on("click", (e)=>{
				$targetBtn.toggleClass("clicked");
				this.toggleTargets($targetList);
				e.stopPropagation();
			});

			$statsDiv.append($targetBtn);
			$html.append($targetList);
		}
		if(messages.length > 0) {
			let $messageBtn = $(`
				<a class="btn btn-default">Messages
					<span class="label tiny-label">${messages.length}</span>
				</a>
			`);
			let $messageList = $(`
				<div class="message-list" style="display: none;" data-taskid=${taskId}></div>
			`);

			$messageBtn.on("click", (e)=>{
				$messageBtn.toggleClass("clicked");
				this.toggleMessages($messageList);
				e.stopPropagation();
			});

			$statsDiv.append($messageBtn);
			$html.append($messageList);
		}
		
		$html.on("click", (e)=>{
			$html.toggleClass("clicked");
			//this.click(e);
		});

		$html.appendTo(this.$list);
	},

	renderMore: function () {
		let $html = $(`
		<li class="historyItem historyMoreItem">
				<span>Load more items</span>
				<i class="fa fa-spinner fa-spin" style="font-size: 29px;"></i>
		</li>`);

		$html.on("click", (e)=>{
			$html.toggleClass("clicked");
			this.loadMore().then(() => {
				this.$historyMoreItem = null;
				$html.remove();
			});
		});

		$html.appendTo(this.$list);
		this.$historyMoreItem = $html;
	},

	loadMore() {
		return this.loadHistory(this.currentNum, 50).then(res => {
			if(res && res.length > 0) {
				this.getFilteredData(res).then(data => this.renderHistory(data));
				if(!this.hasMore){
					this.$historyMoreItem && this.$historyMoreItem.remove();
					this.$historyMoreItem = null;
				}
			}
		});
	},

	getItemName(target, pure) {
		let targetId = target.targetId;
		let systemName = app.types.get(target.objectTypeId).get("systemName");
		let format = (text) => {
			if (text && !(typeof text === 'string')) {
				text = MultilingualString.getCurrentValue(text);
			}
			return pure ? text :`<span> - ${text}</span>`;
		}
		let item = null;

		switch (systemName) {
			case "com.codejig.builder.model.domain.EntityType":
				item = app && app.types && app.types.get(targetId);
				break;
			case "com.codejig.builder.model.domain.EntityBlock":
				item = app && app.blocks && app.blocks.get(targetId);
				break;
			case "com.codejig.builder.model.domain.EntityView":
				item = app && app.views && app.views.get(targetId);
				break;
			default:
				break;
		}
			
		return item ? format(item.get('name')): '';
	},

	getViewName(target, pure) {
		let viewKind = target.viewKind;
		if(viewKind) {
			return pure ? viewKind :`<span> - ${viewKind}</span>`
		} else {
			return '';
		}
	},

	toggleTargets(div$) {
		if(div$.html() == ''){
			let taskId = div$.attr("data-taskid");
			let targets = this.data.filter(task => task.id==taskId)[0].targets;
			let html = '';

			_.each(targets, target => {
				let text = MultilingualString.getCurrentValue(app.types.get(target.objectTypeId).get('name')) + this.getItemName(target) + this.getViewName(target);

				return html = html + 
					`<li >
						<span class="pull-left">${text}</span>
						<span class="pull-right">${target.action}</span>
					</li>`;
			})
			div$.html(html);
			div$.show();
		} else {
			div$.html('');
			div$.hide();
		}
	},

	showAllTargets(show) {
		let targets = this.$el.find(".targets-list");
		_.each(targets, target => {
			target = $(target);
			if((target.html() == '' && show) || (target.html() != '' && !show)) {
				this.toggleTargets(target);
			}
		});
	},

	toggleMessages(div$) {
		if(div$.html() == ''){
			let taskId = div$.attr("data-taskid");
			let messages = this.data.filter(task => task.id==taskId)[0].messages;
			var text = ``;

			_.each(messages, message => {
				let cls = _.escape(message.kind.toLowerCase()) + '-message';
				text += `
				<div class="task-message">
					<i class="${cls} message-icon material-icons notranslate">error_outline</i>
					<span class="${cls} message-kind">${_.escape(message.kind)}</span>
					<small class="message-time">${formattersAndStyles.task.formatDate(message.timeStamp)}</small>
				</div>
				<div class="message-text">${_.escape((new MultilingualString(message.text)).getCurrentValue())}</div>`;
			});

			let html = `
				<div class="pull-right messages-info">
					<small>${messages.length} messages</small>
				</div>
				<br />
				<div class="messages-list">
					${text}
				</div>
			`

			div$.html(html);
			div$.show();
		} else {
			div$.html('');
			div$.hide();
		}
	},


	// FILTER


	addFilter: function () {
		this.filters.push([this.$optionS.select2('val'), this.$valueS.select2('val')]);
		
		this._addFilter({
			id: this.$optionS.select2('data')[0].id,
			text: this.$optionS.select2('data')[0].text,
		},{
			id: this.$valueS.select2('data')[0].id,
			text: this.$valueS.select2('data')[0].text,
		})
	},

	_addFilter: function (option, value) {
		let $newFilter = $(`<div class="filter-block"></div>`);

		let $appFilOp = $(`<span data-opId="${option.id}">${option.text}</span>`);
		let $label = $(`<span class="label">=</span>`);
		let $appFilVal = $(`<span data-opId="${value.id}">${value.text}</span>`);
		let $removeFilter = $(`<span class="material-icons notranslate">close</span>`);

		$removeFilter.on("click", (e) => {
			let option = $appFilOp.attr("data-opId");
			let value = $appFilVal.attr("data-opId");

			$newFilter.remove();

			this.filters = this.filters.filter(item => item[0]!= option && item[1]!= value);
			this.currentData = [];
			this.getFilteredData(this.data).then(data => this.renderHistory(data));
		});

		$newFilter.append($appFilOp);
		$newFilter.append($label);
		$newFilter.append($appFilVal);
		$newFilter.append($removeFilter);

		this.$filterContainer.append($newFilter);
		this.currentData = [];
		this.getFilteredData(this.data).then(data => this.renderHistory(data));
	},


	// EVENTS


	addMutationObserver: function () {
		$.fn.classChange = function(cb) {
			return $(this).each((_, el) => {
			  new MutationObserver(mutations => {
				mutations.forEach(mutation => cb && cb(mutation.target, $(mutation.target).prop(mutation.attributeName)));
			  }).observe(el, {
				attributes: true,
				attributeFilter: ['class']
			  });
			});
		  }
	},

	resizeContainer: function () {
		let affixTop = this.$el.hasClass("affix-top");
		let headerHeight = this.$header.css("height");
		let filterHeight = this.$filter.css("height");
		let containerHeight = this.$filterContainer.css("height");
		let filterIs = !(this.$filter.css("display") == "none");
		let containerIs = !(this.$filterContainer.css("display") == "none");

		this.$list.css("height", `calc(100vh - ${affixTop ? "50px": "0px"} - ${headerHeight} - ${filterIs ? filterHeight: "0px"} - ${containerIs ? containerHeight: "0px"})`);
	}
});
export default ChangeLog;

export function initChangeLog(ids) {
	return new ChangeLog({$container: $("body"), ids: ids});
}
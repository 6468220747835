import TaskKind from './taskKind'
import TaskResult from './taskResult'
import TaskState from './taskState'

let enumsMap = {
  'TASK_STATE': TaskState,
  'TASK_KIND': TaskKind,
  'TASK_RESULT': TaskResult
}
export default enumsMap

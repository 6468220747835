import Constants from '../common/models/constants'
import CreateTheme from './views/createTheme.js';
import utils from '../common/components/utils.js'


export function addTheme() {
  let model = new Backbone.Model({});
  app.createModal.show({
    url: app.urls.create(Constants.ID_TYPE_CSS_THEME),
    headerResource: `add.theme`,
    model: model,
    afterCreateOnSuccess: (data) => {
      console.log(data)
      utils.redirectTo(app.urls.open(Constants.ID_TYPE_CSS_THEME, data.id))
    },
    createView: CreateTheme
  });
}

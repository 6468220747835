import MultilingualString from '../../common/models/multilingualString';
import Constants from '../models/constants';
import {translate} from '../service/stringResourceService'
const ReferrersTreeForMergeTool = Backbone.View.extend({

	el: '#referrersTree',

	getTypeName: function (typeId) {
		return MultilingualString.getCurrentValue(app.types.get(typeId).name())
	},

	getNodes: async function (data) {
		let nodes = [];
		for(let i = 0, l = data.length; i < l; i++) {
			let childrenNodes = [];
      let referrer = data[i]
			for(let j = 0, p = referrer.entities.length; j < p; j++) {
        let item = referrer.entities[j]
        let itemObject = {
					text: item.name,
					children: false,
					a_attr: {
						'href': app.urls.open(item.typeId, item.id)
					}
				}
        if (item.ownerId != null){

          childrenNodes.push({
            text: this.getTypeName(item.ownerId),
            children: [itemObject],
            state: {
              opened: true
            },
            a_attr: {
              'href': app.urls.open(Constants.ID_TYPE_TYPE, item.ownerId)
            }
          })
        } else {
          childrenNodes.push(itemObject)
        }
			}
			let text = referrer.parent.name
			let href = app.urls.open(referrer.parent.typeId, referrer.parent.id)
			if (referrer.parent.ownerId){
				text += " "
				switch (referrer.parent.typeId) {
					case Constants.ID_TYPE_FIELD:
						 text += translate('field')
						 href = app.urls.open(Constants.ID_TYPE_TYPE, referrer.parent.ownerId)
						break;
					case Constants.ID_TYPE_VIEW:
						 text += translate('view')
						break;
					case Constants.ID_TYPE_BLOCK:
						 text += translate('block')
						break;
				}
				text += ` (${this.getTypeName(referrer.parent.ownerId)})`
			}
			nodes.push({
				text: text,
				children: childrenNodes,
				state: {
					opened: false
				},
				a_attr: {
					'href': href
				}
			});
		}
		return nodes;
	},

	render: async function (data) {
		var that = this;
		await this.$el.jstree()
		this.$el.jstree('destroy').empty();
		this.$el.jstree({
			'core': {
				data: function (node, callback) {
					that.getNodes(data).then(nodes => {
						callback.call(this, nodes);
					})
				},
				themes: {
					icons: false,
					name: 'proton',
					responsive: true
				}
			},
			'plugins': ['wholerow']
		}).bind('select_node.jstree', function (e, data) {
			 var href = data.node.a_attr.href;
			 window.open(href);
		});
	}
});

export default ReferrersTreeForMergeTool;

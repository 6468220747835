export default {
	FIELD: 'FIELD',
	SEPARATOR: 'SEPARATOR',
	ROW: 'ROW',
	COLUMN: 'COLUMN',
	GROUPBOX: 'GROUPBOX',
	TAB_CONTAINER: 'TAB_CONTAINER',
	TAB: 'TAB',
	BUTTON: 'BUTTON',
	TEXT: 'TEXT',
	WIDGET: 'WIDGET',
	TOOLBAR: 'TOOLBAR',
	INLINE_ROW: 'INLINE_ROW',
	INLINE_COLUMN: 'INLINE_COLUMN',
	REPORT_TABLE: 'REPORT_TABLE'
};

import TypeKind from '../../common/enums/typeKind';
import CreateUpdateForm from '../../common/views/createUpdateForm.js';
import utils, { checkRequired } from '../../common/components/utils.js';
import ViewKind from '../../common/enums/viewKind';
import BaseCreateView from '../../common/views/baseCreateView';

var CreateView = BaseCreateView.extend({

	initialize: function (options) {
		CreateView.__super__.initialize.apply(this, arguments);
	},

	requiredCheck: function () {
		return checkRequired(this.model.toJSON(), this.$el)
	},

	saveConfiguration: function () {
		var data = this.model.toJSON();
		return data;
	},

	getHTML: function () {
		let result = `<div class="grid-1x-padding">
										<label for="name">${app.getResource('name')}</label>
										<span class="asterisk"> *</span>
									</div>
									<div class="grid-2x-padding">
										<input id="viewName" class="form-control" type="text" data-is-string-multilingual="short" data-field="name" data-required="true"/>
									</div>`;
		return result;
	}

});

export default CreateView;

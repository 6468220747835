/* @flow */

import utils from '../../components/utils';
import StateRecovery from '../../components/stateRecovery';
import { TaskUtils } from '../../components/taskUtils';
import TaskInfoView from './taskInfoView';
import TaskInfoKind from '../../enums/taskInfoKind';
import TaskState from '../../enums/taskState';
import TaskResult from '../../enums/taskResult';

import formattersAndStyles from '../../components/formattersAndStyles';

class TaskView extends Backbone.View {

		constructor() {
				super({
						el: '#control-sidebar-task-tab, #control-sidebar-settings-tab',
						events: {
								'click .sidebar-close': 'close',
								'mousedown .task-element': 'showInfo',
								'click .task-cancel-btn': 'cancelTask',
								'click #close-task-details': 'closeTaskDetails'
						}
				});
		}

		cancelTask(e){
			e.preventDefault();
			let id = e.target.attributes['data-task-id'].value;
		  utils.postPlainText(id, app.urls.task.cancel);
		}

		open(){
			this.opened = true
			this.load();
			app.formBuilder && app.formBuilder.generalEventsViewer && app.formBuilder.generalEventsViewer.$el.addClass('right-sidebar-opened');
			$('.control-sidebar').addClass('control-sidebar-open');
		}

		close(){
			this.opened = false
			$('.control-sidebar').removeClass('control-sidebar-open');
			app.formBuilder && app.formBuilder.generalEventsViewer && app.formBuilder.generalEventsViewer.$el.removeClass('right-sidebar-opened')
			this.taskInfoView.hide();
		}

		toggle () {
			if (this.opened) {
				this.close()
			} else {
				this.open()
			}
		}

		closeTaskDetails(){
			this.taskInfoView.hide();
		}

		showInfo(e){
			e.stopPropagation();
			let $task = $(e.currentTarget);
			let task = app.taskManager.findTask($task.attr('id'));
			this.taskInfoView.show(task, $task);
		}

		initialize() {
				var that = this;
				this.taskInfoView = new TaskInfoView({
						$el: that.$el.find('.task-details'),
						popupMode: true
				});
				this.stateRecovery = StateRecovery;
				this.tasksKey = 'tasks2';
				this.loaded = false;
				this.tasksLimit = app.const.tasksLimit;
				this.$taskList = this.$el.find('.sidebar-list');
				$('.control-sidebar-jobs-btn').click(() => {
					if (!this.opened || this.tasks != false) {
						this.toggle();
					}
					$('.control-sidebar .tab-pane.active').removeClass('active');
					$('#control-sidebar-task-tab').addClass('active');
					this.tasks = true;
				});
				$('.control-sidebar-settings-btn').click(() => {
					if (!this.opened || this.tasks != true) {
						this.toggle();
					}
					$('.control-sidebar .tab-pane.active').removeClass('active');
					$('#control-sidebar-settings-tab').addClass('active');
					this.tasks = false;
				});
				this.$el.find('.scrollable').scroll(() => {
						that.taskInfoView.hide();
				});
				this.$taskManagerErrors = $('#task-manager-errors-label');
		}

		load() {
				if (!this.loaded) { // lazy loading, just once
						this.loaded = true;
						var that = this;
						var tasks = (StateRecovery.get(this.tasksKey) || []).reverse();
						_.each(tasks, function(task) {
								app.taskManager.tasks[task.id] = task;
								that.addTask({
										merged: task
								});
						});
				}
		}

		store(task) {
				var tasks = StateRecovery.get(this.tasksKey) || [];
				if (tasks.length >= this.tasksLimit) {
						tasks.shift();
				}
				let omited = _.omit(task, ['responseBody', 'responseObject']);
				tasks.push(omited);
				StateRecovery.put(this.tasksKey, tasks)
		}

		onFailedTask($taskEl) {
				var err = +(this.$taskManagerErrors.html() || '0');
				this.$taskManagerErrors.html(++err);
				var that = this;
				$taskEl.addClass('border').hover(function() {
						that.onFailuresSeen();
				}, function() {
						$(this).unbind();
				});
		}

		onFailuresSeen() {
				this.$taskManagerErrors.html(''); // no unwatched errors
				this.$taskList.find('li.border').removeClass('border').unbind();
		}

		getText(mls) {
				return mls.translations[app.currentLanguage] || mls.value;
		}

		renderDetailsButton(task) {
				return `<a target="_blank" href="${app.urls.task.rethrow(task.id)}" class="pull-right">Details</a>`;
		}

		renderCancelButton(task) {
				return `<a href="#" class="task-cancel-btn" data-task-id="${task.id}" class="pull-right">Cancel</a>`;
		}

		formatDate(date, format) {
				if (!format){
					format = 'HH:mm:ss d/MM';
				}
				return formattersAndStyles.task.formatDate(date, format);
		}

		buildLink(task){
			if (task.result == TaskResult.FAIL){
				return this.renderDetailsButton(task);
			}
			if (task.state == TaskState.RUNNING || task.state == TaskState.WAITING){
				return this.renderCancelButton(task);
			}
			return '';
		}

		render(obj) {
				let task = obj.merged;
				var result = {
						text: (task.result || task.state || 'in queue')
				};

				if (task.infoKind === TaskInfoKind.PROGRESS_CHANGED) {
						let percentage = TaskUtils.percentage(task.progress);
						result.text = percentage;
				}

				if (result.text === 'FAIL') {
						result.text = `<a href="#" class="fail pull-right">${result.text}</a>`;
				} else if (task.state) {
						result.text = result.text.substr(0, 1).toUpperCase() + result.text.substr(1).toLowerCase();
				}

				return `<div class="pull-left">
									<span class="item-action create">${task.kind}</span>
									<span class="item-date">${this.formatDate(task.timeDone)}</span>
								</div>
								<div class="pull-right">
									<span class="item-result">${result.text}</span>
									<span class="item-link">${this.buildLink(task)}</span>
								</div>
							`;
		}

		addTask(obj) {
				let task = obj.merged;
				var that = this;
				var $taskEl = this.$taskList.find('#' + task.id);
				if (!$taskEl.length) {
						this.taskInfoView.hide();
						if (this.$taskList.children().length >= this.tasksLimit) {
								this.$taskList.children().last().remove();
						}
						$taskEl = $(`<li id="` + task.id + `" class="task-element"></li>`);
						if (obj.raw) {
							this.$taskList.prepend($taskEl);
						} else {
							this.$taskList.append($taskEl);
						}
						this.$el.find('.sidebar-subheader-text').html(this.$taskList.children().length + ' last tasks');
				}
				if (obj.raw) {
						if (task.state === TaskState.DONE && task.result !== TaskResult.SUCCESS) {
								this.onFailedTask($taskEl);
						}
				}
				$taskEl.html(this.render(obj));
				if (this.taskInfoView.isShowing(task)){
					this.taskInfoView.render();
        }
		}

};

export default TaskView;

/* @flow */

export default class MetaObject extends Backbone.Model {

	static of(id, typeId): MetaObject {
		return new MetaObject({
			id: id,
			objectType: {
				id: typeId
			}
		});
	}

	static fromEntity(metaObject): ?MetaObject {
		if (metaObject == null) {
			return null;
		}
		return MetaObject.of(metaObject.id, metaObject.entityTypeId);
	}

	static fromJSON(json): MetaObject {
		return new MetaObject(json);
	}

}

import EntityMenuItemKind from '../enums/entityMenuItemKind';
import PermissionFilterKind from '../enums/permissionFilterKind';
import UnavailableModuleModeKind from '../enums/menuUnavailableModuleModeKind';
import {translate} from '../service/stringResourceService'
import { buildDefaultPopover } from './utils'
import utils from '../../common/components/utils.js';

export default class Menu extends Backbone.View {
	initialize (o) {
		this.maxShortLevelLength = 15;
		this.setElement($('#main-menu'));
		if (app.modulesMask != 0) {
			this.$el
			.find('li[data-modules-mask]')
			.each((index, elem) => {
				const $elem = $(elem)
				const elemMask = Number.parseInt($elem.attr('data-modules-mask'), 10)
				if (elemMask != 0 && (elemMask & app.modulesMask) == 0) {
					const mode = $elem.attr('data-unavailable-module-mode')
					if (mode ==	UnavailableModuleModeKind.HIDDEN) {
						$elem.remove()
					} else if (mode == UnavailableModuleModeKind.DISABLED) {
						$elem.find('a').removeAttr('href')
						$elem.addClass('disabled')
						buildDefaultPopover($elem,
						{
						 toggle: 'popover',
						 content: translate('module.not.available'),
						 html: true,
						 delay: 100,
						 container: 'body',
						 placement: 'right'
					 })
					}
				}
			})
		}
		if (!app.currentUser) {
			this.$el
				.find(`li[data-permission-kind="${PermissionFilterKind.AUTHORIZED}"]`)
				.remove();
		} else {
			this.$el
				.find(`li[data-permission-kind="${PermissionFilterKind.ANONYMOUS}"]`)
				.remove();
		}
		let toRemove = this.$el
			.find(`li[data-permission-kind="${PermissionFilterKind.IN_ANY_OF_ROLES}"]`)
			.add(`li[data-permission-kind="${PermissionFilterKind.NOBODY}"]`);
		app.currentUser && app.currentUser.userRoles.forEach(role => {
			toRemove = toRemove.not(`li[data-roles~="${role.id}"]`);
		});
		toRemove.remove();

		this.$el.find('.menu-level').perfectScrollbar({
			suppressScrollX: true
		});
		let menu = app.raw && app.raw.menus.filter(menu => menu.id === app.menuId)[0];
		if (menu && menu.isTreeView){
			// Tree View
			$('.sidebar-mini .main-sidebar.application-menu').addClass('treeview-menu');

			$('.main-sidebar > .sidebar > #main-menu > .sidebar-menu li[data-kind="FOLDER"] > a').click((e) => {
				$(e.currentTarget.parentElement).toggleClass('opened');
				e.stopPropagation();

				let sidebar = $('.sidebar-mini:not(.sidebar-collapse) .main-sidebar.application-menu.treeview-menu #main-menu > .sidebar-menu');
				if (sidebar){
					sidebar.css('max-height', $(window).height() + $(window).scrollTop() - sidebar.offset().top);
					sidebar.perfectScrollbar('update');
				}

				let subAdvancedMenu = $('.sidebar-mini.sidebar-collapse .menu-sub-advanced.treeview:hover > ul');
				if (subAdvancedMenu){
					subAdvancedMenu.css('max-height', $(window).height() + $(window).scrollTop() - subAdvancedMenu.offset().top);
					subAdvancedMenu.perfectScrollbar('update');
				}
			});

			$('.sidebar-collapse .menu-sub-advanced.treeview').hover(function(){
				let subAdvancedMenu = $('.sidebar-mini.sidebar-collapse .menu-sub-advanced.treeview:hover > ul');
				subAdvancedMenu.css('max-height', $(window).height() + $(window).scrollTop() - subAdvancedMenu.offset().top);
				subAdvancedMenu.perfectScrollbar({
					suppressScrollX: true
				});
			}, function(){
				let subAdvancedMenu = $('.sidebar-mini.sidebar-collapse .menu-sub-advanced.treeview > ul');
				subAdvancedMenu.perfectScrollbar('destroy');
				subAdvancedMenu.css('max-height', '');
			});

			$('.main-sidebar.application-menu.treeview-menu').hover(() => {
				let sidebar = $('.sidebar-mini:not(.sidebar-collapse)  #main-menu > .sidebar-menu');
				if (sidebar){
					sidebar.css('max-height', $(window).height() + $(window).scrollTop() - sidebar.offset().top);
					sidebar.perfectScrollbar({
						suppressScrollX: true
					});
				}
			}, () => {
				let sidebar = $('.sidebar-mini:not(.sidebar-collapse)  #main-menu > .sidebar-menu');
				if (sidebar){
					sidebar.perfectScrollbar('destroy');
				}
			})
		}else{
			// Page View
			this.$el.find('li')
				.click(e => {
					e.stopPropagation();
					const $el = $(e.currentTarget);
					if (!$el.hasClass('disabled') && $el.attr('data-kind') == EntityMenuItemKind.FOLDER) {
						const level = +$el.closest('.menu-level').attr('data-level');
						const $level = this.$el.find(`.menu-level[data-level="${level}"]`);
						const $nextLevel = $level.next('.menu-level');
						const $submenu = $nextLevel
						.children(`ul[data-parent="${$el.attr('id')}"]`);
						$nextLevel.children('ul').hide();
						$submenu.show();
						$nextLevel.removeClass('menu-level-closed').removeAttr('style');
						$nextLevel.find('.back-text')
						.html($el.find('> a > .translatable').clone());
						$nextLevel.find('.back-down').show();
						if ($submenu.children('li.site-menu-item').length <
						this.maxShortLevelLength) {
							$nextLevel.find('.back-down').hide();
						}
						$level.toggleClass('menu-level-closed', true, 170);
					}
				});
			this.$el.find('.back').click(e => {
				const $level = $(e.currentTarget).parent();
				$level.prev('.menu-level').toggleClass('menu-level-closed', false, 90);
			});
			this.$el.find('.back-to-start').click(e => {
				const $level = this.$el.find('.menu-level[data-level="1"]');
				$level.toggleClass('menu-level-closed', false, 170);
			});
			this.$el.find('.back-to-start-text').html(app.getResource('back.to.start'));

			$('.sidebar-mini .main-sidebar.application-menu').addClass('standard-menu');
		}
	}

}

/* @flow */

export default class TextStyle {

	static FULL = new TextStyle();
	static FULL_STANDALONE = new TextStyle();
	static SHORT = new TextStyle();
	static SHORT_STANDALONE = new TextStyle();
	static NARROW = new TextStyle();
	static NARROW_STANDALONE = new TextStyle();
}

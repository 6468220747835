/* @flow */

import type Field from './field';
import type Type from './type';
import MetaObject from './metaObject';
import TypeKind from '../enums/typeKind';
import PrimitiveEntityType from '../enums/primitiveEntityType';
import Constants from './constants';
import EmbeddedEntity from './embeddedEntity';
import EntityReference from './entityReference';
import Entity from './entity';
import EntityExternal from './entityExternal';

import * as time from '../../time/models';

export default class ModelFactory {

	static systemTypes = null;

	static _typeCache = {};

	static getModelTypeByField(field: Field) {
		if (field.isDynamic()) {
			return MetaObject;
		}
		if (field.isCollection()) {
			return require('../collections/entities.js').default;
		}
		// $SuppressFlow: must be not null, we checked for dynamic earlier
		const type: Type = field.type();
		return ModelFactory.getModelType(type);
	}

	static lazyInitSystemTypesMap(){
		if (ModelFactory.systemTypes == null){
			ModelFactory.systemTypes = {
				[Constants.ID_TYPE_REPORT_GROUP]() {
					return require('./reportGroup').default;
				},
				[Constants.ID_TYPE_REPORT_VALUE]() {
					return require('./reportValue').default;
				},
				[Constants.ID_TYPE_FIELD_FILTER]() {
					return require('./fieldFilter').default;
				}
			};
		}
	}

	static getModelType(type: Type) {
		const typeId = type.id;
		ModelFactory.lazyInitSystemTypesMap();
		if (ModelFactory.systemTypes.hasOwnProperty(typeId)) {
			return ModelFactory.systemTypes[typeId]();
		}
		if (ModelFactory._typeCache.hasOwnProperty(typeId)) {
			return ModelFactory._typeCache[typeId];
		}
		let result = null;
		if (type.isEmbedded()) {
			result = function (json) {
				return EmbeddedEntity.fromJSON(json, typeId);
			};
			result.fromJSON = (json) => {
				return EmbeddedEntity.fromJSON(json, typeId);
			};
		} else if (type.kind() === TypeKind.DICTIONARY ||
				type.kind() === TypeKind.DOCUMENT ||
				type.kind() === TypeKind.REPORT) {

			result = function (json) {
				return EntityReference.fromJSON(json, typeId);
			};
			result.fromJSON = (json) => {
				return EntityReference.fromJSON(json, typeId);
			};
			result.reference = (id) => {
				return EntityReference.reference(id, typeId);
			};
		} else if (type.kind() === TypeKind.EXTERNAL) {
				result = function (json) {
					return EntityExternal.fromJSON(json, typeId);
				};
				result.fromJSON = (json) => {
					return EntityExternal.fromJSON(json, typeId);
				}
		} else if (type.kind() === TypeKind.REGISTER || type.kind() === TypeKind.TRANSIENT) {
			result = function (json) {
				return Entity.fromJSON(json, typeId);
			};
			result.fromJSON = (json) => {
				return Entity.fromJSON(json, typeId);
			};
		} else if (type.isPrimitive()) {
			result = ModelFactory.getPrimitiveType(type.primitive());
		}
		ModelFactory._typeCache[typeId] = result;
		return result;
	}

	static getPrimitiveType(primitiveTypeKind: string) {
		switch (primitiveTypeKind) {
			case PrimitiveEntityType.TIMESTAMP: return time.Instant;
			case PrimitiveEntityType.ZONED_DATE_TIME: return time.ZonedDateTime;
			case PrimitiveEntityType.PERIOD: return time.Period;
			case PrimitiveEntityType.DURATION: return time.Duration;
			case PrimitiveEntityType.LOCAL_DATE: return time.LocalDate;
			case PrimitiveEntityType.LOCAL_TIME: return time.LocalTime;
			case PrimitiveEntityType.LOCAL_DATE_TIME: return time.LocalDateTime;
			case PrimitiveEntityType.LOCAL_DATE_RANGE: return time.DateRange;
			case PrimitiveEntityType.MONTH_DAY: return time.MonthDay;
			case PrimitiveEntityType.MONTH: return time.Month;
			case PrimitiveEntityType.DAY_OF_WEEK: return time.DayOfWeek;
			case PrimitiveEntityType.YEAR: return time.Year;
			case PrimitiveEntityType.YEAR_MONTH: return time.YearMonth;
			case PrimitiveEntityType.ZONE_OFFSET: return time.ZoneOffset;
			case PrimitiveEntityType.STRING: return require('./multilingualString').default;
			case PrimitiveEntityType.BINARY: return require('./binary').default;
		}
	}
}

/* @flow */

import ZoneRules from './zoneRules';

import type ZoneOffset from '../zoneOffset';
import type Instant from '../instant';

export default class OffsetZoneRules extends ZoneRules {

	offset: ZoneOffset;

	constructor(offset: ZoneOffset) {
		super();
		this.offset = offset;
	}

	getOffset(instant: Instant): ZoneOffset {
		return this.offset;
	}
}

import { UrlServicePrototype } from "./urlService";
var juri = require('juri')(['filters', 'table', 'field', 'value', 'order', 'kind', 'sort', 'nanos', 'seconds',
'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'LIKE', 'NULL', 'NOT_NULL', 'desc', 'asc', 'extendedmode']);

class ApplicationState {

	constructor() {
		this.state={};
		this.additParams = '';
		this.title = 'filterState';
		this.anchor = '';
		this.urlService = new UrlServicePrototype()
		let stateStr = this.urlService.getParameter(this.title);
		this.state = stateStr && this.decode(stateStr) || {};
	}

	/*
		silently sets param string
	*/
	setParams() {

	}

	decode(string) {
		return juri.decode(decodeURIComponent(string));
	}

	encode(json) {
		return encodeURIComponent(juri.encode(json));
	}

	put(key, value) {
		value = _.isEmpty(value) ? null : value;
		this.state[key] = value;
		this.updateUrl();
	}

	updateUrl() {
		let encode = this.encode(this.state);
		this.urlService.putParameter(this.title, encode);
	}

	removeFilter() {
		this.urlService.deleteParameter(this.title);
	}

	get(key) {
		return this.state[key];
	}

	change (key, func) {
		const state = this.get(key) || {};
		func(state);
		this.put(key, state);
	}

	delete (key, field) {
		const state = this.get(key) || {};
		delete state[field];
		var isNoFilter = true;
		Object.keys(this.state).forEach((el) => {
			if(this.get(el).hasOwnProperty(field)) {
				isNoFilter = false;
			}})
		if (isNoFilter) {
			this.removeFilter();
		} else {
			delete this.state[key];
			this.updateUrl();
		}
	}

	resetUrl() {
		this.urlService = new UrlServicePrototype()
	}

}

export var applicationState = new ApplicationState();
export let ApplicationStatePrototype = ApplicationState
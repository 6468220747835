import utils from '../../common/components/utils';
import MultilingualString from '../../common/models/multilingualString';
import entityManager from '../../common/components/entityManager.js';
import {translate} from '../../common/service/stringResourceService'

const SystemInstancesMergeTree = Backbone.View.extend({

	initialize(): void {
		this.registerText = translate('register');
		this.dictionaryText = translate('dictionary');
		this.reportText = translate('report');
		this.documentText = translate('document');
	},

	render: function() {
		const that = this;
		utils.getRequest(app.urls.systemInstancesForDeploy)
			.then(data => {
				that.systemInstances = that._buildInstanceTreeModel(data);
				that._loadTree();
			});
	},

	_buildInstanceTreeModel: function(data) {
		// Return instances group by type kind
		let groups = _.groupBy(data, item => item.typeKind);

		let groupedTypes = {};
		groupedTypes[this.registerText] = { types: this._buildInstanceSubTreeModel(groups.REGISTER) };
		groupedTypes[this.dictionaryText] = { types: this._buildInstanceSubTreeModel(groups.DICTIONARY) };
		groupedTypes[this.reportText] = { types: this._buildInstanceSubTreeModel(groups.REPORT) };
		groupedTypes[this.documentText] = { types: this._buildInstanceSubTreeModel(groups.DOCUMENT) };
		return groupedTypes;
	},

	_buildInstanceSubTreeModel: function(data) {
		// Return instances groupped by type name
		return (data === undefined) ? {} : _.groupBy(data, item => (new MultilingualString(item.type.name)).getCurrentValue());
	},

	_loadTree: function () {
		let that = this;
    this.TYPE_KIND_PREFIX = "[kind] ";
		this.TYPE_PREFIX = "[type] ";
		this.$el.jstree({
			'core': {
				data: function (node, callback) {
					if (node.id === '#') {
						var nodes = [];
						for (var prop in that.systemInstances) {
								nodes.push({
									id: that.TYPE_KIND_PREFIX + prop,
									text: prop,
									state: {opened: false},
									children: that.systemInstances[prop].types && Object.keys(that.systemInstances[prop].types).length > 0
								});
						}
						callback.call(this, nodes);
          } else if (node.id.startsWith(that.TYPE_KIND_PREFIX)) {
            const typeKind = node.id.substring(that.TYPE_KIND_PREFIX.length);
            const typeData = that.systemInstances[typeKind].types;
  					let nodes = [];
            for (var prop in typeData) {
								nodes.push({
									id: that.TYPE_PREFIX + prop,
									text: prop,
									state: {opened: false},
									children: typeData[prop] && typeData[prop].length > 0
								});
						}
						nodes = nodes.sort((a,b)=>{
							var textA = a.text.toLowerCase()
							var textB = b.text.toLowerCase()
							return textA.localeCompare(textB)
						})
            callback.call(this, nodes);
					} else if (node.id.startsWith(that.TYPE_PREFIX)) {
						const kindParent = node.parents[node.parents.length - 2];
            const typeKind = kindParent.substring(that.TYPE_KIND_PREFIX.length);
						const instanceType = node.id.substring(that.TYPE_PREFIX.length);
            const instanceData = that.systemInstances[typeKind].types[instanceType];
            Promise.all(
    					_.map(instanceData,
    						instance => entityManager.fetchStringView(null, instance.id).then(
    							view => {
    								return {
    									id: instance.id,
    									view: view
    								};
    							}
    						))
    				).then(stringViews => {
              let viewMap = {};
              _.each(stringViews, (stringView, index) => {
                viewMap[stringView.id] = stringView.view;
              });
              var nodes = _.map(instanceData, instance => {
  							return {
  								id: instance.id,
  								text: viewMap[instance.id],
  								children: false
  							};
  						});
  						nodes = nodes.sort((a,b)=>{
							var textA = a.text.toLowerCase()
							var textB = b.text.toLowerCase()
							return textA.localeCompare(textB)
						})
  						callback.call(this, nodes);
    				});
					}
				},
				themes: {
					icons: false,
					name: 'proton',
					responsive: true
				},
			},
			'checkbox' : {
				'keep_selected_style' : false
			},
			'plugins': ['checkbox']
		});

		// this.$el.on('select_node.jstree', function (event, selected) {
		// 	console.log(that.$el.jstree('get_selected', true));
		// 	console.log(that.selectedInstances());
    // });
	},

	selectedInstances: function () {
		let that = this;

		return _.map($('#typesTree').jstree('get_selected', true), node => {

			let typeKind = null;
			let typeName = null;
			let typeId =  null;
			let instanceId = null;

			// Get type kind
			if (node.id.startsWith(that.TYPE_KIND_PREFIX)) {
				typeKind = node.id.substring(that.TYPE_KIND_PREFIX.length);
			} else {
				const kindParent = node.parents[node.parents.length - 2];
				typeKind = kindParent.substring(that.TYPE_KIND_PREFIX.length);
			}

			// Get type id
			if (!node.id.startsWith(that.TYPE_KIND_PREFIX)) {
				if (node.id.startsWith(that.TYPE_PREFIX)) {
					typeName = node.id.substring(that.TYPE_PREFIX.length);
				} else {
					const typeParent = node.parents[node.parents.length - 3];
					typeName = typeParent.substring(that.TYPE_PREFIX.length);
				}
				typeId = that.systemInstances[typeKind].types[typeName][0].type.id;
			}

			// Get instance id
			if (!node.id.startsWith(that.TYPE_KIND_PREFIX) && !node.id.startsWith(that.TYPE_PREFIX)) {
				instanceId = node.id;
			}

			const result = {};
			if (typeKind == that.registerText) {
				result.typeKind = 'REGISTER';
			} else if (typeKind == that.dictionaryText) {
				result.typeKind = 'DICTIONARY';
			} else if (typeKind == that.reportText) {
				result.typeKind = 'REPORT';
			} else if (typeKind == that.documentText) {
				result.typeKind = 'DOCUMENT';
			}
			if (typeId) {
				result.type = {};
				result.type.id = typeId;
				result.type.name = { "value" : typeName };
			}
			result.id = (instanceId ? instanceId : '0');
			return result;
		})
		.filter(
				// If the whole branch is selected then just parent node should be returned
				instance => !that._isFullBranchSelected(instance)
		);
	},

	_isFullBranchSelected: function (instance) {
		let nodeId = null;
		if (instance.id != '0') {
			nodeId = instance.id;
		} else if (instance.type !== undefined) {
			nodeId = this.TYPE_PREFIX + instance.type.name.value;
		} else {
			// Always iterate 'type kind' nodes
			return false;
		}

		const node = this.$el.jstree(true).get_node(nodeId);
		const parentNode = this.$el.jstree(true).get_node(node.parent);
		return parentNode.state.selected;
	}
});

export default SystemInstancesMergeTree;

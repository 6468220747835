/* @flow */

import Constants from './constants';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import ModelFactory from '../../common/models/modelFactory';
import EmbeddedEntity from './embeddedEntity';

export default class FieldFilter extends EmbeddedEntity {

	initialize(json) {
		super.initialize.apply(this, arguments);

		if (json) {
			const field = app.fields.get(json.field && json.field.id);
			this.set('field', field);
			if (json.value != null) {
				this.set('value', FieldFilter.valueFromJSON(field && field.type(), JSON.parse(json.value)));
			}
		}
	}

	static valueFromJSON(type, value) {
		if (!type) {
			return value;
		}
		switch (type.get('primitiveEntityType')) {
			case PrimitiveEntityType.TIMESTAMP:
			case PrimitiveEntityType.DURATION:
			case PrimitiveEntityType.LOCAL_DATE:
			case PrimitiveEntityType.LOCAL_TIME:
			case PrimitiveEntityType.LOCAL_DATE_TIME:
			case PrimitiveEntityType.DAY_OF_WEEK:
			case PrimitiveEntityType.MONTH:
			case PrimitiveEntityType.MONTH_DAY:
			case PrimitiveEntityType.YEAR:
			case PrimitiveEntityType.YEAR_MONTH:
				return ModelFactory.getPrimitiveType(type.get('primitiveEntityType')).fromJSON(value);
			default:
				return value;
		}
	}

	get entityTypeId(): number {
		return Constants.ID_TYPE_FIELD_FILTER;
	}

	toServerJSON() {
		const json = super.toServerJSON.apply(this, arguments);
		if (json.id && isNaN(json.id)) {
			json.id = null;
		}
		delete json._typeId;
		json.value = JSON.stringify(json.value);
		return json;
	}

}

/* @flow */

import ChronoField from '../enums/chronoField';
import TextStyle from '../enums/textStyle';

import {
	MONTHS_PER_YEAR
} from '../constants';

import {
	checkValidInclusive
} from '../utils';

export default class Month {
	static JANUARY = new Month('JANUARY');
	static FEBRUARY = new Month('FEBRUARY');
	static MARCH = new Month('MARCH');
	static APRIL = new Month('APRIL');
	static MAY = new Month('MAY');
	static JUNE = new Month('JUNE');
	static JULY = new Month('JULY');
	static AUGUST = new Month('AUGUST');
	static SEPTEMBER = new Month('SEPTEMBER');
	static OCTOBER = new Month('OCTOBER');
	static NOVEMBER = new Month('NOVEMBER');
	static DECEMBER = new Month('DECEMBER');

	month: number;

	constructor(name: string) {
		switch (name) {
			case 'JANUARY':
				this.month = 1;
				break;
			case 'FEBRUARY':
				this.month = 2;
				break;
			case 'MARCH':
				this.month = 3;
				break;
			case 'APRIL':
				this.month = 4;
				break;
			case 'MAY':
				this.month = 5;
				break;
			case 'JUNE':
				this.month = 6;
				break;
			case 'JULY':
				this.month = 7;
				break;
			case 'AUGUST':
				this.month = 8;
				break;
			case 'SEPTEMBER':
				this.month = 9;
				break;
			case 'OCTOBER':
				this.month = 10;
				break;
			case 'NOVEMBER':
				this.month = 11;
				break;
			case 'DECEMBER':
				this.month = 12;
				break;
			default:
				throw new Error('Not valid month name');
		}
	}

	getValue() {
		return this.month;
	}

	static fromJSON(value: ?any) {
		if (value == null) {
			return null;
		}
		if (_.isString(value)) {
			return Month.valueOf(value);
		} else {
			return Month.of(value.month);
		}
	}

	toJSON() {
		return Month.enumName(this.month);
	}

	toMoment(): moment$Moment {
		return global.moment.utc({
			month: this.month - 1
		});
	}

	static fromMoment(moment: moment$Moment): Month {
		return Month.of(moment.month() + 1);
	}

	static of(month: number) {
		checkValidInclusive(MONTHS_PER_YEAR, month);
		return Month.values()[month - 1];
	}

	static valueOf(name: string): Month {
		switch (name) {
			case 'JANUARY':
				return Month.JANUARY;
			case 'FEBRUARY':
				return Month.FEBRUARY;
			case 'MARCH':
				return Month.MARCH;
			case 'APRIL':
				return Month.APRIL;
			case 'MAY':
				return Month.MAY;
			case 'JUNE':
				return Month.JUNE;
			case 'JULY':
				return Month.JULY;
			case 'AUGUST':
				return Month.AUGUST;
			case 'SEPTEMBER':
				return Month.SEPTEMBER;
			case 'OCTOBER':
				return Month.OCTOBER;
			case 'NOVEMBER':
				return Month.NOVEMBER;
			case 'DECEMBER':
				return Month.DECEMBER;
			default:
				throw new Error('Not valid month name');
		}
	}

	static enumName(month: number): string {
		switch (month) {
			case 1:
				return 'JANUARY';
			case 2:
				return 'FEBRUARY';
			case 3:
				return 'MARCH';
			case 4:
				return 'APRIL';
			case 5:
				return 'MAY';
			case 6:
				return 'JUNE';
			case 7:
				return 'JULY';
			case 8:
				return 'AUGUST';
			case 9:
				return 'SEPTEMBER';
			case 10:
				return 'OCTOBER';
			case 11:
				return 'NOVEMBER';
			case 12:
				return 'DECEMBER';
			default:
				throw new Error('Not valid month number');
		}
	}

	static values(): Array < Month > {
		return [Month.JANUARY, Month.FEBRUARY, Month.MARCH, Month.APRIL, Month.MAY,
			Month.JUNE, Month.JULY, Month.AUGUST, Month.SEPTEMBER, Month.OCTOBER,
			Month.NOVEMBER, Month.DECEMBER
		];
	}

	getDisplayName(style: TextStyle): string {
		switch (style) {
		case TextStyle.FULL:
		case TextStyle.FULL_STANDALONE:
			return global.moment.months()[this.month - 1];
		case TextStyle.SHORT:
		case TextStyle.SHORT_STANDALONE:
			return global.moment.monthsShort()[this.month - 1];
		default:
			throw new Error('Unsupported style kind');
		}
	}

	get(field: ChronoField): number {
		if (field == ChronoField.MONTH_OF_YEAR) {
			return this.month;
		}
		throw new Error('Invalid field');
	}

	isSupported(field: ChronoField): boolean {
		return Month.supportedFields.indexOf(field) > -1;
	}

	compareTo(other: Month): number {
		return this.month - other.month;
	}

	static supportedFields = [
		ChronoField.MONTH_OF_YEAR
	];

	static getMethodSupportedEnumValues = Month.supportedFields;
}

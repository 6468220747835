/* @flow */
import utils from '../../common/components/utils.js';


let _parsePattern = (() => {
	let mem = {}; //memorization, ot to to parse same pattern lot's of times
	return pattern => {
		if (mem[pattern]) {
			return mem[pattern];
		}
		let options = {
			prefix: '',
			suffix: '',
			minimumIntegerDigits: 1,
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
			isPercentage: false,
			groupSize: 0
		};

		let str = pattern;
		for (var i=0; i<pattern.length; i++) {
			if (pattern.charAt(i) == '#' || pattern.charAt(i) == '0') {
				options.prefix = pattern.substring(0,i);
				str = pattern.substring(i);
				break;
			}
		}

		options.suffix = str.replace(/[#]|[0]|[,]|[.]/g , '');
		let num = str.replace(/[^0#,.]/g , '');
		let point = num.indexOf('.');
		let int = point == -1 ? num : num.substring(0, point);
		let frac = point == -1 ? '' : num.substring(point + 1);

		let comma = int.lastIndexOf(',');
		if (comma != -1) {
			options.groupSize = int.length - comma + 1;
		}
		int = int.replace(/[,]/g, '');
		frac = frac.replace(/[,]|[.]+/g , '');

		options.maximumFractionDigits = frac.length;
		let zeros = int.replace(/[^0]/g , '');
		options.minimumIntegerDigits = Math.max(1, zeros.length);
		let fracZeros = frac.replace(/[^0]/g , '');
		options.minimumFractionDigits = fracZeros.length;

		options.isPercentage = options.suffix && options.suffix.charAt(0) === '%';

		return mem[pattern] = options;
	}
})();


export default class NumberFormat  {

	static formatNumber(
			modelValue: number,
			pattern: string,
			primitiveType: string,
			isEditMode: boolean,
			scale: number,
			local: string): string {

		if (modelValue == null || isNaN(modelValue)) {
			//console.log("formatNumber: NaN");
			return "";
		}

		let patternOptions = _parsePattern(pattern);

		if (isNaN(scale)) {
			scale = 0;
		}

		let numberFormatterOptions = NumberFormat.getFormatterOptions(primitiveType, isEditMode, Number(scale), patternOptions);
		if(global.globalize.locale() == "in" && !local) {
			local = "id";
			global.globalize.locale("id");
		}
		var numberFormatter = local ? global.globalize[Globalize._runtimeKey('numberFormatter', local, [numberFormatterOptions])]
									: global.globalize.numberFormatter(numberFormatterOptions);
			if (!numberFormatter) {
			console.log(`Pattern ${pattern} not supported in client side. Use fallback pattrn`);
			numberFormatter = local ? global.globalize[Globalize._runtimeKey('numberFormatter', local, [NumberFormat.getFormatterOptions(primitiveType, isEditMode, Number(scale), null)])]
									: global.globalize.numberFormatter(
					NumberFormat.getFormatterOptions(primitiveType, isEditMode, Number(scale), null));
		}
		var resultValue = numberFormatter(Number(modelValue));
		//console.log("formatNumber: " + modelValue + " / " + resultValue + " / " + scale);
		if (!isEditMode) {
			resultValue = patternOptions.prefix + resultValue + patternOptions.suffix;
		}
		return resultValue;
	}

	static parseNumber(formValue: string, primitiveType: string, scale: number): number {
		if(global.globalize.locale() == "in" && !local) {
			local = "id";
			global.globalize.locale("id");
		}
		var currentLocalNumberParser = global.globalize.numberParser(
				NumberFormat.getParserOptions(primitiveType, true));
		var resultValue = NaN;
		try {
			formValue = formValue.replace(/\s/g, "");
			let replacedFormValue = formValue.replace(/[,.]/g, NumberFormat.getDecimalSymbol());
			resultValue = currentLocalNumberParser(replacedFormValue);
				if (isNaN(resultValue)) {
					var enLocalNumberParser = global.globalize[Globalize._runtimeKey('numberParser', "en", [NumberFormat.getParserOptions(primitiveType, true)])]
					var formValueWithEnSymbol = formValue.replace(/[,.]/g, NumberFormat.getDecimalSymbol("en"));
					resultValue = enLocalNumberParser(formValueWithEnSymbol);
				}

				if (Math.abs(resultValue) > 999999999999999) {
					throw "The value module should be less than 10^15.";
				}
    	} catch (e) {
				resultValue = NaN;
			//console.log("parseNumber ex: " + e.toString());
		}

		// Cut extra decimal number for decimal type
		if (!isNaN(resultValue)
					&& primitiveType == 'DECIMAL'
					&& scale > 0) {
			let scaleMultiplier = Math.pow(10, scale);
			resultValue = resultValue * scaleMultiplier;
			let epsilonValue = resultValue * 2 * Number.EPSILON;
			resultValue += epsilonValue;
			resultValue = Math.trunc(resultValue);
			resultValue = resultValue / scaleMultiplier;
		}

		//console.log("parseNumber formValue: " + formValue + " / " + resultValue);
		return resultValue;
	}





	static getFormatterOptions(primitiveType: string, isEditMode: boolean, scale: number, options: any): json {
		options = options || {
			minimumIntegerDigits: 1,
			minimumFractionDigits: (primitiveType == 'DECIMAL') ? scale : 0,
			maximumFractionDigits: 20,
			groupSize: 3
		};
		switch (primitiveType) {
			case 'DECIMAL':
			  return {
					minimumFractionDigits: (isEditMode ? 0 : options.minimumFractionDigits),
					maximumFractionDigits: (isEditMode ? 20 : options.maximumFractionDigits),
					useGrouping: (isEditMode ? false : options.groupSize !== 0)};
			case 'DOUBLE':
				return {
					minimumFractionDigits: (isEditMode ? 0 : options.minimumFractionDigits),
					maximumFractionDigits: (isEditMode ? 20 : options.maximumFractionDigits),
				 	useGrouping: (isEditMode ? false : options.groupSize !== 0)};
      case 'INTEGER':
				return {
					minimumIntegerDigits: isEditMode ? 1 : options.minimumIntegerDigits,
					useGrouping: (isEditMode ? false : options.groupSize !== 0)};
					default:
				return {};
    }
  }

	static getParserOptions(primitiveType: string, isEditMode: boolean): json {
    switch (primitiveType) {
      case 'DECIMAL':
			case 'DOUBLE':
        return {
					minimumSignificantDigits: 1,
					maximumSignificantDigits: 21,
					minimumFractionDigits: 0,
					maximumFractionDigits: 20 };
      case 'INTEGER':
				return {
					minimumSignificantDigits: 1,
					maximumSignificantDigits: 21 };
      default:
				return {};
    }
  }

	static getDecimalSymbol(local): string {
		if (local || NumberFormat.decimalSymbol == undefined) {
			let sampleValue = NumberFormat.formatNumber(1.1, '##0.00', 'DOUBLE', true, NaN, local);
			NumberFormat.decimalSymbol = sampleValue.replace(/\d/g,'')[0];
		}
		return NumberFormat.decimalSymbol;
	}
}

import MultilingualString from '../../common/models/multilingualString.js';
import BaseModel from '../../common/models/baseModel.js';
import EntityMenuItems from './entityMenuItems';
import PermissionFilterKind from '../../common/enums/permissionFilterKind';
import EntityMenuItemKind from '../../common/enums/entityMenuItemKind';
import MenuItemTypeActionKind from '../../common/enums/menuItemTypeActionKind';

export default class EntityMenu extends BaseModel {
	initialize (o) {
		if (o && o.name) {
			this.set('name', new MultilingualString(o.name));
		}
		if (o && o.menuItems) {
			this.set('menuItems', new EntityMenuItems(o.menuItems));
		} else {
			this.set('menuItems', new EntityMenuItems());
		}
		this.listenTo(this.get('name'), 'change',
				() =>  this.trigger('change:name', this));
	}

	update (data) {
		this.id = data.id;
		this.set('id', data.id);
		data.menuItems.forEach(
			i => {
				let item = this.get('menuItems').get(i.id);
				if (!item) {
					item = this.get('menuItems').get('c' + i.clientId);
				}
				item.id = i.id;
				item.set('id', i.id);
				item.get('text').set('id', i.text.id);
				item.get('permissionFilter').set('id', i.permissionFilter.id);
			});
		this.set('name', new MultilingualString(data.name));
		this.listenTo(this.get('name'), 'change',
				() =>  this.trigger('change:name', this));
		this.set('isTreeView', data.isTreeView);
		this.listenTo(this.get('isTreeView'), 'change',
				() => this.trigger('change:isTreeView', this));
	}
	getName () {
		return this.get('name');
	}

	getIsTreeView () {
		return this.get('isTreeView');
	}

	toServerJSON () {
		return {
			id: this.id,
			name: this.getName().toJSON(),
			isTreeView: this.getIsTreeView(),
			menuItems: this.get('menuItems') ? this.get('menuItems').toServerJSON() : [],
			block: this.get('block')
		};
	}

	_getLevel (item) {
		if (item.get('level')) {
			return item.get('level');
		}
		const level = item.get('parent') ? this._getLevel(item.get('parent')) + 1 : 1;
		item.set('level', level);
		return level;
	}

	toHTML(){
		if (this.getIsTreeView()){
			return this.toTreeViewHTML();
		} else{
			return this.toPageViewHTML();
		}
	}

	toPageViewHTML () {
		const $wrapper = $('<div class="site-menu-wrapper" />');
		this.get('menuItems').each(model => {
			const parent = model.get('parent');
			const level = this._getLevel(model);
			let $level = $wrapper.children(`div.menu-level[data-level="${level}"]`);
			if (!$level.length) {
				$level = $(`<div class="menu-level" data-level="${level}" />`)
					.appendTo($wrapper);
			}
			let $ul = $level.children(parent ?
					`ul.menu-list[data-parent="${parent.get('id')}"]` : 'ul.menu-list');
			if (!$ul.length) {
				$ul = $('<ul class="menu-list" />').appendTo($level);
				if (parent) {
					$ul.attr('data-parent', parent.get('id'));
				}
			}
			const $li = $(`<li id="${model.get('id')}"> class="site-menu-item"`)
				.attr('data-kind', model.get('kind'))
				.attr('data-order', model.get('relativeOrder'))
				.appendTo($ul);
			const $a = $('<a>').html(model.get('text').toObject())
				.appendTo($li);
			if (level === 1 && model.get('icon')) {
				let mirrorIconInRTLClass = model.get('mirrorIconInRTL') ? 'mirror-in-rtl' : '';
				$a.prepend(`<span class="item-icon fa ${model.get('icon')} ${mirrorIconInRTLClass}"/>`);
			}
			if (model.get('kind') == EntityMenuItemKind.FOLDER) {
				$a.append(`<span class="pull-right arrow">
					<span class="fa fa-angle-right mirror-in-rtl" />
				</span>`);
			}
			if (model.get('kind') == EntityMenuItemKind.EXTERNAL_LINK) {
				const url = model.get('url');
				if (url && url.startsWith('/')) {
					$a.attr('th:href', '@{' + url + '}')
				} else {
					$a.attr('href', url)
				}
				$a.attr('tabindex', "-1");
				const ext_prefs = ['https:', 'http:', 'www.', 'ftp:', 'mailto:'];
				if (url && ext_prefs.map(s => url.toLowerCase().startsWith(s)).some(i => i)) {
					$a.attr('target', '_blank');
					$a.attr('rel', 'noopener noreferrer');
				}
			} else if (model.get('kind') == EntityMenuItemKind.INTERNAL_LINK) {
				let link = '@{/entity';
				if (model.get('metaObject')) {
					if (model.get('systemInstanceType')) {
						link += model.get('systemInstanceType').id;
						link += '/update/';
					}
					link += model.get('metaObject').id;
				}
				if (model.get('typeAction') == MenuItemTypeActionKind.CREATE_NEW) {
					link += '/create';
				}
				if (model.get('view') && model.get('view').id) {
					if (!model.get('systemInstanceType') && model.get('typeAction') == MenuItemTypeActionKind.INDEX) {
						link += '?listViewId='
					} else {
						link += '?formViewId='
					}
					link += model.get('view').id
				}
				link += '}';
				$a.attr('th:href', link);
				$a.attr('tabindex', "-1");
			}
			$li.attr('data-unavailable-module-mode', model.get('unavailableModuleMode'))
			$li.attr('data-modules-mask', model.get('modulesMask'))
			$li.attr('data-permission-kind', model.get('permissionFilter').get('kind'));
			if (model.get('permissionFilter').get('kind') ==
				PermissionFilterKind.IN_ANY_OF_ROLES) {
				$li.attr('data-roles',
					model.get('permissionFilter').get('roles').join(' '));
			}
		});
		$wrapper.children().sort((a, b) => {
			const i = $(a).attr('data-level');
			const j = $(b).attr('data-level');
			return +i - +j;
		}).appendTo($wrapper).each((i, level) => {
			const $level = $(level);
			if (+$level.attr('data-level') > 1) {
				const $back = $('<div class="back">')
					.append('<span class="fa fa-angle-left mirror-in-rtl">')
					.append('<span class="back-text">').prependTo($level);
				$level.append($back.clone().addClass('back-down'));
			}
			if (+$level.attr('data-level') > 2) {
				$('<div class="back-to-start">')
				.append('<span class="fa fa-level-up fa-flip-horizontal mirror-in-rtl">')
				.append('<span class="back-to-start-text">').appendTo($level);
			}
			$level.children('ul').each((j, ul) => {
				const $ul = $(ul);
				$ul.children().sort((a,b) => {
					const i = $(a).attr('data-order');
					const j = $(b).attr('data-order');
					return +i - +j;
				}).appendTo($ul);
			});
		});
		return $wrapper.prop('outerHTML');
	}

	toTreeViewHTML(){
		const $wrapper = $('<ul class="sidebar-menu scrollable" style="height: calc(100vh - 50px)" />');
		let menuItems = this.get('menuItems');
		for (var i = 1; i <= 3; i++){
			menuItems.filter(model => +this._getLevel(model) === i).sort((a,b) => {
				const i = a.get('relativeOrder');
				const j = b.get('relativeOrder');
				return +i - +j;
			}).forEach(model => {
				const parent = model.get('parent');
				const level = this._getLevel(model);
					const $li = $(`<li id="${model.get('id')}"> class="site-menu-item"`)
						.attr('data-kind', model.get('kind'))
						.attr('data-order', model.get('relativeOrder'));
					const $a = $('<a>').html(model.get('text').toObject());
					if (+level === 1 && model.get('icon')) {
						let mirrorIconInRTLClass = model.get('mirrorIconInRTL') ? 'mirror-in-rtl' : '';
						$a.prepend(`<i class="item-icon fa ${model.get('icon')} ${mirrorIconInRTLClass}"/>`);
					}
					if (model.get('kind') == EntityMenuItemKind.FOLDER) {
						$a.append(`<div class="pull-right arrow">
								<span class="closed-arrow fa fa-angle-left mirror-in-rtl" />
								<span class="opened-arrow fa fa-angle-down" />
							</div>`);
					}
					if (model.get('kind') == EntityMenuItemKind.EXTERNAL_LINK) {
						const url = model.get('url');
						if (url && url.startsWith('/')) {
							$a.attr('th:href', '@{' + url + '}')
						} else {
							$a.attr('href', url)
						}
						$a.attr('tabindex', "-1");
						const ext_prefs = ['https:', 'http:', 'www.', 'ftp:', 'mailto:'];
						if (url && ext_prefs.map(s => url.toLowerCase().startsWith(s)).some(i => i)) {
							$a.attr('target', '_blank');
							$a.attr('rel', 'noopener noreferrer');
						}
					} else if (model.get('kind') == EntityMenuItemKind.INTERNAL_LINK) {
						let link = '@{/entity';
						if (model.get('metaObject')) {
							if (model.get('systemInstanceType')) {
								link += model.get('systemInstanceType').id;
								link += '/update/';
							}
							link += model.get('metaObject').id;
						}
						if (model.get('typeAction') == MenuItemTypeActionKind.CREATE_NEW) {
							link += '/create';
						}
						if (model.get('view') && model.get('view').id) {
							if (!model.get('systemInstanceType') && model.get('typeAction') == MenuItemTypeActionKind.INDEX) {
								link += '?listViewId='
							} else {
								link += '?formViewId='
							}
							link += model.get('view').id
						}
						link += '}';
						$a.attr('th:href', link);
						$a.attr('tabindex', "-1");
					}
						$a.appendTo($li);

					$li.attr('data-unavailable-module-mode', model.get('unavailableModuleMode'))
					$li.attr('data-modules-mask', model.get('modulesMask'))
					$li.attr('data-permission-kind', model.get('permissionFilter').get('kind'));
					if (model.get('permissionFilter').get('kind') ==
						PermissionFilterKind.IN_ANY_OF_ROLES) {
						$li.attr('data-roles',
							model.get('permissionFilter').get('roles').join(' '));
					}
				if (model.get('kind') == EntityMenuItemKind.FOLDER) {
					let $ul = $('<ul>').appendTo($li);
					if (+level === 1){
						$li.addClass('menu-sub-advanced treeview');
						$ul.addClass("advanced-sub-menus treeview-menu sidebar-menu");
					}else if(+level === 2){
						$li.addClass('menu-sub treeview');
						$ul.addClass("sub-menus");
					}else{
						$li.addClass('sub-menu treeview');
					}
				}
				if (parent){
					$li.appendTo($wrapper.find(`li[id="${parent.get('id')}"] > ul`)[0]);
				}else{
					$li.appendTo($wrapper);
				}
			})
		}
		return $wrapper.prop('outerHTML');
	}
}

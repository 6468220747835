/* @flow */
import BaseModel from './baseModel';
import BinaryKind from '../enums/binaryKind';

var Binary = BaseModel.extend({

	getKind: function() {
		return this.get('kind');
	},

	setKind: function(kind) {
		this.set('kind', kind);
	},

	setValue: function(value) {
		return this.set('value', value);
	},

	getValue: function() {
		return this.get('value');
	},

	setUrl: function (url) {
		this.set('url', url);
	},

	getUrl: function () {
		return this.get('url');
	},

	setInternalFileInfo: function (info) {
		if (this.get('kind') === BinaryKind.INTERNAL) {
			this.set('internalFileInfo', info instanceof BaseModel ? info : new BaseModel(info));
		}
	},

	getInternalFileInfo: function () {
		return this.get('internalFileInfo');
	},

	getFileName: function() {
		return this.get('fileName');
	},

	setFileName: function(name) {
		this.set('fileName', name);
	},

	clone: function() {
		let bin = new Binary();
		bin.setKind(this.getKind());
		bin.setFileName(this.getFileName());
		bin.setInternalFileInfo(this.getInternalFileInfo());
		bin.setUrl(this.getUrl());
		return bin;
	},

	merge: function (data) {
		this.id = data.id;
		this.setKind(data.kind);
		this.setUrl(data.url);
		this.setValue(data.value);
		this.setInternalFileInfo(data.internalFileInfo);
		this.setFileName(data.fileName);
	}

});

Binary.fromJSON = function(obj: ?any): Binary {
	return new Binary(obj);
};

export default Binary;

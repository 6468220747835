/* @flow */

import Duration from '../models/duration';
import Period from '../models/period';
import type {
	TemporalAmount
} from '../declarations';

import ChronoUnit from '../enums/chronoUnit';
import {
	HOURS_PER_DAY,
	MINUTES_PER_HOUR,
	SECONDS_PER_MINUTE
} from '../constants';

const START_STATE = 'START';
const ERROR_STATE = 'ERROR';
const END_STATE = 'END';

const ANY_SYMBOL = 'ANY';
const END_SYMBOL = 'END_SYMBOL';

class FormatStruct {
	tempString: string
	resultString: string
	currentChar: ? string
	temporalAmount: TemporalAmount
	pattern: ? string
	locale: ? string

	constructor() {
		this.tempString = '';
		this.resultString = '';
		this.currentChar = null;
		this.pattern = null;
		this.locale = null;
	}
}

let transitions = {};
let moves = {};
const chrs: Array < string > = ['s', 'S', 'm', 'i', 'h', 'H', 'd', 'M', 'y'];
const getChronoUnit = function(amount: TemporalAmount, unit: ChronoUnit,
	ofParentUnit: boolean): number {

	let result: number;
	switch (unit) {
		case ChronoUnit.DAYS:
			if (amount instanceof Duration) {
				return amount.toDays();
			} else {
				return amount.get(unit);
			}
		case ChronoUnit.HOURS:
			if (amount instanceof Duration) {
				result = amount.toHours();
			} else {
				result = amount.get(unit);
			}

			if (ofParentUnit) {
				return result % HOURS_PER_DAY;
			}

			return result;
		case ChronoUnit.MINUTES:

			if (amount instanceof Duration) {
				result = amount.toMinutes();
			} else {
				result = amount.get(unit);
			}

			if (ofParentUnit) {
				return result % MINUTES_PER_HOUR;
			}

			return result;
		case ChronoUnit.SECONDS:
			result = amount.get(unit);

			if (ofParentUnit) {
				return result % SECONDS_PER_MINUTE;
			}

			return result;
		default:
			return amount.get(unit);
	}
};

const printChronoUnit = function(st: FormatStruct, unit: ChronoUnit,
	width: string, ofParentUnit: boolean): string {

	global.globalize.locale(st.locale);
	switch (unit) {
		case ChronoUnit.DAYS:
			return global.globalize.unitFormatter('day', {
				form: width
			})(getChronoUnit(st.temporalAmount, unit, ofParentUnit));
		case ChronoUnit.HOURS:
			return global.globalize.unitFormatter('hour', {
				form: width
			})(getChronoUnit(st.temporalAmount, unit, ofParentUnit));
		case ChronoUnit.MINUTES:
			return global.globalize.unitFormatter('minute', {
				form: width
			})(getChronoUnit(st.temporalAmount, unit, ofParentUnit));
		case ChronoUnit.MONTHS:
			return global.globalize.unitFormatter('month', {
				form: width
			})(getChronoUnit(st.temporalAmount, unit, ofParentUnit));
		case ChronoUnit.SECONDS:
			return global.globalize.unitFormatter('second', {
				form: width
			})(getChronoUnit(st.temporalAmount, unit, ofParentUnit));
		case ChronoUnit.YEARS:
			return global.globalize.unitFormatter('year', {
				form: width
			})(getChronoUnit(st.temporalAmount, unit, ofParentUnit));
		default:
			throw new Error('Can\'t print unit ' + unit.toString());
	}
};

const getState = function(chr: string, number ? : number): string {
	return number ? number + chr + '_state' : chr + '_state';
};

const charToChronoUnit = function(chr: string): ChronoUnit {
	if (chr === 's' || chr === 'S') {
		return ChronoUnit.SECONDS;
	} else if (chr === 'm' || chr === 'i') {
		return ChronoUnit.MINUTES;
	} else if (chr === 'h' || chr === 'H') {
		return ChronoUnit.HOURS;
	} else if (chr === 'd') {
		return ChronoUnit.DAYS;
	} else if (chr === 'M') {
		return ChronoUnit.MONTHS;
	} else if (chr === 'y') {
		return ChronoUnit.YEARS;
	} else {
		throw new Error('Can\'t convert chr to unit' + chr);
	}
};

const init = function() {
	moves[ERROR_STATE] = {
		state: ERROR_STATE,
		action: st => console.error('Format error')
	};
	moves[END_STATE] = {
		state: END_STATE,
		action: st => {
			st.resultString += st.tempString;
			st.tempString = '';
		}
	};
	moves['any_start'] = {
		state: START_STATE,
		action: (st) => st.resultString += st.currentChar
	};

	moves['any_char'] = {
		state: START_STATE,
		action: (st) => {
			st.resultString += st.tempString;
			st.tempString = '';
			st.resultString += st.currentChar;
		}
	};
	chrs.forEach(function(chr) {
		let ofParentUnit: boolean = chr === 's' || chr === 'm' || chr === 'h';
		moves[getState(chr)] = {
			state: getState(chr),
			action: st => {
				st.resultString += st.tempString + ' ';
				st.tempString = printChronoUnit(st, charToChronoUnit(chr), 'short', ofParentUnit);
			}
		};

		moves[getState(chr, 2)] = {
			state: getState(chr, 2),
			action: st => st.tempString = printChronoUnit(st, charToChronoUnit(chr), 'long', ofParentUnit)
		};

		moves[getState(chr, 3)] = {
			state: getState(chr, 3),
			action: st => st.tempString = getChronoUnit(st.temporalAmount, charToChronoUnit(chr), ofParentUnit)
		};
	});

	transitions[START_STATE] = {};
	transitions[START_STATE][ANY_SYMBOL] = moves['any_start'];
	chrs.forEach(function(chr) {
		transitions[START_STATE][chr] = moves[getState(chr)];
	});
	transitions[START_STATE][END_SYMBOL] = moves[END_STATE];
	transitions[END_STATE] = {};
	transitions[ERROR_STATE] = {};

	chrs.forEach(function(chr1) {
		chrs.forEach(function(chr2) {
			if (chr1 === chr2) {
				transitions[getState(chr1)] = transitions[getState(chr1)] || {};
				transitions[getState(chr1)][chr1] = moves[getState(chr1, 2)];
				transitions[getState(chr1, 2)] = transitions[getState(chr1, 2)] || {};
				transitions[getState(chr1, 2)][chr1] = moves[getState(chr1, 3)];
				transitions[getState(chr1, 3)] = transitions[getState(chr1, 3)] || {};
				transitions[getState(chr1, 3)][chr1] = moves[ERROR_STATE];
			} else {
				transitions[getState(chr1)] = transitions[getState(chr1)] || {};
				transitions[getState(chr1)][chr2] = moves[getState(chr2)];
				transitions[getState(chr1, 2)] = transitions[getState(chr1, 2)] || {};
				transitions[getState(chr1, 2)][chr2] = moves[getState(chr2)];
				transitions[getState(chr1, 3)] = transitions[getState(chr1, 3)] || {};
				transitions[getState(chr1, 3)][chr2] = moves[getState(chr2)];
			}
		});
		transitions[getState(chr1)] = transitions[getState(chr1)] || {};
		transitions[getState(chr1)][END_SYMBOL] = moves[END_STATE];
		transitions[getState(chr1)][ANY_SYMBOL] = moves['any_char'];
		transitions[getState(chr1, 2)] = transitions[getState(chr1, 2)] || {};
		transitions[getState(chr1, 2)][END_SYMBOL] = moves[END_STATE];
		transitions[getState(chr1, 2)][ANY_SYMBOL] = moves['any_char'];
		transitions[getState(chr1, 3)] = transitions[getState(chr1, 3)] || {};
		transitions[getState(chr1, 3)][END_SYMBOL] = moves[END_STATE];
		transitions[getState(chr1, 3)][ANY_SYMBOL] = moves['any_char'];
	});
}();

export default class DateTimeAmountFormatter {

	struct: FormatStruct;

	constructor(pattern: string, locale: string) {
		this.struct = new FormatStruct();
		this.struct.pattern = pattern;
		this.struct.locale = locale;
	}

	static getChronoUnit(amount: TemporalAmount, unit: ChronoUnit,
		ofParentUnit: boolean): number {
		return getChronoUnit(amount, unit, ofParentUnit);
	}

	static ofPattern(pattern: string, locale: string): DateTimeAmountFormatter {
		return new DateTimeAmountFormatter(pattern, locale);
	}

	/*
	 * Symbol Meaning              Presentasion    Examples
	 * -----  -------              ------------    --------
	 *  s     second-of-minute     number/text     55 sec.; 55 seconds; 55;
	 *  S     second               number/text     120 sec.; 120 seconds; 120;
	 *  m     minute-of-hour       number/text     44 min.; 44 minutes; 44;
	 *  i     minute               number/text     144 min.; 144 minutes; 144;
	 *  h     hour-of-day          number/text     22 hr.; 22 hours; 22;
	 *  H     hour                 number/text     27 hr.; 27 hours; 27;
	 *  d     day                  number/text     35 da.; 35 days; 35;
	 *  M     month                number/text     201 mo.; 201 months; 201;
	 *  y     year                 number/text     10 yr.; 10 years; 10;
	 */
	format(temporalAmount: TemporalAmount): string {
		let positive = true
		if (temporalAmount === null || !this.struct.pattern) {
			return '';
		}
		if (temporalAmount instanceof Duration) {
			let millis = temporalAmount.toMillis()
			if (millis < 0) {
				millis = millis * (-1)
				temporalAmount = Duration.ofMillis(millis)
				positive = false
			}
		}
		this.struct.temporalAmount = temporalAmount;

		let currentState: string = START_STATE;

		for (let i:number = 0; i < this.struct.pattern.length; i++) {
			this.struct.currentChar = this.struct.pattern[i];
			if (transitions[currentState][this.struct.currentChar]) {
				transitions[currentState][this.struct.currentChar].action(this.struct);
				currentState = transitions[currentState][this.struct.currentChar].state;
			} else {
				transitions[currentState][ANY_SYMBOL].action(this.struct);
				currentState = transitions[currentState][ANY_SYMBOL].state;
			}
		}
		transitions[currentState][END_SYMBOL].action(this.struct);

		return positive ? this.struct.resultString : `- (${this.struct.resultString} )`;
	}

	parseDuration(value: string): Duration {

		const values = value.replace(/[^\/\d -]/g, ' ').trim().split(' ')
			.filter((v) => v !== '');
		let duration = Duration.ofSeconds(0);
		let currentChar = null;
		if(this.struct && this.struct.pattern) {
			for(let i = 0; i < this.struct.pattern.length; i++) {
				if(chrs.includes(this.struct.pattern[i]) &&
						currentChar !== this.struct.pattern[i]) {
						currentChar = this.struct.pattern[i];
						switch (this.struct.pattern[i]) {
							case 'd':
								if(!values.length){
									throw new Error('Wrong value!');
								}
								duration = duration.plusDays(Number.parseInt(values[0]));
								values.shift();
								break;
							case 'h':
							case 'H':
								if(!values.length){
									throw new Error('Wrong value!');
								}
								duration = duration.plusHours(Number.parseInt(values[0]));
								values.shift();
								break;
							case 'm':
							case 'i':
								if(!values.length){
									throw new Error('Wrong value!');
								}
								duration = duration.plusMinutes(Number.parseInt(values[0]));
								values.shift();
								break;
							case 's':
							case 'S':
								if(!values.length){
									throw new Error('Wrong value!');
								}
								duration = duration.plusSeconds(Number.parseInt(values[0]));
								values.shift();
								break;
							default:
								throw new Error('Wrong duration format!');
						}
				}
			}
		}

		return duration;
	}

	parsePeriod(value: string): Period {

		const values = value.replace(/[^\/\d -]/g,'').trim().split(' ');
		let period = Period.ofDays(0);
		let currentChar = null;
		if(this.struct && this.struct.pattern) {
			for(let i = 0; i < this.struct.pattern.length; i++) {
				if(chrs.includes(this.struct.pattern[i]) &&
						currentChar !== this.struct.pattern[i]) {
						currentChar = this.struct.pattern[i];
						switch (this.struct.pattern[i]) {
							case 'd':
								if(!values.length){
									throw new Error('Wrong value!');
								}
								period = period.plusDays(Number.parseInt(values[0]));
								values.shift();
								break;
							case 'M':
								if(!values.length){
									throw new Error('Wrong value!');
								}
								period = period.plusMonths(Number.parseInt(values[0]));
								values.shift();
								break;
							case 'y':
								if(!values.length){
									throw new Error('Wrong value!');
								}
								period = period.plusYears(Number.parseInt(values[0]));
								values.shift();
								break;
							default:
								throw new Error('Wrong duration format!');
						}
				}
			}
		}

		return period;
	}
}

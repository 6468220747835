import BaseCreateView from '../../common/views/baseCreateView';
import { checkRequired } from '../../common/components/utils'

export default class CreateView extends BaseCreateView {

	initialize(options) {
		super.initialize(options);
	}

	requiredCheck() {
		return checkRequired(this.model.toJSON(), this.$el)
	}

	getHTML() {
		 return `<div class="grid-1x-padding">
							<label for="name">${app.getResource('name')}</label>
							<span class="asterisk"> *</span>
						</div>
						<div class="grid-2x-padding">
							<input class="form-control" type="text" data-is-string-multilingual="short" data-field="name" data-required="true"/>
						</div>
						`;
	}

	saveConfiguration() {
			let data = this.model.toJSON();
			return data;
	}
}

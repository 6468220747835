import ModelFactory from '../models/modelFactory';
import Entity from '../models/entity.js';
import utils from '../components/utils';
import States from '../enums/states';

var Entities = Backbone.Collection.extend({

	comparator: 'relativeOrder',

	initialize: function (values, options) {
		this.entityTypeId = options.entityTypeId;
		this.model = ModelFactory.getModelType(app.types.get(this.entityTypeId));
		this.isExternal = app.types.get(this.entityTypeId).isExternal()
	},

	clear: function () {
		this.reset()
	},
	modelId: function (attr) {
		if (this.isExternal){
			return attr.urlString
		} else {
			return attr.id
		}
	},
	toServerJSON: function (originals) {
		var map = _.object(this.filter(function (item) {
			return item.get('clientState') != States.DELETED && !isNaN(item.id);
		}).map(function (item) {return [item.id, item];}));
		var originalMap = _.object(originals && originals.map(function (item) {
			return [item.id, item];
		}));
		return this.filter(function (i) {
			return (i.get('clientState') != States.DELETED) && (isNaN(i.id) ||
				!originalMap.hasOwnProperty(i.id) ||
				!utils.equals(i.toJSON(), originalMap[i.id], true));
		}).concat((originals && originals.filter(function (i) {
			return !map.hasOwnProperty(i.id);
		})) || []).map(function (i) {
			if (i.toServerJSON && ['AbstractTransient', 'AbstractEmbedded', 'AbstractExternal']
								.indexOf(i.get('className')) > -1) {
				i = i.toServerJSON(originalMap[i.id]);
			} else {
				i = _.pick(i, 'id');
			}
			i.clientState = isNaN(i.id) || !originalMap.hasOwnProperty(i.id) ?
				States.NEW : map.hasOwnProperty(i.id) ?
				States.UPDATED : States.DELETED;
			if (isNaN(i.id)) {
				i.id = null;
			}
			return i;
		});
	},

	asyncFind: async function (promiseFunc, context) {
		for (let i = 0, l = this.models.length; i < l; ++i) {
			const model = this.models[i];
			if (model.get('state') !== States.DELETED) {
				const result = await promiseFunc(model);
				if (result) {
					break;
				}
			}
		}
	},

	clone: function() {
		let modelsJson = _.map(this.models, model => (model.clonedJSON && model.clonedJSON()) || model.clone());
		return Entities.fromJSON(modelsJson, this.entityTypeId);
	},

	toReference() {
		return this.map(item => item.toReference());
	},

	contains(object) {
		for (let i = 0, l = this.models.length; i < l; ++i) {
			const model = this.models[i];
			if (model.equals) {
				if (model.equals(object)) {
					return true;
				}
			} else {
				if (model === object) {
					return true;
				}
			}
		}
		return false;
	}
});

Entities.fromJSON = function (arr, entityTypeId) {
	return new Entities(arr, {entityTypeId: entityTypeId});
};

export default Entities;

import Primitives from '../enums/primitiveType';

export default class utils {
	static getWiderType(type1: any, type2: any): any {
		if (type1 == type2) {
			return type1;
		} else if (this.isWiderThan(type1, type2)) {
			return type1;
		} else {
			return type2;
		}
	}

	static isWiderThan(type1: any, type2: any): boolean {
		if (Primitives[type1].widensTo.indexOf(type2) > -1) {
			return false;
		} else if (Primitives[type2].widensTo.indexOf(type1) > -1) {
			return true;
		} else {
			throw new Error('Incompatible types');
		}
	}

	static canSafelyCast(type1: any, type2: any): boolean {
		if (Primitives[type2].widensTo.indexOf(type1) > -1) {
			return true;
		} else {
			throw new Error('Cannot cast type ' + type2.toString() + ' to type ' + type1.toString());
		}
	}

	static canSafelyCastTo(type1: any, type2: any): boolean {
		try {
			return this.canSafelyCast(type2, type1);
		} catch (e) {
			return false;
		}
	}
}

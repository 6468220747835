import BlockTrigger from '../../common/enums/blockTrigger.js';
import BlockType from '../../common/enums/blockType.js';
import { icons } from '../../common/components/icons.js';
import {translate} from '../../common/service/stringResourceService'


export function buildBlockActions (field) {
	if (field) {
		let availableToCreateBlocks = '';
		let triggers = BlockTrigger.getTriggersForBlock(field);
		field.fieldEvents = field.get('fieldEvents');

		_.each(triggers, trigger => {
			let options = _.clone(icons[trigger]) || {};
			options.text = app.getResource(`on.${trigger.toLowerCase().replace(/_/g, '.')}`);
			options.popover = trigger.toLowerCase().replace(/_/g, '-') + '-block-desc';
			options.data = {
				fieldId: field.id,
				parentFieldId: (field.parent && field.parent.id) || '',
				trigger: trigger,
				type: BlockTrigger.getBlockType(trigger),
			};
			if (!field.fieldEvents[trigger]) {
				options.classes = 'createFieldEventBlock';
				options.hidden = true;
				options.title = translate('create.new.event.block');
			} else {
				options.classes = 'openFieldEventBlock';
				options.title = translate('open.event.block');
			}
			availableToCreateBlocks += getAction(options);
		});
		return availableToCreateBlocks
	}
};


export function buildTypeActions (allBlocks, triggers) {
	let availableToCreateBlocks = '';
	_.each(triggers, trigger => {
		let blocks = allBlocks.filter((block) => block.get("trigger") == trigger);

		if(blocks.length <= 1) {
			let options = _.clone(icons[trigger]) || {};
			options.deleteClass = 'deleteActionBlock';
			options.text = app.getResource(`on.${trigger.toLowerCase().replace(/_/g, '.')}`);
			options.popover = trigger.toLowerCase().replace(/_/g, '-') + '-block-desc';
			options.data = {
				trigger: trigger,
				type: BlockTrigger.getBlockType(trigger),
			};
			if (!blocks.length) {
				options.classes = 'createActionBlock';
				options.hidden = true;
				options.title = translate('create.new.event.block');
			} else {
				options.classes = 'openActionBlock';
				options.title = translate('open.event.block');
			}
			availableToCreateBlocks += getAction(options);
		} else {
			let options = _.clone(icons[trigger]) || {};
			options.classes = 'listMainActionBlock';
			options.deleteClass = 'deleteActionBlock';
			options.text = app.getResource(`on.${trigger.toLowerCase().replace(/_/g, '.')}`);
			options.popover = trigger.toLowerCase().replace(/_/g, '-') + '-block-desc';
			options.openClose = true;
			options.data = {
				trigger: trigger,
				type: BlockTrigger.getBlockType(trigger),
			};
			options.hidden = true;

			availableToCreateBlocks += `<li>${getIcon(options)}
			<ul class="hidden">`

			blocks.forEach(block => {
				options = _.clone(icons[trigger]) || {};
				options.classes = '';
				options.deleteClass = 'deleteActionBlock';
				options.openClass = 'openActionBlock';
				options.text = block.get("name");
				options.data = {
					blockid: block.get("id"),
					trigger: trigger,
					type: BlockTrigger.getBlockType(trigger),
				};

				availableToCreateBlocks += `<li class="listItemActionBlock">${getIcon(options)}</li>`
			});

			availableToCreateBlocks += `</ul></li>`
		}
		
	});
	return availableToCreateBlocks;
};

function getAction (options) {
	return `<li>${getIcon(options)}</li>`;
};

function getEventOptions (options) {
	return `<span class="pull-right dropdown event-option ${options.hidden ? "hidden" : ""}">
							<a class="dropdown-toggle" data-toggle="dropdown">
								<span class="glyphicon glyphicon-option-horizontal expand">
							</a>
							<ul class="dropdown-menu">
								<li class="${options.openClass ? options.openClass : 'openActionBlock'}" field-id="${options.data.fieldId}" data-blockid="${options.data.blockid}" data-trigger="${options.data.trigger}"><a>Open</a></li>
								<li class="${options.deleteClass ? options.deleteClass : 'deleteFieldEventBlock'}" field-id="${options.data.fieldId}"><a>Delete</a></li>
							</ul>
					</span>`;
};

function getIcon (options) {
	let classes = (options.classes && `class="${options.classes}"`) || '';
	let data = (options.data &&
		_.map(Object.keys(options.data), key => {
				return `data-${key}="${options.data[key]}"`;
			}).join(' ')) || '';
	let iconClasses = options.iconClasses || '';
	let icon = options.icon || '';
	let text = options.text || '';
	let href = options.href ? `href="${options.href}"` : '';
	let title = options.title ? `title="${options.title}"` : '';
	let popover = options.popover;
	let openClose = options.openClose;
	let iconData = ((iconClasses || icon) && `<i class="${iconClasses} ${options.classes}">${icon}</i>`) || '';
	return `<a ${href} ${classes} ${data} ${title}>${iconData}<span>${text}</span>${popover ? '<span help-popover="' + popover + '"><span class="help-image"/></span>' : ''}
	${openClose ? '<span class="pull-right open-close-icon"><span class="closed-icon fa fa-angle-right"></span><span class="open-icon fa fa-angle-down"></span></span>' : ''}</a>${getEventOptions(options)}`;
};

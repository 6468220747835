/* @flow */
import TypeKind from '../enums/typeKind';
import StringKind from '../enums/stringKind';
import Primitives from '../enums/primitiveType';
import {isAssignableTo} from '../components/typeHierarchy';

import type Field from './field';
import type Block from './block';

import  ViewModifier from '../enums/viewModifier';

export default class PrimitiveFormatter extends Backbone.Model {

	initialize(): void {
		if (this.get('id')) {
			this.set('id', this.get('id').toString());
		}
	}

	kind(): string {
		return this.get('kind');
	}

	pattern():string {
		return this.get('pattern');
	}

	isPredefined(): boolean {
		return this.get('isPredefined');
	}

	example(): string {
		return this.get('example');
	}

	primitiveType(): string {
		return this.get('primitiveType');
	}
}

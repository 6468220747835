import  Property from './property.js';
import  BaseCollection from '../../../common/collections/baseCollection.js';

var PropertiesCollection = BaseCollection.extend({
	model: Property,
	toServerJSON: function () {
		return this.models
			.filter(p => !p.get('transient'))
			.map(function (el) {
				return el.toServerJSON();
			});
	},
	modelId: function(attrs) {
		return attrs.key;
	}

});

export default PropertiesCollection;

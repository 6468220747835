/* @flow */

import ChronoField from '../enums/chronoField';
import TextStyle from '../enums/textStyle';

import {
	DAYS_PER_WEEK
} from '../constants';

import {
	checkValidInclusive
} from '../utils';

export default class DayOfWeek {
	static MONDAY = new DayOfWeek('MONDAY');
	static TUESDAY = new DayOfWeek('TUESDAY');
	static WEDNESDAY = new DayOfWeek('WEDNESDAY');
	static THURSDAY = new DayOfWeek('THURSDAY');
	static FRIDAY = new DayOfWeek('FRIDAY');
	static SATURDAY = new DayOfWeek('SATURDAY');
	static SUNDAY = new DayOfWeek('SUNDAY');

	dayOfWeek: number;

	constructor(name: string) {
		switch (name) {
			case 'MONDAY':
				this.dayOfWeek = 1;
				break;
			case 'TUESDAY':
				this.dayOfWeek = 2;
				break;
			case 'WEDNESDAY':
				this.dayOfWeek = 3;
				break;
			case 'THURSDAY':
				this.dayOfWeek = 4;
				break;
			case 'FRIDAY':
				this.dayOfWeek = 5;
				break;
			case 'SATURDAY':
				this.dayOfWeek = 6;
				break;
			case 'SUNDAY':
				this.dayOfWeek = 7;
				break;
			default:
				throw new Error('Not valid day of week name');
		}
	}

	static fromJSON(value: ?string) {
		if (value == null) {
			return null;
		}
		return DayOfWeek.valueOf(value);
	}

	toJSON(): ?string {
		return DayOfWeek.enumName(this.dayOfWeek);
	}

	toMoment(): moment$Moment {
		return global.moment.utc().isoWeekday(this.dayOfWeek);
	}

	static fromMoment(moment: moment$Moment): DayOfWeek {
		return DayOfWeek.of(moment.isoWeekday());
	}

	static of(dayOfWeek: number) {
		checkValidInclusive(DAYS_PER_WEEK, dayOfWeek);
		return DayOfWeek.values()[dayOfWeek - 1];
	}

	static valueOf(name: string) {
		switch (name) {
			case 'MONDAY':
				return DayOfWeek.MONDAY;
			case 'TUESDAY':
				return DayOfWeek.TUESDAY;
			case 'WEDNESDAY':
				return DayOfWeek.WEDNESDAY;
			case 'THURSDAY':
				return DayOfWeek.THURSDAY;
			case 'FRIDAY':
				return DayOfWeek.FRIDAY;
			case 'SATURDAY':
				return DayOfWeek.SATURDAY;
			case 'SUNDAY':
				return DayOfWeek.SUNDAY;
			default:
				throw new Error('Not valid day of week name');
		}
	}

	static enumName(weekday: number): string {
		switch (weekday) {
			case 1:
				return 'MONDAY';
			case 2:
				return 'TUESDAY';
			case 3:
				return 'WEDNESDAY';
			case 4:
				return 'THURSDAY';
			case 5:
				return 'FRIDAY';
			case 6:
				return 'SATURDAY';
			case 7:
				return 'SUNDAY';
			default:
				throw new Error('Not valid day of week number');
		}
	}

	static values(): Array < DayOfWeek > {
		return [DayOfWeek.MONDAY, DayOfWeek.TUESDAY, DayOfWeek.WEDNESDAY,
			DayOfWeek.THURSDAY, DayOfWeek.FRIDAY, DayOfWeek.SATURDAY,
			DayOfWeek.SUNDAY
		];
	}

	compareTo(other: DayOfWeek): number {
		return this.dayOfWeek - other.dayOfWeek;
	}

	getDisplayName(style: TextStyle): string {
		switch (style) {
		case TextStyle.FULL:
		case TextStyle.FULL_STANDALONE:
			return global.moment.weekdays()[this.dayOfWeek % 7];
		case TextStyle.SHORT:
		case TextStyle.SHORT_STANDALONE:
			return global.moment.weekdaysShort()[this.dayOfWeek % 7];
		default:
			throw new Error('Unsupported style kind');
		}
	}

	get(field: ChronoField): number {
		throw new Error('Not implemented');
	}
}

export default class RepeatableAction {

    constructor(period, action){
        this.period = period;
        this.action = action;
        this.run();
    }

    run(){
        this.future = setTimeout(this.run.bind(this), this.period, this);
        this.action();
    }

    cancel(){
        clearTimeout(this.future);
    }

}
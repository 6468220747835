import BaseControl from './baseControl.js';
import { translate } from '../service/stringResourceService'
import formatters from './formattersAndStyles.js';

const selection = item => item.html || item.text

var BaseSelect = BaseControl.extend({

	events: {
		'select2:open': 'open'
	},

	initialize: function (options) {
		BaseSelect.__super__.initialize.apply(this, arguments);
		this.options = options;
		this.createNewAction = options.createNewAction;
		this.templateSelection = options.templateSelection || selection;
		this.context = options.context;
		this.placeholder = this.$el.attr('placeholder')
		this.searchPlaceholder = this.$el.attr('search-placeholder')
		this.initializeSelect2(this.options);
	},

	initializeSelect2: function (options) {
		if (!options.dropdownParent) {
			let container = this.$el.parent().children('.container');
			if(container.length == 0) {
				this.$el.parent().append(`<div class='container' style='position:fixed; z-index:100;
					visibility: initial; padding: 0; margin: 0'></div>`);
			}
		
			options.dropdownParent = this.$el.parent().children('.container');
		}
		this.$el.select2({
			data: options.data || [],
			width: options.width || '100%',
			placeholder: this.placeholder || translate('select.item'),
			escapeMarkup: (markup) => {
				return markup;
			},
			sorter: data => {
				if (!Array.isArray(data)) return data
				const predicate = options.comparator || ((first, second) => {
					let _first = this.templateSelection(first)
					let _second = this.templateSelection(second)
					return formatters.predicate(_first, _second)
				});
				return data.sort(predicate);
			},
			templateResult: selection,
			templateSelection: this.templateSelection,
			allowClear: options.allowClear,
			dropdownParent: options.dropdownParent
		}).on('select2:open', (e) => {
			//Check if it is not IE. In IE placeholder doesn't work as expected
			if (navigator.userAgent.indexOf("Trident") == -1){
				$('.select2-search__field').attr('placeholder', this.searchPlaceholder || '')
			}
		}).on('select2:select', (e) => {
			this.convertTitle()
		})

		//With this fix, select doesn't work at all after clear.
		//if (!this.$el.attr('multiple')) {
		//	this.dontOpenDropdownAfterClear();
		//}
	},

	dontOpenDropdownAfterClear: function () {
		this.$el.on('select2:unselect', () => {
			this.$el.data('select2').options.options.disabled = true;
		});
	},

	render: function() {
	 	BaseControl.prototype.render.call(this);
		// Avoid tooltips when nothing is selected
		if (this.getDataFromModel() == null) {
			this.$el.next().find('.select2-selection__rendered').removeAttr('title');
		}
		this.convertTitle()
	},

	convertTitle: function() {
		if (this.model) {
			let title = this.$el.next().find('.select2-selection__rendered').attr('title')
			if (title) {
				if(this.model.get('convertTitle')) {
					let elem = $.parseHTML(title)
					this.$el.next().find('.select2-selection__rendered').attr('title', elem[0].textContent)
				} else if(this.options.withCustomTooltip) {
					let tooltip = this.model.get('values').find(value => value.id == this.model.get('value')).tooltip
					tooltip && this.$el.next().find('.select2-selection__rendered').attr('title', tooltip)
				}
			}
		}
	},

	setValue: function (value) {
		if (this.$el.attr('multiple')) {
			this.$el.val(value).trigger('change')
		} else {
			this.$el.val(value)
			if(value == this.$el.val())
			{
				this.$el.trigger('change');
			}
		}
	},

	getValue: function () {
		if (this.$el.attr('multiple')) {
			return this.$el.val() || [];
		} else {
			return this.$el.val();
		}
	},

	getDataFromModel: function () {
		return this.model && this.model.get(this.modelAttr);
	},

	setDataToModel: function (value) {
		if (this.model) {
			var oldValue = this.model.get(this.modelAttr);
			if (!_.isEqual(value, oldValue)) {
				this.model.set(this.modelAttr, value);
				this.model.trigger('manualChange:' + this.modelAttr, this.model)
			}
		}
	},

	rebuildSelect: function (data, initValue) {
		var val = (initValue !== undefined && initValue) || this.getValue();
		this.$el.find('option').remove();
		this.options.data = data;
		this.initializeSelect2(this.options);
		this.setValue(val);
	},

	disable: function () {
		this.$el.attr('disabled', 'disabled')
	},

	enable: function () {
		this.$el.removeAttr('disabled')
	},

	open: function () {
		var that = this;
		let createNew = $('.select2-results').find('.create-new');
		if (createNew.length){
			createNew.remove();
		}
		if (this.createNewAction) {
			var $a = $('<a>')
				.addClass('create-new btn btn-link')
				.attr('href', 'javascript: void(0)')
				.html(app.getResource('create.new'));
			$('.select2-results').prepend($a);
			$a.click(function (event) {
				that.$el.select2('close');
				that.createNewAction(event);
			});
		}
	}
});

export default BaseSelect;

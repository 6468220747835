/* @flow */
import BaseModel from './baseModel.js';

function isNotBlank(str) {
	return /\S/.test(str || '');
}

function isNotEmpty(str) {
	return str != null && str != '';
}

var MultilingualString = BaseModel.extend({

		initialize: function(options) {
				options = this._fix(options);
				MultilingualString.__super__.initialize.apply(this, [options]);
		},

		_fix: function (options) {
			if (options && !options.translations) {
				options.translations = {};
			} else if (!options) {
				options = {
					translations: {}
				};
			}
			return options;
		},

		clonedJSON: function() {
			const clone = {};
			if(this.getValue()) {
				clone.value = this.getValue();
			}
			clone.translations =  this.getTranslations().toJSON()
			return clone;
		},

		clone: function() {
				return new MultilingualString(this.clonedJSON());
		},

		clear: function() {
				this.set('id', null);
				this.setValue(null);
				this.getTranslations().clear();
		},

		setValue: function(value) {
				this.set('value', value);
		},

		getValue: function() {
				return this.get('value') || '';
		},

		setTranslations: function(translations) {
				var that = this;
				if (translations.toJSON) {
						translations = translations.toJSON();
				}

				_.each(_.keys(that.getTranslations().attributes), function(key) {
						if (!translations[key]) {
							that.getTranslations().set(key, undefined);
						}
				});
				_.each(translations, function(trans, key) {
						that.getTranslations().set(key, trans);
				});

		},

		getTranslations: function() {
				return this.get('translations');
		},

		setTranslation: function(lang, value) {
				const language = lang.languageTag ? lang : app.languages.find(l => l.id === lang.id) || {};
				this.getTranslations().set(language.languageTag, value);
				return this;
		},

		getTranslation: function(lang) {
				const language = lang.languageTag ? lang : app.languages.find(l => l.id === lang.id) || {};
				return this.getTranslations().get(language.languageTag) || '';
		},

		getFirstTranslation: function (withCurrentLanguage: ?boolean) : string {
			const translations = _.map(this.getTranslations().attributes, (val, lang) => {
					return {
						value: val,
						lang: lang
					}
			});
			return _.chain(translations)
				.filter((val) => {return withCurrentLanguage || val.lang !== app.currentLanguage || (val.lang == app.currentLanguage && translations.length == 1)})
				.filter(val => val.value)
				.map((val) => {
					const index = app && app.enabledLanguages ?
						app.enabledLanguages.map(l => l.languageTag).indexOf(val.lang) : -1;
					return {
						relativeOrder: index >= 0 ? index : Number.MAX_VALUE,
						value: val.value,
						lang: val.lang,
					}
				}).min(v => v.relativeOrder).value();
		},


		// language is string value
		getCurrentValue: function(language): string {
			language = language || this._getCurrentLanguage();
			const languageObj = app.enabledLanguages.find(lang => lang.languageTag === language) || {};
			let translation = this.getTranslation(languageObj);
			if (!translation) {
				let firstTranslation = this.getFirstTranslation(false)
				translation = firstTranslation && firstTranslation.value
				if (!translation) {
					translation = this.getValue()
				}
			}
			return translation
		},

		toJSON: function() {
				let res = {
						id: this.get('id'),
						value: this.getValue() == '' ? null : this.getValue(),
						translations: _.pick(this.getTranslations().toJSON(),
								(v, k) => _.where(app.enabledLanguages, {
										languageTag: k
								}).length)
				};
				return res;
		},

		toObject: function(addLanguageTag, containsHTML) {
			return $(this.toHTML(addLanguageTag, containsHTML));
		},



		toHTML: function(addLanguageTag, containsHTML, stringFormatter, oneLanguage): ?string {
				let string = this;
				var value = '';
				let languages
				const existingValues = app.enabledLanguages
				.map(l => l.languageTag)
				.filter(l => string.getTranslations().has(l))
				.filter(l => string.getTranslations().get(l) !== '' &&
				string.getTranslations().get(l) !== null)
				var buildValue = (l) => {
					const translationValue = stringFormatter
						? stringFormatter.format(string, l)
						: string.getTranslations().get(l);

					const translation = prepareForDisplay(translationValue, containsHTML);
					if (addLanguageTag || addLanguageTag === undefined) {
						value += `<span data-language="${l}">
								${translation +
									` <small class="lang-tag">${l}</small>`}
							</span>`
					} else {
						value += `<span data-language="${l}">
								${translation}
							</span>`
					}
					return l;
				}
				if (!oneLanguage){
					languages = _.chain(existingValues)
					.map(l => {
						return buildValue(l)
						}).value().join(' ');
						if (string.getValue()) {
							const defValue = stringFormatter
							? stringFormatter.format(string)
							: string.getValue();
							const strValue = prepareForDisplay(defValue, containsHTML);
							value += (strValue && `<span>${strValue}</span>`) || '';
						}
				} else {
					if (existingValues.includes(this._getCurrentLanguage())) {
						addLanguageTag = false
						languages = this._getCurrentLanguage()
					} else {
						languages = existingValues[0]
					}
					buildValue(languages)
				}
				return `<span class="translatable ${languages}"> ${value} </span>`;
		},
		_getCurrentLanguage () {
			return app.currentLanguage == "in" ? "id" : app.currentLanguage;
		},
		// Checks only enabled languages and value
		isBlank() {
			return this.findValue(isNotBlank) == null;
		},

		findValue(predicate) {
			if (predicate(this.getValue())) {
				return this.getValue();
			}
			return _.chain(app.enabledLanguages)
					.map(l => l.languageTag)
					.filter(l => this.getTranslations().has(l))
					.map(l => this.getTranslations().get(l))
					.find(predicate)
					.value();
		},

		isEmpty() {
			return this.findValue(isNotEmpty) == null;
		},

		merge: function (data) {
			data = this._fix(data)
			this.id = data.id;
			this.set('id', data.id);
			this.mergeWithoutId(data);
		},

		mergeWithoutId: function (data) {
			this.setValue(data.value);
			this.setTranslations(data.translations);
		},

		equals: function (object) {

			if (object === null) return false;

			if (!(object instanceof MultilingualString)) {
				throw 'The given object is not property of MultilingualString type';
			}

			const value1 = this.getValue();
			const value2 = object.getValue();

			if (value1 == null && value2 != null
					|| value1 != null && value2 == null
					|| value1 != null && value2 != null && value1 != value2) {
				return false;
			}

			const translations1 = this.getTranslations();
			const translations2 = object.getTranslations();
			const langs = $.extend(true,
						Object.keys(translations1.attributes),
						Object.keys(translations2.attributes));

			for (let lang of langs) {
				const val1 = translations1.get(lang);
				const val2 = translations2.get(lang);
				if (val1 === undefined && val2.trim() != ''
						|| val2 === undefined && val1.trim() != ''
						|| val1 !== undefined && val2 !== undefined && val1 !== val2) {
					return false;
				}
			}
			return true;
		},

		compareTo: function(object) {
			if (object == null) return 1

			if (!(object instanceof MultilingualString)) {
				throw 'The given object is not property of MultilingualString type'
			}

			const value1 = this.getCurrentValue()
			const value2 = object.getCurrentValue()
			return value1.localeCompare(value2)
		}

});

MultilingualString.fromJSON = (obj: ?any): MultilingualString => {
	return new MultilingualString(obj);
};

MultilingualString.fromStringInCurrentLanguage = (string): MultilingualString => {
	const obj = {
		translations: {
			[app.currentLanguage]: string
		}
	};
	return new MultilingualString(obj);
}

MultilingualString.fromString = (value): MultilingualString => {
	return new MultilingualString({value});
}

MultilingualString.getValue = (mlString): ?string => {
	if (mlString != null) {
		return mlString.getValue();
	}
	return null;
}

MultilingualString.getCurrentValue = (obj: ?any): ?string => {
	if (obj && obj.toJSON) {
		obj = obj.toJSON();
	}
	return (new MultilingualString(obj)).getCurrentValue();
};

MultilingualString.toHTML = (obj: ?any, containsHTML: boolean, oneLanguage: boolean): ?string => {
	return (new MultilingualString(obj)).toHTML(true, containsHTML, null, oneLanguage);
};

function prepareForDisplay(value, containsHTML) {
	if (containsHTML) {
		return value;
	}
	return _.escape(value);
}

export default MultilingualString;

/* @flow */

export default class UsageReferrer {
	static CONFIGURATION = 'CONFIGURATION';
	static ENTITY_TYPE = 'ENTITY_TYPE';
	static ENTITY_FIELD = 'ENTITY_FIELD';
	static ENTITY_BLOCK = 'ENTITY_BLOCK';
	static ENTITY_VIEW = 'ENTITY_VIEW';
	static SHARED_FIELDS_TABLE = 'SHARED_FIELDS_TABLE';
	static SHARED_FIELD = 'SHARED_FIELD';
	static PERMISSION = 'PERMISSION';
	static ENTITY_ROUTE = 'ENTITY_ROUTE';
	static ENTITY_MENU = 'ENTITY_MENU';
	static API_ENTRY = 'API_ENTRY';
	static ENTITY_MENU_ITEM = 'ENTITY_MENU_ITEM';
}

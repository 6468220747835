import MultilingualString from '../../common/models/multilingualString.js';
import BaseSelect from '../../common/components/baseSelect';

const buttonGroupHtml = () => `
<div class="btn-group" role="group">
	<div class="widget-sorting-dropdown btn-group" role="group">
		<button class="add-sorting btn btn-default dropdown-toggle" data-toggle="dropdown"
			aria-haspopup="true" aria-expanded="false">
			<span class="fa fa-unsorted"></span>
		</button>
		<ul class="dropdown-menu sorting-dropdown-menu" style="padding-top: 0; padding-bottom: 0">
			<li>
				<div class="box box-widget box-nomargin" style="width: 265px;">
					<div class="box-body">
						<div class="row">
							<div class="col-md-12">
								<div class="inline-block">
									<select class="fields" data-width="200"></select>
								</div>
								<div class="dropdown inline-block">
									<button class="select-order btn btn-default dropdown-toggle" data-toggle="dropdown"
										aria-haspopup="true" aria-expanded="false" order-value="NULL">
									</button>
									<ul class="dropdown-menu order">
									</ul>
								</div>
							</div>
						</div>
						<div class="row" style="margin-top: 5px">
							<div class="col-md-12">
								<button class="pull-left btn btn-default cancel-sorting">${app.getResource('cancel')}</button>
								<button class="pull-right btn btn-primary apply-sorting">${app.getResource('apply')}</button>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</div>`;


var WidgetSorting = Backbone.View.extend({

  buttonGroupHtml: buttonGroupHtml,

  orderOptions: ['ASC', 'DESC', 'NULL'],

  orderMapToChar: {
    'ASC': '▲',
    'DESC': '▼',
    'NULL': '∅'
  },
	orderMapToStyle: {
		'ASC': "margin-right: 10px;",
		'DESC': "margin-right: 10px;",
		'NULL': "margin-right: 13px; margin-left: 3px"
	},

  orderMapToText () {
    return {
    'ASC': app.getResource('order.ascending'),
    'DESC': app.getResource('order.descending'),
    'NULL': app.getResource('not.sorted')
    }
  },

  orderMapToIcon: {
    'ASC': 'fa fa-sort-asc',
    'DESC': 'fa fa-sort-desc',
    'NULL': 'fa fa-unsorted',
  },

  initialize: function (options) {
    this.field = options.field;
    this.fieldId = options.fieldId;
    this.table = options.table;
    this.model = options.model;
    this.typeId = options.typeId;
    this.collection = this.table.getTableSortingFields()
    this.model.getViewContext && this.model.getViewContext().getOptionsForField(app.fields.get(this.fieldId)).on('change:order', () => {
      this.setSortingFromModel()
      this.updateIcon()
    })
  },

  render: function () {
    this.$el.html(buttonGroupHtml());
    this.dropdown = this.$el.find('.sorting-dropdown-menu');
    this.selectOrderBtn = this.$el.find('.select-order');
    this._initializeDropdownEvents();
    this.fieldSelect = this.buildSortingFieldSelect();
    this.buildSortingOrderSelect();
    this.setSortingFromModel();
    this.updateIcon();
  },

  buildSortingFieldSelect: function () {
    var data = [];
    var $select = this.dropdown.find('.fields');
    _.each(this.collection, (value) => {
      data.push({
        id: value.fieldId,
        text: MultilingualString.getCurrentValue(app.fields.get(value.fieldId).get('name'))
      });
    });
    return new BaseSelect({el:$select, data:data});
  },

  buildSortingOrderSelect: function () {
    var $ul = this.dropdown.find('.order');
    $ul.empty();
    this.orderOptions.forEach(value => {
      const $li = $('<li>');
      $li.attr('data-value', value);
      $li.append($('<a style="cursor: pointer;">')
        .html(`<span style="${this.orderMapToStyle[value]}">` + this.orderMapToChar[value] +
        '</span> ' + this.orderMapToText()[value]));
      $ul.append($li);
    })
  },

  _initializeDropdownEvents: function () {
    this.selectOrderBtn.click((e) => {
      this.selectOrderBtn.parent().toggleClass('open')
      e.stopPropagation()
    });

    this.$el.find('.add-sorting').click((e) => {
      if (!this.dropdown.parent().hasClass('open')){
        this.setSortingFromModel()
      }
    })

    this.dropdown.find('.order').on('click', 'li', (e) => this.orderChanged(e));
    this.dropdown.find('.apply-sorting').click((e) => this.applySortingClicked(e));
    this.dropdown.find('.cancel-sorting').click((e) => this.closeDropdown());

    this.dropdown.find('.col-md-12').on('click', (e) => {e.stopPropagation()})
  },

  closeDropdown: function () {
    this.dropdown.parent().removeClass('open');
  },

  setSortingFromModel: function () {
    if (!app.builderMode) {
      let order = this.model.getViewContext().getOptionsForField(app.fields.get(this.fieldId)).getOrder()
      if (order){
        this.fieldSelect.setValue(order.field.id)
        this.setOrderToSelect(order.ascendingOrder ? 'ASC' : 'DESC')
      } else {
        this.setOrderToSelect('NULL')
      }
    }
  },

  orderChanged: function (e) {
    this.setOrderToSelect($(e.currentTarget).attr('data-value'))
    this.selectOrderBtn.parent().removeClass('open');
    e.stopPropagation()
  },

  setOrderToSelect: function (newOrderValue) {
    this.selectOrderBtn.text(this.orderMapToChar[newOrderValue]);
    this.selectOrderBtn.attr('order-value', newOrderValue)
  },

  applySortingClicked: function () {
    this.model.getViewContext().getOptionsForField(app.fields.get(this.fieldId)).setOrder(this.toJSON())
    this.updateIcon();
    this.closeDropdown();
    this.table.state.setSorting(this.toCompactJSON())
    this.table.orderChanged(this.toJSON());
  },

  updateIcon: function () {
    let $icon = this.$el.find('.add-sorting').first().children().first()
    $icon.removeClass()
    $icon.addClass(this.orderMapToIcon[this.selectOrderBtn.attr('order-value')])
  },

	toCompactJSON: function () {
		let orderValue = this.selectOrderBtn.attr('order-value')
		if (orderValue == 'NULL'){
			return null
		}
		let fieldId = this.dropdown.find('.fields').val()
		return {
			ascendingOrder: orderValue == 'ASC',
			field: {
				id: fieldId,
			},
		}
	},
  toJSON: function () {
    let orderValue = this.selectOrderBtn.attr('order-value')
    if (orderValue == 'NULL'){
      return null
    }
    let fieldId = this.dropdown.find('.fields').val()
    let field = app.fields.get(fieldId)
    return {
      ascendingOrder: orderValue == 'ASC',
      entityTypeId: null,
      field: {
        fieldTypeId: field.type(),
        id: fieldId,
        name: field.get('name')
      },
      id: null,
      relativeOrder: null
    }
  }
});

export default WidgetSorting;

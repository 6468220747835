/* @flow */
import { Service } from './service';
import Constants from '../models/constants';
import  Entity from '../models/entity';

class AppUserAccountService {
	getCurrentAppUserAccount() {
		return (app.currentUser && new Entity.reference(app.currentUser.id, Constants.ID_TYPE_APP_USER_ACCOUNT)) || null;
	}
}

export let appUserAccountService = new AppUserAccountService();

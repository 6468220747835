

export default class GroupBox extends Backbone.View {
	initialize(options) {
		this.id = this.$el.data('item-id');
		this.$el.find('> .box-header[data-collapse="true"]')
			.click(() => {
				if (this.$el.hasClass('collapsed-box')) {
					this.open();
					localStorage.setItem(this.generateKey(), 'true');
				} else {
					this.close();
					localStorage.setItem(this.generateKey(), 'false');
				}
			});
		let state = localStorage.getItem(this.generateKey());
		if (state !== null) {
			if (state === 'true') {
				this.open();
			} else {
				this.close();
			}
		}
	}
	
	open() {
		this.$el.removeClass('collapsed-box');
		this.$el.find('> .box-body').show();
		this.$el.find('> .box-header > div').first().removeClass('fa-plus').addClass('fa-minus');
	}
	
	close() {
		this.$el.addClass('collapsed-box');
		this.$el.find('> .box-body').hide();
		this.$el.find('> .box-header > div').first().removeClass('fa-minus').addClass('fa-plus');
	}
	
	generateKey() {
		return 'groupbox-' + app.configuration.id + '-' + this.id;
	}
	focus () {}
	
	disable () {}
	
	enable () {}
	
}
"use strict";
import { stringViewService } from '../../common/service/stringViewService';
import utils from '../../common/components/utils';
import formatter from '../../common/components/formattersAndStyles';
import { applicationState } from '../../common/components/applicationState';

export default class SearchBoxView extends Backbone.View {

    constructor () {
      super({
        el: '#prototype-navbar-collapse .search-wrapper',

        events: {
            'keyup #search-input': 'delayedReload',
            'keydown #search-input': 'keyPressed',
            'click #search-btn': 'searchBtnClick'
        }
      })
    }

    initialize () {
        let that = this;
        this.selectedItemIndex = -1;
        this.$btn = this.$el.find('#search-btn');
        this.$input = this.$el.find('#search-input');
        this.$dropdown = this.$el.find('#search-results');
        this.$box = this.$el.find('#search-box');
        this.$dropdownList = this.$dropdown.find('ul');
        $('body').click((e) => {
          if ($(e.target).parents('.search-wrapper').length == 0 && that.opened){
            this.searchExit();
          }
        });
        let mql = window.matchMedia('(max-width: 767px)');
        mql.onchange = function(e) {
          if (e.matches) {
            if (that.opened){
              that.searchExit()
            }
          }
        }
    }

    searchBtnClick () {
      if (window.matchMedia("(max-width: 767px)").matches){
        utils.redirectTo(app.urls.home + 'search',true)
      }else{
        this.opened = true;
        this.$btn.hide();
        this.$box.toggle();
        this.$input.focus();
      }
    }

    searchExit () {
      this.opened = false;
      this.closeDropdown();
      this.$box.toggle();
      this.$btn.show();
      this.$input.val('');
    }

    keyPressed (e) {
      if (e.which === 13){ // Enter
        this.handleSelectedItem();
      } else if (e.which === 27){ // esc
        this.setSelectedItem(-1);
      } else if (e.which === 38){ // up
        this.setSelectedItem(Math.max(-1, this.selectedItemIndex - 1));
      } else if (e.which === 40){ // down
        this.setSelectedItem(Math.min(this.$dropdownList.children().size()-1, this.selectedItemIndex + 1));
      } else if (e.which === 35){ // end
        this.setSelectedItem(this.$dropdownList.children().size()-1);
      } else if (e.which === 36){ // home
        this.setSelectedItem(0);
      }
    }

    setSelectedItem (index) {
      this.selectedItemIndex = index;
      this.$dropdownList.children().removeClass('list-item-hovered');
      $(this.$dropdownList.children()[index]).addClass('list-item-hovered');
    }

    handleSelectedItem () {
      let toBeClicked = this.selectedItemIndex;
      if (toBeClicked === -1){
        toBeClicked = this.$dropdownList.children().size()-1;
      }
      $(this.$dropdownList.children()[toBeClicked]).find('a')[0].click();
    }

    closeDropdown () {
        this.$dropdown.removeClass('open');
    }

    render (data) {
        let html = '';
        _.each(data, (v, ind) => {
            if (ind > 6) return true;
            html += `<li style="border-bottom: 1px solid #EBEBEB; overflow: hidden">
                  <a style="display: flex; align-items: center; height: 40px" href="${app.urls.update(v.metaObject.objectType && v.metaObject.objectType.id, v.metaObject.id)}">
                      <div class="row" style="vertical-align:middle">
                          <h4 style="display: inline; padding-right:10px; padding-left:10px">
                          D
                          </h4>
                          ${formatter.highlight(_.escape(v.value), this.$input.val())}
                      </div>
                  </a></li>`; // todo: remove D
        });
        if (data.length) {
            let query = applicationState.encode({q: this.$input.val()});
            html += `<li><a href="${app.urls.home + 'search?filterState=' + query}"><center>
                    ${app.getResource('show.all')}
                </center></a></li>`;
        } else {
            html += `<li><center>${app.getResource('no.results')}</center></li>`;
        }
        this.$dropdown.find('ul').html(html);
        this.$dropdown.addClass('open');
    }

    reload () {
        stringViewService.search({
                size: 50,
                pattern: this.$input.val()
            })
            .then(data => {
                this.selectedItemIndex = -1;
                this.render(data.items);
            });
    }

    delayedReload () {
        if (this.$input.val().length === this.prevLength){
          return;
        }
        this.prevLength = this.$input.val().length;
        if (!this.$input.val().length) {
            this.closeDropdown();
        } else {
            let that = this;
            if (this.future) {
                clearTimeout(this.future);
            }
            this.future = setTimeout(() => {
                that.reload();
            }, 500);
        }
    }

}

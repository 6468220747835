/* @flow */
import { getZoneId, getTimeZoneId } from '../../time/timezoneConstants';
import ZoneId from '../../time/models/zoneId';
import Entity from '../models/entity';
import Constants from '../models/constants';

export function toZoneId(timeZone: Entity): ZoneId {
	if (timeZone == null) {
		return null;
	}
	const zoneId = getZoneId(timeZone.id);
	return ZoneId.of(zoneId);
}

export function fromZoneId(zoneId: ZoneId): Entity {
	if (zoneId == null) {
		return null;
	}
	const timeZoneId = getTimeZoneId(zoneId.getId());
	return Entity.reference(timeZoneId, Constants.ID_TYPE_TIME_ZONE);
}

export function getDefaultTimeZone() {
	const timeZoneId = app.configuration && app.configuration.timeZone && app.configuration.timeZone.id;
	if (!timeZoneId) {
		return null;
	}
	return Entity.reference(timeZoneId, Constants.ID_TYPE_TIME_ZONE);
}

export function getBrowserTimeZone() {
	return fromZoneId(ZoneId.systemDefault());
}

export function getCurrentOffset() {
	let defaultTimeZone = getDefaultTimeZone();
	if (!defaultTimeZone) {
		return 0;
	}
	return toZoneId(defaultTimeZone).getRules().getOffset(app.classes.Instant.now());
}


const ID_SEQUENCE_OFFSET = Math.pow(2, 47);

const mapFromZoneId = {
	'Africa/Abidjan': ID_SEQUENCE_OFFSET + 800,
	'Africa/Accra': ID_SEQUENCE_OFFSET + 801,
	'Africa/Addis_Ababa': ID_SEQUENCE_OFFSET + 802,
	'Africa/Algiers': ID_SEQUENCE_OFFSET + 803,
	'Africa/Asmara': ID_SEQUENCE_OFFSET + 804,
	'Africa/Asmera': ID_SEQUENCE_OFFSET + 805,
	'Africa/Bamako': ID_SEQUENCE_OFFSET + 806,
	'Africa/Bangui': ID_SEQUENCE_OFFSET + 807,
	'Africa/Banjul': ID_SEQUENCE_OFFSET + 808,
	'Africa/Bissau': ID_SEQUENCE_OFFSET + 809,
	'Africa/Blantyre': ID_SEQUENCE_OFFSET + 810,
	'Africa/Brazzaville': ID_SEQUENCE_OFFSET + 811,
	'Africa/Bujumbura': ID_SEQUENCE_OFFSET + 812,
	'Africa/Cairo': ID_SEQUENCE_OFFSET + 813,
	'Africa/Casablanca': ID_SEQUENCE_OFFSET + 814,
	'Africa/Ceuta': ID_SEQUENCE_OFFSET + 815,
	'Africa/Conakry': ID_SEQUENCE_OFFSET + 816,
	'Africa/Dakar': ID_SEQUENCE_OFFSET + 817,
	'Africa/Dar_es_Salaam': ID_SEQUENCE_OFFSET + 818,
	'Africa/Djibouti': ID_SEQUENCE_OFFSET + 819,
	'Africa/Douala': ID_SEQUENCE_OFFSET + 820,
	'Africa/El_Aaiun': ID_SEQUENCE_OFFSET + 821,
	'Africa/Freetown': ID_SEQUENCE_OFFSET + 822,
	'Africa/Gaborone': ID_SEQUENCE_OFFSET + 823,
	'Africa/Harare': ID_SEQUENCE_OFFSET + 824,
	'Africa/Johannesburg': ID_SEQUENCE_OFFSET + 825,
	'Africa/Juba': ID_SEQUENCE_OFFSET + 826,
	'Africa/Kampala': ID_SEQUENCE_OFFSET + 827,
	'Africa/Khartoum': ID_SEQUENCE_OFFSET + 828,
	'Africa/Kigali': ID_SEQUENCE_OFFSET + 829,
	'Africa/Kinshasa': ID_SEQUENCE_OFFSET + 830,
	'Africa/Lagos': ID_SEQUENCE_OFFSET + 831,
	'Africa/Libreville': ID_SEQUENCE_OFFSET + 832,
	'Africa/Lome': ID_SEQUENCE_OFFSET + 833,
	'Africa/Luanda': ID_SEQUENCE_OFFSET + 834,
	'Africa/Lubumbashi': ID_SEQUENCE_OFFSET + 835,
	'Africa/Lusaka': ID_SEQUENCE_OFFSET + 836,
	'Africa/Malabo': ID_SEQUENCE_OFFSET + 837,
	'Africa/Maputo': ID_SEQUENCE_OFFSET + 838,
	'Africa/Maseru': ID_SEQUENCE_OFFSET + 839,
	'Africa/Mbabane': ID_SEQUENCE_OFFSET + 840,
	'Africa/Mogadishu': ID_SEQUENCE_OFFSET + 841,
	'Africa/Monrovia': ID_SEQUENCE_OFFSET + 842,
	'Africa/Nairobi': ID_SEQUENCE_OFFSET + 843,
	'Africa/Ndjamena': ID_SEQUENCE_OFFSET + 844,
	'Africa/Niamey': ID_SEQUENCE_OFFSET + 845,
	'Africa/Nouakchott': ID_SEQUENCE_OFFSET + 846,
	'Africa/Ouagadougou': ID_SEQUENCE_OFFSET + 847,
	'Africa/Porto-Novo': ID_SEQUENCE_OFFSET + 848,
	'Africa/Sao_Tome': ID_SEQUENCE_OFFSET + 849,
	'Africa/Timbuktu': ID_SEQUENCE_OFFSET + 850,
	'Africa/Tripoli': ID_SEQUENCE_OFFSET + 851,
	'Africa/Tunis': ID_SEQUENCE_OFFSET + 852,
	'Africa/Windhoek': ID_SEQUENCE_OFFSET + 853,
	'America/Adak': ID_SEQUENCE_OFFSET + 854,
	'America/Anchorage': ID_SEQUENCE_OFFSET + 855,
	'America/Anguilla': ID_SEQUENCE_OFFSET + 856,
	'America/Antigua': ID_SEQUENCE_OFFSET + 857,
	'America/Araguaina': ID_SEQUENCE_OFFSET + 858,
	'America/Argentina/Buenos_Aires': ID_SEQUENCE_OFFSET + 859,
	'America/Argentina/Catamarca': ID_SEQUENCE_OFFSET + 860,
	'America/Argentina/ComodRivadavia': ID_SEQUENCE_OFFSET + 861,
	'America/Argentina/Cordoba': ID_SEQUENCE_OFFSET + 862,
	'America/Argentina/Jujuy': ID_SEQUENCE_OFFSET + 863,
	'America/Argentina/La_Rioja': ID_SEQUENCE_OFFSET + 864,
	'America/Argentina/Mendoza': ID_SEQUENCE_OFFSET + 865,
	'America/Argentina/Rio_Gallegos': ID_SEQUENCE_OFFSET + 866,
	'America/Argentina/Salta': ID_SEQUENCE_OFFSET + 867,
	'America/Argentina/San_Juan': ID_SEQUENCE_OFFSET + 868,
	'America/Argentina/San_Luis': ID_SEQUENCE_OFFSET + 869,
	'America/Argentina/Tucuman': ID_SEQUENCE_OFFSET + 870,
	'America/Argentina/Ushuaia': ID_SEQUENCE_OFFSET + 871,
	'America/Aruba': ID_SEQUENCE_OFFSET + 872,
	'America/Asuncion': ID_SEQUENCE_OFFSET + 873,
	'America/Atikokan': ID_SEQUENCE_OFFSET + 874,
	'America/Atka': ID_SEQUENCE_OFFSET + 875,
	'America/Bahia': ID_SEQUENCE_OFFSET + 876,
	'America/Bahia_Banderas': ID_SEQUENCE_OFFSET + 877,
	'America/Barbados': ID_SEQUENCE_OFFSET + 878,
	'America/Belem': ID_SEQUENCE_OFFSET + 879,
	'America/Belize': ID_SEQUENCE_OFFSET + 880,
	'America/Blanc-Sablon': ID_SEQUENCE_OFFSET + 881,
	'America/Boa_Vista': ID_SEQUENCE_OFFSET + 882,
	'America/Bogota': ID_SEQUENCE_OFFSET + 883,
	'America/Boise': ID_SEQUENCE_OFFSET + 884,
	'America/Buenos_Aires': ID_SEQUENCE_OFFSET + 885,
	'America/Cambridge_Bay': ID_SEQUENCE_OFFSET + 886,
	'America/Campo_Grande': ID_SEQUENCE_OFFSET + 887,
	'America/Cancun': ID_SEQUENCE_OFFSET + 888,
	'America/Caracas': ID_SEQUENCE_OFFSET + 889,
	'America/Catamarca': ID_SEQUENCE_OFFSET + 890,
	'America/Cayenne': ID_SEQUENCE_OFFSET + 891,
	'America/Cayman': ID_SEQUENCE_OFFSET + 892,
	'America/Chicago': ID_SEQUENCE_OFFSET + 893,
	'America/Chihuahua': ID_SEQUENCE_OFFSET + 894,
	'America/Coral_Harbour': ID_SEQUENCE_OFFSET + 895,
	'America/Cordoba': ID_SEQUENCE_OFFSET + 896,
	'America/Costa_Rica': ID_SEQUENCE_OFFSET + 897,
	'America/Creston': ID_SEQUENCE_OFFSET + 898,
	'America/Cuiaba': ID_SEQUENCE_OFFSET + 899,
	'America/Curacao': ID_SEQUENCE_OFFSET + 900,
	'America/Danmarkshavn': ID_SEQUENCE_OFFSET + 901,
	'America/Dawson': ID_SEQUENCE_OFFSET + 902,
	'America/Dawson_Creek': ID_SEQUENCE_OFFSET + 903,
	'America/Denver': ID_SEQUENCE_OFFSET + 904,
	'America/Detroit': ID_SEQUENCE_OFFSET + 905,
	'America/Dominica': ID_SEQUENCE_OFFSET + 906,
	'America/Edmonton': ID_SEQUENCE_OFFSET + 907,
	'America/Eirunepe': ID_SEQUENCE_OFFSET + 908,
	'America/El_Salvador': ID_SEQUENCE_OFFSET + 909,
	'America/Ensenada': ID_SEQUENCE_OFFSET + 910,
	'America/Fort_Nelson': ID_SEQUENCE_OFFSET + 911,
	'America/Fort_Wayne': ID_SEQUENCE_OFFSET + 912,
	'America/Fortaleza': ID_SEQUENCE_OFFSET + 913,
	'America/Glace_Bay': ID_SEQUENCE_OFFSET + 914,
	'America/Godthab': ID_SEQUENCE_OFFSET + 915,
	'America/Goose_Bay': ID_SEQUENCE_OFFSET + 916,
	'America/Grand_Turk': ID_SEQUENCE_OFFSET + 917,
	'America/Grenada': ID_SEQUENCE_OFFSET + 918,
	'America/Guadeloupe': ID_SEQUENCE_OFFSET + 919,
	'America/Guatemala': ID_SEQUENCE_OFFSET + 920,
	'America/Guayaquil': ID_SEQUENCE_OFFSET + 921,
	'America/Guyana': ID_SEQUENCE_OFFSET + 922,
	'America/Halifax': ID_SEQUENCE_OFFSET + 923,
	'America/Havana': ID_SEQUENCE_OFFSET + 924,
	'America/Hermosillo': ID_SEQUENCE_OFFSET + 925,
	'America/Indiana/Indianapolis': ID_SEQUENCE_OFFSET + 926,
	'America/Indiana/Knox': ID_SEQUENCE_OFFSET + 927,
	'America/Indiana/Marengo': ID_SEQUENCE_OFFSET + 928,
	'America/Indiana/Petersburg': ID_SEQUENCE_OFFSET + 929,
	'America/Indiana/Tell_City': ID_SEQUENCE_OFFSET + 930,
	'America/Indiana/Vevay': ID_SEQUENCE_OFFSET + 931,
	'America/Indiana/Vincennes': ID_SEQUENCE_OFFSET + 932,
	'America/Indiana/Winamac': ID_SEQUENCE_OFFSET + 933,
	'America/Indianapolis': ID_SEQUENCE_OFFSET + 934,
	'America/Inuvik': ID_SEQUENCE_OFFSET + 935,
	'America/Iqaluit': ID_SEQUENCE_OFFSET + 936,
	'America/Jamaica': ID_SEQUENCE_OFFSET + 937,
	'America/Jujuy': ID_SEQUENCE_OFFSET + 938,
	'America/Juneau': ID_SEQUENCE_OFFSET + 939,
	'America/Kentucky/Louisville': ID_SEQUENCE_OFFSET + 940,
	'America/Kentucky/Monticello': ID_SEQUENCE_OFFSET + 941,
	'America/Knox_IN': ID_SEQUENCE_OFFSET + 942,
	'America/Kralendijk': ID_SEQUENCE_OFFSET + 943,
	'America/La_Paz': ID_SEQUENCE_OFFSET + 944,
	'America/Lima': ID_SEQUENCE_OFFSET + 945,
	'America/Los_Angeles': ID_SEQUENCE_OFFSET + 946,
	'America/Louisville': ID_SEQUENCE_OFFSET + 947,
	'America/Lower_Princes': ID_SEQUENCE_OFFSET + 948,
	'America/Maceio': ID_SEQUENCE_OFFSET + 949,
	'America/Managua': ID_SEQUENCE_OFFSET + 950,
	'America/Manaus': ID_SEQUENCE_OFFSET + 951,
	'America/Marigot': ID_SEQUENCE_OFFSET + 952,
	'America/Martinique': ID_SEQUENCE_OFFSET + 953,
	'America/Matamoros': ID_SEQUENCE_OFFSET + 954,
	'America/Mazatlan': ID_SEQUENCE_OFFSET + 955,
	'America/Mendoza': ID_SEQUENCE_OFFSET + 956,
	'America/Menominee': ID_SEQUENCE_OFFSET + 957,
	'America/Merida': ID_SEQUENCE_OFFSET + 958,
	'America/Metlakatla': ID_SEQUENCE_OFFSET + 959,
	'America/Mexico_City': ID_SEQUENCE_OFFSET + 960,
	'America/Miquelon': ID_SEQUENCE_OFFSET + 961,
	'America/Moncton': ID_SEQUENCE_OFFSET + 962,
	'America/Monterrey': ID_SEQUENCE_OFFSET + 963,
	'America/Montevideo': ID_SEQUENCE_OFFSET + 964,
	'America/Montreal': ID_SEQUENCE_OFFSET + 965,
	'America/Montserrat': ID_SEQUENCE_OFFSET + 966,
	'America/Nassau': ID_SEQUENCE_OFFSET + 967,
	'America/New_York': ID_SEQUENCE_OFFSET + 968,
	'America/Nipigon': ID_SEQUENCE_OFFSET + 969,
	'America/Nome': ID_SEQUENCE_OFFSET + 970,
	'America/Noronha': ID_SEQUENCE_OFFSET + 971,
	'America/North_Dakota/Beulah': ID_SEQUENCE_OFFSET + 972,
	'America/North_Dakota/Center': ID_SEQUENCE_OFFSET + 973,
	'America/North_Dakota/New_Salem': ID_SEQUENCE_OFFSET + 974,
	'America/Ojinaga': ID_SEQUENCE_OFFSET + 975,
	'America/Panama': ID_SEQUENCE_OFFSET + 976,
	'America/Pangnirtung': ID_SEQUENCE_OFFSET + 977,
	'America/Paramaribo': ID_SEQUENCE_OFFSET + 978,
	'America/Phoenix': ID_SEQUENCE_OFFSET + 979,
	'America/Port-au-Prince': ID_SEQUENCE_OFFSET + 980,
	'America/Port_of_Spain': ID_SEQUENCE_OFFSET + 981,
	'America/Porto_Acre': ID_SEQUENCE_OFFSET + 982,
	'America/Porto_Velho': ID_SEQUENCE_OFFSET + 983,
	'America/Puerto_Rico': ID_SEQUENCE_OFFSET + 984,
	'America/Rainy_River': ID_SEQUENCE_OFFSET + 985,
	'America/Rankin_Inlet': ID_SEQUENCE_OFFSET + 986,
	'America/Recife': ID_SEQUENCE_OFFSET + 987,
	'America/Regina': ID_SEQUENCE_OFFSET + 988,
	'America/Resolute': ID_SEQUENCE_OFFSET + 989,
	'America/Rio_Branco': ID_SEQUENCE_OFFSET + 990,
	'America/Rosario': ID_SEQUENCE_OFFSET + 991,
	'America/Santa_Isabel': ID_SEQUENCE_OFFSET + 992,
	'America/Santarem': ID_SEQUENCE_OFFSET + 993,
	'America/Santiago': ID_SEQUENCE_OFFSET + 994,
	'America/Santo_Domingo': ID_SEQUENCE_OFFSET + 995,
	'America/Sao_Paulo': ID_SEQUENCE_OFFSET + 996,
	'America/Scoresbysund': ID_SEQUENCE_OFFSET + 997,
	'America/Shiprock': ID_SEQUENCE_OFFSET + 998,
	'America/Sitka': ID_SEQUENCE_OFFSET + 999,
	'America/St_Barthelemy': ID_SEQUENCE_OFFSET + 1000,
	'America/St_Johns': ID_SEQUENCE_OFFSET + 1001,
	'America/St_Kitts': ID_SEQUENCE_OFFSET + 1002,
	'America/St_Lucia': ID_SEQUENCE_OFFSET + 1003,
	'America/St_Thomas': ID_SEQUENCE_OFFSET + 1004,
	'America/St_Vincent': ID_SEQUENCE_OFFSET + 1005,
	'America/Swift_Current': ID_SEQUENCE_OFFSET + 1006,
	'America/Tegucigalpa': ID_SEQUENCE_OFFSET + 1007,
	'America/Thule': ID_SEQUENCE_OFFSET + 1008,
	'America/Thunder_Bay': ID_SEQUENCE_OFFSET + 1009,
	'America/Tijuana': ID_SEQUENCE_OFFSET + 1010,
	'America/Toronto': ID_SEQUENCE_OFFSET + 1011,
	'America/Tortola': ID_SEQUENCE_OFFSET + 1012,
	'America/Vancouver': ID_SEQUENCE_OFFSET + 1013,
	'America/Virgin': ID_SEQUENCE_OFFSET + 1014,
	'America/Whitehorse': ID_SEQUENCE_OFFSET + 1015,
	'America/Winnipeg': ID_SEQUENCE_OFFSET + 1016,
	'America/Yakutat': ID_SEQUENCE_OFFSET + 1017,
	'America/Yellowknife': ID_SEQUENCE_OFFSET + 1018,
	'Antarctica/Casey': ID_SEQUENCE_OFFSET + 1019,
	'Antarctica/Davis': ID_SEQUENCE_OFFSET + 1020,
	'Antarctica/DumontDUrville': ID_SEQUENCE_OFFSET + 1021,
	'Antarctica/Macquarie': ID_SEQUENCE_OFFSET + 1022,
	'Antarctica/Mawson': ID_SEQUENCE_OFFSET + 1023,
	'Antarctica/McMurdo': ID_SEQUENCE_OFFSET + 1024,
	'Antarctica/Palmer': ID_SEQUENCE_OFFSET + 1025,
	'Antarctica/Rothera': ID_SEQUENCE_OFFSET + 1026,
	'Antarctica/South_Pole': ID_SEQUENCE_OFFSET + 1027,
	'Antarctica/Syowa': ID_SEQUENCE_OFFSET + 1028,
	'Antarctica/Troll': ID_SEQUENCE_OFFSET + 1029,
	'Antarctica/Vostok': ID_SEQUENCE_OFFSET + 1030,
	'Arctic/Longyearbyen': ID_SEQUENCE_OFFSET + 1031,
	'Asia/Aden': ID_SEQUENCE_OFFSET + 1032,
	'Asia/Almaty': ID_SEQUENCE_OFFSET + 1033,
	'Asia/Amman': ID_SEQUENCE_OFFSET + 1034,
	'Asia/Anadyr': ID_SEQUENCE_OFFSET + 1035,
	'Asia/Aqtau': ID_SEQUENCE_OFFSET + 1036,
	'Asia/Aqtobe': ID_SEQUENCE_OFFSET + 1037,
	'Asia/Ashgabat': ID_SEQUENCE_OFFSET + 1038,
	'Asia/Ashkhabad': ID_SEQUENCE_OFFSET + 1039,
	'Asia/Baghdad': ID_SEQUENCE_OFFSET + 1040,
	'Asia/Bahrain': ID_SEQUENCE_OFFSET + 1041,
	'Asia/Baku': ID_SEQUENCE_OFFSET + 1042,
	'Asia/Bangkok': ID_SEQUENCE_OFFSET + 1043,
	'Asia/Barnaul': ID_SEQUENCE_OFFSET + 1044,
	'Asia/Beirut': ID_SEQUENCE_OFFSET + 1045,
	'Asia/Bishkek': ID_SEQUENCE_OFFSET + 1046,
	'Asia/Brunei': ID_SEQUENCE_OFFSET + 1047,
	'Asia/Calcutta': ID_SEQUENCE_OFFSET + 1048,
	'Asia/Chita': ID_SEQUENCE_OFFSET + 1049,
	'Asia/Choibalsan': ID_SEQUENCE_OFFSET + 1050,
	'Asia/Chongqing': ID_SEQUENCE_OFFSET + 1051,
	'Asia/Chungking': ID_SEQUENCE_OFFSET + 1052,
	'Asia/Colombo': ID_SEQUENCE_OFFSET + 1053,
	'Asia/Dacca': ID_SEQUENCE_OFFSET + 1054,
	'Asia/Damascus': ID_SEQUENCE_OFFSET + 1055,
	'Asia/Dhaka': ID_SEQUENCE_OFFSET + 1056,
	'Asia/Dili': ID_SEQUENCE_OFFSET + 1057,
	'Asia/Dubai': ID_SEQUENCE_OFFSET + 1058,
	'Asia/Dushanbe': ID_SEQUENCE_OFFSET + 1059,
	'Asia/Gaza': ID_SEQUENCE_OFFSET + 1060,
	'Asia/Harbin': ID_SEQUENCE_OFFSET + 1061,
	'Asia/Hebron': ID_SEQUENCE_OFFSET + 1062,
	'Asia/Ho_Chi_Minh': ID_SEQUENCE_OFFSET + 1063,
	'Asia/Hong_Kong': ID_SEQUENCE_OFFSET + 1064,
	'Asia/Hovd': ID_SEQUENCE_OFFSET + 1065,
	'Asia/Irkutsk': ID_SEQUENCE_OFFSET + 1066,
	'Asia/Istanbul': ID_SEQUENCE_OFFSET + 1067,
	'Asia/Jakarta': ID_SEQUENCE_OFFSET + 1068,
	'Asia/Jayapura': ID_SEQUENCE_OFFSET + 1069,
	'Asia/Jerusalem': ID_SEQUENCE_OFFSET + 1070,
	'Asia/Kabul': ID_SEQUENCE_OFFSET + 1071,
	'Asia/Kamchatka': ID_SEQUENCE_OFFSET + 1072,
	'Asia/Karachi': ID_SEQUENCE_OFFSET + 1073,
	'Asia/Kashgar': ID_SEQUENCE_OFFSET + 1074,
	'Asia/Kathmandu': ID_SEQUENCE_OFFSET + 1075,
	'Asia/Katmandu': ID_SEQUENCE_OFFSET + 1076,
	'Asia/Khandyga': ID_SEQUENCE_OFFSET + 1077,
	'Asia/Kolkata': ID_SEQUENCE_OFFSET + 1078,
	'Asia/Krasnoyarsk': ID_SEQUENCE_OFFSET + 1079,
	'Asia/Kuala_Lumpur': ID_SEQUENCE_OFFSET + 1080,
	'Asia/Kuching': ID_SEQUENCE_OFFSET + 1081,
	'Asia/Kuwait': ID_SEQUENCE_OFFSET + 1082,
	'Asia/Macao': ID_SEQUENCE_OFFSET + 1083,
	'Asia/Macau': ID_SEQUENCE_OFFSET + 1084,
	'Asia/Magadan': ID_SEQUENCE_OFFSET + 1085,
	'Asia/Makassar': ID_SEQUENCE_OFFSET + 1086,
	'Asia/Manila': ID_SEQUENCE_OFFSET + 1087,
	'Asia/Muscat': ID_SEQUENCE_OFFSET + 1088,
	'Asia/Nicosia': ID_SEQUENCE_OFFSET + 1089,
	'Asia/Novokuznetsk': ID_SEQUENCE_OFFSET + 1090,
	'Asia/Novosibirsk': ID_SEQUENCE_OFFSET + 1091,
	'Asia/Omsk': ID_SEQUENCE_OFFSET + 1092,
	'Asia/Oral': ID_SEQUENCE_OFFSET + 1093,
	'Asia/Phnom_Penh': ID_SEQUENCE_OFFSET + 1094,
	'Asia/Pontianak': ID_SEQUENCE_OFFSET + 1095,
	'Asia/Pyongyang': ID_SEQUENCE_OFFSET + 1096,
	'Asia/Qatar': ID_SEQUENCE_OFFSET + 1097,
	'Asia/Qyzylorda': ID_SEQUENCE_OFFSET + 1098,
	'Asia/Rangoon': ID_SEQUENCE_OFFSET + 1099,
	'Asia/Riyadh': ID_SEQUENCE_OFFSET + 1100,
	'Asia/Saigon': ID_SEQUENCE_OFFSET + 1101,
	'Asia/Sakhalin': ID_SEQUENCE_OFFSET + 1102,
	'Asia/Samarkand': ID_SEQUENCE_OFFSET + 1103,
	'Asia/Seoul': ID_SEQUENCE_OFFSET + 1104,
	'Asia/Shanghai': ID_SEQUENCE_OFFSET + 1105,
	'Asia/Singapore': ID_SEQUENCE_OFFSET + 1106,
	'Asia/Srednekolymsk': ID_SEQUENCE_OFFSET + 1107,
	'Asia/Taipei': ID_SEQUENCE_OFFSET + 1108,
	'Asia/Tashkent': ID_SEQUENCE_OFFSET + 1109,
	'Asia/Tbilisi': ID_SEQUENCE_OFFSET + 1110,
	'Asia/Tehran': ID_SEQUENCE_OFFSET + 1111,
	'Asia/Tel_Aviv': ID_SEQUENCE_OFFSET + 1112,
	'Asia/Thimbu': ID_SEQUENCE_OFFSET + 1113,
	'Asia/Thimphu': ID_SEQUENCE_OFFSET + 1114,
	'Asia/Tokyo': ID_SEQUENCE_OFFSET + 1115,
	'Asia/Tomsk': ID_SEQUENCE_OFFSET + 1116,
	'Asia/Ujung_Pandang': ID_SEQUENCE_OFFSET + 1117,
	'Asia/Ulaanbaatar': ID_SEQUENCE_OFFSET + 1118,
	'Asia/Ulan_Bator': ID_SEQUENCE_OFFSET + 1119,
	'Asia/Urumqi': ID_SEQUENCE_OFFSET + 1120,
	'Asia/Ust-Nera': ID_SEQUENCE_OFFSET + 1121,
	'Asia/Vientiane': ID_SEQUENCE_OFFSET + 1122,
	'Asia/Vladivostok': ID_SEQUENCE_OFFSET + 1123,
	'Asia/Yakutsk': ID_SEQUENCE_OFFSET + 1124,
	'Asia/Yangon': ID_SEQUENCE_OFFSET + 1125,
	'Asia/Yekaterinburg': ID_SEQUENCE_OFFSET + 1126,
	'Asia/Yerevan': ID_SEQUENCE_OFFSET + 1127,
	'Atlantic/Azores': ID_SEQUENCE_OFFSET + 1128,
	'Atlantic/Bermuda': ID_SEQUENCE_OFFSET + 1129,
	'Atlantic/Canary': ID_SEQUENCE_OFFSET + 1130,
	'Atlantic/Cape_Verde': ID_SEQUENCE_OFFSET + 1131,
	'Atlantic/Faeroe': ID_SEQUENCE_OFFSET + 1132,
	'Atlantic/Faroe': ID_SEQUENCE_OFFSET + 1133,
	'Atlantic/Jan_Mayen': ID_SEQUENCE_OFFSET + 1134,
	'Atlantic/Madeira': ID_SEQUENCE_OFFSET + 1135,
	'Atlantic/Reykjavik': ID_SEQUENCE_OFFSET + 1136,
	'Atlantic/South_Georgia': ID_SEQUENCE_OFFSET + 1137,
	'Atlantic/St_Helena': ID_SEQUENCE_OFFSET + 1138,
	'Atlantic/Stanley': ID_SEQUENCE_OFFSET + 1139,
	'Australia/ACT': ID_SEQUENCE_OFFSET + 1140,
	'Australia/Adelaide': ID_SEQUENCE_OFFSET + 1141,
	'Australia/Brisbane': ID_SEQUENCE_OFFSET + 1142,
	'Australia/Broken_Hill': ID_SEQUENCE_OFFSET + 1143,
	'Australia/Canberra': ID_SEQUENCE_OFFSET + 1144,
	'Australia/Currie': ID_SEQUENCE_OFFSET + 1145,
	'Australia/Darwin': ID_SEQUENCE_OFFSET + 1146,
	'Australia/Eucla': ID_SEQUENCE_OFFSET + 1147,
	'Australia/Hobart': ID_SEQUENCE_OFFSET + 1148,
	'Australia/LHI': ID_SEQUENCE_OFFSET + 1149,
	'Australia/Lindeman': ID_SEQUENCE_OFFSET + 1150,
	'Australia/Lord_Howe': ID_SEQUENCE_OFFSET + 1151,
	'Australia/Melbourne': ID_SEQUENCE_OFFSET + 1152,
	'Australia/NSW': ID_SEQUENCE_OFFSET + 1153,
	'Australia/North': ID_SEQUENCE_OFFSET + 1154,
	'Australia/Perth': ID_SEQUENCE_OFFSET + 1155,
	'Australia/Queensland': ID_SEQUENCE_OFFSET + 1156,
	'Australia/South': ID_SEQUENCE_OFFSET + 1157,
	'Australia/Sydney': ID_SEQUENCE_OFFSET + 1158,
	'Australia/Tasmania': ID_SEQUENCE_OFFSET + 1159,
	'Australia/Victoria': ID_SEQUENCE_OFFSET + 1160,
	'Australia/West': ID_SEQUENCE_OFFSET + 1161,
	'Australia/Yancowinna': ID_SEQUENCE_OFFSET + 1162,
	'Brazil/Acre': ID_SEQUENCE_OFFSET + 1163,
	'Brazil/DeNoronha': ID_SEQUENCE_OFFSET + 1164,
	'Brazil/East': ID_SEQUENCE_OFFSET + 1165,
	'Brazil/West': ID_SEQUENCE_OFFSET + 1166,
	'CET': ID_SEQUENCE_OFFSET + 1167,
	'CST6CDT': ID_SEQUENCE_OFFSET + 1168,
	'Canada/Atlantic': ID_SEQUENCE_OFFSET + 1169,
	'Canada/Central': ID_SEQUENCE_OFFSET + 1170,
	'Canada/East-Saskatchewan': ID_SEQUENCE_OFFSET + 1171,
	'Canada/Eastern': ID_SEQUENCE_OFFSET + 1172,
	'Canada/Mountain': ID_SEQUENCE_OFFSET + 1173,
	'Canada/Newfoundland': ID_SEQUENCE_OFFSET + 1174,
	'Canada/Pacific': ID_SEQUENCE_OFFSET + 1175,
	'Canada/Saskatchewan': ID_SEQUENCE_OFFSET + 1176,
	'Canada/Yukon': ID_SEQUENCE_OFFSET + 1177,
	'Chile/Continental': ID_SEQUENCE_OFFSET + 1178,
	'Chile/EasterIsland': ID_SEQUENCE_OFFSET + 1179,
	'Cuba': ID_SEQUENCE_OFFSET + 1180,
	'EET': ID_SEQUENCE_OFFSET + 1181,
	'EST5EDT': ID_SEQUENCE_OFFSET + 1182,
	'Egypt': ID_SEQUENCE_OFFSET + 1183,
	'Eire': ID_SEQUENCE_OFFSET + 1184,
	'Etc/GMT': ID_SEQUENCE_OFFSET + 1185,
	'Etc/GMT+0': ID_SEQUENCE_OFFSET + 1186,
	'Etc/GMT+1': ID_SEQUENCE_OFFSET + 1187,
	'Etc/GMT+10': ID_SEQUENCE_OFFSET + 1188,
	'Etc/GMT+11': ID_SEQUENCE_OFFSET + 1189,
	'Etc/GMT+12': ID_SEQUENCE_OFFSET + 1190,
	'Etc/GMT+2': ID_SEQUENCE_OFFSET + 1191,
	'Etc/GMT+3': ID_SEQUENCE_OFFSET + 1192,
	'Etc/GMT+4': ID_SEQUENCE_OFFSET + 1193,
	'Etc/GMT+5': ID_SEQUENCE_OFFSET + 1194,
	'Etc/GMT+6': ID_SEQUENCE_OFFSET + 1195,
	'Etc/GMT+7': ID_SEQUENCE_OFFSET + 1196,
	'Etc/GMT+8': ID_SEQUENCE_OFFSET + 1197,
	'Etc/GMT+9': ID_SEQUENCE_OFFSET + 1198,
	'Etc/GMT-0': ID_SEQUENCE_OFFSET + 1199,
	'Etc/GMT-1': ID_SEQUENCE_OFFSET + 1200,
	'Etc/GMT-10': ID_SEQUENCE_OFFSET + 1201,
	'Etc/GMT-11': ID_SEQUENCE_OFFSET + 1202,
	'Etc/GMT-12': ID_SEQUENCE_OFFSET + 1203,
	'Etc/GMT-13': ID_SEQUENCE_OFFSET + 1204,
	'Etc/GMT-14': ID_SEQUENCE_OFFSET + 1205,
	'Etc/GMT-2': ID_SEQUENCE_OFFSET + 1206,
	'Etc/GMT-3': ID_SEQUENCE_OFFSET + 1207,
	'Etc/GMT-4': ID_SEQUENCE_OFFSET + 1208,
	'Etc/GMT-5': ID_SEQUENCE_OFFSET + 1209,
	'Etc/GMT-6': ID_SEQUENCE_OFFSET + 1210,
	'Etc/GMT-7': ID_SEQUENCE_OFFSET + 1211,
	'Etc/GMT-8': ID_SEQUENCE_OFFSET + 1212,
	'Etc/GMT-9': ID_SEQUENCE_OFFSET + 1213,
	'Etc/GMT0': ID_SEQUENCE_OFFSET + 1214,
	'Etc/Greenwich': ID_SEQUENCE_OFFSET + 1215,
	'Etc/UCT': ID_SEQUENCE_OFFSET + 1216,
	'Etc/UTC': ID_SEQUENCE_OFFSET + 1217,
	'Etc/Universal': ID_SEQUENCE_OFFSET + 1218,
	'Etc/Zulu': ID_SEQUENCE_OFFSET + 1219,
	'Europe/Amsterdam': ID_SEQUENCE_OFFSET + 1220,
	'Europe/Andorra': ID_SEQUENCE_OFFSET + 1221,
	'Europe/Astrakhan': ID_SEQUENCE_OFFSET + 1222,
	'Europe/Athens': ID_SEQUENCE_OFFSET + 1223,
	'Europe/Belfast': ID_SEQUENCE_OFFSET + 1224,
	'Europe/Belgrade': ID_SEQUENCE_OFFSET + 1225,
	'Europe/Berlin': ID_SEQUENCE_OFFSET + 1226,
	'Europe/Bratislava': ID_SEQUENCE_OFFSET + 1227,
	'Europe/Brussels': ID_SEQUENCE_OFFSET + 1228,
	'Europe/Bucharest': ID_SEQUENCE_OFFSET + 1229,
	'Europe/Budapest': ID_SEQUENCE_OFFSET + 1230,
	'Europe/Busingen': ID_SEQUENCE_OFFSET + 1231,
	'Europe/Chisinau': ID_SEQUENCE_OFFSET + 1232,
	'Europe/Copenhagen': ID_SEQUENCE_OFFSET + 1233,
	'Europe/Dublin': ID_SEQUENCE_OFFSET + 1234,
	'Europe/Gibraltar': ID_SEQUENCE_OFFSET + 1235,
	'Europe/Guernsey': ID_SEQUENCE_OFFSET + 1236,
	'Europe/Helsinki': ID_SEQUENCE_OFFSET + 1237,
	'Europe/Isle_of_Man': ID_SEQUENCE_OFFSET + 1238,
	'Europe/Istanbul': ID_SEQUENCE_OFFSET + 1239,
	'Europe/Jersey': ID_SEQUENCE_OFFSET + 1240,
	'Europe/Kaliningrad': ID_SEQUENCE_OFFSET + 1241,
	'Europe/Kiev': ID_SEQUENCE_OFFSET + 1242,
	'Europe/Kyiv': ID_SEQUENCE_OFFSET + 1242,
	'Europe/Kirov': ID_SEQUENCE_OFFSET + 1243,
	'Europe/Lisbon': ID_SEQUENCE_OFFSET + 1244,
	'Europe/Ljubljana': ID_SEQUENCE_OFFSET + 1245,
	'Europe/London': ID_SEQUENCE_OFFSET + 1246,
	'Europe/Luxembourg': ID_SEQUENCE_OFFSET + 1247,
	'Europe/Madrid': ID_SEQUENCE_OFFSET + 1248,
	'Europe/Malta': ID_SEQUENCE_OFFSET + 1249,
	'Europe/Mariehamn': ID_SEQUENCE_OFFSET + 1250,
	'Europe/Minsk': ID_SEQUENCE_OFFSET + 1251,
	'Europe/Monaco': ID_SEQUENCE_OFFSET + 1252,
	'Europe/Moscow': ID_SEQUENCE_OFFSET + 1253,
	'Europe/Nicosia': ID_SEQUENCE_OFFSET + 1254,
	'Europe/Oslo': ID_SEQUENCE_OFFSET + 1255,
	'Europe/Paris': ID_SEQUENCE_OFFSET + 1256,
	'Europe/Podgorica': ID_SEQUENCE_OFFSET + 1257,
	'Europe/Prague': ID_SEQUENCE_OFFSET + 1258,
	'Europe/Riga': ID_SEQUENCE_OFFSET + 1259,
	'Europe/Rome': ID_SEQUENCE_OFFSET + 1260,
	'Europe/Samara': ID_SEQUENCE_OFFSET + 1261,
	'Europe/San_Marino': ID_SEQUENCE_OFFSET + 1262,
	'Europe/Sarajevo': ID_SEQUENCE_OFFSET + 1263,
	'Europe/Simferopol': ID_SEQUENCE_OFFSET + 1264,
	'Europe/Skopje': ID_SEQUENCE_OFFSET + 1265,
	'Europe/Sofia': ID_SEQUENCE_OFFSET + 1266,
	'Europe/Stockholm': ID_SEQUENCE_OFFSET + 1267,
	'Europe/Tallinn': ID_SEQUENCE_OFFSET + 1268,
	'Europe/Tirane': ID_SEQUENCE_OFFSET + 1269,
	'Europe/Tiraspol': ID_SEQUENCE_OFFSET + 1270,
	'Europe/Ulyanovsk': ID_SEQUENCE_OFFSET + 1271,
	'Europe/Uzhgorod': ID_SEQUENCE_OFFSET + 1272,
	'Europe/Uzhhorod': ID_SEQUENCE_OFFSET + 1272,
	'Europe/Vaduz': ID_SEQUENCE_OFFSET + 1273,
	'Europe/Vatican': ID_SEQUENCE_OFFSET + 1274,
	'Europe/Vienna': ID_SEQUENCE_OFFSET + 1275,
	'Europe/Vilnius': ID_SEQUENCE_OFFSET + 1276,
	'Europe/Volgograd': ID_SEQUENCE_OFFSET + 1277,
	'Europe/Warsaw': ID_SEQUENCE_OFFSET + 1278,
	'Europe/Zagreb': ID_SEQUENCE_OFFSET + 1279,
	'Europe/Zaporozhye': ID_SEQUENCE_OFFSET + 1280,
	'Europe/Zaporizhia': ID_SEQUENCE_OFFSET + 1280,
	'Europe/Zurich': ID_SEQUENCE_OFFSET + 1281,
	'GB': ID_SEQUENCE_OFFSET + 1282,
	'GB-Eire': ID_SEQUENCE_OFFSET + 1283,
	'GMT': ID_SEQUENCE_OFFSET + 1284,
	'GMT0': ID_SEQUENCE_OFFSET + 1285,
	'Greenwich': ID_SEQUENCE_OFFSET + 1286,
	'Hongkong': ID_SEQUENCE_OFFSET + 1287,
	'Iceland': ID_SEQUENCE_OFFSET + 1288,
	'Indian/Antananarivo': ID_SEQUENCE_OFFSET + 1289,
	'Indian/Chagos': ID_SEQUENCE_OFFSET + 1290,
	'Indian/Christmas': ID_SEQUENCE_OFFSET + 1291,
	'Indian/Cocos': ID_SEQUENCE_OFFSET + 1292,
	'Indian/Comoro': ID_SEQUENCE_OFFSET + 1293,
	'Indian/Kerguelen': ID_SEQUENCE_OFFSET + 1294,
	'Indian/Mahe': ID_SEQUENCE_OFFSET + 1295,
	'Indian/Maldives': ID_SEQUENCE_OFFSET + 1296,
	'Indian/Mauritius': ID_SEQUENCE_OFFSET + 1297,
	'Indian/Mayotte': ID_SEQUENCE_OFFSET + 1298,
	'Indian/Reunion': ID_SEQUENCE_OFFSET + 1299,
	'Iran': ID_SEQUENCE_OFFSET + 1300,
	'Israel': ID_SEQUENCE_OFFSET + 1301,
	'Jamaica': ID_SEQUENCE_OFFSET + 1302,
	'Japan': ID_SEQUENCE_OFFSET + 1303,
	'Kwajalein': ID_SEQUENCE_OFFSET + 1304,
	'Libya': ID_SEQUENCE_OFFSET + 1305,
	'MET': ID_SEQUENCE_OFFSET + 1306,
	'MST7MDT': ID_SEQUENCE_OFFSET + 1307,
	'Mexico/BajaNorte': ID_SEQUENCE_OFFSET + 1308,
	'Mexico/BajaSur': ID_SEQUENCE_OFFSET + 1309,
	'Mexico/General': ID_SEQUENCE_OFFSET + 1310,
	'NZ': ID_SEQUENCE_OFFSET + 1311,
	'NZ-CHAT': ID_SEQUENCE_OFFSET + 1312,
	'Navajo': ID_SEQUENCE_OFFSET + 1313,
	'PRC': ID_SEQUENCE_OFFSET + 1314,
	'PST8PDT': ID_SEQUENCE_OFFSET + 1315,
	'Pacific/Apia': ID_SEQUENCE_OFFSET + 1316,
	'Pacific/Auckland': ID_SEQUENCE_OFFSET + 1317,
	'Pacific/Bougainville': ID_SEQUENCE_OFFSET + 1318,
	'Pacific/Chatham': ID_SEQUENCE_OFFSET + 1319,
	'Pacific/Chuuk': ID_SEQUENCE_OFFSET + 1320,
	'Pacific/Easter': ID_SEQUENCE_OFFSET + 1321,
	'Pacific/Efate': ID_SEQUENCE_OFFSET + 1322,
	'Pacific/Enderbury': ID_SEQUENCE_OFFSET + 1323,
	'Pacific/Fakaofo': ID_SEQUENCE_OFFSET + 1324,
	'Pacific/Fiji': ID_SEQUENCE_OFFSET + 1325,
	'Pacific/Funafuti': ID_SEQUENCE_OFFSET + 1326,
	'Pacific/Galapagos': ID_SEQUENCE_OFFSET + 1327,
	'Pacific/Gambier': ID_SEQUENCE_OFFSET + 1328,
	'Pacific/Guadalcanal': ID_SEQUENCE_OFFSET + 1329,
	'Pacific/Guam': ID_SEQUENCE_OFFSET + 1330,
	'Pacific/Honolulu': ID_SEQUENCE_OFFSET + 1331,
	'Pacific/Johnston': ID_SEQUENCE_OFFSET + 1332,
	'Pacific/Kiritimati': ID_SEQUENCE_OFFSET + 1333,
	'Pacific/Kosrae': ID_SEQUENCE_OFFSET + 1334,
	'Pacific/Kwajalein': ID_SEQUENCE_OFFSET + 1335,
	'Pacific/Majuro': ID_SEQUENCE_OFFSET + 1336,
	'Pacific/Marquesas': ID_SEQUENCE_OFFSET + 1337,
	'Pacific/Midway': ID_SEQUENCE_OFFSET + 1338,
	'Pacific/Nauru': ID_SEQUENCE_OFFSET + 1339,
	'Pacific/Niue': ID_SEQUENCE_OFFSET + 1340,
	'Pacific/Norfolk': ID_SEQUENCE_OFFSET + 1341,
	'Pacific/Noumea': ID_SEQUENCE_OFFSET + 1342,
	'Pacific/Pago_Pago': ID_SEQUENCE_OFFSET + 1343,
	'Pacific/Palau': ID_SEQUENCE_OFFSET + 1344,
	'Pacific/Pitcairn': ID_SEQUENCE_OFFSET + 1345,
	'Pacific/Pohnpei': ID_SEQUENCE_OFFSET + 1346,
	'Pacific/Ponape': ID_SEQUENCE_OFFSET + 1347,
	'Pacific/Port_Moresby': ID_SEQUENCE_OFFSET + 1348,
	'Pacific/Rarotonga': ID_SEQUENCE_OFFSET + 1349,
	'Pacific/Saipan': ID_SEQUENCE_OFFSET + 1350,
	'Pacific/Samoa': ID_SEQUENCE_OFFSET + 1351,
	'Pacific/Tahiti': ID_SEQUENCE_OFFSET + 1352,
	'Pacific/Tarawa': ID_SEQUENCE_OFFSET + 1353,
	'Pacific/Tongatapu': ID_SEQUENCE_OFFSET + 1354,
	'Pacific/Truk': ID_SEQUENCE_OFFSET + 1355,
	'Pacific/Wake': ID_SEQUENCE_OFFSET + 1356,
	'Pacific/Wallis': ID_SEQUENCE_OFFSET + 1357,
	'Pacific/Yap': ID_SEQUENCE_OFFSET + 1358,
	'Poland': ID_SEQUENCE_OFFSET + 1359,
	'Portugal': ID_SEQUENCE_OFFSET + 1360,
	'ROK': ID_SEQUENCE_OFFSET + 1361,
	'Singapore': ID_SEQUENCE_OFFSET + 1362,
	'Turkey': ID_SEQUENCE_OFFSET + 1363,
	'UCT': ID_SEQUENCE_OFFSET + 1364,
	'US/Alaska': ID_SEQUENCE_OFFSET + 1365,
	'US/Aleutian': ID_SEQUENCE_OFFSET + 1366,
	'US/Arizona': ID_SEQUENCE_OFFSET + 1367,
	'US/Central': ID_SEQUENCE_OFFSET + 1368,
	'US/East-Indiana': ID_SEQUENCE_OFFSET + 1369,
	'US/Eastern': ID_SEQUENCE_OFFSET + 1370,
	'US/Hawaii': ID_SEQUENCE_OFFSET + 1371,
	'US/Indiana-Starke': ID_SEQUENCE_OFFSET + 1372,
	'US/Michigan': ID_SEQUENCE_OFFSET + 1373,
	'US/Mountain': ID_SEQUENCE_OFFSET + 1374,
	'US/Pacific': ID_SEQUENCE_OFFSET + 1375,
	'US/Pacific-New': ID_SEQUENCE_OFFSET + 1376,
	'US/Samoa': ID_SEQUENCE_OFFSET + 1377,
	'UTC': ID_SEQUENCE_OFFSET + 1378,
	'Universal': ID_SEQUENCE_OFFSET + 1379,
	'W-SU': ID_SEQUENCE_OFFSET + 1380,
	'WET': ID_SEQUENCE_OFFSET + 1381,
	'Zulu': ID_SEQUENCE_OFFSET + 1382
};

const mapFromTimeZoneId = global._.invert(mapFromZoneId);

export function getTimeZoneId(zoneId) {
	return mapFromZoneId[zoneId];
}

export function getZoneId(timeZoneId) {
	return mapFromTimeZoneId[timeZoneId];
}
import JournalRecordsTable from '../components/journal/JournalRecordsTable.vue';
import utils from '../../common/components/utils';
import MultilingualString from '../../common/models/multilingualString';
import TabContainer from '../components/tabContainer';
import { translate } from '../../common/service/stringResourceService'

var JournalView = Backbone.View.extend({

	initialize: function (options) {
		this.setElement(options.$el.find('.journal'))
		this.documentId = options.documentId;
		this.$tabs = $('<div>').addClass('nav-tabs-custom').appendTo(this.$el);
		this.mounted();
	},

	async mounted () {
		let recordsFull = await app.utils.getRequest(app.urls.getJournalRecordsCount(this.documentId))
		this.recordsCount = recordsFull.filter((record) => {
			return (record.count)
		}).sort((a, b) => {
			let aP = app.types.get(a.journalType.id).get('journalPriority')
			let bP = app.types.get(b.journalType.id).get('journalPriority')
			return aP-bP
		}) || [];
		this.render();
	},

	render () {
		this.renderToolbar();
		this.renderNavigationTabs();
		this.renderTabsContent();
		new TabContainer({
			el: this.$tabs
		});
	},

	renderToolbar: function() {
		app.entityManager.fetchStringView(null, this.documentId).then((data) => {
		this.$el.parent().prepend(`<div class="fixed-toolbar form-toolbar row" data-spy="affix" data-offset-top="50">
				<div>
					<div class="box box-solid">
						<div class="box-body no-padding">
							<div class="content-header">
								<div class="column inline-column">
									<div class="form-group" style="position: relative; text-align: left;">
										<button type="button" class="btn btn-link btn-lg back-button" style="margin-left: -15px;">
											<span class="fa fa-chevron-left mirror-in-rtl" style="color:#187AB2 "></span>
										</button>
									</div>
									<div class="form-group btn-group open" style="position: relative; text-align: left;">
										<h3 class="btn-md" style="float: left;">
											<span> ${data + ' ' + translate('postings')} </span>
										</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>`);
			this.$el.parent().find('.back-button').click(() => {
				app.cjTabs.activeTab.close();
			})
		});
	},

	renderNavigationTabs: function() {
		let $ul = $('<ul>').addClass('nav nav-tabs');
		this.recordsCount.forEach((record, index) => {
			let recordNav = $('<li>').addClass(index === 0 ? 'active' : '')
									.append($('<a>').attr('data-toggle', 'tab')
									.attr('data-target', '#' + record.journalType.id)
									.text(MultilingualString.getCurrentValue(app.types.get(record.journalType.id).name())));
			$ul.append(recordNav);
		});
		$ul.appendTo(this.$tabs);
	},

	renderTabsContent: function() {
		let $tabContent = $('<div>').addClass('tab-content');
		this.recordsCount.forEach((record, index) => {
			let journalTable = $('<table>').addClass('journal-table');
			let recordContent = $('<div>').addClass(index === 0 ? 'active' : '')
									.addClass('tab-pane')
									.attr('data-item-id', record.journalType.id)
									.append(journalTable);
			$tabContent.append(recordContent);
			new Vue({
				el: journalTable[0],
				render: h => h(JournalRecordsTable, {
					props: {
						referrerDocumentId: this.documentId,
						journalTypeId: record.journalType.id
					}
				})
			});
		});
		$tabContent.appendTo(this.$tabs);
	}
});

export default JournalView;

import SaveForm from '../../common/views/saveForm.js';
import FieldInput from '../../common/components/fieldInput';
import BaseSelect from '../../common/components/baseSelect.js';
import FieldDynamic from '../../common/components/fieldDynamic';
import MultilingualInputField from '../../common/components/multilingualInputField.js';
import MultilingualString from '../../common/models/multilingualString.js';
import utils from '../../common/components/utils.js';
import CheckboxInput from '../../common/components/checkboxInput';

var CreateUpdateForm = SaveForm.extend({

	el: '.create-update-form',

	inputs: {},
	selects: {},

	initialize: function (o) {
		CreateUpdateForm.__super__.initialize.apply(this, arguments);
		this.models = o.models;
		this.initializeInputs();
		this.initializeMultilingualInputs();
		this.initializeSelects();
		this.initializeDynamicFields();
		this.initializeCheckboxes()
	},

	initializeInputs: function () {
		var that = this;
		this.$('input[data-field], textarea[data-field]')
			.not('.multilingual input, .multilingual textarea')
			.not('input[data-is-string-multilingual], textarea[data-is-string-multilingual]')
			.each(function (event) {
				var model = utils.getChildModel(that.model, $(this));
				var ModelType = that.models && that.models[model.attr] || FieldInput;
				var input = new ModelType({
					model: model.model,
					modelAttr: model.attr,
					el: this
				});
				that.inputs[model.attr] = input;
			});
	},
	
  initializeCheckboxes: function () {
    var that = this;
    this.$('input[data-field][type=checkbox]').each(function (event) {
      var model = utils.getChildModel(that.model, $(this));
      var input = new CheckboxInput({
        model: model.model,
        modelAttr: model.attr,
        el: this
      });
      that.inputs[model.attr] = input;
    });
  },

	initializeMultilingualInputs: function () {
		var that = this;
		this.$('input[data-is-string-multilingual], textarea[data-is-string-multilingual]')
			.each(function (event) {
				var model = utils.getChildModel(that.model, $(this));
				var options = that.model.get(model.attr) &&
					that.model.get(model.attr).toJSON();
				that.model.set(model.attr, new MultilingualString(options));
				var ModelType = that.models && that.models[model.attr] || MultilingualInputField;
				var input = new ModelType({
					model: model.model,
					modelAttr: model.attr,
					el: $(this)
				});
				that.inputs[model.attr] = input;
			});
	},

	initializeSelects: function () {
		var that = this;
		this.$('select[data-field]')
			.not('.dynamicType')
			.each(function (event) {
			var model = utils.getChildModel(that.model, $(this));
			var ModelType = that.models && that.models[model.attr] || BaseSelect;
			var select = new ModelType({
				model: model.model,
				modelAttr: model.attr,
				el: this
			});
			that.selects[model.attr] = select;
		});
	},

	initializeDynamicFields: function () {
		var that = this;
		this.$('select.dynamicType').each(function (event) {
				var model = utils.getChildModel(that.model, $(this));
				var dynamic = new FieldDynamic({
					model: model.model,
					modelAttr: model.attr,
					el: this
				});
				that.selects[model.attr] = dynamic;
			});
	},

	render: function () {
		_.each(this.inputs, function (input) {
			input.render();
		});
		_.each(this.selects, function (select) {
			select.render();
		});
	}

});

export default CreateUpdateForm;

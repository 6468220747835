
export default class ProgressBarView extends Backbone.View {
	
	set(percentage){
		this.$el = $('.progress-bar');
		this.$el.show(0, () => {
				this.$el.css('width', percentage);
			});
	}
	
	hide(){
		this.$el.hide();
		this.$el.css('width', '0%');
	}

}

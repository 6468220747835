import BaseCreateView from '../../common/views/baseCreateView';
import BaseSelect from '../../common/components/baseSelect.js';
import MultilingualString from '../../common/models/multilingualString.js';
import { translate } from '../../common/service/stringResourceService'

let CreateView = BaseCreateView.extend({


	initialize: function (options) {
		CreateView.__super__.initialize.apply(this, arguments);
		this.options = options;
		this.themes = app.themes;
		this.$cssThemes = $('#cssThemes');
		this.render();
	},

	render: function () {
		this.rebuildSelect(this.$cssThemes,	this.createSelectDataForThemes(this.themes));
	},

	rebuildSelect: function (select, data) {
		new BaseSelect({
			el: $('#cssThemes'),
			data: data,
			allowClear: true
		}).render();
	},

	createSelectDataForThemes: function (data) {
		return data.map(item => {
							return {
								id: item.id,
								text: (new MultilingualString(item.name)).getCurrentValue()
							}
						})
	},

	getHTML: function () {
		 return `<div class="grid-1x-padding">
							<label for="name">${app.getResource('name')}</label>
						</div>
						<div class="grid-2x-padding">
							<input class="form-control" type="text" data-is-string-multilingual="short" data-field="name"/>
						</div>
						<div id="fieldTypesDiv">
							<div class="grid-1x-padding">
								<label for="cloneId">Clone</label>
							</div>
							<div class="grid-2x-padding" style="display:flex">
								<select class="form-control" id="cssThemes" placeholder="${app.getResource('select.css.theme')}" data-field="cloneId">
								</select>
								</div>
							</div>`;
	},


});

export default CreateView;

/* @flow */

import LocalDateTime from './localDateTime';
import ZoneId from './zoneId';
import ZoneOffset from './zoneOffset';
import ChronoField from '../enums/chronoField';
import ChronoUnit from '../enums/chronoUnit';
import Instant from './instant';
import LocalDate from './localDate';

import type LocalTime from './localTime';
import type { Temporal } from '../declarations';

import {
} from '../constants';

import {
	checkUnitIsSupported
} from '../utils';

/**
 * Serilized form:
 *   year: int
 *   month: short
 *   day: short
 *   hour: byte
 *   minute: byte
 *   second: byte
 *   nano: int
 *   offset: int // in seconds
 *   zone: string
 */

export default class ZonedDateTime {

	dateTime: LocalDateTime;
	offset: ZoneOffset;
	zone: ZoneId;

	constructor(dateTime: LocalDateTime, offset: ZoneOffset, zone: ZoneId) {
		this.dateTime = dateTime;
		this.offset = offset;
		this.zone = zone;
	}

	static fromJSON(value: ?any): ?ZonedDateTime {
		if (value == null) {
			return null;
		}
		return new ZonedDateTime(
			LocalDateTime.of(value.year, value.month, value.day, value.hour, value.minute, value.second, value.nano),
			ZoneOffset.ofTotalSeconds(value.offset),
			ZoneId.of(value.zone));
	}

// 	static of(year: number, month: number, dayOfMonth: number, hour: number, minute: number, second: number, nanoOfSecond: number, zone: string): ZonedDateTime {
//
// 	}
//
// 	static of(date: LocalDate, time: LocalTime, ZoneId zone): ZonedDateTime {
//
// 	}
//
	static of(firstArg): ZonedDateTime {
		if (firstArg instanceof LocalDateTime) {
			const [dateTime: LocalDateTime, zone: ZoneId] = arguments;
			const [correctedDateTime, offset] = ZonedDateTime._calculateOffset(dateTime, zone);
			return new ZonedDateTime(correctedDateTime, offset, zone);
		}
		if (firstArg instanceof LocalDate) {
			const [date: LocalDate, time: LocalTime, zone: ZoneId] = arguments;
			const dateTime = LocalDateTime.of(date, time);
			return ZonedDateTime.of(dateTime, zone);
		}
		if (!isNaN(firstArg)) {
			const [year: number, month: number, dayOfMonth: number,
				hour: number, minute: number, second: number, nanoOfSecond: number, zone: string] = arguments;
			const dateTime = LocalDateTime.of(year, month, dayOfMonth, hour, minute, second, nanoOfSecond);
			return ZonedDateTime.of(dateTime, zone);
		}
		throw new Error('Invalid type of arguments');
	}

	static _calculateOffset(dateTime: LocalDateTime, zone: ZoneId): [LocalDateTime, moment$Moment] {
		const m = global.moment.tz({
			year: dateTime.getYear(),
			month: dateTime.getMonthValue() - 1,
			day: dateTime.getDayOfMonth(),
			hour: dateTime.getHour(),
			minute: dateTime.getMinute(),
			second: dateTime.getSecond()
		}, zone.getId());
		let correctedDateTime = LocalDateTime.fromMoment(m);
		correctedDateTime = correctedDateTime.withNano(dateTime.getNano());
		const offsetMinutes = m.utcOffset();
		return [correctedDateTime, ZoneOffset.ofTotalMinutes(offsetMinutes)];
	}

	static ofInstant(instant: Instant, zone: ZoneId): ZonedDateTime {
		const offset = zone.getRules().getOffset(instant);
		const dateTime = LocalDateTime.ofEpochSecond(instant.getEpochSecond(), instant.getNano(), offset);
		return new ZonedDateTime(dateTime, offset, zone);
	}

// static ZonedDateTime 	ofInstant(LocalDateTime localDateTime, ZoneOffset offset, ZoneId zone)
// Obtains an instance of ZonedDateTime from the instant formed by combining the local date-time and offset.
// static ZonedDateTime 	ofLocal(LocalDateTime localDateTime, ZoneId zone, ZoneOffset preferredOffset)
// Obtains an instance of ZonedDateTime from a local date-time using the preferred offset if possible.
// static ZonedDateTime 	ofStrict(LocalDateTime localDateTime, ZoneOffset offset, ZoneId zone)
// Obtains an instance of ZonedDateTime strictly validating the combination of local date-time, offset and zone ID.

	static now(zone: ZoneId): ZonedDateTime {
		return Instant.now().atZone(zone);
	}

	toJSON() {
		return {
			year: this.dateTime.getYear(),
			month: this.dateTime.getMonthValue(),
			day: this.dateTime.getDayOfMonth(),
			hour: this.dateTime.getHour(),
			minute: this.dateTime.getMinute(),
			second: this.dateTime.getSecond(),
			nano: this.dateTime.getNano(),
			offset: this.offset.getTotalSeconds(),
			zone: this.zone.getId()
		};
	}

	toMoment(): moment$Moment {
		if(this.zone instanceof ZoneOffset) {
			const instant = this.toInstant();
			const offsetInMinutes = this.zone.getTotalMinutes();
			return global.moment(instant.toEpochMilli()).utcOffset(offsetInMinutes);
		} else {
			const instant = this.toInstant();
			return moment.tz(instant.toEpochMilli(), this.zone.getId());
		}
	}

	static fromMoment(moment: moment$Moment): ZonedDateTime {
		const zoneId = ZoneId.of(moment.tz());
		const instant = Instant.ofEpochMilli(moment.valueOf());
		return ZonedDateTime.ofInstant(instant, zoneId);
	}

	toLocalDate(): LocalDate {
		return this.dateTime.toLocalDate();
	}

	toLocalDateTime(): LocalDateTime {
		return this.dateTime;
	}

	toLocalTime(): LocalTime {
		return this.dateTime.toLocalTime();
	}

	toEpochSecond(): number {
		return this.dateTime.toEpochSecond(this.offset);
	}

	toInstant(): number {
		return Instant.ofEpochSecond(this.toEpochSecond(), this.dateTime.getNano());
	}

	withZoneSameInstant(zone: ZoneId): ZonedDateTime {
		const instant = this.toInstant();
		return ZonedDateTime.ofInstant(instant, zone);
	}

	getDayOfMonth(): number {
		return this.dateTime.getDayOfMonth();
	}

	getDayOfYear(): number {
		return this.dateTime.getYear();
	}

	getHour(): number {
		return this.dateTime.getHour();
	}

	getMinute(): number {
		return this.dateTime.getMinute();
	}

	getMonthValue(): number {
		return this.dateTime.getMonthValue();
	}

	getNano(): number {
		return this.dateTime.getNano();
	}

	getOffset(): ZoneOffset {
		return this.offset;
	}

	getSecond(): number {
		return this.dateTime.getSecond();
	}

	getYear(): number {
		return this.dateTime.getYear();
	}

	getZone(): ZoneId {
		return this.zone;
	}

	get(field: ChronoField): number {
		throw new Error('Not implemented');
	}

	withNano(nanoOfSecond: number): ZonedDateTime {
		return new ZonedDateTime(this.dateTime.withNano(nanoOfSecond), this.offset, this.zone);
	}

	plus(amount: number, unit: ChronoUnit): ZonedDateTime {
		checkUnitIsSupported(this, unit);
		if (unit == ChronoUnit.MILLIS) {
			throw new Error('Not implemented');
		}
		const moment = this.toMoment().add(amount, unit.toMoment());
		let dateTime = ZonedDateTime.fromMoment(moment);
		dateTime = dateTime.withNano(this.getNano());
		return dateTime;
	}

	until(endExclusive: Temporal, unit: ChronoUnit): number {
		throw new Error('Not implemented');
	}

	// compareTo(other: LocalDateTime): number {
	// 	const d = this.dateTime.compareTo(dateTime);
	// 	if (d !== 0) {
	// 		return d;
	// 	}
	// 	return this.time.compareTo(other.time);
	// }
	//
	// equals(other: ?any): boolean {
	// 	if (other == null) {
	// 		return false;
	// 	}
	// 	if (!(other instanceof LocalDateTime)) {
	// 		return false;
	// 	}
	// 	return this.date.equals(other.date) &&
	// 			this.time.equals(other.time);
	// }
	//
	// isAfter(other: LocalDateTime): boolean {
	// 	return this.compareTo(other) > 0;
	// }
	//
	// isBefore(other: LocalDateTime): boolean {
	// 	return this.compareTo(other) < 0;
	// }

	isSupported(field: ChronoField | ChronoUnit): boolean {
		if (field instanceof ChronoField) {
			return ZonedDateTime.supportedFields.indexOf(field) > -1;
		}
		if (field instanceof ChronoUnit) {
			return ZonedDateTime.supportedUnits.indexOf(field) > -1;
		}
		throw new Error('Not supported type of the argument');
	}

	static supportedFields = [
		ChronoField.NANO_OF_SECOND,
		ChronoField.NANO_OF_DAY,
		ChronoField.MICRO_OF_SECOND,
		ChronoField.MICRO_OF_DAY,
		ChronoField.MILLI_OF_SECOND,
		ChronoField.MILLI_OF_DAY,
		ChronoField.SECOND_OF_MINUTE,
		ChronoField.SECOND_OF_DAY,
		ChronoField.MINUTE_OF_HOUR,
		ChronoField.MINUTE_OF_DAY,
		ChronoField.HOUR_OF_AMPM,
		ChronoField.CLOCK_HOUR_OF_AMPM,
		ChronoField.HOUR_OF_DAY,
		ChronoField.CLOCK_HOUR_OF_DAY,
		ChronoField.AMPM_OF_DAY,
		ChronoField.DAY_OF_WEEK,
		ChronoField.ALIGNED_DAY_OF_WEEK_IN_MONTH,
		ChronoField.ALIGNED_DAY_OF_WEEK_IN_YEAR,
		ChronoField.DAY_OF_MONTH,
		ChronoField.DAY_OF_YEAR,
		ChronoField.EPOCH_DAY,
		ChronoField.ALIGNED_WEEK_OF_MONTH,
		ChronoField.ALIGNED_WEEK_OF_YEAR,
		ChronoField.MONTH_OF_YEAR,
		ChronoField.PROLEPTIC_MONTH,
		ChronoField.YEAR_OF_ERA,
		ChronoField.YEAR,
		ChronoField.ERA,
		ChronoField.INSTANT_SECONDS,
		ChronoField.OFFSET_SECONDS
	];

	static supportedUnits = [
		ChronoUnit.NANOS,
		ChronoUnit.MICROS,
		ChronoUnit.MILLIS,
		ChronoUnit.SECONDS,
		ChronoUnit.MINUTES,
		ChronoUnit.HOURS,
		ChronoUnit.HALF_DAYS,
		ChronoUnit.DAYS,
		ChronoUnit.WEEKS,
		ChronoUnit.MONTHS,
		ChronoUnit.YEARS,
		ChronoUnit.DECADES,
		ChronoUnit.CENTURIES,
		ChronoUnit.MILLENNIA,
		ChronoUnit.ERAS
	];

	static getMethodSupportedEnumValues = ZonedDateTime.supportedFields;
}


import utils from './utils';


export default class TasksQueue {

  constructor() {
    this.queue = [];
    this.runningTasks = 0;
  }

  add(job, resolveFunc, rejectFunc) {
    return new Promise((resolve, reject) => {

      const request = {
        job: job,
        resolve: resolveFunc || resolve,
        reject: reject
      };

      this._enqueue(request);
      this._dequeue();

    }).catch(e => {
      rejectFunc && rejectFunc(e);
      console.error(e);
      throw e;
    });
  }

  _enqueue(request) {
    this.queue.push(request);
  }

  _dequeue() {

    if (this.runningTasks !== 0) {
      return false;
    }
    const request = this.queue.shift();

    if (!request) {
      return false;
    }
    try {
      this.runningTasks++;
      Promise.resolve(request.job())
        .then(data => {
          try {
            request.resolve(data);
          } catch (e) {
            console.error('Error in request.resolve:', e);
          } finally {
            this.runningTasks--;
            this._dequeue();
          }
        })
        .catch(error => {
          try {
            request.reject(error);
          } catch (e) {
            console.error('Error in request.resolve:', e);
          } finally {
            this.runningTasks--;
            this._dequeue();
          }
        })
    } catch (error) {
       this.runningTasks--;
       request.reject(error);
       this._dequeue();
    }
    return true;
  }

}

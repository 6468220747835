import UsageTree from './usageTree';

export default class ConfirmModal extends Backbone.View {
	initialize () {
		this.setElement($('#confirm-modal'));
		this.usageTree = new UsageTree();
	}

	show (options) {
		if (options.confirmRequired === undefined ||
			options.confirmRequired === true ||
				(_.isFunction(options.confirmRequired) && options.confirmRequired())) {
			options.hideHeader && this.$el.find('.modal-header').hide();
			this.$el.find('.modal-footer > button').hide();
			this.$el.find('#usageTree').hide();
			if (options.confirmDetails) {
				this.$el.find('#confirmDetails').show();
				this.$el.find('#usageTree').slideDown();
			}
			if (options.strictDependenies) {
				this.$el.find('#confirmDetails').show();
				this.$el.find('#usageTree').show();
			}
			const headerResource = options.headerResource && app.getResource(options.headerResource) || app.getResource('references.are.found')
			this.$el.find('.confirmModalHeader').html(headerResource)
			let resource = (options.resource && options.resource.getCurrentValue && options.resource.getCurrentValue()) ||
				(options.resource && app.getResource(options.resource)) || '';
			if (options.additionalText) {
				resource += ' ' + app.getResource(options.additionalText)
			}
			this.$el.find('.modal-body p').html(resource).css('text-align', 'left');
			if (options.dependencies && (options.strictDependenies || options.confirmDetails)) {
				this.usageTree.render(options.dependencies,this.$el.find('#usageTree'), {nodeProperties: options.nodeProperties})
				if (!options.dependencies.usages.length) {
					this.$el.find('.modal-body p').html(app.getResource('no.usages.found'))
						.css('text-align','center');
				}
			}
			this.initializeEnterPressingEvent();
			_.each(options.buttons, (func, btn) => {
				this.$el.find(`.modal-footer > button[data-action="${btn}"]`)
					.off('click')
					.on('click', e => {
						this.$el.modal('hide');

						let result = func(e);
						if (options.then) {
							options.then(result);
						}
					})
					.show();
			});
			this.$el.modal({backdrop: 'static'})
		} else {
			if (options.then) {
				options.then();
			}
		}
	}

	initializeEnterPressingEvent() {
		let removeHandler;
		let cancelFunc = () => {
			var keycode = (event.keyCode ? event.keyCode : event.which);
			if(keycode == '13') {
				this.$el.modal('hide');
				removeHandler();
			}
		}
		removeHandler = () => $(document).off("keyup", cancelFunc);

		$(document).on("keyup", cancelFunc);
	}

}

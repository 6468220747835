import Formatter from '../../common/components/formatter';
import Instant from '../../time/models/instant';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import MetaObjectState from '../../common/enums/metaObjectState';
import utils from '../../common/components/utils';
import {scaleDecimals} from '../../common/components/utils';
import WizardView from './wizardView';
import Entity from '../../common/models/entity';
import entityManager from '../../common/components/entityManager.js';
import MultilingualString from '../../common/models/multilingualString';
import Constants from "../../common/models/constants";


var HistoryView = Backbone.View.extend({

	buttonsToDisable: [],

	initialize: function (o) {
		var that = this;
		this.context = o.context;
		this.state = false;
		this.$el = $('#sidebar-wrapper .sidebar-list');
		$('#wrapper').removeClass('toggled');
		if (this._isHistoryRestoreEnabled()) {
			this.context.$el.find('.restore')
				.off('click')
				.on('click', e => this.restore(e));
		}
	},

	showHistory: function () {
		this.state = !this.state;
		this.update();
	},

	update: function() {
		return new Promise((resolve, reject) => {
			if (this.state) {
				$('#wrapper').addClass('toggled');
				this.context.$el.find('.show-history').html(`<span class="fa fa-history"></span>${app.getResource('hide.history')}`);
				this.loadHistory();
				this.buttonsToDisable = this.context.wizardView.forms[0].triggerButtons.filter((button)=>{return !button.isDisabled()})
				this.buttonsToDisable.forEach((button)=>{
					button.disable();
				});
			} else {
				$('#wrapper').removeClass('toggled');
				this.context.$el.find('.buttons').show();
				this.context.$el.find('.show-history').html(`<span class="fa fa-history"></span>${app.getResource('show.history')}`);
				this.buttonsToDisable.forEach((button)=>{
					button.enable();
				})
				this.context.wizardView.showUpdateSaveButtons();
				this.context.$el.find('.restore').hide();
				this.lastEditedData && this.updateWizard(this.lastEditedData);
				this.$el.find('li.historyItem').off('click');
			}
			resolve();
		});
	},

	loadHistory: async function () {
		var that = this;
		this.context.wizardView.hideUpdateSaveButtons();
		this.context.$el.find('.restore').show();
		if (this.context.model.get('metaObjectState') == MetaObjectState.DELETED) {
			this.context.$el.find('.restore').attr('disabled', 'disabled');
			this.context.$el.find('.show-history').remove();
		}
		else if (!this._isHistoryRestoreEnabled()) {
			this.context.$el.find('.restore').attr('disabled', 'disabled');
		}
		this.lastEditedData = this.context.model.toJSON();
		let data = (await utils.getRequest(app.urls.history(this.context.type.id, this.context.objectId))) || [];
		this.$el.html('');
		const applyRelieveHistory = (await this.getApplyRelieveHistory()) || [];
		data = data.concat(applyRelieveHistory).sort(
				(a, b) => {
					if (!a.timeStamp)return -1;
					if (!b.timeStamp)return 1;
					return Instant.fromJSON(a.timeStamp).compareTo(Instant.fromJSON(b.timeStamp))
				});
		$('.item-count').html(data.length + ' ' + app.getResource('modifications'));
		_.each(data, item => {
			var li = $('<li />').addClass('historyItem').prependTo(that.$el);
			if (item.kind != 'POST' && item.kind != 'UNPOST') {
				li.attr('data-item-entry', item.item.id);
			} else {
				li.addClass('disabled');
			}
			var left = $('<div class="pull-left" />').appendTo(li);
			let cssClass = (item.kind || 'update').toLowerCase();
			if (item.kind === 'POST' || item.kind === 'UNPOST') {
				cssClass = 'document-' + item.kind.toLowerCase();
			}
			left.append($('<span class="item-action ' + cssClass + '"/>').text(item.kind || 'update'));
			left.append($('<span class="item-date">')
				.text(Formatter.formatWithPredefinedFormat(Instant.fromJSON(item.timeStamp), {
					primitiveType: PrimitiveEntityType.TIMESTAMP
				})));
			var right = $('<div class="pull-right text-right" />').appendTo(li);
			const user = (item.user || {firstName: {value: 'anonymous'}, lastName: {value: ''}});
			right.append($('<span class="item-user" />')
				.text(MultilingualString.getCurrentValue(user.firstName) + ' ' + MultilingualString.getCurrentValue(user.lastName))
			);
			if (item.task) {
				right.append(`<div><a href="${app.urls.open(Constants.ID_TYPE_TASK, item.task.id)}" class="fa fa-external-link-square" target="_blank"></a></div>`);
			}
		});
		if ($('.form').hasClass('updating')) {
			this.$el.prepend($(
			`<li class="current-link">
					<div>
						<span class="item-action current">${app.getResource('user')}</span>
						<span class="item-date">${app.getResource('back.to.editing')}</span>
					</div>
				</li>`
			));
		}
		if (!this._isHistoryViewEnabled()) {
			this.$el.after($(
				`<div id="permission-info">
					<span>${app.getResource('modification.history.view.permission.info')}</span>
				 </div>`
			));
		}
		else if (!this._isHistoryRestoreEnabled()) {
			this.$el.after($(
				`<div id="permission-info">
					<span>${app.getResource('modification.history.restore.permission.info')}</span>
				 </div>`
			));
		}
		this.$el.find('li.historyItem').click(e => this.click(e));
	},

	getApplyRelieveHistory: async function() {
		if (app.types.get(this.context.type.id).kind() == 'DOCUMENT' && this.context.objectId) {
			return await utils.getRequest(app.urls.tasksByTarget(this.context.objectId));
		}
		return [];
	},

	click: async function (e) {
		e.stopPropagation();
		const $el = $(e.currentTarget);
		if ($el.is('[data-item-entry]')) {
			this.$el.find('.active').removeClass('active');
			$el.addClass('active');
			const id = $el.attr("data-item-entry");
			const url = app.urls.data(this.context.type.id, id, {
				viewId: this.context.viewId
			});
			const data = (await utils.getRequest(url)).item;
			scaleDecimals(app.types.get(this.context.type.id), data)
			data.id = id;
			this.updateWizard(data);
			this.context.wizardView._disableAllForms();
			this.context.$el.find('.buttons').hide();
		} else {
			if (!$el.hasClass('disabled')) {
				this.updateWizard(this.lastEditedData);
				this.restore()
			}
		}
	},

	updateWizard: function (data) {
		const json = data instanceof Entity ? data.toJSON() : data;
		json.id = this.context.objectId;
		json.viewContext = this.context.model.getViewContext().toJSON() // put current filters to historic model
		this.context.model.merge(json, {});
		entityManager.invokePageLoad(this.context);
		this.context.wizardView.render();
	},

	restore: function(e) {
		this.context.$el.find('.restore').hide();
		this.context.wizardView.edit();
		this.context.$el.find('.buttons').show();
		this.context.$el.find('.show-history').html(`<span class="fa fa-history"></span>${app.getResource('show.history')}`);
		if (this.state) {
			$('#wrapper').removeClass('toggled');
			this.state = false;
			this.$el.find('li.historyItem').off('click');
		}
	},

	destroy: function() {
		this.$el.find('li.historyItem').off('click');
	},

	_isHistoryViewEnabled: function() {
		return !app.builderMode
			&& app.permissionChecks.modificationHistory.isViewEnabled;
	},

	_isHistoryRestoreEnabled: function() {
		return !app.builderMode
			&& app.permissionChecks.modificationHistory.isViewEnabled
			&& app.permissionChecks.modificationHistory.isRestoreEnabled;
	}
});
export default HistoryView;

/* @flow */
import  ViewKind from '../../common/enums/viewKind';
import  ViewModifier from '../../common/enums/viewModifier';
import type Block from '../../common/models/block'
import FormItems from "../collections/FormItems";
import MultilingualString from "./multilingualString";

export default class View extends Backbone.Model {

	kind(): string {
		return this.get('viewKind');
	}

	owner(): string {
		return this.get('ownerId');
	}

	name(): any {
		return this.get('name');
	}

	parent(): View {
		return app.views.get(this.get('parentViewId'));
	}

	isSpreadsheetView(): boolean {
		return this.kind() == ViewKind.SPREADSHEET;
	}

	isDocView(): boolean {
		return this.kind() == ViewKind.DOC;
	}

	isXmlView(): boolean {
		return this.kind() == ViewKind.XML;
	}

	isFormView(): boolean {
		return this.kind() == ViewKind.FORM;
	}

	isStringView(): boolean {
		return this.kind() == ViewKind.STRING;
	}

	isRowView(): boolean {
		return this.kind() == ViewKind.ROW;
	}

	isPrimitiveView(): boolean {
		return this.kind() == ViewKind.PRIMITIVE;
	}

	isClientView(): boolean {
		return this.kind() == ViewKind.CLIENT
	}

	isWidgetView(): boolean {
		return this.kind() == ViewKind.WIDGET
	}

	isListView(): boolean {
		return this.kind() == ViewKind.LIST
	}

	viewModifier(): string {
		return this.get('viewModifier');
	}

	isDefault(): boolean {
		return this.viewModifier() == ViewModifier.DEFAULT;
	}

	isGenerated(): boolean {
		return this.get('isGenerated');
	}

	isShared(): boolean {
		return this.get('isShared');
	}

	hasBlock(blockTrigger: string): boolean {
		return !!this.get('viewEvents')[blockTrigger];
	}

	getBlock(blockTrigger: string): Block {
		return app.blocks.get(this.get('viewEvents')[blockTrigger]);
	}

	primitiveFormat() {
		return this.get('primitiveFormat');
	}

	formItems(): FormItems {
		return app.formItems.getByViewId(this.id);
	}

	getName(lang): string {
		return new MultilingualString(this.get('name')).getCurrentValue(lang);
	}

	forClientView(): boolean {
		return this.kind() == ViewKind.WIDGET || this.kind() == ViewKind.FORM;
	}

}

/* @flow */
import MultilingualString from '../models/multilingualString';

const NOT_FOUND_KEY = 'resource.not.found';

class ResourceCacheManager {

  constructor() {
    this._resourcesMap = null;
    this._resourcesIdMap = null;
    this._initializeResourceMaps()
  }

  isResourceDefined(key) {
  	return key && this._getResourcesMap().has(key);
  }

  getResource(key) {
  	if (!this.isResourceDefined(key)) {
  		key = NOT_FOUND_KEY;
  	}
  	return this._getResourcesMap().get(key)
  }

  enumToResource(_enum) {
  	var key = _enum.toLowerCase().replace(new RegExp('_', 'g'), '.');
  	return this.getResource(key);
  }

  enumToResourceForSelect(_enum) {
  	return {
  		html: this.enumToResource(_enum),
  		id: _enum
  	};
  }

  isResourceDefinedById(id) {
  	return this._getResourcesIdMap().has(id);
  }

  getResourceDataById(id) {
    let key = this.NOT_FOUND_KEY;
  	if (this.isResourceDefinedById(id)) {
      key = this._getResourcesIdMap().get(id);
    }
  	const value = this._getResourcesMap().get(key);
    const cloned = JSON.parse(JSON.stringify(value));
    return cloned;
  }

  addResource(resource) {
    this._getResourcesMap().set(resource.key, resource);
    this._getResourcesIdMap().set(resource.id, resource.key);
  }

  _getResourcesMap() {
    return this._resourcesMap;
  }

  _getResourcesIdMap() {
    return this._resourcesIdMap;
  }

  _initializeResourceMaps() {
    if (!app.resources) {
      throw "No resource data have been prepared.";
    }
    const resMap = new Map();
    const propKeys = Object.keys(app.resources);
    for (let i = 0; i < propKeys.length; i++) {
      const itemKey = propKeys[i];
      const item = app.resources[itemKey];
      resMap.set(itemKey, item);
    }
    this._resourcesMap = resMap
    app.resources = undefined;
  }
}

export default new ResourceCacheManager();

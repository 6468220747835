/* @flow */

import ListWithFieldSelect from './listWithFieldSelect';
import ReportValue from '../../common/models/reportValue';
import AggregateKind from '../../common/enums/aggregateKind';
import MultilingualString from '../../common/models/multilingualString';
import Constants from '../../common/models/constants';
import entityManager from '../../common/components/entityManager';
import utils from '../../common/components/utils';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';

const ReportValues = ListWithFieldSelect.extend({

	initialize: function (options) {
		ListWithFieldSelect.prototype.initialize.apply(this, arguments);
		const field = app.fields.get(Constants.ID_FIELD_REPORT_VALUES);
		const fieldNameHTML = MultilingualString.toHTML(field.name());
		this.$('.box-title').html(fieldNameHTML);
	},

	constructItem: function (field) {
		return ReportValue.fromJSON({
			field: {'id': field.id, 'isReference': true},
			relativeOrder: this.model.length
		});
	},

	constructRow: function (row, value) {
		const field = app.fields.get(value.field().id);

		const stringView = $('<div class="row-text"/>');
		stringView.html(utils.stringView(
			entityManager.fetchStringView(null, field.id),
			null, field.id));
		row.append(stringView);

		const toolsDiv = $('<div class="basic-mode report-tools"/>');
		row.append(toolsDiv);

		const select = $('<select class="form-control aggregate-kind-select"/>');
		toolsDiv.append(select);
		select.on('change', () => {
			value.set('aggregateKind', select.val());
			this.initTypeSelect(row, value, this.getPrimitiveTypeForFormatters(value))
			this.initFormatterSelect(row, value, (value.toType() && value.toType().get('primitiveEntityType')) || this.getPrimitiveTypeForFormatters(value))
		});

		this.appendFormatterSelect(toolsDiv, value, this.getPrimitiveTypeForFormatters(value), true)
		this.appendTypeSelect(toolsDiv, value, this.getPrimitiveTypeForFormatters(value))
	},

	getPrimitiveTypeForFormatters: function(value) {
		return (value.get('aggregateKind') == AggregateKind.CUSTOM && (value.get('formatterOptions') && value.get('formatterOptions').get('type') && value.get('formatterOptions').get('type').get('primitiveEntityType'))) ||
			((value.get('aggregateKind') == AggregateKind.COUNT || value.get('aggregateKind') == AggregateKind.AVERAGE) ? PrimitiveEntityType.DECIMAL : this.getFieldPrimitiveType(value))
	},

	initializeRow: function (row, value) {
		const field = app.fields.get(value.field().id);

		const aggregateKindSelect = row.find('.aggregate-kind-select');

		const selectData = [];
		_.each(_.keys(AggregateKind), kind => {
			selectData.push({
				id: kind,
				text: kind
			});
		});
		aggregateKindSelect.select2({
			placeholder: 'Aggregate function',
			data: selectData,
			dropdownAutoWidth : true,
			width: 'auto'
		});
		aggregateKindSelect.val(value.get('aggregateKind') || 'COUNT').trigger('change');

		this.initTypeSelect(row, value, this.getPrimitiveTypeForFormatters(value));
		this.initFormatterSelect(row, value, (value.toType()  && value.toType().get('primitiveEntityType')) || this.getPrimitiveTypeForFormatters(value))
	}

});

export default ReportValues;

import utils, {buildDefaultPopover} from '../../common/components/utils.js';
import { showConfirmModal } from '../../common/components/confirmModalVue';
import MultilingualString from '../../common/models/multilingualString.js';
import { urls } from '../../common/service/linkBuilder'

export default class CjHelper {
		constructor(dismissUP) {
      this.flows = []
      this.activeFlow = null
			this.dismiss = dismissUP
		}
		isShown(upid) {
			return this.shownFlowsLS().includes(upid)
		}
		shownFlowsLS() {
			return JSON.parse(localStorage.getItem("shownFlows")) || []
		}
		sendFlowSucceded(flowId) {
			utils.postRequest(flowId, urls.addFlowToUserAccount)
		}
		clearFlowIds() {
			utils.postRequest(null, urls.clearFlowIds)
		}
    showFlow(flow) {
      this.activeFlow = flow
      flow.show({
        showEndCallback: () => {
          this.activeFlow = null
					let shownFlows = this.shownFlowsLS()
					shownFlows.push(flow.upid)
					shownFlows.push(flow.id)
					localStorage.setItem("shownFlows", JSON.stringify(shownFlows))
					this.sendFlowSucceded(flow.id)
        },
				cancelCallback: () => {
					this.activeFlow = null
				}
      })
    }
    findFlow(flowId) {
      return this.flows.find((f) => {return f.id == flowId})
    }
		findFlowByUpid(upid) {
			return this.flows.find((f) => {return f.upid == upid})
		}
    fetchFlow(flowId) {
      return utils.getRequest(urls.flowById(flowId)).then((f) => {
        let flow = new Flow(f)
        this.flows.push(flow)
        return flow
      })
    }
		fetchFlowByUpid(upid, onlyNeeded) {
			let url = onlyNeeded ? urls.flowByUpidIfNeeded(upid) : urls.flowByUpid(upid)
			return utils.getRequest(url).then((f) => {
				if (f) {
					let flow = new Flow(f)
					this.flows.push(flow)
					return flow
				} else {
					return null
				}
			})
		}

		triggerFlow(flowId) {
			if (!this.activeFlow) {
				let flow = this.findFlow(flowId)
				if (!flow) {
					this.fetchFlow(flowId).then((f) => {
						this.showFlow(f)
					})
				} else {
					this.showFlow(flow)
				}
			}
		}
		triggerFlowByUpid(upid) {
			if (!this.activeFlow) {
				let flow = this.findFlowByUpid(upid)
				if (!flow) {
					this.fetchFlowByUpid(upid, false).then((f) => {
						this.showFlow(f)
					})
				} else {
					this.showFlow(flow)
				}
			}
		}
		triggerFlowByUpidIfNeeded(upid) {
			if (!this.activeFlow && !this.dismiss && !this.isShown(upid)) {
				this.fetchFlowByUpid(upid, true).then((f) => {
					if (f && !this.activeFlow) {
						this.showFlow(f)
					}
				})
			}
		}
}
class Flow {
  constructor(options) {
    this.tips = []
		this.id = options.id
		this.upid = options.upid
    options.tips.sort((a, b) => {
			return a.order - b.order
		}).forEach((item, i) => {
      this.tips.push(new Tip(item))
    });
    this.activeTipIndex = 0
  }
  show(options) {
    if (options && options.showEndCallback) {
      this.showEndCallback = options.showEndCallback
    }
    let tip = this.tips[this.activeTipIndex]
    if (tip){
      this.showTip(tip, options)
    } else {
      options.showEndCallback()
    }
  }
  showTip(tip, options) {
    tip.show({
      nextCallback: () => {
				if (tip.exitAction == "stop") {
					options.showEndCallback()
				} else {
					let nextTip = this.getNextTip()
					if (nextTip) {
						this.showTip(nextTip, options)
					} else {
						options.showEndCallback()
					}
				}
      },
			cancelCallback: () => {
				options.cancelCallback()
			}
    })
  }
  getNextTip() {
    let nextIndex = this.activeTipIndex + 1
    if (nextIndex >= this.tips.length) {
      this.activeTipIndex = 0;
      return null
    } else {
      this.activeTipIndex = nextIndex
      return this.tips[nextIndex]
    }
  }
}
class Tip {
  constructor(options) {
		this.kind = options.kind
		this.content = MultilingualString.fromJSON(options.content).getCurrentValue()
		this.exitAction = options.exitAction
		this.width = options.width
		this.widthType = options.widthType
		this.selector = options.selector
		this.header = options.header
		this.button = options.button
  }
  show(options) {
		if (this.kind && this.kind.id == "2500000010159330460") {
			let content = $("<div>" + this.content + "</div>")
			let nextButton = $(`<button type='button' class='confirm btn btn-danger' style="margin:9px;float:right;">${this.button}</button>`)
			let item = $(this.selector)
			if (item.is(":hidden")) {
				options.nextCallback()
			} else {
				$('body').addClass('no-scroll')
				let top = item.offset().top - 5
				let left = item.offset().left - 5
				let width = item.outerWidth() + 10
				let height = item.outerHeight() + 10
				let backgrounds = $(`<div class='left' style="
																	left:0px;
																	width:${left}px;
																	top:0px;
																	height:100%;
																	position:absolute;
																	z-index:9999;
																	background-color:rgba(0,0,0,.3);">
														</div>
														<div class='top' style="
																	left:${left}px;
																	width:${width}px;
																	top:0px;
																	height:${top}px;
																	position:absolute;
																	z-index:9999;
																	background-color:rgba(0,0,0,.3);">
														</div>
														<div class='bottom' style="
																	left:${left}px;
																	width:${width}px;
																	top:${top+height}px;
																	height:100%;
																	position:absolute;
																	z-index:9999;
																	background-color:rgba(0,0,0,.3);">
														</div>
														<div class='right' style="
																	left:${left + width}px;
																	width:100%;
																	top:0px;
																	height:100%;
																	position:absolute;
																	z-index:9999;
																	background-color:rgba(0,0,0,.3);">
														</div>`)
				let container = $(`
											<div class='super-container' style='
														width:${width}px;
														height:${height}px;
														top:${top}px;
														left:${left}px;
														position:absolute;
														border:1px solid red;
														z-index:10000'>
											</div>`)
				let c = $("<div class='c' style='width:400px'></div>")
				let styleTag = $(`<style>
					.c > .popover {
						max-width: ${this.width}px;
						min-width: ${this.width}px;

					}
				</style>`)
				styleTag.appendTo('body')
				c.appendTo(container)
				container.appendTo('body')
				let hide = false
				nextButton.on('click',() => {
					hide = true
					$(container).popover('hide')
					container.remove()
					styleTag.remove()
					backgrounds.remove()
					$('body').removeClass('no-scroll')
					options.nextCallback()
				})
				let fitLeft = (left - this.width) > 0
				let fitRight = (left + width + this.width) < window.innerWidth
				let direction = 'bottom'
				if (fitLeft) {
					direction = 'left'
				}
				if (fitRight) {
					direction = 'right'
				}
				content.append(nextButton)
				container.popover({
					toggle: 'manual',
					content: content,
					html: true,
					delay: 1000,
					container: c,
					placement: direction
				})
				container.after(backgrounds)
				$(container).popover('show')
				$(container).on('hide.bs.popover', (a) => {
					if (!hide){
						a.preventDefault()
					}
				})
			}
		} else {
			showConfirmModal({
				onCancel: options.cancelCallback,
				onConfirm: options.nextCallback,
				title: this.header,
				confirmButtonText: this.button,
				text: this.content,
				hideCancelButton: true,
				stopCloseOnBackdropClick: true
			})
		}
  }
}

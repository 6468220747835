/* @flow */

import ChronoUnit from '../enums/chronoUnit';

import type { Temporal } from '../declarations';

import {
	NANOS_PER_SECOND,
	HOURS_PER_DAY,
	MINUTES_PER_HOUR,
	SECONDS_PER_HOUR,
	SECONDS_PER_MINUTE,
	SECONDS_PER_DAY,
	MILLIS_PER_SECOND,
	NANOS_PER_MILLI
} from '../constants';

/**
 * Serilized form:
 *   seconds: long
 *   nanos: int
 */

export default class Duration {

	seconds: number;
	nanos: number;

	/**
	 * Private constructor
	 */
	constructor(seconds: number, nanos: number) {
		this.seconds = seconds;
		this.nanos = nanos;
	}

	static ofDays(days: number): Duration {
		return new Duration(days * HOURS_PER_DAY * MINUTES_PER_HOUR * SECONDS_PER_MINUTE, 0);
	}

	static ofHours(hours: number): Duration {
		return new Duration(hours * MINUTES_PER_HOUR * SECONDS_PER_MINUTE, 0);
	}

	static ofMillis(millis: number): Duration {
		const seconds = millis / MILLIS_PER_SECOND | 0;
		const milliAdjustment = millis % MILLIS_PER_SECOND;
		return Duration.ofSeconds(seconds, milliAdjustment * NANOS_PER_MILLI);
	}

	static ofMinutes(minutes: number): Duration {
		return new Duration(minutes * SECONDS_PER_MINUTE, 0);
	}

	static ofNanos(nanos: number): Duration {
		const seconds = nanos / NANOS_PER_SECOND | 0;
		const nanoAdjustment = nanos % NANOS_PER_SECOND;
		return Duration.ofSeconds(seconds, nanoAdjustment);
	}

	static ofSeconds(seconds: number, nanoAdjustment ? : number): Duration {
		let plusSeconds = 0;
		let nanos = 0;
		if (nanoAdjustment) {
			nanos = nanoAdjustment % NANOS_PER_SECOND;
			plusSeconds = Math.floor(nanoAdjustment / NANOS_PER_SECOND);
			nanos = nanoAdjustment - plusSeconds * NANOS_PER_SECOND;
		}
		return new Duration(seconds + plusSeconds, nanos);
	}

	static between(start: Temporal, endExclusive: Temporal): Duration {
		const nanos = start.until(endExclusive, ChronoUnit.NANOS);
		return Duration.ofNanos(nanos);
	}

	static fromJSON(value: ? any): ? Duration {
		if (value == null) {
			return null;
		}
		return new Duration(value.seconds, value.nanos);
	}

	toJSON() {
		return {
			seconds: this.seconds,
			nanos: this.nanos
		};
	}

	toMoment(): moment$Moment {
		return global.moment.duration(this.toMillis());
	}

	toDays(): number {
		return Math.sign(this.seconds) * Math.floor(Math.abs(this.seconds) / SECONDS_PER_DAY);
	}

	toMinutes(): number {
		return Math.sign(this.seconds) * Math.floor(Math.abs(this.seconds) / SECONDS_PER_MINUTE);
	}

	toHours(): number {
		return Math.sign(this.seconds) * Math.floor(Math.abs(this.seconds) / SECONDS_PER_HOUR);
	}

	toMillis(): number {
		return this.seconds * MILLIS_PER_SECOND + this.nanos / NANOS_PER_MILLI;
	}

	getSeconds(): number {
		return this.seconds;
	}

	getNano(): number {
		return this.nanos;
	}

	get(unit: ChronoUnit) {
		if (unit === ChronoUnit.SECONDS) {
			return this.seconds;
		} else if (unit == ChronoUnit.NANOS) {
			return this.nanos;
		} else {
			throw new Error('Unsupported unit: ' + unit.toString());
		}
	}

	plus(duration: Duration): Duration {
		return Duration.ofSeconds(this.seconds + duration.seconds, this.nanos + duration.nanos);
	}

	plusDays(days: number):Duration {
		return Duration.ofSeconds(this.seconds + days * SECONDS_PER_DAY, this.nanos);
	}

	plusHours(hours: number):Duration {
		return Duration.ofSeconds(this.seconds + hours * SECONDS_PER_HOUR, this.nanos);
	}

	plusSeconds(seconds: number):Duration {
		return Duration.ofSeconds(this.seconds + seconds, this.nanos);
	}

	plusMinutes(days: number):Duration {
		return Duration.ofSeconds(this.seconds + days * SECONDS_PER_MINUTE, this.nanos);
	}

	compareTo(otherDuration: Duration): number {
		const s = this.seconds - otherDuration.seconds
		if (s !== 0) {
			return s;
		}
		return this.nanos - otherDuration.nanos;
	}

	equals(otherDuration: ? any): boolean {
		if (otherDuration == null) {
			return false;
		}
		if (!(otherDuration instanceof Duration)) {
			return false;
		}
		return this.seconds === otherDuration.seconds &&
			this.nanos === otherDuration.nanos;
	}

	getUnits() {
		return Duration.supportedUnits;
	}

	static supportedUnits = [
		ChronoUnit.SECONDS,
		ChronoUnit.NANOS
	];

	static getMethodSupportedEnumValues = Duration.supportedUnits;
}

/* @flow */
import TypeKind from '../enums/typeKind';
import StringKind from '../enums/stringKind';
import Primitives from '../enums/primitiveType';
import {isAssignableTo} from '../components/typeHierarchy';
import PrimitiveEntityType from '../enums/primitiveEntityType';
import MultilingualString from './multilingualString';

import type Field from './field';
import type Block from './block';

import  ViewModifier from '../enums/viewModifier';

export default class Type extends Backbone.Model {

	initialize(): void {
		if (this.get('id')) {
			this.set('id', this.get('id').toString());
		}
		this._fieldsByName = null;
	}

	isPhantomType(): boolean {
		return !!this.get('isPhantom');
	}

	kind(): string {
		return this.get('typeKind');
	}

	treeViewKind():string {
		return this.get('metaObjectTreeViewKind');
	}

	primitive(): any {
		return this.get('primitiveEntityType');
	}

	isPrimitive(): boolean {
		return this.kind() === TypeKind.PRIMITIVE;
	}

	isEmbedded(): boolean {
		return this.kind() === TypeKind.EMBEDDED;
	}

	isTransient(): boolean {
		return this.kind() === TypeKind.TRANSIENT;
	}

	isExternal(): boolean {
		return this.kind() === TypeKind.EXTERNAL;
	}

	isReport(): boolean {
		return this.kind() === TypeKind.REPORT;
	}

	isDocument(): boolean {
		return this.kind() === TypeKind.DOCUMENT;
	}

	isDictionary(): boolean {
		return this.kind() === TypeKind.DICTIONARY;
	}

	isRegister(): boolean {
		return this.kind() === TypeKind.REGISTER;
	}

	hasMetaObject(): boolean {
		return this.kind() === TypeKind.DICTIONARY ||
				this.kind() === TypeKind.REPORT ||
				this.kind() === TypeKind.DOCUMENT;
	}

	reportDataType(): Type {
		return app.types.get(this.get('reportDataTypeId'));
	}

	fields(): any[] {
		return app.fields.byOwnerId(this.get('id')) || [];
	}

	blocks(): any[] {
		return app.blocks.byOwnerId(this.get('id')) || [];
	}

	views() {
		return app.views.byOwnerId(this.get('id')) || [];
	}

	getServerFunctions(): Array<Block> {
		return _.filter(this.blocks(), block => {
			return block.hasServerFunction();
		});
	}

	getClientFunctions(): Array<Block> {
		return _.filter(this.blocks(), block => {
			return block.hasClientFunction();
		});
	}

	hasServerFunctions(): boolean {
		return this.getServerFunctions().length > 0;
	}

	hasClientFunctions(): boolean {
		return this.getClientFunctions().length > 0;
	}

	getShortSystemName(decapitalize: boolean): string {
		let typeName = this.get('systemName');
		let result = (typeName && typeName.substring(typeName.lastIndexOf('.') + 1));
		if (result && decapitalize) {
			return result[0].toLowerCase() + result.substring(1);
		}
		return result;
	}

	isPredefined(): boolean {
		return this.get('isPredefined');
	}

	isSystem(): boolean {
		return this.get('isSystem');
	}

	stringHasTranslations(): boolean {
		return this.get('primitiveTypeProperties') && this.get('primitiveTypeProperties').stringHasTranslations;
	}

	isNullable(): boolean {
		if (this.isPrimitive()) {
			const hasUnboxed = Primitives[this.primitive()].primitive != null;
			if (hasUnboxed) {
				return this.isValueOptional();
			}
		}
		return true;
	}

	isValueOptional(): boolean {
		return this.get('primitiveTypeProperties').isNullable;
	}

	isStringHTML(): boolean {
		return this.get('primitiveTypeProperties') && this.get('primitiveTypeProperties').stringKind == StringKind.HTML;
	}

	isStringShort(): boolean {
		return this.get('primitiveTypeProperties') && this.get('primitiveTypeProperties').stringKind == StringKind.SHORT;
	}

	isStringLong(): boolean {
		return this.get('primitiveTypeProperties') && this.get('primitiveTypeProperties').stringKind == StringKind.LONG;
	}

	isDecimal(): boolean {
		return this.isPrimitive() && this.primitive() === 'DECIMAL';
	}

	decimalScale(): number {
		// return this.get('primitiveTypeProperties') && this.get('primitiveTypeProperties').decimalScale;
		if (this.isPrimitive()) {
			if (this.primitive() === 'DECIMAL') {
				return this.get('primitiveTypeProperties').decimalScale
			}
		}
		return 0;
	}

	sharedFieldsIds(): Array<string> {
		return this.get('sharedFieldsIds') || [];
	}

	sharedFields(): Array<Field> {
		let fields = [];
		_.each(this.sharedFieldsIds(), function (fieldId) {
			fields.push(app.fields.get(fieldId));
		});
		return fields;
	}

	hasSharedField(fieldId: any): boolean {
		for(let i = 0, l = this.sharedFieldsIds().length; i < l; i++) {
			if (this.sharedFieldsIds()[i] == fieldId) {
				return true;
			}
		}
		return false;
	}

	_groupFieldsByName(): void {
		const fieldsByName = {};
		_.each(this.fields(), (field) => {
			fieldsByName[field.fieldName()] = field;
		});
		_.each(this.sharedFields(), (field) => {
			fieldsByName[field.fieldName()] = field;
		});
		this._fieldsByName = fieldsByName;
	}

	fieldByName(fieldName: string): Field {
		let fields = fieldName.split('.');
		if (fields.length > 1){
			let first = fields[0];
			fields.shift();
			// $SuppressFlow
			return this.fieldByName(first).type().fieldByName(fields.join('.'));
		}
		if (this._fieldsByName == null) {
			this._groupFieldsByName();
		}
		return this._fieldsByName[fieldName];
	}

	defaultValue() {
		if (this.isEmbedded()) {
			return require('../models/entity').default.fromJSON({}, this.id);
		}
		if (this.isNullable()) {
			return null;
		}
		return this.primitiveDefaultValue();
	}

	primitiveDefaultValue() {
		return Primitives[this.primitive()].runtimeDefaultValue;
	}

	defaultCollectionValue() {
		return require('../collections/entities').default.fromJSON([], this.id);
	}

	isTemporalAccessor(): boolean {
		if (!this.isPrimitive()) {
			return false;
		}
		return isAssignableTo(Primitives[this.primitive()].class, 'TemporalAccessor');
	}

	name(): Object {
		return this.get('name');
	}

	currentLanguageName(): Object {
		return (new MultilingualString(this.get('name'))).getCurrentValue();
	}

	systemName(): string {
		return this.get('systemName') || '';
	}

	// is used to construct type name for blocks code
	getTypeName_(): string {
		return this.isPrimitive() ?
			Primitives[this.primitive()]['class'] : (this.systemName() || (`Entity${this.id}`));
	}

	isHidden() {
		return this.get('isHidden');
	}

	defaultView(viewKind) {
		return _.find(this.views(), view => {
			return view.kind() == viewKind &&
				view.viewModifier() == ViewModifier.DEFAULT;
		});
	}

	allFields() {
		return this.fields().concat(this.sharedFields());
	}

	isEmbeddable() {
		return this.isPrimitive() && this.primitive() == PrimitiveEntityType.LOCAL_DATE_RANGE;
	}

	formatter() {
		return this.get('formatter') && app.primitiveFormatters.get(this.get('formatter').id);
	}

	isNotAllowedForApiEntryAsCollection() {
		return this.isPrimitive() && (this.primitive() == PrimitiveEntityType.BINARY
				|| this.primitive() == PrimitiveEntityType.SYSTEM_STRING
				|| this.primitive() == PrimitiveEntityType.JSON_NODE
			);
	}

	dataSourceId() {
		return this.get('dataSourceId');
	}

	primaryKeyField() {
		return this.fields().find(f => f.get("isDataSourceKey"));
	}

	primaryKeyFields() {
		return this.fields().filter(f => f.get("isDataSourceKey"));
	}

	isWithCompositeKey() {
		return this.primaryKeyFields().length > 1;
	}

	tableName() {
		return this.get('tableName');
	}

	decimalFields() {
		return this.allFields().filter((f) => {
			return f.type() && f.type().isDecimal()
		})
	}

	nonPrimitiveFields() {
		return this.allFields().filter((f) => {
			return !(f.type() && f.type().isPrimitive())
		})
	}
}

export function getCurrentType() {
	let type = app.model && app.types.get(app.model.id)
	if (!type){
		type = app.owner
	}
	return type || new Type()
}

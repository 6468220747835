import { Table } from '@codejig/ui-components'

function validate ($el, options) {
  if (!$el) {
    throw new Error('arg1 must be a DOM element')
  }
  if (!options) {
    throw new Error('args2 must be defined')
  }
  if (!options.rows) {
    throw new Error('Table rows must be defined')
  }
  if (!options.columns) {
    throw new Error('Table columns must be defined')
  }
}

export default function ($el, options) {
  validate($el, options)
  const { rows, columns, table = {}, events = {} } = options
  return {
    connect: function () {
      console.log('Vue.js table connect.')
      let vue = new Vue({
        el: $el,
        data: { rows, columns, table },
        render: h => h(Table, {
          ref: 'table',
          props: { rows, columns, table },
          on: {
            rowClicked: events.rowClicked || _.noop,
            selectionChanged: events.selectionChanged || _.noop,
            editModeExit: events.editModeExit || _.noop,
            afterRender: events.afterRender || _.noop,
            headerDragged: events.headerDragged || _.noop,
            headerClicked: events.headerClicked || _.noop
          }
        })
      }).$refs.table
      const { getSelection, openEditMode, resetMore, getOrder, setOrder, getReorderPermutation, setSelection, setValidation, checkOne, isChecked } = vue
      let api = { getSelection, openEditMode, resetMore, getOrder, setOrder, getReorderPermutation, setSelection, setValidation, checkOne, isChecked }
      return api
    }
  }
}

import MultilingualString from '../../common/models/multilingualString';
import { multilingualStringService } from '../../common/service/multilingualStringService';

/**
 * Available as `app.notificationManager`
 */
class NotificationManager {

	constructor() {
		this.configurePNotify();
	}

	configurePNotify() {
		PNotify.defaults.styling = "bootstrap3";
		PNotify.defaults.icons = "fontawesome4";
		PNotify.defaults.textTrusted = true
		PNotify.modules.Buttons.defaults.sticker = false;
		PNotify.allNotices = [];
		this.constants = {};
		this.constants.stack_bar_bottom = {
			"dir1": "up",
			"firstpos1": 30,
			"spacing1": 10,
			"push": "bottom"
		};
	}

	/**
	 * @param {MultilingualString} message - error message to be shown
	 */
	addError(message: MultilingualString, priority: any){
		message = multilingualStringService.stringToModel(message);
		return this.showMessage(message.getCurrentValue(), 'error', null, priority)
	}

	/**
	 * @param {MultilingualString} message - error message to be shown and trow ex
	 */
	addErrorAndThrow(message: MultilingualString){
		message = multilingualStringService.stringToModel(message);
		this.showMessage(message.getCurrentValue(), 'error')
		throw message
	}

	/**
	 * @param {MultilingualString} message - warning message to be shown
	 */
	addWarning(message: MultilingualString, priority: any){
		message = multilingualStringService.stringToModel(message);
		return this.showMessage(message.getCurrentValue(), 'notice', null, priority)
	}

	/**
	 * @param {MultilingualString} message - info message to be shown
	 */
	addInfo(message: MultilingualString, priority: any){
		message = multilingualStringService.stringToModel(message);
		return this.showMessage(message.getCurrentValue(), 'info', null, priority)
	}

	/**
	 * @param {MultilingualString} message - success message to be shown
	 */
	addSuccess(message: MultilingualString, priority: any){
		message = multilingualStringService.stringToModel(message);
		return this.showMessage(message.getCurrentValue(), 'success', null, priority)
	}

	updateMessage(notification: PNotify, message: MultilingualString) {
		notification.update({
			text: message.getCurrentValue()
		});
	}

	addMessage(message: any){
		this.addMessages([message])
	}

	addMessages(messages: any){
		_.each(messages, m => {
			if (m.kind === 'ERROR'){
				this.addError(m.text);
			}
			if (m.kind === 'WARNING'){
				this.addWarning(m.text);
			}
			if (m.kind === 'INFO'){
				this.addInfo(m.text);
			}
			if (m.kind === 'SUCCESS'){
				this.addSuccess(m.text);
			}
		});
	}

	showMessage(message: string, type: string, options: any, priority: any) {
		let opts = {
			text: message,
			addClass: "pnotify-width",
			stack: this.constants.stack_bar_bottom,
			delay: 2500,
			width: "35vw",
			priority: priority
		};
		if (options) {
			_.extend(opts, options);
		}

		const now = new Date();
		PNotify.allNotices.push({
			time: now.getHours() + ':' + now.getMinutes(),
			message: message,
			type: type
		})

		if(priority || this.isFreeSpaceForNtf(type)) {
			return this._showMessage(type, opts);
		}
	}

	_showMessage(type: string, opts: any) {
		let notification;
		switch (type) {
			case 'error':
				opts.delay = 10000;
				notification = new PNotify.error(opts);
				break;
			case 'info':
				notification = new PNotify.info(opts);
				break;
			case 'notice':
				opts.delay = 10000;
				notification = new PNotify.notice(opts);
				break;
			case 'success':
				notification = new PNotify.success(opts);
				break;
		}
		$(notification.refs.textContainer).on('click', () => this.showErrorModal());
		return notification;
	}

	showTooMuchMesssage(type) {
		let ntf = PNotify.notices.filter(notice => notice.options.data.isMoreAlert && notice.options.data.type == type)[0];
		if (ntf) {
			ntf.options.data.numMore = ntf.options.data.numMore + 1;
			$(ntf.refs.textContainer).children('span').text(`${ntf.options.data.numMore} more ${type} messages.`);
		} else {
			let message  = new MultilingualString();

			ntf = this._showMessage(type, {
				text: multilingualStringService.stringToModel(message).getCurrentValue(),
				addClass: "pnotify-width",
				stack: this.constants.stack_bar_bottom,
				delay: 2500,
				width: "35vw",
				isMoreAlert: true,
				numMore: 1
			});

			$(ntf.refs.textContainer).html(`<span>1 more ${type} message. </span><a style="margin-left:10px;color: #3c8dbc;">Details</a>`);
		}
	}

	removeNtf(type) {
		for(var i=PNotify.notices.length-1; i>=0; i--) {
			let notice = PNotify.notices[i];
			if(notice.options.data.type == type && !notice.options.data.isMoreAlert && !notice.options.data.priority) {
				PNotify.notices[i].remove();
				PNotify.notices = PNotify.notices.slice(0, i).concat(PNotify.notices.slice(i+1, PNotify.notices.length));
				break;
			}
		}
		this.showTooMuchMesssage(type);
	}

	isFreeSpaceForNtf(type) {
		if(PNotify.notices.length >= 8) {
			var warningNtc = PNotify.notices.filter(notice => notice.options.data.type == "notice" && !notice.options.data.isMoreAlert && !notice.options.data.priority);
			var infoNtc = PNotify.notices.filter(notice => notice.options.data.type == "info" && !notice.options.data.isMoreAlert && !notice.options.data.priority);

			if (type == 'error' || type == 'success') {
				if(infoNtc.length > 0) {
					this.removeNtf('info');
					return true;
				} else if(warningNtc.length > 0) {
					this.removeNtf('notice');
					return true;
				}
			} else if (type == 'notice') {
				if(infoNtc.length > 0) {
					this.removeNtf('info');
					return true;
				}
			}
			this.showTooMuchMesssage(type);
			return false;
		}
		return true;
	}


	buildCard(type, list) {
		if(list.length) {
			let cardBody = "";
			list.forEach(ntf => {
				cardBody = cardBody +`
					<li>
						<span class="ntf-time">`+ ntf.time + ` - </span>
						<span class="ntf-message">`+ ntf.message +`</span>
					</li>
				`;
			})

			let card = $(`
				<div class="card `+ type + `-card">
					<div class="card-header" id="heading-`+ type +`">
						<h5 class="mb-0">
							<button class="btn btn-link" data-toggle="collapse" data-target="#collapse-`+ type +`" aria-expanded="true" aria-controls="collapse-`+ type +`">
							`+ type.toLocaleUpperCase() +`
							</button>
						</h5>
					</div>
				
					<div id="collapse-`+ type +`" class="collapse" aria-labelledby="heading-`+ type +`" data-parent="#errorsAccordion">
						<div class="card-body">`+ cardBody +`</div>
					</div>
				</div>`);
			
			return card;
		}
	}

	showErrorModal() {
		if(app && app.errorsModal) {
			let html = $("<div></div>");

			let info = PNotify.allNotices.filter(ntf=>ntf.type == "info").reverse();
			let error = PNotify.allNotices.filter(ntf=>ntf.type == "error").reverse();
			let notice = PNotify.allNotices.filter(ntf=>ntf.type == "notice").reverse();
			let success = PNotify.allNotices.filter(ntf=>ntf.type == "success").reverse();

			html.append(this.buildCard("error", error));
			html.append(this.buildCard("success", success));
			html.append(this.buildCard("notice", notice));
			html.append(this.buildCard("info", info));

			app.errorsModal.show({html: html});
		}
	}
}

export default NotificationManager

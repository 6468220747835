/* @flow */

import type ZoneRules from './zone/zoneRules';

export default class ZoneId {

	id: string;

	constructor(id: string) {
		this.id = id;
	}

	static of(id: string) {
		if (id === 'Z' || id === '+' || id === '-') {
			return require('./zoneOffset').default.of(id);
		}
		if (id.startsWith('UT') || id.startsWith('UTC') || id.startsWith('GMT')) {
			throw new Error('Not implemented');
		}
		return require('./regionZone').default.of(id);
	}

	static systemDefault(): ZoneId {
		global.momentJsHelper.loadTimezonePackage();
		const zone: string = global.moment.tz.guess();
		return require('./regionZone').default.of(zone);
	}

	getId(): string {
		return this.id;
	}

	getRules(): ZoneRules {
		throw new Error('abstract');
	}
}

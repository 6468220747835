/* @flow */

import ZoneId from './zoneId';
import ZoneRulesProvider from './zone/zoneRulesProvider';

import type ZoneRules from './zone/zoneRules';

export default class RegionZone extends ZoneId {

	rules: ZoneRules;

	constructor(zoneId: string) {
		super(zoneId);
	}

	static of(id: string) {
		return new RegionZone(id);
	}

	getRules(): ZoneRules {
		if (this.rules == null) {
			this.rules = ZoneRulesProvider.getRules(this.id, true);
		}
		return this.rules;
	}
}

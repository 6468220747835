/* @flow */
import {buildDefaultPopover} from '../common/components/utils'
import FormatterInfo from '../common/service/formatterInfo'

export default class BuilderInfo {

	render(): void {
		buildDefaultPopover($('#button-info'),
		{
			content: `<table>
								<tr><td>${app.getResource('builder')}</td><td><span>${FormatterInfo.formatValue(app.info.builderName)}</span></td></tr>
								<tr><td>${app.getResource('owner')}</td><td><span>${FormatterInfo.formatOwner(app.info.owner)}</span></td></tr>
								<tr><td>${app.getResource('core')}</td><td><span>${FormatterInfo.formatValue(app.info.version)}</span></td></tr>
								<tr><td>${app.getResource('common')}</td><td><span>${FormatterInfo.formatValue(app.info.versionCommon)}</span></td></tr>
								</table>
								<div style="overflow-y: auto; width: 300px; max-height: 150px; margin-top:8px;">${FormatterInfo.formatValue(app.info.license)}</div>`,
			placement: 'bottom',
			html: true
		});
	}
}

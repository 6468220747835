/* @flow */

import utils from '../../common/components/utils.js';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import DayOfWeek from '../../time/models/dayOfWeek';
import Formatter from '../../common/components/formatter';
import BaseSelect from '../../common/components/baseSelect';

const DayOfWeekSelect = Backbone.View.extend({

	events: {
		'change': 'changed'
	},

	initialize: function(options) {
		var that = this;
		this.modelAttr = options.modelAttr;
		this.context = options.context;
		const primitiveType = utils.getPrimitiveType(this.$el);
		const format: string = this.$el.attr('data-format');
		new BaseSelect({
			el:this.$el,
			data:_.map(DayOfWeek.values(), (value, index) => {
				return {
					text: Formatter.format(value.toJSON(), {
						primitiveType: PrimitiveEntityType.DAY_OF_WEEK,
						format: format
					}),
					id: value.toJSON()
				};
			})
		})
		if (this.model) {
			this.listenTo(this.model, 'change:' + this.modelAttr, this.render);
		}
	},

	render: function() {
		this.setValue(this.getDataFromModel());
	},

	setValue: function(value: ? DayOfWeek) {
		this.$el.val(value && value.toJSON()).trigger('change');
	},

	getValue: function() {
		return this.$el.val();
	},

	getObject: function() {
		return this.$el.val() ? DayOfWeek.valueOf(this.$el.val()) : null;
	},

	getDataFromModel: function() {
		return this.model && this.model.get(this.modelAttr);
	},

	setDataToModel: function(obj: DayOfWeek): void {
		if (this.model && !_.isEqual(this.model.get(this.modelAttr) &&
				this.model.get(this.modelAttr).toJSON(), obj && obj.toJSON())) {
			this.model.set(this.modelAttr, obj);
		}
	},

	changed: function() {
		this.setDataToModel(this.getObject());
		if (this.model) {
			this.model.trigger('manualChange:' +  this.modelAttr, this.model);
		}
	},

	enable: function() {
		this.$el.removeAttr('disabled');
	},

	disable: function() {
		this.$el.attr('disabled', 'disabled');
	}

});

export default DayOfWeekSelect;

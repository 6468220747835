/* @flow */

import FieldKind from '../enums/fieldKind';
import type Type from './type';

export default class Field extends Backbone.Model {

	isPhantomField(): boolean {
		return this.get('isPhantomField') === true;
	}

	type(): ?Type {
		return app.types.get(this.get('fieldTypeId') || (this.get('fieldType') && this.get('fieldType').id));
	}

	keyType(): ?Type {
		return this.get('keyTypeId') && app.types.get(this.get('keyTypeId'))
	}

	owner(): ?Type {
		return app.types.get(this.get('ownerId'));
	}

	kind(): string {
		return this.get('fieldKind');
	}

	isShared(): boolean {
		return this.get('isShared');
	}

	isCollection(): boolean {
		return !this.isPhantomField() && this.kind() === FieldKind.COLLECTION;
	}

	isMap(): boolean {
		return !this.isPhantomField() && this.kind() === FieldKind.MAP;
	}

	isDynamic(): boolean {
		return !this.isPhantomField() && this.kind() === FieldKind.DYNAMIC;
	}

	isPredefined(): boolean {
		return this.get('isPredefined');
	}

	isSystem(): boolean {
		return this.get('isSystem');
	}

	isReadOnly(): boolean {
		return this.get('isReadOnly');
	}

	isGdprSensitive(): boolean {
		return this.get('isGdprSensitive');
	}

	isCompositeKey() {
		return this.isDataSourceKey() && this.owner().isWithCompositeKey();
	}

	getShortSystemName(capitalize: boolean): string {
		let fieldName = this.get('systemName');
		let result = (fieldName && fieldName.substring(fieldName.lastIndexOf('.') + 1));
		if (result && capitalize) {
			return result[0].toUpperCase() + result.substring(1);
		}
		return result;
	}

	systemName(): string {
		return this.get('systemName') || '';
	}

	columnName(): string {
		return this.get('columnName') || this.fieldName(false);
	}

	mappedById() : EntityField {
		return this.get('mappedById')
	}

	isDataSourceKey(): boolean {
		return this.get('isDataSourceKey');
	}

	isAutoIncrement(): boolean {
		return this.get('isAutoIncrement');
	}

	fieldName(capitalize: boolean): string {
		if (this.isPredefined()) {
			return this.getShortSystemName(capitalize);
		}
		let result = this.systemName() || `field${this.id}`;
		if (capitalize) {
			return result[0].toUpperCase() + result.substring(1);
		}
		return result;
	}

	defaultValue() {
		if (this.kind() == FieldKind.REGULAR) {
			// $SuppressFlow design problem
			return this.type().defaultValue();
		}
		if (this.kind() == FieldKind.COLLECTION) {
			// $SuppressFlow
			return this.type().defaultCollectionValue();
		}
		return null;
	}

	name(): any {
		return this.get('name');
	}

	parent(): any {
		return this.get('parent');
	}

	isVirtual() {
		return this.get('isVirtual');
	}

	isTransient() {
		return this.get('isTransient');
	}

	isHidden() {
		return this.get('isHidden');
	}
}

import MultilingualString from '../../common/models/multilingualString';
import UserActionKind from '../../common/enums/userActionKind';
import BaseModel from '../../common/models/baseModel';
import PermissionFilterCollection from './permissionFilterCollection';
import PermissionFilter from './permissionFilter';
import PermissionConstants from './permissionConstants';

export default class PermissionModel extends BaseModel {
	initialize (o) {
		this.set('configurationId', o.configurationId);
		if (o && o.name) {
			this.set('name', new MultilingualString(o.name));
		}
		this.set('networkWhitelist', o && o.networkWhitelist && o.networkWhitelist.id);

		this.set('defaultFilter', new PermissionFilter(this.defaultFilterFromServerJSON(o && o.defaultFilter || null)));
		this.set('filters', new PermissionFilterCollection(this.filtersFromServerJSON(o && o.filters || {})));
		if (o && o.customAccessDeniedPageHtml) {
			this.set('customAccessDeniedPageHtml', new MultilingualString(o.customAccessDeniedPageHtml));
		}
		this.listenTo(this.get('name'), 'change',
				() =>  this.trigger('change:name', this));
	}

	update (o) {
		this.stopListening();
		this.initialize(o);
	}

	toServerJSON () {
		return {
			id: this.id,
			configurationId: this.get('configurationId'),
			name: this.get('name').toJSON(),
			defaultFilter: this.get('defaultFilter') ? this.get('defaultFilter').toServerJSON() : null,
			filters: this.get('filters') ? this.filtersToServerJSON(this.get('filters').models) : {},
			redirectAnonymousUserToAccessDeniedPage: this.get('redirectAnonymousUserToAccessDeniedPage'),
			hasCustomAccessDeniedPage: this.get('hasCustomAccessDeniedPage'),
			customAccessDeniedPageHtml: this.get('customAccessDeniedPageHtml') ? this.get('customAccessDeniedPageHtml').toJSON() : null,
			networkWhitelist: this.get('networkWhitelist') ? {
				id: this.get('networkWhitelist')
			} : null
		};
	}

	filtersToServerJSON(filters) {
		let result = {};
		for (let i = 0; i < filters.length; i++) {
			const filter = filters[i];
			const json = filter.toServerJSON();
			if (json) {
				result[filter.get('actionKind')] = json;
			}
		}
		return result;
	}

	filtersFromServerJSON(filters){
		let dfsOrder = UserActionKind.getDfsOrder();
		const data = _.chain(app.userActionKind)
			.filter(UserActionKind.showPermissionFilter)
			.sortBy(kind => dfsOrder[kind]) //WARN :: probably that's not the best solution
																			// should be changed when refactoring this page with Vue
			.map(kind => {
					const resultFilter = filters[kind] ? PermissionFilter.parseJSON(filters[kind]) : {};
					resultFilter.actionKind = kind;
					return resultFilter;
				})
			.value();
		return data;
	}

	defaultFilterFromServerJSON(filter){
		const resultFilter = filter ? PermissionFilter.parseJSON(filter) : {};
		resultFilter.actionKind = PermissionConstants.DEFAULT_USER_ACTION_KIND;
		return resultFilter;
	}
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { position: "relative" } }, [
    _c("div", {
      ref: "filter",
      staticClass: "filters",
      staticStyle: { display: "inline" }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "pull-right" }, [
      _c(
        "button",
        {
          ref: "extended",
          staticClass: "btn btn-default",
          on: { click: _vm.showColumnList }
        },
        [_c("span", { staticClass: "glyphicon glyphicon-th-large" })]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "clearfix" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "newTableWrapper" },
      [
        _c("Table", {
          ref: "table",
          staticClass: "highViewport",
          attrs: {
            table: { loadMore: _vm.loadMore, rowClasses: _vm.rowClasses },
            rows: _vm.rows,
            columns: _vm.columns
          },
          on: { rowClicked: _vm.onClickCell }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import BaseCollection from '../../common/collections/baseCollection.js';
import EntityMenuItem from './entityMenuItem';

const EntityMenuItems = BaseCollection.extend({

	model: EntityMenuItem,

	constructor: function (data) {
		EntityMenuItems.__super__.constructor.apply(this, arguments);
		this.forEach(item => {
			if (item.get('parent')) {
				item.set('parent', this.get(item.get('parent').id) || 'c' + item.get('parent').clientId);
			}
		});
	}
});

export default EntityMenuItems;

import JournalView from './journalView.js';



class JournalPresenter {

  constructor() {}



  present(options) {
    const documentId = options.documentId;
    const $el = options.$el;
    $el.append($('<div>').addClass('journal content'));
    let journal = new JournalView({
      $el: $el,
      documentId: documentId
    })
  }
};

export default new JournalPresenter();

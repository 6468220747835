import entityManager from '../../common/components/entityManager'
import utils, {showInstance} from '../../common/components/utils'
import {urls} from '../../common/service/linkBuilder'
import {translate} from '../../common/service/stringResourceService'
import OpenMode from '../../common/enums/openMode'


export default class SeedInstancesList extends Backbone.View {


		initialize(o) {
			this.events = this.events || {};
			_.extend(
					this.events,
					SeedInstancesList.prototype.events, {
						'click .openInstance': '_open',
						'click .deleteInstance': '_delete',
						'click .createInstance': '_createNew'
					}
			);
			this.delegateEvents();
			this.type = o.type;
			this.instancesViewer = o.instancesViewer;
		}

		render() {
			let that = this;
			this.$el.empty();
			let instances = app.instances[this.type.id] || [];
			$(`<li class="createInstance"><a href="#">
				${translate('create.new')}
			</a></li>`).appendTo(this.$el);
			_.sortBy(instances, 'id').forEach(x => {
				let $li = $(`<li class="openInstance" data-id="${x.id}">`).appendTo(this.$el);
				let $a = $('<a>').attr('href', 'javascript:void(0);').appendTo($li);
				let $text = $(`<span>${translate('loading.string.view')}</span>`).appendTo($a)
				entityManager.fetchStringView(null, x.id)
					.then(sv => $text.text(sv));
				$a.append(`<span class="pull-right dropdown field-options">
												<a href="#" class="dropdown-toggle" data-toggle="dropdown">
													<span class="glyphicon glyphicon-option-horizontal expand">
												</a>
												<ul class="dropdown-menu">
													<li><a href="${urls.update(that.type.id, x.id)}" target="_blank">
														${translate('open.in.new.tab')}
													</a></li>
													<li class="deleteInstance"><a>
														${translate('delete')}
													</a></li>
												</ul>
										</span>`);
			});
			this.updateAmountOfInstances();
		}

		_delete(e) {
			e.stopPropagation();
			let parent = $(e.currentTarget).parents('.openInstance[data-id]');
			let id = parent.data('id');
			utils.confirmDelete(
				[id],
				urls.dependencies(this.type.id),
				urls.delete(this.type.id),
				null,
				() => {
					parent.remove();
					this.instancesViewer.close();
					const instances = app.instances[this.type.id];
					const idx = _.findIndex(instances, i => i.id == id);
					instances.splice(idx, 1);
					this.updateAmountOfInstances();
				});
		}

		_open(e) {
			let id = $(e.currentTarget).data('id');
			if ($(e.target).is('.dropdown, .dropdown *')) {
				return ;
			}
			showInstance({
				openMode: OpenMode.NESTED_WINDOW,
				forceReplace: true,
				typeId: this.type.id,
				objectId: id,
				preventPageLeave: false,
				callback: () => {
					entityManager.evictStringView(id);
					this.render();
				}
			})
		}

		_createNew() {
			showInstanceViewer(this.instancesViewer, this.type.id, () => { this.render() });
		}

		updateAmountOfInstances() {
			let instances = app.instances[this.type.id] || [];
			if (instances.length > 0) {
				$('.amount-of-instances').show();
			} else {
				$('.amount-of-instances').hide();
			}
			$('.amount-of-instances').text(instances.length);
		}
}

export function showInstanceViewer(instancesViewer, typeId, afterCreate) {
	showInstance({
		openMode: OpenMode.NESTED_WINDOW,
		typeId: typeId,
		preventPageLeave: false,
		callback: data => {
			app.instances[typeId] = app.instances[typeId] || [];
			app.instances[typeId].push({
				id: data.item.id,
				owner: {
					id: typeId
				}
			})
			afterCreate()
		}
	});
}

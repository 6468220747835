/* @flow */

import ChronoField from '../enums/chronoField';
import LocalDate from './localDate';

import type ZoneId from './zoneId';

import {
	MONTHS_PER_YEAR
} from '../constants';

import {
	checkValidInclusive,
	checkValidDayOfMonth
} from '../utils';

/**
 * Serilized form:
 *   month: int
 *   day: int
 */

export default class MonthDay {

	month: number;
	day: number;

	constructor(month: number, day: number) {
		checkValidInclusive(MONTHS_PER_YEAR, month);
		checkValidDayOfMonth(month, day);
		this.month = month;
		this.day = day;
	}

	static fromJSON(value: ?any): ?MonthDay {
		if (value == null) {
			return null;
		}
		return new MonthDay(value.month, value.day);
	}

	static of(month: number, dayOfMonth: number): MonthDay {
		return new MonthDay(month, dayOfMonth);
	}

	static now(zone: ZoneId): MonthDay {
		const date = LocalDate.now(zone);
		return new MonthDay(date.getMonthValue(), date.getDayOfMonth());
	}

	toJSON() {
		return {
			month: this.month,
			day: this.day
		}
	}

	toMoment() {
		return global.moment.utc({
			year: 2016,
			month: this.month - 1,
			day: this.day
		});
	}

	static fromMoment(moment: moment$Moment): MonthDay {
		return MonthDay.of(moment.month() + 1, moment.date());
	}

	getDayOfMonth(): number {
		return this.day;
	}

	getMonthValue(): number {
		return this.month;
	}

	get(field: ChronoField): number {
		switch (field) {
			case ChronoField.MONTH_OF_YEAR: return this.month;
			case ChronoField.DAY_OF_MONTH: return this.day;
		}
		throw new Error('Invalid field');
	}

	compareTo(other: MonthDay): number {
		const m = this.month - other.month;
		if (m !== 0) {
			return m;
		}
		return this.day - other.day;
	}

	equals(other: ?any): boolean {
		if (other == null) {
			return false;
		}
		if (!(other instanceof MonthDay)) {
			return false;
		}
		return this.month === other.month &&
				this.day === other.day;
	}

	isAfter(other: MonthDay): boolean {
		return this.compareTo(other) > 0;
	}

	isBefore(other: MonthDay): boolean {
		return this.compareTo(other) < 0;
	}

	isSupported(field: ChronoField): boolean {
		if (field instanceof ChronoField) {
			return MonthDay.supportedFields.indexOf(field) > -1;
		}
		throw new Error('Not supported type of the argument');
	}

	static supportedFields = [
		ChronoField.MONTH_OF_YEAR,
		ChronoField.YEAR
	];

	static getMethodSupportedEnumValues = MonthDay.supportedFields;
}

import utils from '../components/utils';
import BaseSelect from '../components/baseSelect';
import BaseModel from '../models/baseModel';
import FieldInput from '../../common/components/fieldInput';
import MultilingualInputField from '../components/multilingualInputField.js';
import MultilingualString from '../models/multilingualString';
import CheckboxInput from '../../common/components/checkboxInput';
import {buildHelpPopover} from '../../help/docsResource' 

var BaseCreateView = Backbone.View.extend({

	initialize: function (options) {
		this.setElement($(options.$el));
		this.$el.append(this.getHTML());
		this.$el.find('[help-popover]').each(function (a, elem) {
			buildHelpPopover((elem))
		})
		this.inputs = {};
		this.selects = {};

		this.initializeSelects();

		this.initializeMultilingualInputs();
		this.initializeInputs();
		this.initializeCheckboxes()
	},


	initializeMultilingualInputs: function() {
		var that = this;
		this.$el.find('input[data-is-string-multilingual]')
			.each(function(event) {
				$(this).val("");
				var model = utils.getChildModel(that.model, $(this));
				var options = that.model.get(model.attr) &&
					that.model.get(model.attr).toJSON();
				that.model.set(model.attr, new MultilingualString(options));
				var ModelType = that.models && that.models[model.attr] || MultilingualInputField;
				var input = new ModelType({
					model: model.model,
					modelAttr: model.attr,
					el: $(this)
				});
				that.inputs[model.attr] = input;
		});
	},

	initializeSelects: function() {
		var that = this;
		this.$el.find('select[data-field]').each(function(event) {
			var model = utils.getChildModel(that.model, $(this));
			var ModelType = that.models && that.models[model.attr] || BaseSelect;
			var select = new ModelType({
				model: model.model,
				modelAttr: model.attr,
				el: this
			});
			that.selects[model.attr] = select;
		});
	},

	initializeInputs: function() {
		var that = this;
		this.$el.find('input[data-field], textarea[data-field]')
			.not('.multilingual input, .multilingual textarea')
			.not('input[data-is-string-multilingual], textarea[data-is-string-multilingual]')
			.each(function (event) {
				$(this).val("");
				var model = utils.getChildModel(that.model, $(this));
				var ModelType = that.models && that.models[model.attr] || FieldInput;
				var input = new ModelType({
					model: model.model,
					modelAttr: model.attr,
					el: this
				});
				that.inputs[model.attr] = input;
			});
	},

  initializeCheckboxes: function () {
    var that = this;
    this.$el.find('input[data-field][type=checkbox]').each(function (event) {
      var model = utils.getChildModel(that.model, $(this));
      var input = new CheckboxInput({
        model: model.model,
        modelAttr: model.attr,
        el: this
      });
      that.inputs[model.attr] = input;
    });
  },

	saveConfiguration: function () {
		return this.model.toJSON();
	}

});

export default BaseCreateView;

import utils from '../../common/components/utils'
import BinaryUploaderVue from './BinaryUploaderVue.vue'

function validate ($el, options) {
  if (!$el) {
    throw new Error('arg1 must be a DOM element')
  }
  if (!options) {
    throw new Error('args2 must be defined')
  }
}

export default function ($el, options) {
  validate($el, options)
  return {
    connect: function () {
      let events = options.events || {}
      let vue = new Vue({
        el: $el,
        render: h => h(BinaryUploaderVue, {
          ref: 'binaryInput',
          props: {
            props: {
              binaryData: options.binaryData,
              dzOptions: Object.assign(
                {
                  url: options.uploadFileUrl || app.urls.uploadFile,
                  headers: utils.getCSRFHeaders()
                },
                options.dzOptions
              ),
              defaultMessage: app.getResource('browse.or.drop'),
              defaultMessageHtml: options.defaultMessageHtml,
              defaultNoFileMessage: app.getResource('no.file'),
              previewTemplateFn: options.previewTemplateFn,
              fileDescriptionFn: options.buildFileDescriptionFn,
              fileDownloadUrlFn: options.buildFileDownloadUrlFn,
            },
            events: {
              onError: function (file, response) {
                app.taskManager.showResult(response);
              },
              onSuccess: events.onSuccess || _.noop,
              onRemovedFile: events.onRemovedFile || _.noop,
              onReset: events.onReset || _.noop,
              onComplete: events.onComplete || _.noop
            }
          }
        })
      }).$refs.binaryInput
      let binaryInput = vue.$refs.binaryInput
      let api = {
        openSelectionWindow: binaryInput.openSelectionWindow,
        enable: binaryInput.enable,
        disable: binaryInput.disable,
        setValue: binaryInput.setValue
      }
      return api
    }
  }
}

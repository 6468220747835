/* @flow */

import BaseModel from '../../common/models/baseModel';
import { translate } from '../../common/service/stringResourceService.js';
import utils from "../../common/components/utils";
import CheckboxInput from "../../common/components/checkboxInput";

export default class MergeTemplateModal extends Backbone.View {

	constructor() {
		super({
			events: {}
		});
	}

	initialize () {
		let $el = $('#merge-template-modal');
		if ($el.length == 0) {
			var el = document.createElement('div')
			el.id = 'merge-template-modal'
			el.classList.add('modal')
			el.classList.add('fade')
			document.body.appendChild(el)
			$el = $('#merge-template-modal')
		}
		$el.html(this.getHTML())
		this.setElement($el);
		this.publishBtn = this.$el.find('.okButton');
		this.cancelBtn = this.$el.find('.cancelButton');
	}

	getHTML () {
		return `
		<div class="modal-dialog modal-md">
			<div class="modal-content">
				<div class="modal-header create-modal-header">
					<h4>
						<span id="headerText">${translate('template.import')}</span>
					</h4>
				</div>
				<div class="modal-body">
						<span id="headerText">${translate('template.publish.modal.text')}</span>
						<div class="modal-row-margin" style="margin-top: 20px;">
							<div class="replaceRoute"></div>
							<span class="modal-row-text">${translate('reset.start.page')}</span>
						</div>
						<div class="modal-row-margin">
							<div class="replaceMenu"></div>
							<span class="modal-row-text">${translate('reset.menu')}</span>
						</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-default cancelButton" data-action="cancel"
							data-dismiss="modal">${app.getResource('continue.without.publishing')}
					</button>
					<button type="button" class="btn btn-danger pull-right okButton" data-action="create"
							data-dismiss="modal">${app.getResource('publish.and.continue')}
					</button>
				</div>
			</div>
		</div>`;
	}

	show (options: Object) {
		this.model = options.model || new BaseModel({});
		this.model.set('replaceRoute', true);
		this.model.set('replaceMenu', true);
		new CheckboxInput({
			el: this.$el.find('.replaceRoute')[0],
			model: this.model,
			modelAttr: 'replaceRoute'
		});

		new CheckboxInput({
			el: this.$el.find('.replaceMenu')[0],
			model: this.model,
			modelAttr: 'replaceMenu'
		});

		this.publishBtn.off('click').on('click', e => {
			utils.postRequest({}, app.urls.replaceRoute + '?replaceMenu=' + this.model.get('replaceMenu') + '&replaceRoute=' + this.model.get('replaceRoute'))
			app.buildView._onPublish(e, true);
			utils.reload();
		});

		this.cancelBtn.off('click').on('click', e => {
			utils.postRequest({}, app.urls.replaceRoute + '?replaceMenu=' + this.model.get('replaceMenu') + '&replaceRoute=' + this.model.get('replaceRoute'))
			utils.reload();
		});

		this.$el.modal('show')
	}
}

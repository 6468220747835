import MultilingualString from '../models/multilingualString';

function translate (key) {
  return app.getResource(key)
}
function translateToMls (key) {
	const json = { translations: {} };
	json.translations[app.currentLanguage] = translate(key);
	return new MultilingualString(json);
}
function fillResourceValues ($item) {
  $item.find('[data-resource-key]').each((ind, el) => {
    let $el = $(el);
    let val = $el.attr(`data-resource-key`);
    $el.html(app.getResource(val))
  })
}
export { translate, translateToMls, fillResourceValues }

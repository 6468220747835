import BaseCreateView from '../../common/views/baseCreateView';

let CreateNamedEntityView = BaseCreateView.extend({

	getHTML: function () {
		return `<div class="grid-1x-padding">
										<label for="name">${app.getResource('name')}</label>
									</div>
									<div class="grid-2x-padding">
										<input id="viewName" class="form-control" type="text" data-is-string-multilingual="short" data-field="name"/>
									</div>`;
	}

});

export default CreateNamedEntityView;

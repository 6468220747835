/* @flow */
import Constants from '../../common/models/constants';
import FieldSelect from '../../common/components/fieldSelect';
import MultilingualString from '../../common/models/multilingualString'
import FieldKind from '../../common/enums/fieldKind'
import {buildDefaultPopover} from '../../common/components/utils';
import Primitives from '../../common/enums/primitiveType';
import AggregateKind from '../../common/enums/aggregateKind';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';

export default /*ListWithFieldSelect*/ Backbone.View.extend({

	initialize(options) {
		this.modelAttr = options.modelAttr;
		this.model = this.model.get(this.modelAttr);
		this.items = [];
		this.waitForJqueryUiLoad = options.waitForJqueryUiLoad
		this.hasFormatters = options.hasFormatters

		this.fieldSelectModel = new Backbone.Model({
			field: null
		});
		let entityTypeId = options.model.get('entityTypeId') || options.model.entityTypeId
		let reportDataType = app.types.get(app.types.get(entityTypeId).get('reportDataTypeId'))
		let fields = app.fields.filter((f) => {
			return (f.get('ownerId') == reportDataType.id || (reportDataType.get('sharedFieldsIds').includes(f.id) && !f.get('isSystem'))) && f.get('fieldKind') != FieldKind.COLLECTION
		})
		let fieldsCollection = new Backbone.Collection(fields.map((f) => {
			return {
				id: f.id,
				name: f.fieldName()
			}
		}))
		this.fieldSelect = new FieldSelect({
			el: this.$('.field-select'),
			model: this.fieldSelectModel,
			parentField: this.modelAttr,
			modelAttr: 'field',
			typeId: Constants.ID_TYPE_FIELD,
			context: options.context,
			dataSource: fieldsCollection
		});
		this.listenTo(this.fieldSelectModel, 'change:field', this.onFieldChange);
		this.listenTo(this.model, 'add', this.addRow);
		this.listenTo(this.model, 'remove', this.removeRow);
	},

	onFieldChange() {
		const field = this.fieldSelectModel.get('field');
		if (field) {
			this.model.add(this.constructItem(field));
			this.fieldSelectModel.set('field', null);
		}
	},

	constructItem(field) {
		// abstract
	},

	removeRow(item) {
		this.$('#row' + item.cid).remove();

		if (!this.model.length) {
			this.$('.placeholder').show();
		}
	},

	removeAllRows() {
		this.$('.item-row').remove();
	},

	addRow(item) {
		this.$('.placeholder').hide();

		const row = $('<div/>', {
			'id': 'row' + item.cid,
			'data-item-cid': item.cid,
			'class': 'box-header with-border item-row draggable-item'
		});
		const toolsDiv = $('<div class="report-list-tools">')
		const closeButton = $('<i class="material-icons notranslate report-button secondary-button" role="button">close</i>');
		closeButton.click(() => this.model.remove(item));
		toolsDiv.append(closeButton);
		row.append(toolsDiv);

		if (item.field() == null) {
			row.addClass('has-error');
			row.append($('<span>').text('DELETED FIELD'));
			this.$('.items').append(row);
		} else {
			this.constructRow(row, item);
			this.$('.items').append(row);
			this.initializeRow(row, item);
		}
	},


	constructRow(row, item) {
		// abstract
	},

	initializeRow(row, item) {
		// abstract
	},

	_makeDraggable() {
		this.waitForJqueryUiLoad.then(()=>{
			this.$('.items').sortable({
				items: '.draggable-item',
				connectWith: this.$('.items'),
				zIndex: 10001,
				scroll: false,
				helper: function (event, el) {
					return $('<div>').append(el.clone());
				},
				tolerance: 'pointer',
				opacity: 0.6,
				stop: (event, helper) => {
					helper.item.parent().children('.draggable-item').each((index, el)=>{
						this.model.get(el.getAttribute('data-item-cid')).set('relativeOrder',index)
					})
				},
				appendTo: 'body',
				placeholder: 'sort-highlight',
				forcePlaceholderSize: true
			})
		})
	},
	render() {
		this.removeAllRows();
		this.hasFormatters.then(() => {
			this.model.sort();
			this.model.each(item => {
				this.addRow(item);
			});
			this._makeDraggable()
		})
	},

	getFieldType(value) {
		let field = app.fields.get(value.field().id)
		let type = app.types.get(field.get('fieldTypeId'))
		return type
	},

	getFieldPrimitiveType(value) {
		let type = this.getFieldType(value)
		return type && type.get('primitiveEntityType')
	},

	addTextToRow(row, text) {
		let formatterText = row.find('.selected-formatter')
		formatterText.html(`<span>${text ? text : ''}</span>`)
		if (text) {
			formatterText.show()
		} else {
			formatterText.hide()
		}
	},

	addFormatterAsTextToRow (value, row) {
		let selected = value.get('formatter') && app.formattersWithExamples._byId[value.get('formatter').id]
		let text = selected && selected.get('example')
		let selectedType = value.toType();
		
		if(selectedType) {
			this.addTextToRow(row, " ( " + MultilingualString.getCurrentValue(selectedType.get("name")) + " ) " + (text ? text : ''));
		} else {
			this.addTextToRow(row, text);
		}
	},

	addStringViewAsTextToRow (value, row) {
		let text = value.get('stringView') && MultilingualString.getCurrentValue(value.get('stringView').get('name'))
		let selectedType = value.toType();
		
		if(selectedType) {
			this.addTextToRow(row, " ( " + MultilingualString.getCurrentValue(selectedType.get("name")) + " ) " + (text ? text : ''));
		} else {
			this.addTextToRow(row, text);
		}
	},

	setStringView(valueObj, selectedId) {
		let selectedStr = selectedId && app.stringViews.find((s) => { return s.id == selectedId })
		valueObj.set('stringView', selectedStr)
	},

	appendFormatterSelect(toolsDiv, value, primitiveType, isValue) {
		let type = this.getFieldType(value)
		let row = toolsDiv.closest('.item-row')
		if (type && !type.get('isPredefined') && this.buildDataForStringViewSelect(type.id).length > 1 && !isValue) {
			this.addTypeAsTextToRow = this.addStringViewAsTextToRow;

			this.setStringView(value, value.get('stringView') && value.get('stringView').id)
			let stringViewSelect = this.addFormatterOrStringView(toolsDiv, value, 'stringView', 'S')
			this.addStringViewAsTextToRow(value, row)
			stringViewSelect.on('change', () => {
				this.setStringView(value, stringViewSelect.val())
			})
		} else if (primitiveType && app.primitiveFormatters.find((f) => { return f.get('primitiveType') == primitiveType }) || isValue) {
			this.addTypeAsTextToRow = this.addFormatterAsTextToRow;

			let formatterSelect = this.addFormatterOrStringView(toolsDiv, value, 'formatter', 'F')
			this.addFormatterAsTextToRow(value, row)
			formatterSelect.on('change', () => {
				value.set('formatter', app.primitiveFormatters._byId[formatterSelect.val()]);
			})
		}
	},

	appendTypeSelect(toolsDiv, value, primitiveType) {
		if (primitiveType) {
			let typeSelect = this.addTypeSelect(toolsDiv, value);
			let row = toolsDiv.closest('.item-row');

			typeSelect.on('change', () => {
				if(typeSelect.val()){
					value.set('toType', {"id": typeSelect.val()});
				} else {
					value.set('toType', null);
				}

				this.initFormatterSelect(row, value, (value.toType() && value.toType().get('primitiveEntityType')) || 
					(value.get('aggregateKind') == AggregateKind.CUSTOM && (value.get('formatterOptions') && value.get('formatterOptions').get('type') && value.get('formatterOptions').get('type').get('primitiveEntityType'))) ||
					((value.get('aggregateKind') == AggregateKind.COUNT || value.get('aggregateKind') == AggregateKind.AVERAGE) ? PrimitiveEntityType.DECIMAL : this.getFieldPrimitiveType(value)));
			});
		}
	},

	addFormatterOrStringView(toolsDiv, value, kind, letter) {
		const formatterSelect = $(`<select class="form-control formatter-select" data-allow-clear="true"/>`)
		const formatterIcon = $(`<div class="letter-icon-div formatter-icon"><span class="letter-icon">${letter}</span></div>`)
		const divFormatterSelect = $(`<div class="formatter-select-div" style="display: none;"></div>`)
		const closeFormatterMode = $('<div class="check-icon report-button"><i class="fa fa-check"></i><div>')
		let row = toolsDiv.closest('.item-row')

		toolsDiv.append(formatterIcon)
		divFormatterSelect.append(formatterSelect).append(closeFormatterMode)
		row.append(divFormatterSelect)
		formatterIcon.before(`<div class="selected-formatter"><span></span></div>`)

		formatterIcon.click(() => {
			row.find('.basic-mode').hide()
			divFormatterSelect.show()
		})
		let func = this.addStringViewAsTextToRow
		if (kind == 'formatter') {
			func = this.addFormatterAsTextToRow
		}
		closeFormatterMode.click(() => {
			divFormatterSelect.hide()
			func.call(this, value, row)
			row.find('.basic-mode').show()
		})
		return formatterSelect
	},

	addTypeSelect (toolsDiv, value) {
		const typeSelect = $(`<select class="form-control type-select" data-allow-clear="true"/>`)
		const typeIcon = $(`<div class="letter-icon-div type-icon"><span class="letter-icon">T</span></div>`)
		const divTypeSelect = $(`<div class="formatter-select-div type-select-div" style="display: none;"></div>`)
		const closeTypeMode = $('<div class="check-icon report-button"><i class="fa fa-check"></i><div>')
		let row = toolsDiv.closest('.item-row')

		toolsDiv.append(typeIcon)
		divTypeSelect.append(typeSelect).append(closeTypeMode)
		row.append(divTypeSelect)
		typeIcon.click(() => {
			row.find('.basic-mode').hide()
			divTypeSelect.show()
		})

		closeTypeMode.click(() => {
			divTypeSelect.hide()
			this.addTypeAsTextToRow.call(this, value, row)
			row.find('.basic-mode').show()
		})

		return typeSelect
	},

	buildDataForFormatterSelect (primitiveType) {
		let selectedFormatters = app.formattersWithExamples.filter((f) => { return f.get('primitiveType') == primitiveType })
		let selectedDate = []
		_.each(selectedFormatters, formatter => {
			selectedDate.push({
				id: formatter.get('id'),
				text: formatter.get('example')
			})
		})
		return selectedDate
	},

	buildDataForStringViewSelect (typeId) {
		let selectedStringViews = app.stringViews.filter((s) => { return s.ownerId == typeId })
		let selectedDate = []
		_.each(selectedStringViews, stringView => {
			selectedDate.push({
				id: stringView.id,
				text: MultilingualString.getCurrentValue(stringView.name)
			})
		})
		return selectedDate
	},

	buildDataForTypeSelect (primitiveType) {
		if(!primitiveType) return null;
		
		let selectedTypes = ['INTEGER', 'SYSTEM_INTEGER', 'DOUBLE', 'DECIMAL', 'STRING', 'SYSTEM_STRING'];
		selectedTypes.push(primitiveType);

		let selectedDate = []
		_.each(selectedTypes, type => {
			let ts = app.types.filter(t=> t.get("primitiveEntityType") == type);
			_.each(ts, t => {
				selectedDate.findIndex(typ => typ.id==t.get("id")) === -1 && selectedDate.push({
					id: t.get("id"),
					text: MultilingualString.getCurrentValue(t.get("name"))
				})
			})
		})
		return selectedDate
	},

	templateForSelectWithHtml(value, result) {
		return (state) => {
			return $(`<span>${state.text}</span>`)
		}
	},

	templateForFormattersSelectWithHtml(value, result) {
		return (state) => {
			if(result || !value || !value.field || !value.field() || !value.field().id || !state.id || !app.formattersWithExamples.get(state.id)) {
				return $(`<span>${state.text}</span>`)
			} else {
				let html = $(`<div class="selectFormatterOptionDiv"></div>`);
				let div = $(`<div class="formatterPatternDiv"></div>`);
				let text = $(`<span class="formatterPatternSpan">${state.text}</span>`);
				let icon = $(`<span id="info-${value.field().id}-${state.id}" data-fieldId ="${value.field().id}" data-fomatterId = "${state.id}" class="formatterPatternIcon"><a><span class="glyphicon glyphicon-info-sign"></span></a></span>`);
				let popoverDiv = $(`<span></span>`);
				popoverDiv.text(app.formattersWithExamples.get(state.id).get("pattern"));

				var initSelectPopovers = () => {
					buildDefaultPopover(icon, { content: popoverDiv, container: html, placement: 'right', html: true, delay: 300});
				}

				div.append(text);
				div.append(icon);
				html.append(div);

				icon.ready(initSelectPopovers);

				return html;
			}
		}
	},

	templateForTypeSelectWithHtml(value, result) {
		return (state) => {
			if(result || !value || !value.field || !value.field() || !value.field().id || !state.id ) {
				return $(`<span>${state.text}</span>`)
			} else {
				let html = $(`<div class="selectFormatterOptionDiv"></div>`);
				let div = $(`<div class="formatterPatternDiv"></div>`);
				let text = $(`<span class="formatterPatternSpan">${state.text}</span>`);
				let icon = $(`<span id="info-${value.field().id}-${state.id}" data-fieldId ="${value.field().id}" data-typeId = "${state.id}" class="formatterPatternIcon"><a><span class="glyphicon glyphicon-info-sign"></span></a></span>`);

				div.append(text);
				div.append(icon);
				html.append(div);
				return html;
			}
		}
	},

	initFormatterSelect(row, value, primitiveType) {
		if (!value.field().isDynamic() || primitiveType) {
			row.find('.formatter-icon .letter-icon').show()
			if (row.find('.formatter-select').length) {
				let selectedData = primitiveType ? this.buildDataForFormatterSelect(primitiveType) : this.buildDataForStringViewSelect(this.getFieldType(value).get('id'))
				const formatterSelect = row.find('.formatter-select')
				formatterSelect.find('option').remove()
				formatterSelect.select2({
					placeholder: primitiveType ? 'Formatter' : 'String view',
					data: selectedData,
					templateSelection: this.templateForFormattersSelectWithHtml(value, true),
					templateResult: this.templateForFormattersSelectWithHtml(value, false),
					dropdownAutoWidth : true,
					width: 'auto'
				})
				let valueToSet = primitiveType ? (value.get('formatter') && value.get('formatter').id) : (value.get('stringView') && value.get('stringView').id)
				formatterSelect.val(valueToSet).trigger('change')
				this.addTypeAsTextToRow.call(this, value, row);
			}
		} else {
			 row.find('.formatter-icon .letter-icon').hide()
		}
	},

	initTypeSelect(row, value, primitiveType) {
		if (primitiveType) {
			let selectedData = this.buildDataForTypeSelect(primitiveType);

			if (selectedData && selectedData.length > 1) {
				row.find('.type-icon .letter-icon').show();

				if (row.find('.type-select').length) {
					
					const typeSelect = row.find('.type-select')
					typeSelect.find('option').remove()
					typeSelect.select2({
						placeholder: 'To type',
						data: selectedData,
						templateSelection: this.templateForTypeSelectWithHtml(value, true),
						templateResult: this.templateForTypeSelectWithHtml(value, false),
						dropdownAutoWidth : true,
						width: 'auto'
					})
					if(!selectedData.find(element => element.id == (value.get("toType") && value.get("toType").id))) {
						value.set('toType', null);
					}
					typeSelect.val(value.get("toType") && value.get("toType").id).trigger('change')
					this.addTypeAsTextToRow.call(this, value, row);
				}
			} else {
				value.set('toType', null);
				row.find('.type-icon .letter-icon').hide();
			}
		} else {
			 row.find('.type-icon .letter-icon').hide()
		}
	}

});

/* @flow */

import ReportGroups from './reportGroups';
import MultilingualString from '../../common/models/multilingualString';
import Constants from '../../common/models/constants';

export default ReportGroups.extend({

	initialize(options) {
		ReportGroups.prototype.initialize.apply(this, arguments);
		const field = app.fields.get(Constants.ID_FIELD_REPORT_ROW_GROUPS);
		const fieldNameHTML = MultilingualString.toHTML(field.name());
		this.$('.box-title').html(fieldNameHTML);
	}
});

import TypeKind from '../../common/enums/typeKind';
import StringKind from '../../common/enums/stringKind';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import CreateUpdateForm from '../../common/views/createUpdateForm.js';
import EntitySelect from '../../common/components/entitySelect';
import FieldKind from '../../common/enums/fieldKind';
import MultilingualString from '../../common/models/multilingualString';
import BaseCreateView from '../../common/views/baseCreateView';
import MetaObjectTreeViewKind from '../../common/enums/metaObjectTreeViewKind'
import entityTypeService from '../../EntityType/service/entityTypeService';
import utils, { checkRequired } from '../../common/components/utils';
import BaseSelect from '../../common/components/baseSelect';
import StateRecovery from '../../common/components/stateRecovery'
import {addNewCategory} from './entityCategoryView'
import { getCurrentType } from '../../common/models/type'

var CreateView = BaseCreateView.extend({

	events: {
		'change #primitiveSelect': 'primitive',
		'select2:open #reportDataTypes': 'openDataTypeSelect'
	},

	initialize: function (o) {
		o.models = {reportDataType: EntitySelect};
		CreateView.__super__.initialize.apply(this, arguments);
		this.$primitiveSelect = this.$el.find('#primitiveSelect');
		this.$categoriesSelect = this.$el.find('#categoriesSelect');
		this.$reportDataTypeSelect = this.$el.find('#reportDataTypes');
		this.$primitiveClassForm = this.$el.find('#primitiveClassForm');
		this.$decimalScaleForm = this.$el.find('#decimalScaleForm');
		this.$decimalScale = this.$el.find('#decimalScale');
		this.$isNullableForm = this.$el.find('#isNullableForm');
		this.$reportDataTypesDiv = this.$el.find('#reportDataTypesDiv');
		this.$reportDataTypeSelect = this.$el.find('#reportDataTypes');
		this.$stringHasTranslationsForm = this.$el.find('#stringHasTranslationsForm');
		this.$stringHasNoLimitsForm = this.$el.find('#stringHasNoLimitsForm');
		this.$isStringHTMLForm = this.$el.find('#isStringHTMLForm');
		this.$addListToMenu = this.$el.find('#addListToMenu')
		this.$addCreateToMenu = this.$el.find('#addCreateToMenu')
		this.$dataSourceDiv = this.$el.find('#dataSourceDiv')
		this.$dataSourceSelect = this.$el.find('#dataSourcesSelect')
		this.initSelects();
		this.initDependOnKind();
	},

	requiredCheck: function(){
		return checkRequired(this.model.toJSON(), this.$el)
	},

	showStringMultilingual: function () {
		this.$stringHasTranslationsForm.show();
		this.$isNullableForm.find('input').prop('checked', false);
	},
	
	showStringHasNoLimits: function () {
		this.$stringHasNoLimitsForm.show();
	},

	hideStringHasNoLimits: function () {
		this.$stringHasNoLimitsForm.hide();
		this.$stringHasNoLimitsForm.find('input').prop('checked', false);
	},

	hideStringMultilingual: function () {
		this.$stringHasTranslationsForm.hide();
		this.$isNullableForm.find('input').prop('checked', false);
	},

	showStringHTML: function () {
		this.$isStringHTMLForm.show();
	},

	hideStringHTML: function () {
		this.$isStringHTMLForm.hide();
		this.$isStringHTMLForm.find('input').prop('checked', false);
	},

	showDecimalScale: function () {
		this.$decimalScaleForm.show();
		this.$decimalScale.val(0).trigger('change');
	},

	hideDecimalScale: function () {
		this.$decimalScaleForm.hide();
		this.$decimalScaleForm.find('input').val(null).trigger('change');
	},

	showIsNullable: function () {
		this.$isNullableForm.show();
		this.$isNullableForm.find('input').prop('checked', false);
	},

	hideIsNullable: function () {
		this.$isNullableForm.hide();
		this.$isNullableForm.find('input').prop('checked', false);
	},

	showReportTransient: function () {
		this.$reportDataTypesDiv.show();
		this.$reportDataTypeSelect
			.val(this.$reportDataTypeSelect.find('option:first').val())
			.trigger('change');
		this.$reportDataTypeSelect.prop('disabled', false);
	},

	hideReportTransient: function () {
		this.$reportDataTypesDiv.hide();
		this.$reportDataTypeSelect.val([]).trigger('change');
		this.$reportDataTypeSelect.prop('disabled', true);
	},

	showPrimitiveClass: function () {
		this.$primitiveSelect.prop('disabled', false);		
		this.$primitiveSelect.val(PrimitiveEntityType.SYSTEM_STRING).trigger('change');
		this.$primitiveClassForm.show();
		this.primitive();
	},

	hidePrimitiveClass: function () {
		this.$primitiveClassForm.hide();
		this.$primitiveSelect.val([]).trigger('change');
		this.$primitiveSelect.prop('disabled', true);
		this.primitive();
	},

	showAddToMenu() {
		this.$addListToMenu.show()
		this.$addCreateToMenu.show()
	},

	hideAddToMenu() {
		this.$addListToMenu.hide()
		this.$addCreateToMenu.hide()
	},

	showDataSourceSelect() {
		this.$dataSourceDiv.show()
		this.model.set('dataSourceId', null)
	},

	hideDataSourceSelect() {
		this.$dataSourceDiv.hide()
		this.model.set('dataSourceId', null)
	},

	initDependOnKind: function () {
		this.hideReportTransient();
		this.hidePrimitiveClass();

		if (this.model.get('typeKind') == TypeKind.PRIMITIVE) {
			this.showPrimitiveClass();
		} else if (this.model.get('typeKind') == TypeKind.REPORT) {
			this.showReportTransient();
		}
		if (this.model.get('typeKind') == TypeKind.DOCUMENT || this.model.get('typeKind') == TypeKind.REPORT || this.model.get('typeKind') == TypeKind.DICTIONARY) {
			this.showAddToMenu()
			const fromLS = StateRecovery.get('addListToMenuCheckbox')
			if (fromLS) {
				this.model.set('addListToMenu', fromLS.state)
			} else {
				this.model.set('addListToMenu', true)
			}
			this.model.on('change:addListToMenu', function (model, value) {
				StateRecovery.put('addListToMenuCheckbox',{state:value})
			})
		} else {
			this.hideAddToMenu()
		}
		if (this.model.get('typeKind') == TypeKind.EXTERNAL) {
			this.showDataSourceSelect()
		} else {
			this.hideDataSourceSelect()
		}
	},

	primitive: function () {
		this.hideDecimalScale();
		this.hideStringMultilingual();
		this.hideStringHTML();
		this.hideIsNullable();
		this.hideStringHasNoLimits()
		var primitiveType = this.$primitiveSelect.val();

		if (primitiveType == PrimitiveEntityType.DECIMAL ||
			primitiveType == PrimitiveEntityType.DOUBLE ||
			primitiveType == PrimitiveEntityType.INTEGER ||
			primitiveType == PrimitiveEntityType.BOOLEAN) {
			this.showIsNullable();
		}

		if (primitiveType == PrimitiveEntityType.DECIMAL) {
			this.showDecimalScale();
		}
		if (primitiveType == PrimitiveEntityType.STRING) {
			this.showStringMultilingual();
			this.showStringHTML();
		}
		if (primitiveType == PrimitiveEntityType.SYSTEM_STRING) {
			this.showStringHasNoLimits();
		}
	},

	initSelects: function () {
		let primitiveTypes = PrimitiveEntityType.getAvailableToCreate();
		new BaseSelect({
			el:this.$primitiveSelect,
			data:primitiveTypes
		})
		new BaseSelect({
			el:this.$decimalScale,
			data:[0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
		})

		let sourceSelect = new BaseSelect({
			el: this.$dataSourceSelect,
			data: _.each(app.raw.dataSources, source => source.text = source.name)
		})
		if (getCurrentType().isExternal()) {
			this.model.on('change:dataSourceId', () => sourceSelect.setValue(getCurrentType().get('dataSourceId')))
		}

		let that = this
		utils.getRequest(app.urls.getCategories)
			.then(data => {
				new BaseSelect({
					el:this.$categoriesSelect,
					createNewAction: () => {
						addNewCategory((category) => {
							category.text = (new MultilingualString(category.name)).getCurrentValue()
							data.unshift(category)
							let values = that.selects.categories.getValue()
							values.push(category.id)
							that.selects.categories.rebuildSelect(data, values)
						})
					},
					data:_.each(data, category =>
						category.text = (new MultilingualString(category.name)).getCurrentValue())
				})
			});
		if (this.model.get('typeKind') == TypeKind.REPORT) {
			entityTypeService.findAll()
				.then(data => {
					this.typesForReport = data;
					new BaseSelect({
						el:this.$reportDataTypeSelect,
						data:_.chain(this.typesForReport)
							.filter(type => type.typeKind == TypeKind.DOCUMENT ||
											type.typeKind == TypeKind.DICTIONARY ||
											type.typeKind == TypeKind.REGISTER ||
											type.typeKind == TypeKind.TRANSIENT ||
											type.typeKind == TypeKind.EXTERNAL)
							.each(type => type.text = (new MultilingualString(type.name)).getCurrentValue())
							.value()
					})
					this.showReportTransient();
				});
		}
	},

	getHTML: function () {
		return `<div class="grid-1x-padding">
							<label for="name">${app.getResource('name')}</label> <span class="asterisk">*</span>
						</div>
						<div class="grid-2x-padding">
							<input class="form-control" type="text" data-is-string-multilingual="short" data-field="name" data-required="true"/>
						</div>

			<div id="primitiveClassForm">
				<div class="grid-1x-padding">
					<label for="primitiveClass">${app.getResource('primitive.class')}</label>
				</div>
				<div class="grid-2x-padding">
					<select id="primitiveSelect" class="form-control" data-field="primitiveEntityType">
					</select>
				</div>
			</div>
			<div style="display:none">
				<div class="grid-1x-padding">
					<label for="categories">${app.getResource('categories')}</label>
				</div>
				<div class="grid-2x-padding">
					<select id="categoriesSelect" multiple="multiple" class="form-control" data-field="categories" data-allow-clear="true">
					</select>
				</div>
			</div>
			<div id="reportDataTypesDiv">
				<div class="grid-1x-padding">
					<label for="reportDataType">${app.getResource('data.type')}</label>
					<a help-popover="report-create-data-type"><span class="help-image"/></a>
				</div>
				<div class="grid-2x-padding">
					<select class="form-control" id="reportDataTypes" data-field="reportDataType">
					</select>
				</div>
			</div>
			<div id="dataSourceDiv">
				<div class="grid-1x-padding">
					<label for="dataSourceId">${app.getResource('data.source')}</label> <span class="asterisk">*</span>
					<a help-popover="data-source-create-data-type"><span class="help-image"/></a>
				</div>
				<div class="grid-2x-padding">
					<select ${getCurrentType().isExternal() ? 'disabled' : ''} class="form-control" id="dataSourcesSelect" data-field="dataSourceId">
					</select>
				</div>
			</div>

			<div id="stringHasTranslationsForm" class="grid-1x-padding">
				<div class="checkbox checkbox-without-margin">
					<label>
						<input type="checkbox" data-primitive-type='BOOLEAN' data-field="primitiveTypeProperties.stringHasTranslations"/>
						${app.getResource('translations')}
					</label>
				</div>
			</div>
			
			<div id="stringHasNoLimitsForm" class="grid-1x-padding">
				<div class="checkbox checkbox-without-margin">
					<label>
						<input type="checkbox" data-primitive-type='BOOLEAN' data-field="primitiveTypeProperties.stringHasNoLimits"/>
						${app.getResource('string.has.no.limits')}
					</label>
				</div>
			</div>

			<div id="isStringHTMLForm" class="grid-1x-padding">
					<div class="checkbox checkbox-without-margin">
						<label>
							<input type="checkbox" data-primitive-type='BOOLEAN' data-field="primitiveTypeProperties.isStringHTML"/>
							${app.getResource('html')}
						</label>
					</div>
			</div>

			<div class="grid-1x-padding" id="isNullableForm">
				<div class="checkbox checkbox-without-margin">
					<label>
						<input type="checkbox" data-primitive-type='BOOLEAN' data-field="primitiveTypeProperties.isNullable"/>
						${app.getResource('nullable')}
					</label>
				</div>
			</div>

			<div id="decimalScaleForm">
				<div class="grid-1x-padding">
					<label for="scale">${app.getResource('scale')}</label>
				</div>
				<div class="grid-2x-padding">
					<select class="form-control" id="decimalScale" data-field="primitiveTypeProperties.decimalScale">
					</select>
				</div>
			</div>

			<div id="addListToMenu" class="grid-1x-padding">
				<div class="checkbox checkbox-without-margin">
					<label>
						<input type="checkbox" data-primitive-type='BOOLEAN' data-field="addListToMenu"/>
						${app.getResource('add.list.to.menu')}
					</label>
				</div>
			</div>

			<div id="addCreateToMenu" class="grid-1x-padding">
				<div class="checkbox checkbox-without-margin">
					<label>
						<input type="checkbox" data-primitive-type='BOOLEAN' data-field="addCreateToMenu"/>
						${app.getResource('add.create.to.menu')}
					</label>
				</div>
			</div>
			`;
	},

	saveConfiguration: function () {
		let data = this.model.toJSON();
		if (data.primitiveTypeProperties && data.primitiveTypeProperties.isStringHTML) {
			data.primitiveTypeProperties.stringKind = StringKind.HTML;
			delete data.primitiveTypeProperties.isStringHTML;
		}
		if (this.model.get('typeKind') == TypeKind.DOCUMENT ||
				this.model.get('typeKind') == TypeKind.REPORT ||
				this.model.get('typeKind') == TypeKind.DICTIONARY) {
					data.metaObjectTreeViewKind = MetaObjectTreeViewKind.NOT_USED;
		}
		if (this.model.get('typeKind') == TypeKind.PRIMITIVE &&
				!this.model.get('primitiveEntityType')) {
					data.primitiveEntityType = this.$primitiveSelect.val();
				}
		if (this.model.get('typeKind') == TypeKind.REPORT) {
			data.reportDataType = this.typesForReport.find(type => type.id == data.reportDataType);
		}
		return data;
	},

	onCreateCheck: function () {
		if (this.model.get('typeKind') == TypeKind.EXTERNAL && !this.model.get('dataSourceId')) {
			app.notificationManager.addError('Data source can\'t be emty for external type.')
			return false
		}
		return true
	}

});

export default CreateView;

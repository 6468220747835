/* @flow */

import MultilingualString from "./multilingualString";
import  FormItemKind from '../../common/enums/formItemKind';

export default class FormItem extends Backbone.Model {

	initialize(): void {
	}

	getViewId(): string {
		return this.get('viewId');
	}

	kind(): string {
		return this.get('kind');
	}

	isField(): boolean {
		return this.kind() == FormItemKind.FIELD;
	}

	isSeparator(): boolean {
		return this.kind() == FormItemKind.SEPARATOR;
	}

	isRow(): boolean {
		return this.kind() == FormItemKind.ROW;
	}

	isColumn(): boolean {
		return this.kind() == FormItemKind.COLUMN;
	}

	isGroupbox(): boolean {
		return this.kind() == FormItemKind.GROUPBOX;
	}

	isTabContainer(): boolean {
		return this.kind() == FormItemKind.TAB_CONTAINER;
	}

	isTab(): boolean {
		return this.kind() == FormItemKind.TAB;
	}

	isButton(): boolean {
		return this.kind() == FormItemKind.BUTTON;
	}

	isText(): boolean {
		return this.kind() == FormItemKind.TEXT;
	}

	isWidget(): boolean {
		return this.kind() == FormItemKind.WIDGET;
	}

	isToolbar(): boolean {
		return this.kind() == FormItemKind.TOOLBAR;
	}

	isInlineRow(): boolean {
		return this.kind() == FormItemKind.INLINE_ROW;
	}

	isInlineColumn(): boolean {
		return this.kind() == FormItemKind.INLINE_COLUMN;
	}

	isReportTable(): boolean {
		return this.kind() == FormItemKind.REPORT_TABLE;
	}

	properties() {
		return this.get("properties")
	}

	getProperty(key) {
		return this.properties().find(p => p.key == key);
	}

	hasIdentifier(): boolean {
		let p = this.getProperty("IDENTIFIER");
		return !!(p && p.value && p.value.value);
	}

	getEntityField() {
		return this.kind() == "FIELD" ? app.fields.get(this.get("entityField").id) : null;
	}

	getName(lang): string {
		if (this.kind() == "FIELD") {
			return new MultilingualString(this.getEntityField().get("name")).getCurrentValue(lang).toLowerCase();
		}

		return null;
	}

	getIdentifierString(): string {
		let p = this.getProperty("IDENTIFIER");
		if (p && p.value && p.value.value) {
			return p.value.value;
		}

		return null;
	}

}

/* @flow */

export const NANOS_PER_SECOND = 1000000000;
export const NANOS_PER_MILLI = 1000000;
export const NANOS_PER_MICRO = 1000;
export const MILLIS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const SECONDS_PER_HOUR = SECONDS_PER_MINUTE * MINUTES_PER_HOUR;
export const HOURS_PER_DAY = 24;
export const SECONDS_PER_DAY = 86400;
export const DAYS_PER_WEEK = 7;
export const MONTHS_PER_YEAR = 12;

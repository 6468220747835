export default class ApplicationType {
	static REGULAR = 'REGULAR';
	static REMOTE = 'REMOTE';
	static REMOTE_USE_CODEJIG_INFRASTRUCTURE = 'REMOTE_USE_CODEJIG_INFRASTRUCTURE';
	static DEVELOPMENT = 'DEVELOPMENT';


	static isRemote(type) {
		return type == this.REMOTE || type == this.REMOTE_USE_CODEJIG_INFRASTRUCTURE;
	}
}

import BaseCreateView from '../../../common/views/baseCreateView'
import BaseSelect from '../../../common/components/baseSelect'
import { translate } from '../../../common/service/stringResourceService'
import FieldDynamic from '../../../common/components/fieldDynamic'
import PropertiesCollection from '../../../EntityView/models/properties/propertiesCollection'
import PropertyKey from '../../../common/enums/propertyKey'
import defaultProperties from '../../../EntityView/models/properties/defaultProperties'

var CreateListView = BaseCreateView.extend({

	initialize: function (options) {
		CreateListView.__super__.initialize.apply(this, arguments)

		let listViewTypeSelect = new BaseSelect({
			model: this.model,
			modelAttr: 'listViewType',
			data: this.getDataForSelect('listViewType'),
			el: this.$el.find(`.listViewType-select`)
		})
		let customIndexPageSelect = new FieldDynamic({
			model: this.model,
			modelAttr: 'customIndexPage',
			el: this.$el.find(`.customIndexPage-select`),
			allowClear: true
		})
		this.selects = [ listViewTypeSelect, customIndexPageSelect ]

		this.updateControls()
		this.model.on('change', () => {
			this.updateControls()
		})
	},

	getDataForSelect: function (fieldName) {
		if (fieldName == 'listViewType') {
			return ['Standard', 'custom'].map(type => {
				return {
					id: type,
					text: translate(`${type}`)
				}
			})
		} else {
			return []
		}
	},

	updateControls: function () {
		if (this.model.get('listViewType') == 'custom'){
			this.$el.find('.fullCustomSelect').show()
		} else {
			this.$el.find('.fullCustomSelect').hide()
		}
	},

	getHTML: function () {
		let result =
			`<div class="grid-1x-padding">
					<label for="name">${app.getResource('name')}</label>
				</div>
				<div class="grid-2x-padding">
					<input id="viewName" class="form-control" type="text" data-is-string-multilingual="short" data-field="name"/>
				</div>
				<div class="grid-1x-padding">
					<label>${app.getResource('kind')}</label>
				</div>
				<div class="grid-2x-padding">
					<select class="listViewType-select"></select>
				</div>
				<div class="fullCustomSelect">
					<div class="grid-1x-padding">
						<label>${app.getResource('custom.index.page')}</label>
					</div>
					<div class="grid-2x-padding">
						<select data-create-new-action="open.in.new.tab" class="customIndexPage-select"></select>
					</div>
				</div>`
		return result
	},

	saveConfiguration: function(e) {
		if (!this.model.get('customIndexPage') && this.model.get('listViewType') == 'custom') {
			let s = "Define custom index page!"
			app.notificationManager.addError(s)
			throw new Error(s)
		}
		return this.model.toJSON()
	}

})

export default CreateListView

var Tab = Backbone.View.extend({

	initialize: function (options) {
		this.tabs = []
		this.viewId = options.viewId;
		this.$el.find('[data-target]').off('keypress').on('keypress', function (e) {
			if (e.keyCode == 13 || e.keyCode == 32) {
				e.preventDefault();
				$(e.target).click();
			}
		});
		this.$el.find(`>.tab-content > div`).first().addClass('active');
		this.$el.find('> .nav-tabs > li > a').each((index, el) => {
			const tab = this.createTab(el)
			this.tabs.push(tab)
			if (tab.viewItemId){
				el.addEventListener("click", () => {
					this.activate(tab)
				})
			}
		});
	},

	activate: function (tab) {
		this.clearActive()
		tab.tabContent.addClass('active')
		tab.tabHeader.addClass('active')
	},

	activateWithViewItemId: function (viewItemId) {
		const tab = this.tabs.find((t) => {return t.viewItemId == viewItemId})
		if (tab) {
			this.activate(tab)
		}
	},

	contains: function (viewItemId) {
		return this.tabs.find((t) => {return t.viewItemId == viewItemId}) != null
	},

	clearActive: function () {
		this.$el.find(`> .tab-content > div.active`).removeClass('active');
		this.$el.find(`> .nav.nav-tabs > li.active`).removeClass('active');
	},

	createTab: function(element) {
		let tabId = $(element).data('target')
		if (tabId) {
			tabId = tabId.substring(1, tabId.length)
		}
		const subTab = {
				viewItemId: tabId,
				tabContent: this.$el.find(`> .tab-content  > div[data-item-id=${tabId}]`),
				tabHeader: $(element).parent(),
				activate: () => {
					this.activate(subTab)
				},
				tab: this
		}
		return subTab
	}
});

export default Tab;

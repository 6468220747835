var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("cjg-binary-uploader", {
    ref: "binaryInput",
    attrs: {
      "binary-data": _vm.props.binaryData,
      "dz-options": _vm.props.dzOptions,
      "default-message": _vm.props.defaultMessage,
      "default-message-html": _vm.props.defaultMessageHtml,
      "default-no-file-message": _vm.props.defaultNoFileMessage,
      "preview-template-fn": _vm.props.previewTemplateFn,
      "file-description-fn": _vm.props.fileDescriptionFn,
      "file-download-url-fn": _vm.props.fileDownloadUrlFn
    },
    on: {
      success: _vm.events.onSuccess,
      error: _vm.events.onError,
      removedfile: _vm.events.onRemovedFile,
      reset: _vm.events.onReset,
      complete: _vm.events.onComplete
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/* @flow */

import ZonedDateTime from './zonedDateTime';
import ChronoField from '../enums/chronoField';
import ChronoUnit from '../enums/chronoUnit';
import ZoneId from './zoneId';

import type { Temporal } from '../declarations';

import {
	NANOS_PER_SECOND,
	MILLIS_PER_SECOND,
	NANOS_PER_MILLI,
	NANOS_PER_MICRO,
	SECONDS_PER_MINUTE,
	SECONDS_PER_HOUR,
	SECONDS_PER_DAY
} from '../constants';

import {
	checkUnitIsSupported,
	mod
} from '../utils';

/**
 * Serilized form:
 *   seconds: long
 *   nanos: int
 */

export default class Instant {

	seconds: number;
	nanos: number;

	/**
	 * Private constructor
	 */
	constructor(seconds: number, nanos: number) {
		this.seconds = seconds;
		this.nanos = nanos;
	}

	static ofEpochSecond(epochSecond: number, nanoAdjustment?: ?number): Instant {
		let plusSeconds = 0;
		let nanos = 0;
		if (nanoAdjustment) {
			nanos = nanoAdjustment % NANOS_PER_SECOND;
			plusSeconds = Math.floor(nanoAdjustment / NANOS_PER_SECOND);
			nanos = nanoAdjustment - plusSeconds * NANOS_PER_SECOND;
		}
		return new Instant(epochSecond + plusSeconds, nanos);
	}

	static ofEpochMilli(epochMilli: number): Instant {
		const seconds = epochMilli / MILLIS_PER_SECOND | 0;
		const milliAdjustment = epochMilli % MILLIS_PER_SECOND;
		return Instant.ofEpochSecond(seconds, milliAdjustment * NANOS_PER_MILLI);
	}

	static fromJSON(value: any): ?Instant {
		if (!value) {
			return null;
		}
		return new Instant(value.seconds, value.nanos);
	}

	static fromMoment(value: moment$Moment): Instant {
		return Instant.ofEpochMilli(value.valueOf());
	}

	static now(): Instant {
		return Instant.ofEpochMilli(Date.now());
	}

	atZone(zone: ZoneId): ZonedDateTime {
		return ZonedDateTime.ofInstant(this, zone);
	}

	getEpochSecond() {
		return this.seconds;
	}

	getNano() {
		return this.nanos;
	}

	get(field: ChronoField): number {
		switch (field) {
			case ChronoField.INSTANT_SECONDS: return this.seconds;
			case ChronoField.NANO_OF_SECOND: return this.nanos;
			case ChronoField.MILLI_OF_SECOND: return Math.floor(this.nanos / NANOS_PER_MILLI);
			case ChronoField.MICRO_OF_SECOND: return Math.floor(this.nanos / NANOS_PER_MICRO);
		}
		throw new Error('Unsupported field');
	}

	toJSON(): any {
		return {
			seconds: this.seconds,
			nanos: this.nanos
		};
	}

	toMoment(): moment$Moment {
		global.momentJsHelper.loadTimezonePackage();
		return global.moment.tz(this.toEpochMilli(), ZoneId.systemDefault().getId());
	}

	toEpochMilli(): number {
		if (this.seconds < 0) {
			const s = (this.seconds + 1) * MILLIS_PER_SECOND;
			const n = MILLIS_PER_SECOND - Math.trunc(this.nanos / NANOS_PER_MILLI);
			return s - n;
		} else {
			const s = this.seconds * MILLIS_PER_SECOND;
			const n = Math.trunc(this.nanos / NANOS_PER_MILLI);
			return s + n;
		}
	}

	plus(amount: number, unit: ChronoUnit): Instant {
		checkUnitIsSupported(this, unit);
		switch (unit) {
			case ChronoUnit.NANOS: {
				const sumNanos = this.nanos + amount;
				const seconds = this.seconds + Math.floor(sumNanos / NANOS_PER_SECOND);
				const nanos = mod(sumNanos, NANOS_PER_SECOND);
				return new Instant(seconds, nanos);
			}
			case ChronoUnit.MICROS: {
				const nanos = amount * NANOS_PER_MICRO;
				return this.plus(nanos, ChronoUnit.NANOS);
			}
			case ChronoUnit.MILLIS: {
				const nanos = amount * NANOS_PER_MILLI;
				return this.plus(nanos, ChronoUnit.NANOS);
			}
			case ChronoUnit.SECONDS: {
				return new Instant(this.seconds + amount, this.nanos);
			}
			case ChronoUnit.MINUTES: {
				const seconds = amount * SECONDS_PER_MINUTE;
				return this.plus(seconds, ChronoUnit.SECONDS);
			}
			case ChronoUnit.HOURS: {
				const seconds = amount * SECONDS_PER_HOUR;
				return this.plus(seconds, ChronoUnit.SECONDS);
			}
			case ChronoUnit.HALF_DAYS: {
				const seconds = amount * SECONDS_PER_DAY / 2;
				return this.plus(seconds, ChronoUnit.SECONDS);
			}
			case ChronoUnit.DAYS: {
				const seconds = amount * SECONDS_PER_DAY;
				return this.plus(seconds, ChronoUnit.SECONDS);
			}
		}
	}

	until(endExclusive: Temporal, unit: ChronoUnit): number {
		if (unit == ChronoUnit.NANOS) {
			const seconds = endExclusive.get(ChronoField.INSTANT_SECONDS) - this.seconds;
			const nanos = endExclusive.get(ChronoField.NANO_OF_SECOND) - this.nanos;
			return seconds * NANOS_PER_SECOND + nanos;
		} else {
			throw new Error('Not implemented');
		}
	}

	compareTo(otherInstant: Instant): number {
		const s = this.seconds - otherInstant.seconds
		if (s !== 0) {
			return s;
		}
		return this.nanos - otherInstant.nanos;
	}

	isAfter(otherInstant: Instant): boolean {
		return this.compareTo(otherInstant) > 0;
	}

	isBefore(otherInstant: Instant): boolean {
		return this.compareTo(otherInstant) < 0;
	}

	equals(otherInstant: ?any): boolean {
		if (otherInstant == null) {
			return false;
		}
		if (!(otherInstant instanceof Instant)) {
			return false;
		}
		return this.seconds === otherInstant.seconds &&
				this.nanos === otherInstant.nanos;
	}

	isSupported(field: ChronoField | ChronoUnit): boolean {
		if (field instanceof ChronoField) {
			return Instant.supportedFields.indexOf(field) > -1;
		}
		if (field instanceof ChronoUnit) {
			return Instant.supportedUnits.indexOf(field) > -1;
		}
		throw new Error('Not supported type of the argument');
	}

	static supportedFields = [
		ChronoField.NANO_OF_SECOND,
		ChronoField.MICRO_OF_SECOND,
		ChronoField.MILLI_OF_SECOND,
		ChronoField.INSTANT_SECONDS
	];

	static supportedUnits = [
		ChronoUnit.NANOS,
		ChronoUnit.MICROS,
		ChronoUnit.MILLIS,
		ChronoUnit.SECONDS,
		ChronoUnit.MINUTES,
		ChronoUnit.HOURS,
		ChronoUnit.HALF_DAYS,
		ChronoUnit.DAYS
	];

	static getMethodSupportedEnumValues = Instant.supportedFields;
}

import FieldSelect from './fieldSelect'
import Entity from '../models/entity';
import entityManager from './entityManager';
import { translate } from '../service/stringResourceService';
import {showInstance} from '../../common/components/utils.js';
import OpenMode from '../../common/enums/openMode';

const FieldMultiselect = FieldSelect.extend({
	initialize(options) {
		FieldMultiselect.__super__.initialize.apply(this, arguments);
		if (this.model) {
			this.listenTo(this.model.get(this.modelAttr), 'update reset', this.render);
		}
	},
	_getSelect2Options(options) {
		return _.extend(
			FieldMultiselect.__super__._getSelect2Options.apply(this, arguments),{
				multiple: true
		});
	},
	setDataToModel(collection) {
		collection = collection &&
		collection.map(x => Entity.reference(x.id, this.typeId)) || [];
		if (this.model) {
			let current = this.model.get(this.modelAttr);
			if (current.length != collection.length ||
					_.any(collection, x => !current.get(x.id))) {
				current.set(collection);
			}
		}
	},
	getValue() {
		return this.$el.val()? this.$el.val().map(id => ({id: id})) : null;
	},
	setValue(values) {
		Promise.all(values.map(v => {
			return entityManager.fetchStringView(this.viewId, v.id)
			.catch(e => translate('failed.to.load.string.view'));
		})).then(views => {
			const data = _.zip(values.models, views).map(x => {
				return {
					id: x[0].id,
					text: x[1]
				}
			});
			const fromModel = this.getDataFromModel();
			if (fromModel && data.length == fromModel.length && _.every(data, x => fromModel.get(x.id))) {
				this._setSelectValue(data);
			}
		});
	},
	_setSelectValue(values) {
		this.$el.empty();
		this.$textEl.empty();
		values.forEach((v, idx) => {
			const option = new Option(v.text, v.id, true, true);
			this.$el.append(option);
			if (this.readOnlyText) {
				if (this.hasViewLink){
					this.$textEl.append(`<button style="padding:0px;" class="btn-link ref" typeid="${this.typeId}" objectid="${v.id}">${v.text}</button>`);
				}else {
					this.$textEl.append(`<span>${v.text}</span>`);
				}
				if (idx != values.length -1) {
					this.$textEl.append(', ');
				}
			}
		})
		this.$el.val(values.map(x => x.id)).trigger('change');
		if (!values.length) {
			this.$textEl.html('-');
		}
		this.$textEl.find('button').click((event) => {
			const link = $(event.target)
			showInstance({
				openMode: this.openMode,
				exactParent: this.model,
				typeId: link.attr('typeid'),
				objectId: link.attr('objectid'),
				callback: (data) => {
					entityManager.evictStringView(data.item.id);
					const old = this.getValue();
					this.setDataToModel(null);
					this.setDataToModel(old);
				},
				modalOpt: this.modalOpt
			})
		})
	},
	templateSelection(state) {
		return state.text;
	},

	async _showExtendedView () {
		const filters = await this._getFilters();
		showInstance({
			openMode: OpenMode.NESTED_WINDOW,
			typeId: this.typeId,
			isIndex: true,
			clickCallback: function (data) {
				const table = this.control['indexTable'].newTable;
				table.checkOne({
					item: data,
					value: !table.isChecked(data)
				});
			},
			onConfirmSelect: (ids) => {
				this.setDataToModel(ids.map(id => ({id:id})));
				app.instanceViewer.close();
			},
			hideSelection: false,
			canCreate: false,
			hideContextMenu: true,
			showConfirmSelect: true,
			selected: this.getValue(),
			filters: filters,
			modalOpt: this.modalOpt
		});
	},

	_showCreateNew: function() {
		showInstance({
			exactParent: this.model,
			openMode: this.createNewAction,
			typeId: this.typeId,
			callback: (() => {
				let created = false;
				return (data) => {
					const old = this.getValue();
					if(created) {
						entityManager.evictStringView(data.item.id);
					} else {
						old.push(data.item)
					}
					this.setDataToModel(null);
					this.setDataToModel(old);
					created = true
				}
			})(),
			previousContext: this.context,
			modalOpt: this.modalOpt
		})
	}

});

export default FieldMultiselect;

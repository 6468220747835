/* @flow */

export default class AggregateKind {
	static AVERAGE = 'AVERAGE';
	static COUNT = 'COUNT';
	static MAX = 'MAX';
	static MIN = 'MIN';
	static SUM = 'SUM';
	static CUSTOM = 'CUSTOM';
}

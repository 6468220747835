import FormItem from '../models/formItem';

export default class FormItems extends Backbone.Collection<FormItem> {

	initialize() {
		this.model = FormItem;
	}

	getByViewId(viewId): FormItems {
		return new FormItems(this.filter(f => f.getViewId() == viewId));
	}

}

import BaseModel from '../../common/models/baseModel'
import { translate } from '../../common/service/stringResourceService.js'
import utils from '../../common/components/utils'

export default class ProposeToReplaceRouteModal extends Backbone.View {

	initialize () {
		let $el = $('#proposeToReplaceRouteModal')
		if ($el.length == 0) {
			let el = document.createElement('div')
			el.id = 'proposeToReplaceRouteModal'
			el.classList.add('modal')
			el.classList.add('fade')
			document.body.appendChild(el)
		}
		$el = $('#proposeToReplaceRouteModal')
		$el.html(this.getHTML())
		this.setElement($el)
	}

	getHTML () {
		return `
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header create-modal-header" style="padding-top: 10px; padding-bottom: 20px;">
							<h4 style="font-size: 16px; font-weight: 400;">
								<span class="headerText"></span>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
								</button>
							</h4>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-danger no" data-dismiss="modal">${app.getResource('no')}</button>
							<button type="button" class="btn btn-success yes" data-dismiss="modal">${app.getResource('yes')}</button>
						</div>
					</div>
				</div>`
	}

	show (options: Object) {
		const that = this
		this.model = new BaseModel({})

		this.$el.find('.modal-header h4 .headerText').html(translate('replace.route.propose.modal.text'))
		this.$el.find('.yes').click(() => {
			utils.postRequest({}, app.urls.replaceRoute)
				.then(() => {
					options.onSuccess && options.onSuccess();
					that.$el.modal('hide')
				})
				.catch(() => {
					options.onFail && options.onFail();
					that.$el.modal('hide')
				});
		});
		this.$el.find('.no, .close').click(() => {
			options.onClose && options.onClose();
			that.$el.modal('hide')
		});

		this.$el.modal('show')
	}

}

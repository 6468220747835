/* @flow */

import ChronoField from '../enums/chronoField';
import ChronoUnit from '../enums/chronoUnit';
import YearMonth from './yearMonth';

import type { Temporal } from '../declarations';
import type ZoneId from './zoneId';

import {
} from '../constants';

import {
	checkValidYear
} from '../utils';

/**
 * Serilized form:
 *   year: int
 */

export default class Year {

	year: number;

	constructor(year: number) {
		checkValidYear(year);
		this.year = year;
	}

	static of(year: number) {
		return new Year(year);
	}

	static fromJSON(value: ?any): ?Year {
		if (value == null) {
			return null;
		}
		return new Year(value.year, value.month);
	}

	static now(zone: ZoneId): Year {
		return new Year(YearMonth.now(zone).getYear());
	}

	toJSON() {
		return {
			year: this.year
		};
	}

	toMoment() {
		return global.moment.utc({year: this.year});
	}

	static fromMoment(moment: moment$Moment): Year {
		return Year.of(moment.year());
	}

	getValue(): number {
		return this.year;
	}

	get(field: ChronoField): number {
		throw new Error('Not implemented');
	}

	until(endExclusive: Temporal, unit: ChronoUnit): number {
		throw new Error('Not implemented');
	}

	compareTo(other: Year): number {
		return this.year - other.year;
	}

	equals(other: ?any): boolean {
		if (other == null) {
			return false;
		}
		if (!(other instanceof Year)) {
			return false;
		}
		return this.year === other.year;
	}

	isAfter(other: Year): boolean {
		return this.compareTo(other) > 0;
	}

	isBefore(other: Year): boolean {
		return this.compareTo(other) < 0;
	}

	isSupported(field: ChronoField | ChronoUnit): boolean {
		if (field instanceof ChronoField) {
			return Year.supportedFields.indexOf(field) > -1;
		}
		if (field instanceof ChronoUnit) {
			return Year.supportedUnits.indexOf(field) > -1;
		}
		throw new Error('Not supported type of the argument');
	}

	static supportedFields = [
		ChronoField.YEAR_OF_ERA,
		ChronoField.YEAR,
		ChronoField.ERA
	];

	static supportedUnits = [
		ChronoUnit.YEARS,
		ChronoUnit.DECADES,
		ChronoUnit.CENTURIES,
		ChronoUnit.MILLENNIA,
		ChronoUnit.ERAS
	];

	static getMethodSupportedEnumValues = Year.supportedFields;
}

import Entity from '../models/entity'
import {scaleDecimals} from '../components/utils.js'
import entityManager from '../components/entityManager.js'


/* @flow */

export function registerWebsocketListener(callback) {
  app.webSocketClient.registerCustomUserListener((data) => {
    const type = app.types.get(data.entityTypeId)
    scaleDecimals(type, data)
    entityManager.evictStringViews(data)
    let model = Entity.fromJSON(data, type.id)
    entityManager.fetchStringViews()
    callback(model)
    console.log("registered custom websocket listener")
  })
}

import MultilingualString from "../models/multilingualString";

export function isNullOrEmpty(multilingualString) {
	if (typeof multilingualString === "string") {
		multilingualString = MultilingualString.fromString(multilingualString);
	}
	return multilingualString == null || multilingualString.isEmpty();
}

export function isNullOrBlank(multilingualString) {
	if (typeof multilingualString === "string") {
		multilingualString = MultilingualString.fromString(multilingualString);
	}
	return multilingualString == null || multilingualString.isBlank();
}

export function concat() {
	if (arguments.length == 0) {
		return null;
	}
	const result = arguments[0].clone();
	if (result.getValue() == null) {
		result.setValue('');
	}
	for (let i = 1; i < arguments.length; ++i) {
		const str = arguments[i];

		_.chain(result.getTranslations().keys())
			.intersection(str.getTranslations().keys())
			.each(lang => {
				const newValue = result.getTranslations().get(lang) + str.getTranslations().get(lang);
				result.setTranslation({languageTag: lang}, newValue);
			});

		const strDefaultValue = str.getValue() || '';
		_.chain(result.getTranslations().keys())
			.difference(str.getTranslations().keys())
			.each(lang => {
				const newValue = result.getTranslations().get(lang) + strDefaultValue;
				result.setTranslation({languageTag: lang}, newValue);
			});

		_.chain(str.getTranslations().keys())
			.difference(result.getTranslations().keys())
			.each(lang => {
				const newValue = result.getValue() + str.getTranslations().get(lang);
				result.setTranslation({languageTag: lang}, newValue);
			});

		result.setValue(result.getValue() + strDefaultValue);
	}
	return result;
}

export function cloneMultilingualString(inputString: MultilingualString): MultilingualString {
	if(inputString) {
		return inputString.clone();
	} else {
		return new MultilingualString();
	}
}
import Pairs from '../collections/pairs';
import Suggestions from './suggestions';

export default class DatepickerSuggestions extends Suggestions {

	onInputBlur() {
	}

	onInputFocus() {
		this.open();
	}

	onInputChange() {
	}

	onOpen() {
	}

	close() {
	}
}

import { Toggle } from '@codejig/ui-components'

class ToggleSwitch {
  constructor (options) {
    var that = this
    this.model = options.model
    this.modelAttr = options.modelAttr
    this.context = options.context;
    this.el = options.el
    this.disabled = this.el.getAttribute('data-disabled') == 'true'
    this.props = {
      identifier: this.el.getAttribute('id').slice(7),
      disabled: this.el.hasAttribute('disabled') || false,
      value: this.model.get(this.modelAttr),
      selectedClass: this.el.getAttribute('data-style')
    }
    this.toggleSwitch = new Vue({
      el: that.el,
      data: {
        props: that.props
      },
      render: h => h(Toggle, {
        props: this.props,
        ref: 'toggleSwitch',
        on: {
          input: function (data) {
            if (that.model && that.model.get(that.modelAttr) !== data) {
              that.model.set(that.modelAttr, data)
              that.model.trigger(`manualChange:${that.modelAttr}`, that.model)
            }
          }
        }
      })
    }).$refs.toggleSwitch

    this.el = this.toggleSwitch.$el
    this.model.on('change:' + this.modelAttr, (model, value) => {
      that.props.value = value
    })
  }

  render () {}

  focus () {
//    this.toggleSwitch.focus()
  }

  isDisabledInFormBuilder () {
    return this.disabled
  }

  disable () {
    this.props.disabled = true
  }

  enable () {
    this.props.disabled = false
  }
}
export default ToggleSwitch

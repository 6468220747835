/* @flow */

export default class EntitiesWithOwner<T> extends Backbone.Collection {

	_byOwnerId: Array<T> = null;

	initialize() {
		super.initialize.apply(this, arguments);
		// This collection grouped by 'ownerId' field
		this._byOwnerId = null;
		this.on('update reset', this._groupByOwnerId);
	}

	_groupByOwnerId(collection: EntitiesWithOwner<T>): void {
		collection._byOwnerId = collection.groupBy(entity => {
			return entity.get('ownerId');
		});
	}

	byOwnerId(ownerId: any): T {
		if (this._byOwnerId === null) {
			this._groupByOwnerId(this);
		}
		return this._byOwnerId[ownerId];
	}
}

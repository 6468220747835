import { applicationState } from '../common/components/applicationState';
import StateRecovery from '../common/components/stateRecovery.js';
import MultilingualString from '../common/models/multilingualString';

export function filterTable(filterObject, connector){
  		if (!filterObject){
			filterObject=getFromUrl();
			if (!(filterObject.filters.length||filterObject.search)){
				filterObject=getFromLS();
			}
      showFilters(filterObject);
		}else{
			filterObject=generateFilterObject();
		}
		var filtered=applyFilter(filterObject)
		app.showData.splice(0,app.showData.length)
		_.each(filtered,(data)=>{app.showData.push(data)})
		addToUrl(filterObject)
		addToLS(filterObject)
		updateNumbers(connector);
    return filterObject
}

function compareCaseInsensitive(search,name):boolean{
	if (!search){
		return true;
	}
	search=search.toLowerCase();
	name=name.toLowerCase();
  let find=name.indexOf(search);
  if (find==-1){
    return false
  }else{
    return true
  }
 }
function generateFilterObject():object{
	var ob={}
	ob.filters=generateFilters();
	var t=$(".tableSearch")[0].value
	if (t){
		ob.search=t;
	}
	return ob
}
export function generateFilters(): array{
	var filters=[];
		$('.entityCategory.active').each(function (index, category) {
			filters.push({categoryId:$(category).attr('data-categoryid')})
		});
		$('.module.active').each(function (index, module) {
			filters.push({moduleId:$(module).attr('data-moduleid')})
		});
		$('.typeKind.active').each(function (index, kind) {
			filters.push({kind:$(kind).attr('data-kind')})
			});
		return filters;
}
function addToUrl(o){
	applicationState.put("entitiesTable",o)
}
function addToLS(o){
	StateRecovery.put("entitiesTable",o)
}
function getFromUrl():object{
	var f=applicationState.get("entitiesTable")
	if (!(f)){
		f={}
	}
	if (!(f.filters)){
		f.filters=[];
	}
	if (!(f.search)){
		f.search=""
	}
	return f
}
function getFromLS():object{
	var f=StateRecovery.get("entitiesTable")
	if (!(f)){
		f={}
	}
	if (!(f.filters)){
		f.filters=[];
	}
	if (!(f.search)){
		f.search=""
	}
	return f
}
function applyFilter(a):array{
	var res=[]
	res=_.filter(app.data,(d)=>{
		var filteredKind=false
		var filteredCat=false
		var filteredModule = false;
		if (a.filters){
			var wasFilter=false
			_.each(a.filters,(f)=>{
				if (f.kind){
					wasFilter=true;
					if (d.typeKind==f.kind){
						filteredKind=true;
					}
				}
			})
			if (!wasFilter){
				filteredKind=true;
			}
			wasFilter=false;
			_.each(a.filters,(f)=>{
				if (f.categoryId){
					if (f.categoryId===app.nocategory&&(d.categories.length===0)){
						filteredCat=true
					}
					wasFilter=true;
					_.each(d.categories,(c)=>{
						if (c.id==f.categoryId){
							filteredCat=true;
						}
					})
				}
			})
			if (!wasFilter){
				filteredCat=true;
			}
			let moduleFilters = a.filters.filter(f => f.moduleId);
			filteredModule = moduleFilters.length == 0 ||
				_.any(moduleFilters,
						f => f.moduleId == app.nomodule && d.modules.length == 0
								|| _.any(d.modules, m => m.id === f.moduleId));

		}
		let name=(new MultilingualString(d.name)).getCurrentValue()||""
		return !d.isPredefined && !d.isHidden && filteredKind && filteredCat && filteredModule && compareCaseInsensitive(a.search,name);
	})
	return res;
}
function showFilters(filterObject){
	_.each(filterObject.filters,(f)=>{
		if (f.kind){
			$(`.typeKind[data-kind=${f.kind}]`).addClass("active")
		}else if (f.categoryId){
			$(`.entityCategory[data-categoryid="${f.categoryId}"]`).addClass("active")
		} else if (f.moduleId) {
			$(`.module[data-moduleid="${f.moduleId}"]`).addClass('active');
		}
	})
		$(".tableSearch")[0].value=filterObject.search;
}
function updateNumbers(connector){
	$("#entitiesTable_allEntities").text(app.showData.length)
  if (connector){
	   $("#entitiesTable_selectedEntities").text(connector.getSelection().length);
  }
}

import BaseModel from '../../common/models/baseModel'
import { translate } from '../service/stringResourceService'
import CreateModal from "../components/createModal";
import ViewKind from "../enums/viewKind";
import utils from "../components/utils";
import CreateView from "../../EntityView/views/createView";

export default class EmptyProjectModal extends Backbone.View {

	initialize () {
		let $el = $('#emptyProjectModal')
		if ($el.length == 0) {
			let el = document.createElement('div')
			el.id = 'emptyProjectModal'
			el.classList.add('modal')
			el.classList.add('fade')
			document.body.appendChild(el)
		}
		$el = $('#emptyProjectModal')
		$el.html(this.getHTML())
		this.setElement($el)
	}

	getHTML () {
		return `
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header create-modal-header">
							<h4>
								<span>${translate('publish.empty.project')}</span>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
								</button>
							</h4>
						</div>
						<div class="modal-body">
							<span>${translate('no.data.types')}</span>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default pull-left cancel" data-dismiss="modal">${app.getResource('cancel')}</button>
							<button type="button" class="btn btn-primary publish" data-dismiss="modal">${app.getResource('publish.anyway')}</button>
							<button type="button" class="btn btn-success createNewPage" data-dismiss="modal">${app.getResource('create.new.page')}</button>
						</div>
					</div>
				</div>`
	}

	show (options: Object) {
		const that = this;
		this.$el.find('.publish').click((e) => {
			app.buildView._onPublish(e, true);
			that.$el.modal('hide');
		});
		this.$el.find('.cancel, .close').click(() => {
			that.$el.modal('hide');
		});
		this.$el.find('.createNewPage').click((e) => {
			this.createModal = new CreateModal();
			let model = new BaseModel();
			model.set('viewKind', ViewKind.FORM);
			e.stopPropagation()
			this.createModal.show({
				url: app.urls.createWebPage,
				headerResource: `add.form.view`,
				model: model,
				afterCreateOnSuccess: (view) => {
					utils.redirectTo(app.urls.editView(view.id, view.owner.id));
				},
				createView: CreateView
			});
			that.$el.modal('hide');
		});
		this.$el.modal({backdrop: 'static'});
	}

}

import Entity from './entity'

const EntityExternal = Entity.extend({
  idAttribute: 'urlString'
})

EntityExternal.fromJSON = function (obj, entityTypeId) {
	return new EntityExternal(obj, {entityTypeId})
};
export default EntityExternal

import utils from '../../common/components/utils.js';
import entityManager from '../../common/components/entityManager.js';

var TriggerButton = Backbone.View.extend({

	events: {
		'click': 'clicked'
	},

	initialize: function (options) {
		this.beforeServerEvent = this.$el.data('before_server_event');
		this.serverEvent = this.$el.data('server_event');
		this.afterServerEvent = this.$el.data('after_server_event');
		this.context = options.context;
		//TODO :: remove this hack
		this.runInUpdateQueue = this.$el.parent().hasClass('runInUpdateQueue');
	},

	clicked: function () {
		this.disable()
		let invokedEvent = entityManager.invokeCustomEvent({
			beforeServerEventBlockId: this.beforeServerEvent,
			serverEventBlockId: this.serverEvent,
			afterServerEventBlockId: this.afterServerEvent
		}, this.context, this.runInUpdateQueue);
		if (invokedEvent){
			invokedEvent.then(() => {
				setTimeout(() => {this.enable()},0)
			}).catch(() => {
				setTimeout(() => {this.enable()},0)
			})
		}else {
			setTimeout(() => {this.enable()},0)
		}
	},

	enable: function () {
		if (!this.$el.hasClass('do-not-enable-after-event')){
			this.$el.removeAttr('disabled');
			this.$el.focus();
		}
	},

	disable: function () {
		this.$el.attr('disabled', 'disabled');
	},

	isDisabled: function () {
		return !!this.$el.attr('disabled');
	}
});

export default TriggerButton;

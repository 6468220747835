/* @flow */

import ChronoField from '../enums/chronoField';
import ChronoUnit from '../enums/chronoUnit';
import LocalDate from './localDate';

import type { Temporal } from '../declarations';
import type ZoneId from './zoneId';

import {
	MONTHS_PER_YEAR
} from '../constants';

import {
	checkValidYear,
	checkValidInclusive,
	checkUnitIsSupported,
	mod
} from '../utils';

/**
 * Serilized form:
 *   year: int
 *   month: int
 */

export default class YearMonth {

	year: number;
	month: number;

	constructor(year: number, month: number) {
		checkValidYear(year);
		checkValidInclusive(MONTHS_PER_YEAR, month);
		this.year = year;
		this.month = month;
	}

	static of(year: number, month: number) {
		return new YearMonth(year, month);
	}

	static fromJSON(value: ? any): ? YearMonth {
		if (value == null) {
			return null;
		}
		return new YearMonth(value.year, value.month);
	}

	static now(zone: ZoneId): YearMonth {
		const date = LocalDate.now(zone);
		return new YearMonth(date.getYear(), date.getMonthValue());
	}

	toJSON() {
		return {
			year: this.year,
			month: this.month
		}
	}

	toMoment() {
		return global.moment.utc({
			year: this.year,
			month: this.month - 1
		});
	}

	static fromMoment(moment: moment$Moment): YearMonth {
		return YearMonth.of(moment.year(), moment.month() + 1);
	}

	getMonthValue(): number {
		return this.month;
	}

	getYear(): number {
		return this.year;
	}

	get(field: ChronoField): number {
		throw new Error('Not implemented');
	}

	plus(amount: number, unit: ChronoUnit): YearMonth {
		checkUnitIsSupported(this, unit);
		switch (unit) {
			case ChronoUnit.MONTHS:
				const sumMonth = this.month + amount;
				const year = this.year + Math.floor((sumMonth - 1) / MONTHS_PER_YEAR);
				const month = mod(sumMonth - 1, MONTHS_PER_YEAR) + 1;
				return new YearMonth(year, month);
			case ChronoUnit.YEARS:
				return new YearMonth(this.year + amount, this.month);
			default:
				throw new Error('Not implemented');
		}
	}

	until(endExclusive: Temporal, unit: ChronoUnit): number {
		throw new Error('Not implemented');
	}

	compareTo(other: YearMonth): number {
		const y = this.year - other.year;
		if (y !== 0) {
			return y;
		}
		return this.month - other.month;
	}

	equals(other: ? any): boolean {
		if (other == null) {
			return false;
		}
		if (!(other instanceof YearMonth)) {
			return false;
		}
		return this.year === other.year &&
			this.month === other.month;
	}

	isAfter(other: YearMonth): boolean {
		return this.compareTo(other) > 0;
	}

	isBefore(other: YearMonth): boolean {
		return this.compareTo(other) < 0;
	}

	isSupported(field: ChronoField | ChronoUnit): boolean {
		if (field instanceof ChronoField) {
			return YearMonth.supportedFields.indexOf(field) > -1;
		}
		if (field instanceof ChronoUnit) {
			return YearMonth.supportedUnits.indexOf(field) > -1;
		}
		throw new Error('Not supported type of the argument');
	}

	static supportedFields = [
		ChronoField.MONTH_OF_YEAR,
		ChronoField.PROLEPTIC_MONTH,
		ChronoField.YEAR_OF_ERA,
		ChronoField.YEAR,
		ChronoField.ERA
	];

	static supportedUnits = [
		ChronoUnit.MONTHS,
		ChronoUnit.YEARS,
		ChronoUnit.DECADES,
		ChronoUnit.CENTURIES,
		ChronoUnit.MILLENNIA,
		ChronoUnit.ERAS
	];

	static getMethodSupportedEnumValues = YearMonth.supportedFields;
}

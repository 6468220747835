/* @flow */

import RepeatableAction from "../../service/repeatableAction";
import {MultilingualString} from "../../components/classes";
import Instant from '../../../time/models/instant';
import Duration from '../../../time/models/duration';
import formattersAndStyles from '../../components/formattersAndStyles';
import { timeSynchronizer } from '../../../time/utils';
import TaskState from '../../enums/taskState';
import TaskResult from '../../enums/taskResult';
import TaskKind from "../../enums/taskKind";
import TaskInfoKind from "../../enums/taskInfoKind";

type Task = {
  state: string,
  id: string,
  timeReceived: any,
  timeStarted: any,
  timeDone: any,
  kind: TaskKind,
  infoKind: TaskInfoKind
}

export default class TaskInfoView extends Backbone.View {

    task: Task;

    initialize(o) {
        this.$el = o.$el;
        this.popupMode = o.popupMode;
    }

    show(task: Task, $task) {
        if (this.liveDuration){
            this.liveDuration.cancel();
            this.liveDuration = null;
        }
        if (this.isShowing(task)){
            this.hide();
        } else {
            this.task = task;
            this.$task = $task;
            this.render();
            this.$el.show();
        }
    }

    render() {
      let that = this;
        if (this.liveDuration){
            this.liveDuration.cancel();
            this.liveDuration = null;
        }
        if (this.task.state != TaskState.DONE){
            this.liveDuration = new RepeatableAction(1000, () => {
                if (that.task) {
                  let fromTime = that.task.state == TaskState.RUNNING ? that.task.timeStarted : that.task.timeReceived;
                  this.taskDuration = timeSynchronizer.getServerEventAge(Instant.fromJSON(fromTime));
                  this.taskDuration = Duration.ofSeconds(this.taskDuration.getSeconds());
                  this.$el.find('.task-duration').html(this.formatDuration(this.taskDuration));
                }
            });
        }
        let reversed = false;
        if (this.popupMode) {
          reversed = this.$task.offset().top + 350 > window.innerHeight;
          if (reversed) {
            this.$el.css('top', 'auto');
            this.$el.css('bottom', 'calc(100vh - ' + (this.$task.offset().top + 20)+ 'px)');
          } else {
            this.$el.css('bottom', 'auto');
            this.$el.css('top', this.$task.offset().top + 'px');
          }
        }
        let elements = [];

        let closeTaskArrow = `<span id="close-task-details" class="pull-right-container" style="cursor: pointer;">
            <i class="fa fa-angle-right pull-right mirror-in-rtl"></i>
        </span>`;

        let additionalInfo = `<div>
            <span><i>${app.getResource('user')}: </i>${formattersAndStyles.userAccountFormatter(this.task.userAccount)}</span>
            <span class="pull-right">${this.renderTaskResult()}</span>
          </div>`

        elements.push(`
                <div class="control-sidebar-heading box-header" style="margin-bottom: 0px">
                    <b>${ this.task.description ?
                       (new MultilingualString(this.task.description)).getCurrentValue() :
                       this.task.kind}
                    </b>
                    ${this.popupMode ? closeTaskArrow : additionalInfo}
                </div>
        `);
        let remoteEndpoints = this.task.remoteEndpoints || [];
        if (remoteEndpoints.length > 0) {
          elements.push(`<hr />`);
          elements.push(this.renderRemoteEndpoint(remoteEndpoints[0]));
        }
        elements.push(`<hr />`);
        elements.push(this.renderTaskInfo());
        let messages = this.task.messages;
        if (messages && messages.length){
          elements.push(`<hr />`);
          elements.push(this.renderMessages(messages));
        }
        if (reversed){
          elements.reverse();
        }
        this.$el.html(`<div class="treeview-menu task-info">
                        ${elements.join('\n')}
                      </div>`);

        this.$el.find('#messages-list').perfectScrollbar();
    }

    renderTaskResult() {
	return this.task.result == TaskResult.FAIL
		? `<a href="${app.urls.task.rethrow(this.task.id)}" title="${app.getResource('stacktrace')}" class="pull-right">${this.task.result}</a>`
		: this.task.result;
    }

    renderTaskInfo(){
      let alternative = (this.task.state == TaskState.DONE || this.task.state == TaskState.RUNNING) ? `
                      <div class="col-xs-4">
                              <label class="control-sidebar-subheading">Started</label>
                              <p>${this.formatDate(this.task.timeStarted)}</p>
                      </div>
                      <div class="col-xs-4">
                              <label class="control-sidebar-subheading">Taken time</label>
                              <p class="task-duration">${this.formatTaskDuration(this.task)}</p>
                      </div>
      ` : `
                      <div class="col-xs-4">
                              <label class="control-sidebar-subheading">Not started</label>
                      </div>
                      <div class="col-xs-4">
                              <label class="control-sidebar-subheading">Waiting time</label>
                              <p class="task-duration">${this.formatWaitingDuration()}</p>
                      </div>
      `;
      return `<div class="row text-center">
              <div class="col-xs-4">
                      <label class="control-sidebar-subheading">Received</label>
                      <p>${this.formatDate(this.task.timeReceived)}</p>
              </div>
              ${alternative}
              </div>
            `;
    }

    formatWaitingDuration() {
        return this.formatDuration(Duration.ofSeconds(timeSynchronizer.getServerEventAge(Instant.fromJSON(this.task.timeReceived)).getSeconds()));
    }

    renderMessages(messages) {
        var that = this;
        var text = ``;
        _.each(messages, message => {
            let cls = _.escape(message.kind.toLowerCase()) + '-message';
            text += `<div class="task-message menu-info">
                                    <i class="${cls} material-icons notranslate menu-icon">error_outline</i>
                                    <span class="${cls}" style="margin-left: 5px">${_.escape(message.kind)}</span>
                                <small style="margin-left: 5px">${this.formatDate(message.timeStamp, 'HH:mm:ss')}</small>
                    </div>
                    <div style="margin-left: 30px">${_.escape((new MultilingualString(message.text)).getCurrentValue())}</div>`;
        });
        return `<div class="pull-right menu-info" style="margin-right: 8px">
										<small>${messages.length} messages</small>
								</div>
                <br />
                <div id="messages-list" style="margin: 0 8px 8px 8px; max-height: 200px; overflow: hidden">
                    ${text}
                </div>`;
    }

    renderRemoteEndpoint(remoteEndpoint) {
      return `<div>
        <span><i>${app.getResource('builder')}: </i><a>${remoteEndpoint}</a></span>
      </div>`;
    }

    formatTaskDuration(task){
        if (task.state == TaskState.RUNNING){
            return this.formatDuration(this.taskDuration);
        }
        return formattersAndStyles.task.formatTaskDuration(null, task);
    }

    formatDuration(d: Duration){
        return formattersAndStyles.task.formatDuration(d);
    }

    formatDate(date, format) {
        return formattersAndStyles.task.formatDate(date);
    }

    hide() {
        this.task = null;
        this.$el.hide();
    }

    isShowing(task) {
        return this.task && this.task.id === task.id;
    }

};

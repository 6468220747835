import MultilingualString from '../common/models/multilingualString.js';
import MetaObjectTreeViewKind from '../common/enums/metaObjectTreeViewKind.js';
import Constants from '../common/models/constants'
import { fillResourceValues } from '../common/service/stringResourceService'
import PropertyKey from '../common/enums/propertyKey'
import { getListPropValue } from '../common/components/utils'

export default {
	main: {
		render: () => {
			$('.logo-mini > .navbar-brand').attr('href', app.urls.home);
			$('.logo > .logo-lg > .navbar-brand').attr('href', app.urls.home);
			if (!app.currentUser) {
				$('.user-menu').hide();
				$('.user-login').show();
				$('.user-login-a').attr('href', app.urls.customLoginPage ? app.urls.customLoginPage : app.urls.login);
			} else {
				$('.user-menu').show();
				$('.user-login').hide();
				$('.user-menu .user-name').html(`
					<img src="${app.urls.userIcon}" class="user-image" alt="User Image">
					<span class="hidden-xs">${MultilingualString.toHTML(app.currentUser.firstName) +
							' ' + MultilingualString.toHTML(app.currentUser.secondName)}</span>
				`);
				$('.user-menu .user-header').html(`
					<img src="${app.urls.userIcon}" class="img-circle" alt="User Image">
					<p>${MultilingualString.toHTML(app.currentUser.firstName) +
							' ' + MultilingualString.toHTML(app.currentUser.secondName)}</p>
				`);
			}
			$('.configuration-update').attr('href', app.urls.update(Constants.ID_TYPE_CONFIGURATION));
			$('.system-menu-item.appUsers > a').attr('href', app.urls.open(Constants.ID_TYPE_APP_USER_ACCOUNT));
		}
	},
	clientFormTemplate: $el => ({
		render: (html) => {
			let formsContainer = $el.find('.forms-container')
			if (formsContainer.length == 0) formsContainer = $el
			formsContainer.html('<div>' + html + '</div>');
			fillResourceValues($el.find('.forms-container'));
			if (!app.builderMode) {
				$el.find('.change-read-only').remove();
			}
			$el.find('.confirm.remove')
				.attr('data-text', app.getResource('action.delete.items.question'))
				.attr('title', app.getResource('delete'));
		}
	}),
	indexTemplate: op => ({
		render: (html) => {
				const $el = op && op.$el
				$el.find('.forms-container').html('<div>' + html + '</div>');
				$el.find('.caption').html(op.entityTypeName);
				let canDeleteVal = getListPropValue(op.listProperties, PropertyKey.CAN_DELETE)
				if (!(op.hasMetaObject || op.isExternal) || !(canDeleteVal == null || canDeleteVal)) {
					$el.find('.table-index-table_remove').parent().remove();
				}
				if (!op.hasMetaObject || !op.listProperties || !getListPropValue(op.listProperties, PropertyKey.HAS_FOLDER_TREE)) {
					$el.find('.folders-switcher').remove();
					$el.find('.folders-col').remove();
					$el.find('.table-col').addClass('slide-content col-md-12')
				} else if (getListPropValue(op.listProperties, PropertyKey.USE_TREE_ONLY)) {
					$el.find('.table-col').addClass('col-0')
					$el.find('.folders-col').removeClass('col-md-2').addClass('col-md-12')
				} else {
					$el.find('.table-col').addClass('slide-content col-md-10')
				}
				if (getListPropValue(op.listProperties, PropertyKey.HIDE_CHECKBOX)){
					$el.find('.table-index-table_selected-dropdown').remove()
				}
				if (op.canViewHistory != null && !op.canViewHistory) {
					$el.find('.show-deleted').remove();
				}
				let canAddVal = getListPropValue(op.listProperties, PropertyKey.CAN_ADD)
				if (!(op.hasMetaObject || op.isExternal) || !(op.canCreate == null || op.canCreate) || !(canAddVal == null || canAddVal)) {
					$el.find('.table-index-table_add').remove();
				}
				if (op.hideSelection) {
					$el.find('.table-index-table_selected-dropdown').remove();
				}
				if (!op.isDocuments) {
					$el.find('.table-index-table_applyMany').parent().remove();
					$el.find('.table-index-table_relieveMany').parent().remove();
				}
				if (op.hideContextMenu) {
					$el.find('.context-menu-dropdown').remove();
				}
				if (!op.showConfirmSelect) {
					$el.find('.table-index-table_confirm-select').remove();
				}
				// We have create new of some type as default route. Open app, create new instance, appears on the type index page and when press back button appears on create new.
				// VK supposes that this infinite cycle is a bit confusing for new user.
				// To avoid that we decided moved back button from index page of type which is used in default create new route.
				// Also condition check tabs to not lost back button in case whe index page is not on first cjTab (then on button press we go to first tab).
				if (app.cjTabs.activeTab.id == 1 && op.sameTypeAsDefaultRoute) {
					$el.find('.fa-chevron-left').parent().remove()
				}
		}
	})
};

/* @flow */

export default class FormatterInfo {

		static formatOwner(owner: Object): string {
			if (!owner.firstName && !owner.lastName) {
				return app.getResource('not.assigned');
			}
			if (owner.firstName && owner.lastName) {
				return `${_.escape(owner.firstName)} ${_.escape(owner.lastName)}`;
			}
			return _.escape(owner.firstName) || _.escape(owner.lastName);
		}

		static formatVersion(version: ?string, buildNumber: string, isDev: ?boolean): string {
			let result = _.escape(buildNumber);
			if (version) {
				result = `${_.escape(version)} (${result})`;
			}
			if (isDev) {
				result += ` (${app.getResource('dev.build')})`;
			}
			return result;
		}

		static formatValue(value: string): string {
			return _.escape(value) || app.getResource('not.assigned');
		}
}

import BaseForm from '../../common/views/baseForm.js';
import utils from '../../common/components/utils.js';
import {translate} from '../../common/service/stringResourceService'

export default class SaveForm extends BaseForm {

	constructor(o) {
		super(o || {});
	}

	initialize(o) {
		super.initialize(o);
		this.events = this.events || {};
		_.extend(
				this.events,
				SaveForm.prototype.events,
				{[this._getSaveEvent()]: 'save'}
		);
		this.delegateEvents();
		this.$('.save .animation').hide();
		this.redirectOnCreateUrl = o && o.redirectOnCreateUrl;
		if (o && o.redirectToIndexOnSave != undefined) {
			this.redirectToIndexOnSave = o.redirectToIndexOnSave;
		} else if (this.redirectToIndexOnSave == undefined) {
			this.redirectToIndexOnSave = true;
		}
		if (o && o.reload != undefined) {
			this.reload = o.reload;
		} else if (this.reload == undefined) {
			this.reload = true;
		}
	}

	_getSaveEvent() {
		return 'click .save';
	}

	save(e, options) {
		app.tasksQueue.add(() => {
			if (this.validate()) {
				this.beforeSave();
				var config = this.saveConfiguration();
				return utils.postRequest(config.data, config.url)
					.then((data)=> {
						this.saveSuccess(data);
						const redirectUrl = options && options.redirectUrl
									|| e && $(e.target).attr('data-redirect');
						if (redirectUrl) {
							location.href = redirectUrl;
						} else if (data && !isNaN(data) && this.redirectOnCreateUrl) {
							location.href = this.redirectOnCreateUrl + '/' + data;
						} else if (this.redirectToIndexOnSave) {
							utils.redirectToIndex();
						} else {
							if (this.reload) {
								location.reload();
							}
						}
					})
					.catch( (e) => {
						this.saveError(e);
					});
			}
		})
	}

	validate() {
		return true;
	}

	beforeSave() {
		this.$('.save .animation').show();
		this.$('.save .static').hide();
	}

	saveConfiguration() {
		return {
			url: window.location.href,
			data: this.model.toJSON()
		};
	}

	saveSuccess() {
		this.$('.save .animation').hide();
		this.$('.save .static').show();
		app.notificationManager.addSuccess(translate('saved'));
	}

	saveError(e) {
		console.log(e);
		this.$('.save .animation').hide();
		this.$('.save .static').show();
	}
}

<template>

    <cjg-binary-uploader
            ref="binaryInput"
            :binary-data="props.binaryData"
            :dz-options="props.dzOptions"
            :default-message="props.defaultMessage"
            :default-message-html="props.defaultMessageHtml"
            :default-no-file-message="props.defaultNoFileMessage"
            :preview-template-fn="props.previewTemplateFn"
            :file-description-fn="props.fileDescriptionFn"
            :file-download-url-fn="props.fileDownloadUrlFn"
            @success="events.onSuccess"
            @error="events.onError"
            @removedfile="events.onRemovedFile"
            @reset="events.onReset"
            @complete="events.onComplete">
    </cjg-binary-uploader>

</template>

<script>
import { BinaryUploader } from '@codejig/ui-components'

export default {
    props: ['props', 'events'],
    components: {
        'cjg-binary-uploader': BinaryUploader
    }
}
</script>

import BaseModel from '../../../common/models/baseModel.js';
import MultilingualString from '../../../common/models/multilingualString.js';
import PropertyKey from '../../../common/enums/propertyKey.js';
import PropertyType from '../../../common/enums/propertyType.js';
var Property = BaseModel.extend({

	initialize: function (o) {
		for (var prop in o) {
			if (o.hasOwnProperty(prop) && prop === 'value') {
				if ([PropertyType.MLS, PropertyType.HTML].indexOf(this.get('type')) > -1) {
					this.set(prop, new MultilingualString(o[prop]));
				} else {
					this.set('valueId', o[prop].id);
					switch (this.get('type')) {
						case PropertyType.BOOLEAN:
						case PropertyType.INT:
						case PropertyType.DOUBLE:
							this.set(prop, JSON.parse(o[prop].value || null));
							break;
						case PropertyType.MULTISELECT:
							this.set(prop, JSON.parse(o[prop].value || "[]"));
							break;
						default:
							this.set(prop, o[prop].value || null);
							break;
					}
				}
			} else {
				this.set(prop, o[prop]);
			}
		}
		this.on('change', this._onChange, this);
	},

	onChange: function (eventName, model) {
		if (this.bubbleMap[eventName]) {
			this.trigger(this.bubbleMap[eventName], this);
		}
	},

	toServerJSON: function () {
		var json = {
			key: this.get('key'),
			type: this.get('type'),
			relativeOrder: this.get('relativeOrder')
		};
		if (this.id && this.id !== this.cid) {
			json.id = this.id;
		} else {
			json.clientId = this.cid.substr(1);
		}
		if ([PropertyType.MLS, PropertyType.HTML].indexOf(this.get('type')) > -1) {
			json.value = this.get('value').toJSON();
		} else {
			json.value = {
				value: this.get('type') == PropertyType.MULTISELECT ?
											JSON.stringify(this.get('value')) : this.get('value'),
				id: this.get('valueId')
			};
		}
		return json;
	}
});

export default Property;

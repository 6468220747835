/* @flow */

import ZoneRules from './zoneRules';

import ZoneOffset from '../zoneOffset';
import type Instant from '../instant';

import {
	SECONDS_PER_MINUTE
} from '../../constants';

export default class MomentJsZoneRules extends ZoneRules {

	zoneId: string;

	constructor(zoneId: string) {
		super();
		this.zoneId = zoneId;
	}

	getOffset(instant: Instant): ZoneOffset {
		if (global.momentJsHelper) {
			global.momentJsHelper.loadTimezonePackage();
		}
		const reverseMinutes = global.moment.tz.zone(this.zoneId).utcOffset(instant.toEpochMilli());
		const seconds = -1 * reverseMinutes * SECONDS_PER_MINUTE;
		return ZoneOffset.ofTotalSeconds(seconds);
	}
}

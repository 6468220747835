/* @flow */
import  utils from '../components/utils';
import { Service } from './service';

class EntityFolderService extends Service {

  prefix(){
    return 'entityFolder';
  }

  reorder(ids){
    return utils.postRequest(ids, app.urls.home + this.prefix() + '/reorder');
  }

}

export let entityFolderService = new EntityFolderService();

import MultilingualString from '../../common/models/multilingualString';
import BaseCreateView from '../../common/views/baseCreateView';

var CreateSharedStyleModal = BaseCreateView.extend({

	initialize: function (options) {
		CreateSharedStyleModal.__super__.initialize.apply(this, arguments);
	},

	getHTML: function () {
		return `<div id="NameDiv">
							<div class="grid-1x-padding">
								<label for="name">${app.getResource('name')}</label>
							</div>
							<div class="grid-2x-padding">
								<input class="form-control" type="text" data-is-string-multilingual="short" data-field="name"/>
							</div>
						</div>
						<div id="CssDiv">
							<div class="grid-1x-padding">
								<label for="blockType">${app.getResource('css')}</label>
							</div>
							<div class="grid-2x-padding">
								<textarea class="form-control" type="text" data-field="css"/></textarea>
							</div>
						</div>`;
	}
});

export default CreateSharedStyleModal;

export default class ErrorCode {
	static LACK_THE_BLOCKS_EDITING_LICENSE = 'LACK_THE_BLOCKS_EDITING_LICENSE';
	static LACK_THE_USERS_QUOTA_LICENSE = 'LACK_THE_USERS_QUOTA_LICENSE';
	static LACK_THE_PROJECT_UPDATE_LICENSE = 'LACK_THE_PROJECT_UPDATE_LICENSE';
	static LACK_THE_FIELD_UPDATE_LICENSE = 'LACK_THE_FIELD_UPDATE_LICENSE' ;
	static LACK_THE_BLOCK_UPDATE_LICENSE = 'LACK_THE_BLOCK_UPDATE_LICENSE';
	static LACK_THE_VIEW_UPDATE_LICENSE = 'LACK_THE_VIEW_UPDATE_LICENSE';
	static REMOVING_INSTANCE_WITH_REFERENCES = 'REMOVING_INSTANCE_WITH_REFERENCES';
	static LACK_THE_MERGE_UPDATE_LICENSE = 'LACK_THE_MERGE_UPDATE_LICENSE';
	static LACK_THE_CUSTOM_CODE_VIEW_LICENSE = 'LACK_THE_CUSTOM_CODE_VIEW_LICENSE';
	static LACK_OF_LICENSE = 'LACK_OF_LICENSE';
	static META_DATA_IS_STALE = 'META_DATA_IS_STALE';
}

/* @flow */

export function isPushNotificationAvailable() {
	return app.pushNotificationsReady
		.then(() => true)
		.catch(() => false)
}
export function unsubscribe() {
	return app.pushNotificationsReady.then(pushNotifications => {
		return pushNotifications.unsubscribe()
	})
}
export function subscribe() {
	return app.pushNotificationsReady.then(pushNotifications => {
		return pushNotifications.isSubscribed().then((state) => {
			if (!state) {
				return pushNotifications.subscribe()
			}
		})
	})
}

import MultilingualString from './common/models/multilingualString';
import resourceCacheManager from './common/components/resourceCacheManager';

var resources = {};

resources.isResourceDefined = function (key) {
	return resourceCacheManager.isResourceDefined(key);
};

resources.getResource = function (key) {
	return resourceCacheManager.getResource(key);
};

resources.enumToResource = function (_enum) {
	return resourceCacheManager.enumToResource(_enum);
};

resources.enumToResourceForSelect = function (_enum) {
	return resourceCacheManager.enumToResourceForSelect(_enum);
};

export default resources;

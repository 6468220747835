import PrimitiveFormatter from '../models/primitiveFormatter';

export default class PrimitiveFormatters extends Backbone.Collection<PrimitiveFormatter> {

	initialize() {
		this.model = PrimitiveFormatter;
	}

	getPattern(formatterId: string): string {
		const formatter = this.get(formatterId);
		return (formatter && formatter.pattern()) || app.messages.missingFormatterError;
	}

	getFormattersForType(primitiveEntityType) {
		return this.models.filter(f => f.primitiveType() == primitiveEntityType);
	}

}


export default class Pair extends Backbone.Model {

	one() {
		return this.get('one');
	}

	two() {
		return this.get('two');
	}
}

import { urlByDocsResource, articleByDocsResource, userPilotByDocsResource, articleById, shortArticleById } from '../help/docsResource'
export { initHelpPopovers } from './docsResource'
import utils, { addCookie, farFutureDate, buildDefaultPopover } from '../common/components/utils'
import CheckboxInput from '../common/components/checkboxInput'
import stateRecovery from '../common/components/stateRecovery'
import {translate} from '../common/service/stringResourceService'
import LocalStorageKeysService from '../common/service/localStorageKeysService'
import ItemKind from './enums/itemKind'

export function addHrefToDocs() {
  document.querySelectorAll('[docs-resource]').forEach(function(elem) {
    elem.setAttribute('href', urlByDocsResource(elem.getAttribute('docs-resource')))
  })
}

function showDocsModalIcon() {
  $('.modal-docs-icon').css('visibility','visible')
}

function hideQuickTipIcon() {
  $('.modal-docs-icon').css('visibility','hidden')
}

function loadDocsModal(attr, headerResource) {
  return articleByDocsResource(attr).then((article)=>{
    document.body.insertAdjacentHTML('beforeend',
    `<div id="help-modal" class="modal fade" tabindex="-1" style="display: block;">
      <div class="modal-dialog">
        <div class="modal-content">
        <div style="background-color:#3c8dbc;color:white;height:45px">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="padding:10px">
        <span aria-hidden="true" style="color:white">×</span>
        </button>
        <h4 class="box-title" style="padding:12px">
          ${!headerResource ? translate('quick.tips') : translate(headerResource)}
        </h4>
        </div>
          <div class="modal-body" style="max-height:70vh; padding:35px; padding-top:15px; padding-bottom:15px; overflow-y:auto">
            ${article.content}
          </div>
        <div class="modal-footer" style="text-align:end">
        <div class="pull-left" style="text-align:start;${headerResource ? 'display:none':''}">
          <div class="checkbox">
            <label>
              <input type="checkbox" id="donotshowthis">
              <span>Don't show this again</span>
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" id="donotshowall">
              <span>Don't show any tips</span>
            </label>
          </div>
        </div>
        <div class="clearfix"></div>
        <button type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close">
        Close
        </button>
        </div>
        </div>
      </div>
    </div>`)
    $("#help-modal").modal('hide').css('display','none')
    var model = new Backbone.Model({
      showThis: stateRecovery.get(LocalStorageKeysService.buildKeyForNotShow(attr)),
      showAll: stateRecovery.get(LocalStorageKeysService.buildKeyForNotShow('Tips'))
    })
    new CheckboxInput({
          el: $('#donotshowthis')[0],
          model: model,
          modelAttr: 'showThis'
        })
    new CheckboxInput({
          el: $('#donotshowall')[0],
          model: model,
          modelAttr: 'showAll'
        })
    model.on('change:showThis', (a,b)=>{
      stateRecovery.put(LocalStorageKeysService.buildKeyForNotShow(attr), a.get('showThis'))
    })
    model.on('change:showAll', (a,b)=>{
      stateRecovery.put(LocalStorageKeysService.buildKeyForNotShow('Tips'), a.get('showAll'))
    })
    $('.modal-docs-icon').on('click', () => {
      $('#help-modal').addClass('to-right-top-corner')
      showDocsModal(attr)
      hideQuickTipIcon()
    })
  }
  )
}

function showDocsModal(attr) {
  $("#help-modal").modal('show').css('display','block')
  $("#help-modal").one('hidden.bs.modal', () => {
    showDocsModalIcon()
    $("#help-modal").html($("#help-modal").html())
  })
}

export function addUserPilots() {
  document.querySelectorAll('[modal-docs-resource]').forEach(function(elem) {
    var attr = elem.getAttribute('modal-docs-resource')
    const headerResource = elem.getAttribute('modal-docs-header-resource')
    const userPilot = userPilotByDocsResource(attr)
    if (!userPilot){
      const headerResource = elem.getAttribute('modal-docs-header-resource')
      doLoadDocsModal(attr, headerResource)
    } else {
      $('.modal-docs-icon').on('click', () => {
        app.cjHelper.triggerFlowByUpid(userPilot)
      })
      app.cjHelper.triggerFlowByUpidIfNeeded(userPilot)
    }
    showDocsModalIcon()
  })
}

function doLoadDocsModal(attr, headerResource){
  loadDocsModal(attr, headerResource).then(()=>{
    if (!(stateRecovery.get(LocalStorageKeysService.buildKeyForNotShow(attr)) || stateRecovery.get(LocalStorageKeysService.buildKeyForNotShow('Tips')))){
      $("#help-modal").one('hidden.bs.modal', () => {
        stateRecovery.put(LocalStorageKeysService.buildKeyForNotShow(attr), true)
      })
      $("#help-modal").one('shown.bs.modal', () => {
        $('#help-modal').addClass('to-right-top-corner')
      })
      showDocsModal(attr)
    }else{
      showDocsModalIcon()
    }
  })
}

export function addDocsModal() {
  document.querySelectorAll('[modal-docs-resource]').forEach(function(elem) {
    var attr = elem.getAttribute('modal-docs-resource')
    const headerResource = elem.getAttribute('modal-docs-header-resource')
    doLoadDocsModal(attr, headerResource)
  })
}

export function initTutorials () {
  let tutorials = $('#tutorial-section'), active, currentTab, currentQuickTip, tutorialSection, videosList = [], tutorialsList = []
  const controlSectionTag = document.getElementById("control-section")
  let arePreviewsAdded = false
  let savedHtmls = new Map()

  const addOpenedTutorialCookie = (state) => {
    addCookie({
      name: 'openedTutorial',
      value: state,
      expiresTime: farFutureDate().toGMTString(),
      path: '/'
    })
  }
  let tutorialInfo = {documentId: "", section:0, opened: false, activeTab: ""}
  const toggleTutorial = [ $("#toggle-tutorial"), $('#close-tutorial')]
  const bodyTag = document.getElementsByTagName("body").item(0)
  if (!tutorials) {
    return
  }
  toggleTutorial.forEach((toggle) => {
    toggle.click((e) => {
      if (bodyTag.classList.contains('tutorial-opened') && (!app.codejigChat || !app.codejigChat.isOpened)) {
        bodyTag.classList.remove('tutorial-opened')
        tutorialInfo.opened = false
      } else {
        bodyTag.classList.add('tutorial-opened')
        tutorialInfo.opened = true
        addPreviews()
        buildLinkPopovers($('#tutorialsTab'))   
      }
      if(tutorialInfo.opened && app.codejigChat && app.codejigChat.isOpened) {
        app.codejigChat.removeChat(); //remove chat if tutorial open
      }
      addOpenedTutorialCookie(tutorialInfo.opened)
      localStorage.setItem('tutorial-info', JSON.stringify(tutorialInfo))
    })
  })
  let sections = 0
  $('#showTutorialsList').click(() => {
    $('#control-section .for-header .header-placement').empty()
    tutorialInfo.documentId = ""
    tutorialInfo.section = 0
    localStorage.setItem('tutorial-info', JSON.stringify(tutorialInfo))
    renderTutorialsList()
  })
  let ls = localStorage.getItem('tutorial-info')
  if (ls) {
    ls = JSON.parse(ls)
    tutorialInfo.documentId = ls.documentId
    tutorialInfo.section = ls.section
    tutorialInfo.opened = ls.opened
    tutorialInfo.activeTab = ls.activeTab
  } else {
    $('body').addClass('tutorial-opened')
    tutorialInfo.opened = true
    addOpenedTutorialCookie(tutorialInfo.opened)
  }
  let buildAccordion = (sectionId) => {
    let count = 0
    Array.from($(`#${sectionId}`).find('tutorial-section')).forEach((mySection) => {
      let $mySection = $(mySection)
      if (!$mySection.hasClass('additional')) {
        $mySection.addClass('panel')
        $mySection.find('p').wrapAll(`<div id="${sectionId}Section${count}" class="collapse"></div>`)
        $mySection.find('h1').wrap(`<div class="accordion-header cursor-pointer" data-toggle="collapse" aria-expanded="false" data-parent="#${sectionId}" href="#${sectionId}Section${count}"></div>`)
        let $oneSection = $(`#${sectionId}Section${count}`).detach()
        $mySection.append($oneSection)
        $mySection.find('h1').prepend(`<span id="triangle${$mySection.attr('section-number')}" class="pull-left accordion-state fa fa-caret-right mirror-in-rtl"></span><span class="pull-left accordion-state fa fa-caret-down"></span>`)
        ++count
      }
    })
    $(`#${sectionId}`).on('shown.bs.collapse', () => {
      $('#tutorial-content .tab-pane.active').perfectScrollbar('update')
    })
  }
  let buildLinkPopover = ($el) => {
    let href = $el.attr('href')
    if (href.includes('codejig')) {
      let id = href.substring(href.lastIndexOf('/') + 1)
      shortArticleById(id).then((data) => {
        buildDefaultPopover( $el, {
          container: 'body',
          content: data.shortVersion,
          placement: 'right',
          html: true,
          popoverClass: 'libraryPopover'
        })
      })
    }
  }
  let buildLinkPopovers = ($newContent) => {
    Array.from($newContent.find('[href*=docs]')).forEach((a) => {
      let $link = $(a)
      $link.css({'color': 'rgb(55, 175, 251)', 'text-decoration': 'underline'})
      buildLinkPopover($link)
    })
  }
  let tutorialPromise = utils.getRequest(app.urls.home + "video-info/tutorials").then(data => {
    data.forEach(item => {
      buildItem(item)
    })
    tutorialSection = document.getElementById('tutorialsTab')
    if (currentQuickTip){
      articleById(currentQuickTip.id).then((article) => {
        let $tab = $('#quick_tipsTab')
        $tab.html(article.content)
        $tab.find('h1').first().wrap('<div class="quick-tip-header" style="margin-top: 10px;"></div>').wrap('<div class="header-placement"></div>')
        initImagesModal()
        if (currentQuickTip.isAccordion) {
          buildAccordion('quick_tipsTab')
        }
        savedHtmls.set('quick_tips', $tab.html())
        if (tutorialInfo.activeTab == 'tutorials') {
          $tab.empty()
        }
        buildLinkPopovers($('#quick_tipsTab'))
        onShowTabContentEvent()
      })
    }
    $('#templatesTab .merge-link').click((e) => {
      let target = $(e.currentTarget);
      utils.postRequest({}, target.data('href'));
      if (app.currentUser && !$('.merge-progressbar').length) {
        addLoader();
      }
    })
    $('#tutorial-content .tab-pane').perfectScrollbar()
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    let urlTutorId = urlParams.get("tutorial-id")
    let urlSection = urlParams.get("section")
    if (urlTutorId) {
      tutorialInfo.documentId = urlTutorId
    }
    if (urlSection) {
      tutorialInfo.section = urlSection
    }
    if (tutorialInfo.documentId) {
      onSelectTutorial()
      tutorialInfo.activeTab = "tutorials"
    } else {
      renderTutorialsList()
      tutorialInfo.activeTab = "quick_tips"
    }
    onShowTabContentEvent()
    tutorials.find(`#${tutorialInfo.activeTab}Tab`).addClass('active')
    tutorials.find(`[href=#${tutorialInfo.activeTab}Tab]`).parent().addClass('active')
    $('#tutorial-tabs li').on(('hide.bs.tab'), (e) => {
      let id = $(e.target).data('url')
      if (id == 'quick_tips' || (id == 'tutorials' && tutorialInfo.documentId)) {
        let $tab = $(`#${id}Tab`)
        savedHtmls.set(id, $tab.html())
        $tab.empty()
      }
    })
    $('#tutorial-tabs li').on('show.bs.tab', (e) => {
      let id = $(e.target).data('url')
      if (id == 'quick_tips' || (id == 'tutorials' && tutorialInfo.documentId)) {
        let $tab = $(`#${id}Tab`)
        $tab.html(savedHtmls.get(id))
        $('#additional-next').find('> a').click(makeNext)
        buildLinkPopovers($tab)
        initImagesModal()
      }
    })
    $('#tutorial-tabs li').on('shown.bs.tab', () => {
      let activeTab = $('#tutorial-tabs').find('.active').find('> a')
      tutorialInfo.activeTab = activeTab.attr('data-url')
      localStorage.setItem('tutorial-info', JSON.stringify(tutorialInfo))
      onShowTabContentEvent()
    })
  }).then(() => {
    if (tutorialInfo.opened) {
      addPreviews();
    }
  })

  function initPrevNext(part) {
    controlSectionTag.querySelector('a.prev').addEventListener('click', makePrev)
    controlSectionTag.querySelector('a.next').addEventListener('click', makeNext)
    $('#additional-next').find('> a').click(makeNext)
  }

  function makePrev() {
    hideSection()
    tutorialInfo.section--
    if (tutorialInfo.section < 0) {
      tutorialInfo.section = 0
    }
    showSection()
    let name = $('#control-section .header-placement > h1').text();
    utils.postRequest({
      name: name,
      step: tutorialInfo.section + 1
    }, app.urls.nextStep);
    localStorage.setItem('tutorial-info', JSON.stringify(tutorialInfo))
  }

  function makeNext() {
    hideSection()
    tutorialInfo.section++
    if (sections - 1 < tutorialInfo.section) {
      tutorialInfo.section = sections - 1
    }
    showSection()
    let name = $('#control-section .header-placement > h1').text();
    utils.postRequest({
      name: name,
      step: tutorialInfo.section + 1
    }, app.urls.nextStep);
    localStorage.setItem('tutorial-info', JSON.stringify(tutorialInfo))
  }

  function calcSections() {
    return tutorialSection.getElementsByTagName('tutorial-section').length
  }

  function hideSection() {
    tutorialSection.querySelector(`[section-number="${tutorialInfo.section}"]`).style.display = "none"
  }

  function showSection() {
    if (sections > tutorialInfo.section + 1) {
      $('#additional-next').show()
    } else {
      $('#additional-next').hide()
    }
    controlSectionTag.querySelector('.step').textContent = tutorialInfo.section + 1
    const sec = tutorialSection.querySelector(`[section-number="${tutorialInfo.section}"]`)
    if (!sec.querySelector('.fixed-header')){
      const h1 = sec.querySelector('h1')
      let fixedHeader = $(`
        <div class="fixed-header">
          <div class="circle">${tutorialInfo.section + 1}</div>
          ${h1 && h1.outerHTML || ""}
        </div>
      `)
      $(sec.querySelector('h1')).remove()
      $(sec).prepend(fixedHeader)
    }

    sec.style.display = "block"
    $('#tutorial-content #tutorialsTab').scrollTop(0)
    $('#tutorial-content #tutorialsTab').perfectScrollbar('update')
  }
  const imageModal = document.getElementById("image-modal")
  const imageTag = imageModal && imageModal.querySelector("img")
  imageTag && imageTag.addEventListener('click', (event) => {
    event.stopPropagation()
  })
  function initImagesModal() {
    tutorials[0].querySelectorAll('img').forEach((img) => {
      if (!$(img).parent().hasClass('preview-image')) {
        img.addEventListener('click', () => {
          imageModal.style.display = 'block'
          imageModal.querySelector('img').setAttribute('src', img.getAttribute('src'))
          bodyTag.addEventListener('keydown', hideModal)
          imageModal.addEventListener('click', hideModalOnBgClick)
        })
      }
    })
  }
  function hideModal (event) {
    if (event.keyCode == 27) {
      imageModal.style.display = 'none'
      bodyTag.removeEventListener('keydown', hideModal)
      imageModal.removeEventListener('click', hideModalOnBgClick)
    }
  }
  function hideModalOnBgClick (event) {
      imageModal.style.display = 'none'
      bodyTag.removeEventListener('keydown', hideModal)
      imageModal.removeEventListener('click', hideModalOnBgClick)
  }

  function addPreviews() {
    if (!arePreviewsAdded) {
      Array.from($('#tutorialsTab').find('.preview-image a')).forEach((img) => {
        let image = `<img class="preview-image" src="${$(img).data('image')}">`
        $(img).append(image)
      })
      arePreviewsAdded = true
    }
  }

  function buildItem(item) {
    if (item.kind == ItemKind.SECTION) {
      let tabTitle = item.title.toLowerCase().replace(' ', '_').replace('#', '_')
      tutorials.find('.nav-tabs').append(`<li>
        <a href="#${tabTitle}Tab" data-url="${tabTitle}" data-toggle="tab" aria-expanded="true">
          <span>${translate(tabTitle).toUpperCase()}</span>
        </a>
      </li>`)
      tutorials.find('.tab-content').append(`<div class="tab-pane" id="${tabTitle}Tab"></div>`)
      tutorials.find(`#${tabTitle}Tab`).append('<ul class="tutorialsList"></ul>')
      if (!tutorialInfo.activeTab) {
        tutorialInfo.activeTab = tabTitle
      }
      currentTab = tabTitle
    } else if (item.kind == ItemKind.TUTORIALS) {
      tutorialsList.push({
        title: item.title,
        id: item.id,
        getCodeLink: item.getCodeLink,
        viewAppLink: item.viewAppLink,
        viewVideoLink: item.viewVideoLink,
        dificulty: item.dificulty
      })
    } else if (item.kind == ItemKind.TEMPLATE) {
      let $links = ''
      if (item.getCodeLink) {
				let mergeUrl = window.location.origin + '/' + app.currentLanguage + '/' + app.configuration.id + item.getCodeLink
				$links += `<a class="btn btn-sm btn-link merge-link" data-href="${mergeUrl}">${translate('get.code')}</a>`
      }
      if (item.viewAppLink) {
        $links += `<a class="btn btn-sm btn-link" href="${item.viewAppLink}" target="_blank">${translate('view.app')}</a>`
      }
      if (item.viewVideoLink) {
        $links += `<a class="btn btn-sm btn-link" href="${item.viewVideoLink}" target="_blank">${translate('view.video')}</a>`
      }
      let $item = $(`<li class="tutorialItem" style="padding-left: 20px;">
        <div style="margin-bottom: 5px;"><span class="tutorial-box-title">${item.title}</span></div>
        <div style="display: flex; justify-content: flex-start; margin-bottom: -8px; margin-inline-start: -11px;">
          ${$links}
        </div>
      </li>`)
      $('#templatesTab').find('.tutorialsList').append($item)
    } else if (item.kind == ItemKind.VIDEO) {
      videosList.push({
        title: item.title,
        video: item.video,
        preview: item.preview
      })
    } else {
      if (!currentQuickTip) {
        let currentUrl = window.location.href
        if ((item.page == 'entityTypeDetails' && currentUrl.includes('details')) ||
            (item.page.includes('Editor') && currentUrl.includes(item.page.substr(0, item.page.indexOf("Editor")))) ||
            (item.page == 'instances' && currentUrl.toLowerCase().includes('instance')) ||
            (item.page == 'dataTypes' && currentUrl.includes('entityType') && !currentUrl.includes('details')) ||
            (currentUrl.includes(item.page))) {
          currentQuickTip = item
        }
      }
    }
  }

  function onSelectTutorial() {
    sections = 0
    if (tutorialInfo.documentId){
      articleById(tutorialInfo.documentId).then((article) => {
        $('#tutorialsTab').html(article.content)
        const additionalNext = $(`<div id="additional-next">
          <a class="btn btn-primary" style="display: block; background-color: #3c8dbc !important;">${translate('next')}</a>
        </div>`)
        $('#tutorialsTab').append(additionalNext)
        $('#additional-next').find('> a').click(makeNext)
        if (tutorialInfo.activeTab == 'tutorials') {
          $('#control-section').show()
          $('#additional-next').show()
        }

        const header = $('#tutorialsTab h1').first()
        $('#control-section .for-header .header-placement').html(header.clone())
        header.remove()
        localStorage.setItem('tutorial-info', JSON.stringify(tutorialInfo))
        sections = calcSections()
        controlSectionTag.querySelector('.quantity').textContent = sections
        let height = $('#control-section').height()
        height += 75
        $('#tutorialsTab').css({height: `calc(100vh - ${height}px)`})
        showSection()
        tutorials[0].classList.remove("hide-controlls")
        initImagesModal()
        arePreviewsAdded = false
        savedHtmls.set('tutorials', $('#tutorialsTab').html())
        if (tutorialInfo.activeTab == 'quick_tips' && tutorialInfo.documentId) {
          $('#tutorialsTab').empty()
        }
        buildLinkPopovers($('#tutorialsTab'))
      }).catch(() => {
        tutorialInfo.documentId = ""
        tutorialInfo.section = 0
        renderTutorialsList()
      })
      initPrevNext()
    }
  }

  function renderTutorialsList() {
    let $container = $('#tutorialsTab')
    $container.css({height:""})
    $container.html('<ul class="tutorialsList"></ul>')
    tutorialsList.forEach((item) => {
      let $links = ''
      if (item.getCodeLink) {
        let mergeUrl = window.location.origin + '/' + app.currentLanguage + '/' + app.configuration.id + item.getCodeLink
        $links += `<a class="btn btn-sm btn-link merge-link" data-href="${mergeUrl}">${translate('get.code')}</a>`
      }
      if (item.viewAppLink) {
        $links += `<a class="btn btn-sm btn-link" href="${item.viewAppLink}" target="_blank">${translate('view.app')}</a>`
      }
      if (item.viewVideoLink) {
        $links += `<a class="btn btn-sm btn-link" href="${item.viewVideoLink}" target="_blank">${translate('view.video')}</a>`
      }
      let star = '<i class="fa fa-star" style="padding-inline-end: 1px; color: #F67B02;"/>'
      let stars = `<span style="padding-inline-end: 4px;">${star}`
      if (item.dificulty == 'Intermediate') {
        stars += star
      }
      if (item.dificulty == 'Advanced') {
        stars += star + star
      }
      stars += '</span>'
      let $item = $(`<li class="tutorialItem">
        <div class="tutorial-box-title" data-id="${item.id}">${item.title}</div>
        <div>
          <div style="margin-bottom: 10px;">
            <div style="font-size: 12px; cursor: default;">${stars + item.dificulty}</div>
            <div><button class="btn btn-sm btn-primary" data-id="${item.id}" style="margin-top: 17px;">${translate('start.tutorial')}</button></div>
          </div>
          <div style="display: flex; justify-content: flex-start; margin-bottom: -8px; margin-inline-start: -11px;">
            ${$links}
          </div>
        </div>
      </li>`)
      $container.find('.tutorialsList').append($item)
      $item.find(`[data-id=${item.id}]`).click(() => {
        tutorialInfo.documentId = item.id
        tutorialInfo.section = 0
        localStorage.setItem('tutorial-info', JSON.stringify(tutorialInfo))
        utils.postRequest({
					name: item.title
				}, app.urls.startTutorial);
        onSelectTutorial()
      })
    })

    videosList.forEach((item) => {
      let $item = `<li class="tutorialItem" style="padding-left: 20px;">
        <div class="preview-image">
          <a class="tutorial-link preview-image" target="_blank" href="${item.video}" data-image="${item.preview}">
            <span class="fa fa-play" style="display:none;"></span>
          </a>
        </div>
        <div style="margin-top: 6px;">
          <div style="margin-bottom: 3px;"><span class="video-link tutorial-box-title">${item.title}</span></div>
          <div><a target="_blank" href="${item.video}">${translate('view.video')}</a></div>
        </div>
      </li>
      `
      let $videoBox = $('<div>').addClass('template-box').append($item)
      $container.append($videoBox)
    })

		$container.find('.merge-link').click((e) => {
			let target = $(e.currentTarget);
			utils.postRequest({}, target.data('href'));
			if (app.currentUser && !$('.merge-progressbar').length) {
				addLoader();
			}
		})
    addPreviews()
    onShowTabContentEvent()
  }

  function addLoader() {
		$('body').prepend(`
			<div class="merge-progressbar" style="width: 100vw; height: 100vh; position: fixed;z-index: 11111111;background: #00000070;color: white;">
				<div class="loading-message" style="top: calc(100% - 30vh - 100px); font-weight: 500;">
          <div id="loader-merge-in-progress">
            <div>${translate('merging.template.into.your.project')}</div>
           </div>
        </div>
        <div class="screen-loader" >
           <div class="load"  style="width:90vw;border: 1px solid white;background-color:#fff">
             <div id="line-loader" >  </div>
         </div>
        </div>
    </div>
		`);
      setTimeout(() => {
        $('.merge-progressbar #line-loader').addClass('loading');
      }, 20000);
	}

  function onShowTabContentEvent() {
    if (tutorialInfo.activeTab == 'tutorials' && tutorialInfo.documentId) {
      $('#control-section').show()
      $('#additional-next').show()
    } else {
      $('#control-section').hide()
      $('#additional-next').hide()
    }
    $('#tutorial-content .tab-pane.active').scrollTop(0)
    $('#tutorial-content .tab-pane.active').perfectScrollbar('update')
  }

}

export function hideMergeLoader() {
    $('.merge-progressbar').remove();
}

import utils from '../components/utils';

export class Service {

  prefix(){
    return '';
  }

  findAll(includeHidden){
    let url = app.urls.home + this.prefix() + '/findAll';
    if (includeHidden) {
      url += '?includeHidden=true';
    }
    return utils.getRequest(url);
  }

  create(item){
    return utils.postRequest(item, app.urls.home + this.prefix() + '/create');
  }

  update(item){
    return utils.postRequest(item, app.urls.home + this.prefix() + '/update');
  }

  delete(item){
    return utils.postRequest({}, app.urls.home + this.prefix() + `/delete/${item.id}`);
  }

  deleteMany(ids){
    return utils.postRequest(ids, app.urls.home + this.prefix() + `/deleteSelected`);
  }

}

import CreateExportView from '../baseExportViews/createExportView';

var CreateSpreadsheetView = CreateExportView.extend({

	initialize: function (options) {
		const that = this;

		options.acceptedFiles = '.xls, .xlsx';

		options.onSuccess = (file, response) => {
			that.model.get('spreadsheetProperties').template = response;
		}
		options.onRemovedFile = (file) => {
			that.model.get('spreadsheetProperties').template = null;
		}

		CreateSpreadsheetView.__super__.initialize.apply(this, arguments);
	}

});

export default CreateSpreadsheetView;

import PropertyKey from '../../../common/enums/propertyKey';
import {translate} from '../../../common/service/stringResourceService'
import Constants from '../../../common/models/constants';
import MultilingualString from '../../../common/models/multilingualString';
import FieldKind from '../../../common/enums/fieldKind';
import ViewKind from '../../../common/enums/viewKind';

export function	_transformPresetProperties(propertiesObj) {
		return _.map(propertiesObj, (v, k) => {
			return {
				key: k,
				value: {
					value: v
				}
			}
		})
}

export let getPresets  = (() => {
	let instance;
	return () => {
		let isExternal = app.types.get(app.view.ownerId).isExternal()
		if (instance === undefined) {
			instance = [{
					name: translate('text.input'),
					icon: 'fa fa-font',
					isGroup: true,
					items: [{
						icon: 'fa fa-font',
						name: translate('nontranslatable.text'),
						fieldTypeId: Constants.ID_TYPE_STRING,
					}, {
						icon: 'fa fa-font',
						name: translate('translatable.text'),
						fieldTypeId: Constants.ID_TYPE_MULTILINGUAL_STRING,
					}, {
						icon: 'fa fa-text-height',
						name: translate('multiline.text'),
						fieldTypeId: Constants.ID_TYPE_STRING,
						properties: {
							[PropertyKey.MULTILINE] : true
						}
					}, {
						icon: 'fa fa-text-height',
						name: translate('translatable.multiline.text'),
						fieldTypeId: Constants.ID_TYPE_MULTILINGUAL_STRING,
						properties: {
							[PropertyKey.MULTILINE] : true
						}
					}, {
						icon: 'fa fa-paint-brush',
						name: translate('rich.text'),
						fieldTypeId: Constants.ID_TYPE_HTML_STRING_NO_TRANSLATIONS,
					}, {
						icon: 'fa fa-paint-brush',
						name: translate('translatable.rich.text'),
						fieldTypeId: Constants.ID_TYPE_HTML_STRING,
					}
				]}, {
					name: translate('number'),
					icon: 'fa fa-calculator',
					isGroup: true,
					forExternals: true,
					items: [{
						icon: 'fa fa-calculator',
						name: translate('integer'),
						fieldTypeId: Constants.ID_TYPE_INTEGER_NULLABLE,
						forExternals: true
					}, {
						icon: 'fa fa-calculator',
						name: translate('decimal'),
						fieldTypeId: Constants.ID_TYPE_DECIMAL2
					}
				]}, {
							name: translate('yes.no'),
							icon: 'fa fa-check-square',
							isGroup: true,
							forExternals: true,
							items: [{
								name: translate('switch'),
								fieldTypeId: Constants.ID_TYPE_BOOLEAN,
								icon: 'fa fa-toggle-on',
								properties: {
									[PropertyKey.TEMPLATE] : 'Switch'
								},
								forExternals: true
							}, {
								name: translate('checkbox'),
								fieldTypeId: Constants.ID_TYPE_BOOLEAN,
								icon: 'fa fa-check-square',
								forExternals: true
							}
						]}, {
							name: translate('date.time'),
							icon: 'fa fa-calendar',
							isGroup: true,
							forExternals: true,
							items:[{
								name: translate('date.time'),
								fieldTypeId: Constants.ID_TYPE_LOCAL_DATE_TIME,
								icon: 'fa fa-calendar',
								forExternals: true
							}, {
								name: translate('date'),
								fieldTypeId: Constants.ID_TYPE_LOCAL_DATE,
								icon: 'fa fa-calendar',
								forExternals: true
							}, {
								name: translate('time'),
								fieldTypeId: Constants.ID_TYPE_LOCAL_TIME,
								icon: 'fa fa-clock-o'
							}
						]},  {
					name: translate('link'),
					icon: 'fa fa-link',
					fieldTypeId: Constants.ID_TYPE_STRING,
					properties: {
						[PropertyKey.PATTERN]: '^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$',
						[PropertyKey.PLACEHOLDER] : MultilingualString.fromStringInCurrentLanguage('www.domain.com'),
						[PropertyKey.FORMATTER] : Constants.ID_FORMATTER_LINK
					}
				}, {
					name: translate('email'),
					icon: 'fa fa-envelope',
					fieldTypeId: Constants.ID_TYPE_STRING,
					properties: {
						[PropertyKey.PLACEHOLDER]:  MultilingualString.fromStringInCurrentLanguage('mail@domain.com'),
						[PropertyKey.PATTERN]: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$',
						[PropertyKey.FORMATTER] : Constants.ID_FORMATTER_EMAIL
					}
				}, {
					name: translate('phone.number'),
					icon: 'fa fa-phone',
					fieldTypeId: Constants.ID_TYPE_STRING,
					properties: {
						[PropertyKey.PLACEHOLDER]:  MultilingualString.fromStringInCurrentLanguage('+1-541-754-3010'),
						[PropertyKey.PATTERN]: '^[+]*[-\s\./0-9]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$'
					}
				}, {
					isGroup: true,
					name: translate('select.component'),
					icon: 'fa fa-list',
					forExternals: true,
					customId: isExternal ? 2 : null,
					items: [{
						name: translate('select.component'),
						icon: 'fa fa-list',
						fieldTypeId: 'GENERATE_ENUM'
					}, {
						name: translate('multiselect'),
						icon: 'fa fa-list',
						fieldTypeId: 'GENERATE_ENUM',
						fieldKind: FieldKind.COLLECTION,
						viewKinds: [ViewKind.FORM],
						properties: {
							[PropertyKey.TEMPLATE] : 'multiselect'
						}
					}, {
						name: translate('lookup'),
						icon: 'fa fa-search',
						fieldTypeId: null,
						forExternals: true
					}, {
						name: translate('multilookup'),
						icon: 'fa fa-search',
						fieldTypeId: null,
						fieldKind: FieldKind.COLLECTION,
						viewKinds: [ViewKind.FORM],
						properties: {
							[PropertyKey.TEMPLATE] : 'multiselect'
						},
						forExternals: true
					}]
				}, {
				name: translate('table'),
				icon: 'fa fa-table',
				fieldTypeId: null,
				isGroup: true,
				viewKinds: [ViewKind.FORM],
				forExternals: true,
				customId: isExternal ? 1 : null,
				items: [{
					name: translate('table'),
					icon: 'fa fa-table',
					fieldTypeId: 'GENERATE_ENUM',
					fieldKind: FieldKind.COLLECTION,
					viewKinds: [ViewKind.FORM]
				}, {
					name: translate('lookup.table'),
					icon: 'fa fa-search',
					fieldTypeId: null,
					fieldKind: FieldKind.COLLECTION,
					viewKinds: [ViewKind.FORM],
					forExternals: true
				}]
			}, {
				name: translate('lookup.card.grid'),
				icon: 'fa fa-columns',
				fieldTypeId: null,
				fieldKind: FieldKind.COLLECTION,
				viewKinds: [ViewKind.FORM],
				hideVirtual: true,
				typeFilter: (type) => app.types.get(type.id).views().filter(view => view.isWidgetView() && view.isDefault()).length != 0,
				isWidgetView: true,
				properties: {
					[PropertyKey.TEMPLATE] : 'card.grid'
				}
			}, {
				name: translate('embedded'),
				icon: 'fa fa-newspaper-o',
				fieldTypeId: null,
				isGroup: true,
				viewKinds: [ViewKind.FORM],
				items: [{
					name: translate('embedded'),
					icon: 'fa fa-newspaper-o',
					fieldTypeId: 'GENERATE_ENUM',
					fieldKind: FieldKind.REGULAR,
					typeFilter: (type) => app.types.get(type.id).isEmbedded(),
					isEmbedded: true
				}, {
					name: translate('lookup.embedded'),
					icon: 'fa fa-search',
					fieldTypeId: null,
					fieldKind: FieldKind.REGULAR,
					typeFilter: (type) => app.types.get(type.id).isEmbedded(),
					isEmbedded: true
				}]
		}, {
					name: translate('upload.file'),
					icon: 'fa fa-file',
					isGroup: true,
					items: [{
						name: translate('arbitrary.file'),
						fieldTypeId: Constants.ID_TYPE_BINARY,
						icon: 'fa fa-file'
					}, {
						name: translate('image'),
						fieldTypeId: Constants.ID_TYPE_BINARY,
						icon: 'fa fa-image',
						properties: {
							[PropertyKey.TYPE] : ['Image'],
							[PropertyKey.PREVIEW]: 'Preview'
						}
					}, {
						name: translate('video'),
						fieldTypeId: Constants.ID_TYPE_BINARY,
						icon: 'fa fa-film',
						properties: {
							[PropertyKey.TYPE] : ['Video'],
							[PropertyKey.PREVIEW]: 'Preview'
						}
					}, {
						name: translate('youtube.video'),
						fieldTypeId: Constants.ID_TYPE_BINARY,
						icon: 'fa fa-youtube',
						properties: {
							[PropertyKey.UPLOAD] : 'Link',
							[PropertyKey.PREVIEW]: 'Preview'
						}
					}, {
						name: translate('document'),
						fieldTypeId: Constants.ID_TYPE_BINARY,
						icon: 'fa fa-file',
						properties: {
							[PropertyKey.TYPE] : ['Document', 'PDF'],
							[PropertyKey.PREVIEW]: 'Preview'
						}
					}
				]}, {
					name: translate('define'),
					icon: 'fa fa-lightbulb-o',
					isGroup: true,
					forExternals: true,
					items: [{
						icon: 'fa fa-lightbulb-o',
						name: translate('regular.field.components'),
						fieldTypeId: null,
						fieldKind: FieldKind.REGULAR,
						allowWithoutMetaObject: true,
						forExternals: true
					}, {
						icon: 'fa fa-database',
						name: translate('collection.field'),
						fieldTypeId: null,
						fieldKind: FieldKind.COLLECTION,
						allowWithoutMetaObject: true,
						forExternals: true
					}, {
						icon: 'fa fa-angle-double-right',
						name: translate('dynamic.field'),
						fieldTypeId: null,
						fieldKind: FieldKind.DYNAMIC
					}
				]},
			]
		}
		return instance;
	}
}) ();

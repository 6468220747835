/* @flow */

import ChronoField from '../enums/chronoField';
import LocalDate from './localDate';
import type { Temporal } from '../declarations';

// import {
// 	MONTHS_PER_YEAR
// } from '../constants';
// 
// import {
// 	checkValid,
// 	checkValidYear,
// 	checkValidInclusive,
// 	checkValidDayOfMonth
// } from '../utils';

/**
 * Serilized form:
 *   from: LocalDate
 *   to: LocalDate
 */

export default class DateRange {

	from: LocalDate;
	to: LocalDate;

	constructor(from, to) {
		if (from.compareTo(to) < 0) {
			this.from = from
			this.to = to
		} else {
			this.from = to
			this.to = from
		}
	}

	static of(from, to): DateRange {
		return new DateRange(LocalDate.of(from),LocalDate.of(to))
	}

	static fromJSON(value: ?any): ?DateRange {
		if (value == null) {
			return null;
		}
		return new DateRange(
			LocalDate.of(value.from.year, value.from.month, value.from.day),
			LocalDate.of(value.to.year, value.to.month, value.to.day)
		)
	}

	toJSON() {
		return {
			from: this.from.toJSON(),
			to: this.to.toJSON()
		};
	}

	static fromMoments(from: moment$Moment, to: moment$Moment): DateRange {
		return new DateRange(LocalDate.fromMoment(from), LocalDate.fromMoment(to))
	}

	getFrom(): LocalDate {
		return this.from
	}

	getTo(): LocalDate {
		return this.to
	}

	get(field: ChronoField): number {
		throw new Error('Not implemented');
	}
}

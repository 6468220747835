var PropertyType = {
	STRING: 'STRING',
	MLS: 'MLS',
	INT: 'INT',
	DOUBLE: 'DOUBLE',
	BOOLEAN: 'BOOLEAN',
	SELECT: 'SELECT',
	SECTION: 'SECTION',
	HTML: 'HTML',
	MULTISELECT: 'MULTISELECT',
	ICON: 'ICON',
	BUTTON: 'BUTTON',
	DATETIME: 'DATETIME',
	COLOR: 'COLOR',
	FIELD_EVENTS: 'FIELD_EVENTS',
	TYPE_LINK: 'TYPE_LINK',
	SEED_INSTANCES: 'SEED_INSTANCES'
};

export default PropertyType;

/* @flow */

import View from '../models/view.js';
import EntitiesWithOwner from './entitiesWithOwner';
import MultilingualString from '../models/multilingualString';

export default class Views extends EntitiesWithOwner<View> {

	initialize() {
		super.initialize.apply(this, arguments);
		this.model = View;
	}

	viewName(viewId: string, language: any): string {
		const view = this.get(viewId);
		return (view && (new MultilingualString(view.get('name'))).getCurrentValue(language || null)) || app.messages.missingViewError;
	}

	getWizardRoot(viewId: string): View {
		let view = this.get(viewId);
		while(view && view.parent()) {
			view = view.parent();
		}
		return view;
	}
}

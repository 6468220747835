/* @flow */

export default class ChronoField {

	static NANO_OF_SECOND = new ChronoField('NANO_OF_SECOND', 'NanoOfSecond', true);
	static NANO_OF_DAY = new ChronoField('NANO_OF_DAY', 'NanoOfDay', false);
	static MICRO_OF_SECOND = new ChronoField('MICRO_OF_SECOND', 'MicroOfSecond', true);
	static MICRO_OF_DAY = new ChronoField('MICRO_OF_DAY', 'MicroOfDay', false);
	static MILLI_OF_SECOND = new ChronoField('MILLI_OF_SECOND', 'MilliOfSecond', true);
	static MILLI_OF_DAY = new ChronoField('MILLI_OF_DAY', 'MilliOfDay', false);
	static SECOND_OF_MINUTE = new ChronoField('SECOND_OF_MINUTE', 'SecondOfMinute', true);
	static SECOND_OF_DAY = new ChronoField('SECOND_OF_DAY', 'SecondOfDay', false);
	static MINUTE_OF_HOUR = new ChronoField('MINUTE_OF_HOUR', 'MinuteOfHour', true);
	static MINUTE_OF_DAY = new ChronoField('MINUTE_OF_DAY', 'MinuteOfDay', false);
	static HOUR_OF_AMPM = new ChronoField('HOUR_OF_AMPM', 'HourOfAmPm', true);
	static CLOCK_HOUR_OF_AMPM = new ChronoField('CLOCK_HOUR_OF_AMPM', 'ClockHourOfAmPm', false);
	static HOUR_OF_DAY = new ChronoField('HOUR_OF_DAY', 'HourOfDay', true);
	static CLOCK_HOUR_OF_DAY = new ChronoField('CLOCK_HOUR_OF_DAY', 'ClockHourOfDay', false);
	static AMPM_OF_DAY = new ChronoField('AMPM_OF_DAY', 'AmPmOfDay', true);
	static DAY_OF_WEEK = new ChronoField('DAY_OF_WEEK', 'DayOfWeek', true);
	static ALIGNED_DAY_OF_WEEK_IN_MONTH = new ChronoField('ALIGNED_DAY_OF_WEEK_IN_MONTH', 'AlignedDayOfWeekInMonth', false);
	static ALIGNED_DAY_OF_WEEK_IN_YEAR = new ChronoField('ALIGNED_DAY_OF_WEEK_IN_YEAR', 'AlignedDayOfWeekInYear', false);
	static DAY_OF_MONTH = new ChronoField('DAY_OF_MONTH', 'DayOfMonth', true);
	static DAY_OF_YEAR = new ChronoField('DAY_OF_YEAR', 'DayOfYear', true);
	static EPOCH_DAY = new ChronoField('EPOCH_DAY', 'EpochDay', false);
	static ALIGNED_WEEK_OF_MONTH = new ChronoField('ALIGNED_WEEK_OF_MONTH', 'AlignedWeekOfMonth', false);
	static ALIGNED_WEEK_OF_YEAR = new ChronoField('ALIGNED_WEEK_OF_YEAR', 'AlignedWeekOfYear', false);
	static MONTH_OF_YEAR = new ChronoField('MONTH_OF_YEAR', 'MonthOfYear', true);
	static PROLEPTIC_MONTH = new ChronoField('PROLEPTIC_MONTH', 'ProlepticMonth', false);
	static YEAR_OF_ERA = new ChronoField('YEAR_OF_ERA', 'YearOfEra', false);
	static YEAR = new ChronoField('YEAR', 'Year', true);
	static ERA = new ChronoField('ERA', 'Era', false);
	static INSTANT_SECONDS = new ChronoField('INSTANT_SECONDS', 'InstantSeconds', true);
	static OFFSET_SECONDS = new ChronoField('OFFSET_SECONDS', 'OffsetSeconds', true);

	value: string;
	_name: string;
	blocklyVisible: boolean;

	constructor(value: string, _name: string, blocklyVisible: boolean) {
		this.value = value;
		this._name = _name;
		this.blocklyVisible = blocklyVisible;
	}

	getDisplayName(): string {
		if (translations.hasOwnProperty(this.value)) {
			return translations[this.value];
		}
		return this._name;
	}

	static values(): Array<ChronoField> {
		return values || (values = _.values(ChronoField));
	}

	static valueOf(name: string): ChronoField {
		// $SuppressFlow
		return ChronoField[name];
	}

	name(): string {
		return this.value;
	}

	toString(): string {
		return this._name;
	}
}

let values;

const translations = {
	'NANO_OF_SECOND': 'nano-of-second',
	'NANO_OF_DAY': 'nano-of-day',
	'MICRO_OF_SECOND': 'micro-of-second',
	'MICRO_OF_DAY': 'micro-of-day',
	'MILLI_OF_SECOND': 'milli-of-second',
	'MILLI_OF_DAY': 'milli-of-day',
	'SECOND_OF_MINUTE': 'second-of-minute',
	'SECOND_OF_DAY': 'second-of-day',
	'MINUTE_OF_HOUR': 'minute-of-hour',
	'MINUTE_OF_DAY': 'minute-of-day',
	'HOUR_OF_AMPM': 'hour-of-am-pm',
	'CLOCK_HOUR_OF_AMPM': 'clock-hour-of-am-pm',
	'HOUR_OF_DAY': 'hour-of-day',
	'CLOCK_HOUR_OF_DAY': 'clock-hour-of-day',
	'AMPM_OF_DAY': 'am-pm-of-day',
	'DAY_OF_WEEK': 'day-of-week',
	'ALIGNED_DAY_OF_WEEK_IN_MONTH': 'aligned day-of-week within a month',
	'ALIGNED_DAY_OF_WEEK_IN_YEAR': 'aligned day-of-week within a year',
	'DAY_OF_MONTH': 'day-of-month',
	'DAY_OF_YEAR': 'day-of-year',
	'EPOCH_DAY': 'epoch-day',
	'ALIGNED_WEEK_OF_MONTH': 'aligned week within a month',
	'ALIGNED_WEEK_OF_YEAR': 'aligned week within a year',
	'MONTH_OF_YEAR': 'month-of-year',
	'PROLEPTIC_MONTH': 'proleptic-month',
	'YEAR_OF_ERA': 'year of era',
	'YEAR': 'year',
	'ERA': 'era',
	'INSTANT_SECONDS': 'epoch-seconds',
	'OFFSET_SECONDS': 'offset seconds',
};

import MultilingualString from '../../common/models/multilingualString';
import BaseCreateView from '../../common/views/baseCreateView';
import BaseSelect from '../../common/components/baseSelect';
import RadioGroupInputs from '../../common/components/radioGroupInputs';
import { translate } from '../../common/service/stringResourceService.js';

const CreateFillFunctionView = BaseCreateView.extend({

	initialize: function () {
		CreateFillFunctionView.__super__.initialize.apply(this, arguments);
		this.$sourceTypeSelect = this.$el.find('#sourceTypeSelect');
		this.$resultTypeSelect = this.$el.find('#resultTypeSelect');
		let that = this

		this.radioButtonSource = new RadioGroupInputs({
			el: that.$el.find('#radioGroupFillFunctionsSource')[0],
			options: [{
					text: translate('source'),
					value: 'SOURCE'
				}],
			value: null,
			selectionChanged: (value) => {
				that.radioButtonResult.props.value = null
				that.$resultTypeSelect.val(app.model.id).trigger('change');
				that.$resultTypeSelect.prop('disabled', true);
				that.$sourceTypeSelect.prop('disabled', false);
			}
		})

		this.radioButtonResult = new RadioGroupInputs({
			el: that.$el.find('#radioGroupFillFunctionsResult')[0],
			options: [{
					text: translate('result'),
					value: 'RESULT'
				}],
			value: 'RESULT',
			selectionChanged: (value) => {
				that.radioButtonSource.props.value = null
				that.defaultInit();
			}
		})

		this.rebuildSelects();
		this.defaultInit();
	},

	rebuildSelects: function () {
		let data = _.map(app.types.models, type => {
			return {
				id: type.id,
				text: (new MultilingualString(type.name())).getCurrentValue()
			}
		});
		new BaseSelect({
			el: this.$sourceTypeSelect,
			data: data
		})
		new BaseSelect({
			el: this.$resultTypeSelect,
			data: data
		})
	},

	defaultInit: function () {
		this.$sourceTypeSelect.val(app.model && app.model.id).trigger('change');
		this.$sourceTypeSelect.prop('disabled', true);
		this.$resultTypeSelect.prop('disabled', false);
	},

	saveConfiguration: function () {
		let data = this.model.toJSON();
		data.ownerId = this.$sourceTypeSelect.val();
		data.fillResultTypeId = this.$resultTypeSelect.val();
		return data;
	},

	getHTML: function () {
		return `<div id="blockNameDiv" class="fill-function-padding">
							<label for="name">${app.getResource('name')}</label>
							<input class="form-control" type="text" data-field="name"/>
						</div>
						<div class="modal-component fill-function-radio-group">
							<div id="radioGroupFillFunctionsSource"></div>
						</div>
						<div id="sourceDiv" class="fill-function-padding">
							<select id="sourceTypeSelect"></select>
						</div>
						<div class="modal-component fill-function-radio-group">
							<div id="radioGroupFillFunctionsResult"></div>
						</div>
						<div id="resultDiv">
							<select id="resultTypeSelect"></select>
						</div>`;
	}
});

export default CreateFillFunctionView;

/* @flow */

import utils from '../../common/components/utils.js';
import type {
	TemporalAccessor
} from '../../time/declarations';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import DateTimeFormatter from '../../time/formatters/dateTimeFormatter';
import Formatter from '../../common/components/formatter';
import * as time from '../../time/models/index';
import {translate} from '../../common/service/stringResourceService';
import DatepickerInput from './datepickerInput';

var DateRangeInput = DatepickerInput.extend({

	events: {
		'click': 'click',
		'input': 'changed'
	},

	initialize: function(options) {
		DateRangeInput.__super__.initialize.apply(this, arguments);
		this._buildDOM();
		this._initializeWidgetEvents();
		this._initializeDatepicker();
		if (this.disabled){
			this.button.attr('disabled', 'disabled');
		}
    this.silent = false
	},

	_initializeDatepicker: function () {
		const that = this;
		const primitiveType = utils.getPrimitiveType(this.$el);

		let opts = {
			language: app.currentLanguage,
			inline: true,
			clearButton: false,
			multipleDates:2,
			onRenderCell:(date, cellType) => {
				return {
					classes: this.isBetween(date)?'-in-range-':''
				}
			},
			onSelect: (formattedDate, date, inst) => {
				that._onSelect(formattedDate, date, inst);
			}
		}
		this.datepicker.datepicker(opts);
		this.datepickerInstance = this.datepicker.datepicker().data('datepicker');

		this.datepicker.find('.datepicker')
				.children()
				.wrapAll('<div class="datepicker--container1"><div class="datepicker--container2">');

		this._addOkButton();
	},

	isBetween (date) {
		let a = this.model.get(this.modelAttr)
		if (!a) return false
		let from = a.from.toMoment().toDate()
		let to = a.to.toMoment().toDate()
		return from<date && date<to
	},

	_onDropdownClick: function(e) {
		 DateRangeInput.__super__._onDropdownClick.apply(this, arguments);
		 if ($(e.target).hasClass("datepicker--cell") && this.datepickerInstance.selectedDates.length == 2) {
			 this._selectNew(e)
		 }
		 this.datepickerLook()
		 if ($(e.target).hasClass("datepicker--cell")) {
			 this.saveNewDateInInput()
		 }
	},

	_selectNew: function(e) {
		var obj = time.DateRange.fromMoments(global.moment(this.datepickerInstance.lastSelectedDate),global.moment(this.datepickerInstance.lastSelectedDate))
		var date = new time.LocalDate(e.target.dataset.year, Number(e.target.dataset.month) + 1, e.target.dataset.date);
		var dates = this.datepickerInstance.selectedDates
		var from = new time.LocalDate(dates[0].getFullYear(), dates[0].getMonth() + 1, dates[0].getDate());
		var to = new time.LocalDate(dates[1].getFullYear(), dates[1].getMonth() + 1, dates[1].getDate());
		if ( date.compareTo(from)!== 0 && date.compareTo(to)!== 0) {
			var obj = time.DateRange.fromMoments(global.moment(this.datepickerInstance.lastSelectedDate),global.moment(this.datepickerInstance.lastSelectedDate))
			this.setValue(obj)
		}
	},

	_onSelect: function(formattedDate, date, inst) {
		if (this.silent) {
			return
		}

		if(!date) {
			this.setDataToModel(null)
			return
		}

		if (date.length == 1) {
			var obj = time.DateRange.fromMoments(global.moment(date[0]),global.moment(date[0]))
			this.setValue(obj)
		} else {
			var obj = time.DateRange.fromMoments(global.moment(date[0]),global.moment(date[1]))
			this.setValue(obj)
		}
	},

	saveNewDateInInput: function () {
		if(this.validate()) {
			this.setNormalView()
			this.setDataToModel(this.getObject());
		} else {
			this.setErrorView()
		}
	},

	setValue: function(value) {
		if (!value) {
			this.$el.val(null);
			this.datepickerInstance.clear();
			this.$textEl.html('-');
			return;
		}

		const primitiveType = utils.getPrimitiveType(this.$el);
		const formatterId = this.$el.attr('data-formatter-id');
		const typeId = this.$el.attr('data-entity-type-id');
		const text =  Formatter.format(value, {
			primitiveType: primitiveType,
			typeId: typeId,
			formatterId: formatterId
		});
		this.$el.val(text);
		this.$textEl.html(text || '-');
		this.silent = true
		this.datepickerInstance.clear()

		var to = value.to.toMoment()
		var from = value.from.toMoment()
		var toDate = new Date(to.year(), to.month(), to.date())
		var fromDate = new Date(from.year(), from.month(), from.date())
		this.datepickerInstance.selectDate([toDate, fromDate]);
		this.silent = false
	},

	datepickerLook: function() {
		var dates = this.datepickerInstance.selectedDates
		if (dates.length == 2) {
			var from = new time.LocalDate(dates[0].getFullYear(), dates[0].getMonth() + 1, dates[0].getDate());
			var to = new time.LocalDate(dates[1].getFullYear(), dates[1].getMonth() + 1, dates[1].getDate());
			if (from.compareTo(to) > 0) {
				var helpVariable = to;
				to = from;
				from = helpVariable;
			}
		}
		if (this.datepickerInstance.selectedDates.length == 2 && from.compareTo(to) !== 0) {
			var cells = this.datepicker.find('.datepicker--cells-days').children()
			Array.from(cells).forEach( function (cell) {
				var cellDate = new time.LocalDate(cell.dataset.year, Number(cell.dataset.month) + 1, cell.dataset.date);
				if ( cellDate.compareTo(from) > 0 && cellDate.compareTo(to) < 0) {
					$(cell).addClass('-in-range-')
				} else {
					$(cell).removeClass('-in-range-')
				}
				return cell
				})
		} else {
			var inRangeCell = this.datepicker.find('.-in-range-');
			Array.from(inRangeCell).forEach( (cell) => { $(cell).removeClass('-in-range-') })
		}
	},

	getObject: function() {
		const value = this.$el.val()
		if (!value) return null
		let dates = value.split(' - ')
		const primitiveType = utils.getPrimitiveType(this.$el);
		const formatterId = this.$el.attr('data-formatter-id');
		const typeId = this.$el.attr('data-entity-type-id');
		const format= DateTimeFormatter.javaPatternToMomentPattern(Formatter.getEditingFormat({
			primitiveType: primitiveType,
			typeId: typeId,
			formatterId: formatterId
		}))
		let mo1 = global.moment(dates[0], format, app.currentLanguage, true);
		let mo2 = global.moment(dates[1], format, app.currentLanguage, true);
		return time.DateRange.fromMoments(mo1,mo2)
	},

	validate: function () {
		const value = this.$el.val()
		if (!value) return true
		let dates = value.split(' - ')
		const primitiveType = utils.getPrimitiveType(this.$el);
		const formatterId = this.$el.attr('data-formatter-id');
		const typeId = this.$el.attr('data-entity-type-id');
		if (dates.length !== 2) return false
		const format= DateTimeFormatter.javaPatternToMomentPattern(Formatter.getEditingFormat({
			primitiveType: primitiveType,
			typeId: typeId,
			formatterId: formatterId
		}))
		let mo1 = global.moment(dates[0], format, app.currentLanguage, true);
		let mo2 = global.moment(dates[1], format, app.currentLanguage, true);
		return mo1.isValid() && mo2.isValid()
	},

	changed: function () {
		if (this.validate()) {
			this.setDataToModel(this.getObject());
		}
	},

	prepareToSave: function() {
		let obj = this.getObject();
		if (this.model && !_.isEqual(this.model.get(this.modelAttr) &&
			this.model.get(this.modelAttr).toJSON(), obj && obj.toJSON()) && this.validate()) {
			this.model.set(this.modelAttr, obj);
		}
	}
});

export default DateRangeInput;

import { ModalChangePassword } from '@codejig/ui-components'
const empty = () => {}

export function showChangePassModal (options) {
  var el = document.createElement('div')
  document.body.appendChild(el)
  new Vue({
    el,
    render: h => h(ModalChangePassword, {
      ref: 'modal',
      props: { options }
    })
  })
}

/* @flow */

import ChronoUnit from '../enums/chronoUnit';

import {
	DAYS_PER_WEEK
} from '../constants';

/**
 * Serilized form:
 *   years: int
 *   months: int
 *   days: int
 */

export default class Period {

	years: number;
	months: number;
	days: number;

	/**
	 * Private constructor
	 */
	constructor(years: number, months: number, days: number) {
		this.years = years;
		this.months = months;
		this.days = days;
	}

	static of(years: number, months: number, days: number): Period {
		return new Period(years, months, days);
	}

	static ofDays(days: number): Period {
		return new Period(0, 0, days);
	}

	static ofMonths(months: number): Period {
		return new Period(0, months, 0);
	}

	static ofWeeks(weeks: number) {
		return new Period(0, 0, weeks * DAYS_PER_WEEK);
	}

	static ofYears(years: number) {
		return new Period(years, 0, 0);
	}

	static fromJSON(value: ? any): ? Period {
		if (value == null) {
			return null;
		}
		return new Period(value.years, value.months, value.days);
	}

	get(unit: ChronoUnit) {
		if (unit == ChronoUnit.YEARS) {
			return this.getYears();
		} else if (unit == ChronoUnit.MONTHS) {
			return this.getMonths();
		} else if (unit == ChronoUnit.DAYS) {
			return this.getDays();
		} else {
			throw new Error('Unsupported unit: ' + unit.toString());
		}
	}

	toJSON() {
		return {
			years: this.years,
			months: this.months,
			days: this.days
		};
	}

	getDays(): number {
		return this.days;
	}

	getMonths(): number {
		return this.months;
	}

	getYears(): number {
		return this.years;
	}

	plusDays(days:number):Period {
		return Period.of(this.years, this.months, this.days + days);
	}

	plusMonths(months:number):Period {
		return Period.of(this.years, this.months + months, this.days);
	}

	plusYears(years:number):Period {
		return Period.of(this.years + years, this.months, this.days);
	}

	equals(other: ? any): boolean {
		if (other == null) {
			return false;
		}
		if (!(other instanceof Period)) {
			return false;
		}
		return this.years === other.years &&
			this.months === other.months &&
			this.days === other.days;
	}

	getUnits() {
		return Period.supportedUnits;
	}

	static supportedUnits = [
		ChronoUnit.YEARS,
		ChronoUnit.MONTHS,
		ChronoUnit.DAYS
	];

	static getMethodSupportedEnumValues = Period.supportedUnits;
}

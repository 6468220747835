import BaseCreateView from '../../../common/views/baseCreateView';
import BinaryUploaderConnector from '../../../common/components/BinaryUploaderConnector'

var CreateExportView = BaseCreateView.extend({

	initialize: function (options) {
		CreateExportView.__super__.initialize.apply(this, arguments);
		this.initializeTemplateField(options);
	},

	initializeTemplateField: function(options) {

		const that = this;

		this.dropzone = BinaryUploaderConnector("#templateFile", {

			binaryData: null,

			dzOptions: {
				acceptedFiles: options.acceptedFiles
			},

			events : {
				onSuccess: options.onSuccess,
				onRemovedFile: options.onRemovedFile
			},

			buildFileDescriptionFn: function (file, binary) {
				return `<span tabindex="-1">${file.name}</span>`;
			}
		}).connect();
	},

	getHTML: function () {
		let result =
			`<div class="grid-1x-padding">
					<label for="name">${app.getResource('name')}</label>
				</div>
				<div class="grid-2x-padding">
					<input id="viewName" class="form-control" type="text" data-is-string-multilingual="short" data-field="name"/>
				</div>
				<div class="grid-1x-padding">
					<label for="name">${app.getResource('template')}</label>
				</div>
				<div class="grid-2x-padding">
					<div id="templateFile"></div>
				</div>`;
		return result;
	}
});

export default CreateExportView;

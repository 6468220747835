export default class UserActionKind {
	static CREATE = 'CREATE';
	static UPDATE = 'UPDATE';
	static DELETE = 'DELETE';
	static READ = 'READ';
	static VIEW_HISTORY = 'VIEW_HISTORY';
	static EXECUTE = 'EXECUTE';
	static IMPORT = 'IMPORT';
	static EXPORT = 'EXPORT';
	static POST = 'POST';
	static UNPOST = 'UNPOST';
	static VIEW_POSTING = 'VIEW_POSTING';
	static VIEW_ALL = 'VIEW_ALL';
	static VIEW_AS_STRING = 'VIEW_AS_STRING';
	static VIEW_AS_ROW = 'VIEW_AS_ROW';
	static VIEW_AS_FORM = 'VIEW_AS_FORM';
	static VIEW_AS_XML = 'VIEW_AS_XML';
	static VIEW_AS_DOC = 'VIEW_AS_DOC';
	static VIEW_AS_SPREADSHEET = 'VIEW_AS_SPREADSHEET';
	static WRITE = 'WRITE';
	static POST_UNPOST = 'POST_UNPOST';
	static VIEW_DELETED = 'VIEW_DELETED';
	static CLEAR_DATABASE = 'CLEAR_DATABASE';
	static PATCH = 'PATCH';
	static DOWNLOAD_BINARY = 'DOWNLOAD_BINARY';
	static UPLOAD_BINARY = 'UPLOAD_BINARY';

	static getParent(action) {
		if (action == UserActionKind.READ)return null;
		if (action == UserActionKind.WRITE)return null;
		if (action == UserActionKind.EXECUTE)return null;
		if (action == UserActionKind.POST_UNPOST)return null;

		if (action == UserActionKind.VIEW_ALL)return UserActionKind.READ;
		if (action == UserActionKind.VIEW_POSTING)return UserActionKind.READ;
		if (action == UserActionKind.VIEW_AS_STRING)return UserActionKind.READ;
		if (action == UserActionKind.VIEW_AS_FORM)return UserActionKind.READ;
		if (action == UserActionKind.VIEW_AS_ROW)return UserActionKind.READ;
		if (action == UserActionKind.VIEW_AS_XML)return UserActionKind.READ;
		if (action == UserActionKind.VIEW_AS_DOC)return UserActionKind.READ;
		if (action == UserActionKind.VIEW_AS_SPREADSHEET)return UserActionKind.READ;
		if (action == UserActionKind.VIEW_DELETED)return UserActionKind.READ;
		if (action == UserActionKind.VIEW_HISTORY)return UserActionKind.READ;
		if (action == UserActionKind.EXPORT)return UserActionKind.READ;
		if (action == UserActionKind.DOWNLOAD_BINARY)return UserActionKind.READ;

		if (action == UserActionKind.CREATE)return UserActionKind.WRITE;
		if (action == UserActionKind.UPDATE)return UserActionKind.WRITE;
		if (action == UserActionKind.DELETE)return UserActionKind.WRITE;
		if (action == UserActionKind.IMPORT)return UserActionKind.WRITE;
		if (action == UserActionKind.UPLOAD_BINARY)return UserActionKind.WRITE;

		if (action == UserActionKind.PATCH)return UserActionKind.UPDATE;

		if (action == UserActionKind.POST)return UserActionKind.POST_UNPOST;
		if (action == UserActionKind.UNPOST)return UserActionKind.POST_UNPOST;

		return null;
	}

	static showPermissionFilter(action) {
		return action != UserActionKind.CLEAR_DATABASE
					&& action != UserActionKind.PATCH;
	}

	static getDfsOrder() {
		let adjList =  _.chain(Object.getOwnPropertyNames(UserActionKind))
			.filter(_.isString)
			.groupBy(kind => UserActionKind.getParent(kind) || "-")
			.value();
		let order = [];
		let dfs = v => {
			if (v != null) {
				order.push(v);
			}
			_.each(adjList[v || "-"] || [], dfs);
		};
		dfs(null);
		return _.chain(order)
			.map((x, i) => [x, i])
			.object()
			.value();
	}


	static getLevel(action) {
		let par = UserActionKind.getParent(action);
		if (!par) {
			return 0;
		}
		return UserActionKind.getLevel(par) + 1;
	}

}

const typeFilterKinds = {
	STRING: [
		'LIKE', 'NULL', 'NOT_NULL'
	],
	INTEGER: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	DOUBLE: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	BOOLEAN: [
		'EQUAL', 'NOT_EQUAL', 'NULL', 'NOT_NULL'
	],
	BINARY: [
		'NULL', 'NOT_NULL'
	],
	PERIOD: [
		'NULL', 'NOT_NULL'
	],
	ZONE_OFFSET: [
		'NULL', 'NOT_NULL'
	],
	ZONED_DATE_TIME: [
		'NULL', 'NOT_NULL'
	],
	DECIMAL: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	SYSTEM_STRING: [
		'LIKE', 'NULL', 'NOT_NULL'
	],
	TIMESTAMP: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	DURATION: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	LOCAL_DATE: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	LOCAL_TIME: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	LOCAL_DATE_TIME: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	DAY_OF_WEEK: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	MONTH: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	MONTH_DAY: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	YEAR: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	YEAR_MONTH: [
		'EQUAL', 'NOT_EQUAL', 'GREATER', 'GREATER_EQUAL', 'LESS', 'LESS_EQUAL', 'NULL', 'NOT_NULL'
	],
	DOCUMENT_REGISTER_STATE: [
		'EQUAL', 'NOT_EQUAL'
	],
	META_OBJECT_STATE: [
		'EQUAL', 'NOT_EQUAL'
	],
	ENUM: [
		'EQUAL', 'NOT_EQUAL'
	],
	'': [
		'EQUAL', 'NOT_EQUAL', 'NULL', 'NOT_NULL'
	]
};

export default typeFilterKinds;

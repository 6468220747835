import entityPresenter from '../../entity/views/entityPresenter';
import ErrorCode from '../../common/enums/errorCode';
import MultilingualString from '../../common/models/multilingualString';
import {translate} from '../../common/service/stringResourceService'
import ConfirmModal from '../../common/components/confirmModal';
import navigationPresenter from '../../Navigation/navigationPresenter';


export default class InstanceViewer extends Backbone.View {
	initialize() {
		super.initialize.apply(this, arguments);
		this.setElement($('#instance-view'));
		this.closed = true;
		this.presentationContext = null;
		this.$body = $('body');
		this.$('.close-instance').click(() => this.checkBeforeClose());
		if (app.withoutHeader) {
			this.$el.parent().css(app.utils.getStartDirection(), '0')
		}
	}

	checkBeforeClose() {
		if (this.preventPageLeave && this.preventPageLeave.predicate && this.preventPageLeave.predicate()) {
			var modal = new ConfirmModal()
			modal.show({
				headerResource: 'unsaved.changes',
				resource: 'unstaged.changes',
				buttons: {
					'save': (e) => {
						return this.preventPageLeave.save(e)
					},
					'continue': () => {}
				},
				then: (promise) => {
					let makeOnClose = () => {
						this.preventPageLeave.removeEventHandler && this.preventPageLeave.removeEventHandler()
						this.closeCustomOrSystem()
					}
					if (promise) {
						promise.then(() => {
							makeOnClose()
						})
					} else {
						makeOnClose()
					}
			}})
		}
		else {
			this.closeCustomOrSystem()
		}
	}

	closeCustomOrSystem() {
		if (this.presentationContext && this.presentationContext.onClose) {
			this.presentationContext.onClose()
		} else {
			this.close()
		}
	}

	close() {
		if (!this.closed) {
			if (this.presentationContext) {
				this.presentationContext.destroy();
				this.presentationContext = null;
			}
			this.previousContext && this.previousContext.update();

			this.closed = true;
			this.$el.css({ 'width': '', 'height': '' })
			this.$el.parent().find('.close-instance').css({ 'top': '', 'bottom': '', 'right': '', 'left': ''})
			let viewerClass = Array.from(this.$el[0].classList).find((c) => { return c.includes('custom-viewer') })
			this.$el.removeClass(viewerClass)
			this.$el.parent().find('.close-instance').detach().appendTo(this.$el)
			if (this.resizeObserver) {
				this.resizeObserver.unobserve(this.el)	
			}
			this.$el.parent().css({
				width: 'unset',
				height: 'unset'
			});
			this.$body
				.removeClass('editing-instance')
			setTimeout(() => {
				this.$el.parent().css({
					width: '',
					height: ''
				})
			}, 300);
			window.scroll(0, this.scroll);
		}
	}

	save() {
		if (this.presentationContext) {
			this.presentationContext.wizardView.save();
		}
	}

	setTopForCross($close, pos, height) {
		if (pos == 'start' || pos == 'end' || pos == 'center') {
			$close.css('top', `calc((100% - ${height}) / 2)`)
		} else if (pos.includes('bottom')) {
			$close.css('top', `calc(100% - ${height})`)
		}
	}

	updateTopForCross($close, pos) {
		let h = this.$el.height() + 'px'
		this.setTopForCross($close, pos, h)
	}

	async show(url, callback, options) {
		this._show(() => {});
		options = options || {}
		let modalOpt = options.modalOpt;

		let smallScreen = window.innerWidth <= 992
		if (modalOpt && modalOpt.modalFloat) {
			if (smallScreen) {
				modalOpt.modalFloat = 'center'
			} else {
				modalOpt.modalWidth && this.$el.css('width', modalOpt.modalWidth)
				modalOpt.modalHeight && this.$el.css('height', modalOpt.modalHeight)
			}
			this.$el.addClass(`custom-viewer-${modalOpt.modalFloat}`)
		}
		this.previousContext = options.previousContext;
		try {
			this.presentationContext = await entityPresenter.present(
				_.extend({
					el: this.$el.children('.instance'),
					url: url,
					viewControl: this,
					ignoreBlocks: app.builderMode,
					hideLoading: () => this.hideLoading(),
					afterFormLoaded: () => {
						let $fixedToolbar = this.$('.form-toolbar')
						$fixedToolbar.addClass('affix')
						if (modalOpt && modalOpt.modalFloat) {
							let startPad = this.$el.css('padding-' + app.utils.getStartDirection());
							let endPad =this.$el.css('padding-' + app.utils.getEndDirection());
							let topPad = this.$el.css('padding-top');

							let fixedToolbarCss = { 'width': `calc(100% + ${parseInt(startPad) + parseInt(endPad)}px)`, 'position': 'sticky', 'right': '0',
								'top': '-' + topPad, 'margin-top': '-' + topPad,'z-index': '300' };
							fixedToolbarCss['padding-' + app.utils.getStartDirection()] =  '0';
							fixedToolbarCss['margin-' + app.utils.getStartDirection()] = '-' + startPad;

							$fixedToolbar.css(fixedToolbarCss)
							let pos = modalOpt.modalFloat
							let $close = this.$('.close-instance').detach()
							this.$el.parent().append($close)
							if (!smallScreen) {
								if (pos.includes('start')) {
									$close.css(app.utils.getStartDirection(), modalOpt.modalWidth);
								} else if (pos.includes('end')){
									$close.css(app.utils.getEndDirection(), modalOpt.modalWidth);
								} else {
									$close.css(app.utils.getStartDirection(), `calc((100% - ${modalOpt.modalWidth}) / 2 + ${modalOpt.modalWidth})` );
								}
								if (modalOpt.modalHeight && modalOpt.modalHeight.includes('fit-content')) {
									this.resizeObserver = new ResizeObserver(() => {
										this.updateTopForCross($close, pos)
									})
									this.resizeObserver.observe(this.el)
									this.updateTopForCross($close, pos)
								} else {
									this.setTopForCross($close, pos, modalOpt.modalHeight)
								}
							}
							this.$('.instance').addClass('custom-viewer')
							this.$('.forms-container').addClass('custom-forms-container')
							this.$el.perfectScrollbar({ suppressScrollX: true })
						}
					},
					afterSaved: (data) => {
						callback && callback(data);
					},
					onClose: () => this.close()
			}, options));
		} catch (e) {
			if (e.message == ErrorCode.META_DATA_IS_STALE) {
				app.notificationManager.addError(
					MultilingualString.fromStringInCurrentLanguage(translate('stale.meta.data'))
				);
				this.closeCustomOrSystem()
			} else {
				throw e;
			}
		}
		this.$('.scrollable').perfectScrollbar('update');
	}

	async _show(showFn) {
		this.$el.addClass('hold-transition');
		this.$el.parent().removeClass('navigation-tab')
		this.$('.loading').show();
		if (this.closed) {
			this.closed = false;
			this.scroll = window.scrollY;
			this.$body
				.addClass('editing-instance')
		}
		if (this.presentationContext) {
			this.presentationContext.destroy();
		}

		this.$el.children('.instance')
			.html('<div class="forms-container" />');

		await showFn();

		this.$('.scrollable').perfectScrollbar('update');
	}

	async showNavigation(options) {
		return await this._show(async () => {
			navigationPresenter.present({
				el: this.$el.children('.instance'),
				hideLoading: () => this.hideLoading()
			});
		});
	}

	hideLoading() {
		this.$('.loading').fadeOut('fast')
		setTimeout(() => {
			this.$el.removeClass('hold-transition');
		}, 100);
	}

	canShow() {
		return !this.presentationContext;
	}
};

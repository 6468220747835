import Entity from './entity.js';

var EmbeddedEntity = Entity.extend({

	initialize: function (o) {
		EmbeddedEntity.__super__.initialize.apply(this, arguments);
		this.set('className', 'AbstractEmbedded');
	},

	deepEquals: function (json) {
		return this.equals(json);
	},

	// shallow check
	equals: function (json) {
		var propertiesToSkip = {
			clientState: true,
			metaObject: true,
			entityTypeId: true,
			entityViewId: true
		};
		for (var prop in json) {
			if (!propertiesToSkip.hasOwnProperty(prop)) {
				if (this.attributes.hasOwnProperty(prop) === false) {
					return false;
				}

				if (_.isObject(this.get(prop))) {
					if ((typeof json[prop] === 'undefined' || json[prop] === null) ||
						this.get(prop).id !== json[prop].id) {
						return false;
					}
				} else {
					if (this.get(prop) != json[prop]) {
						return false;
					}
				}
			}
		}
		return true;
	},

	clonedJSON: function() {
		let result = this.toJSON();
		result.id = null;
		result.clientId = null;
		const type = app.types.get(this.entityTypeId);
		const fields = type.fields().concat(type.sharedFields());
		_.each(fields, field => {
			if (field.type() && field.type().primitive() === 'STRING') {
				const fieldName = field.fieldName();
				if(this.get(fieldName) != null) {
					result[fieldName] = this.get(fieldName).clonedJSON();
				}
			}
		})
		return result;
	},

	clone: function() {
		return new EmbeddedEntity(this.clonedJSON(), { entityTypeId: this.entityTypeId });
	}
});

export default EmbeddedEntity;

/* @flow */

import ZoneRules from './zoneRules';
import MomentJsZoneRules from './momentJsZoneRules';

export default class ZoneRulesProvider {

	static zones: Dictionary<ZoneRulesProvider> = {};
	static providers: Array<ZoneRulesProvider> = [];

	static getAvailableZoneIds(): Array<string> {
		return _.keys(ZoneRulesProvider.zones);
	}

	static getRules(zoneId: string, forCaching: boolean): ZoneRules  {
		return ZoneRulesProvider.zones[zoneId].provideRules(zoneId, forCaching);
	}

// static NavigableMap<String,ZoneRules> 	getVersions(String zoneId)
// Gets the history of rules for the zone ID.

// protected boolean 	provideRefresh()
// SPI method to refresh the rules from the underlying data provider.

	provideRules(zoneId: string, forCaching: boolean): ZoneRules {
		throw new Error('abstract');
	}

// protected abstract NavigableMap<String,ZoneRules> 	provideVersions(String zoneId)
// SPI method to get the history of rules for the zone ID.

	provideZoneIds(): Array<string> {
		throw new Error('abstract');
	}

// static boolean 	refresh()
// Refreshes the rules from the underlying data provider.

	static registerProvider(provider: ZoneRulesProvider) {
		_.each(provider.provideZoneIds(), zoneId => {
			if (ZoneRulesProvider.zones.hasOwnProperty(zoneId)) {
				throw new Error('Unable to register zone as one already registered with that ID: ' + zoneId);
			}
			ZoneRulesProvider.zones[zoneId] = provider;
		});
		ZoneRulesProvider.providers.push(provider);
	}
}

class MomentJsZoneRulesProvider extends ZoneRulesProvider {

	provideRules(zoneId: string, forCaching: boolean): ZoneRules {
		return new MomentJsZoneRules(zoneId);
	}

	provideZoneIds(): Array<string> {
		if (global.momentJsHelper) {
			global.momentJsHelper.loadTimezonePackage();
		}
		return global.moment.tz.names();
	}
}

class FixedUaZoneRulesProvider extends ZoneRulesProvider {

	static fixedToStandart = {
		'Europe/Kyiv': 'Europe/Kiev',
		'Europe/Uzhhorod': 'Europe/Uzhgorod',
		'Europe/Zaporizhia': 'Europe/Zaporozhye'
	};

	provideRules(zoneId: string, forCaching: boolean): ZoneRules {
		const standartZoneId = FixedUaZoneRulesProvider.fixedToStandart[zoneId];
		return ZoneRulesProvider.getRules(standartZoneId, forCaching);
	}

	provideZoneIds(): Array<string> {
		this.registerMomentLinks();
		return _.keys(FixedUaZoneRulesProvider.fixedToStandart);
	}

	registerMomentLinks() {
		const keys = _.keys(FixedUaZoneRulesProvider.fixedToStandart);
		const links = _.map(keys, key => {
			return key + '|' + FixedUaZoneRulesProvider.fixedToStandart[key];
		});
		global.moment.tz.link(links);
	}
}

ZoneRulesProvider.registerProvider(new MomentJsZoneRulesProvider());
ZoneRulesProvider.registerProvider(new FixedUaZoneRulesProvider());

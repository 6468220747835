/* @flow */

export function login(username: String, password: String, redirectUrl: String, redirectOnError: String, captcha: String) {	
	let url = app.urls.login
	if (redirectUrl) {
		url +='?redirectOnSuccess=' + encodeURIComponent(redirectUrl)
	}
	if (redirectOnError) {
		url += redirectUrl ? '&' : '?' 
		url += 'redirectOnError=' + encodeURIComponent(redirectOnError)
	}
	form(url, [
		{type: "text", name: "password", value: password},
		{type: "text", name: "username", value: username},
		{type: "text", name: "captcha", value: captcha}
	]).submit()
}

export function logout() {	
	form(app.urls.logout).submit()
}

export function isCaptchaNeeded() {
	return app.iscaptchaneeded
}
function form(url, inputs) {
	const form = document.createElement("form")
	attr(form, 'action', url)
	attr(form, 'method', 'POST')
	form.append(input('text', '_csrf', document.querySelector("meta[name='_csrf']").content))
	inputs && inputs.forEach((i) => {
		form.append(input(i.type, i.name, i.value))
	})
	document.body.append(form)
	return form
}

function input(type, name, value) {
	const i = document.createElement("input")
	attr(i, "type", type)
	attr(i, "name", name)
	i.value = value
	return i
}

function attr(el, name, value) {
	el.setAttribute(name, value)
}

export async function save(closeOnSave, temporary, silent) {
	return await this.wizardView.save(closeOnSave, temporary, silent).then((data) => {
		if (temporary) {
			return data.id
		} else {
			return this.model.id
		}
	})
}
import { Checkbox } from '@codejig/ui-components'

class CheckboxInput {

  constructor (options) {
    var that = this
    this.model = options.model
    this.getModelOuter = options.getModel
    this.modelAttr = options.modelAttr
    this.el = options.el
    this.disabled = this.el.getAttribute('data-disabled') == 'true'
    this.context = options.context;
    this.props = {
      disabled: this.el.hasAttribute('disabled') || false,
      value: this.getModel().get(this.modelAttr),
      skin: this.el.getAttribute('data-style')

    }
    this.checkbox = new Vue({
      el: that.el,
      data: {
        props: that.props
      },
      render: h => h(Checkbox, {
        props: this.props,
        ref: 'checkbox',
        on: {
          input: function (data) {
            if (that.getModel() && that.getModel().get(that.modelAttr) !== data) {
              that.getModel().set(that.modelAttr, data)
              that.getModel().trigger('manualChange:' +  that.modelAttr, that.getModel())
            }
          }
        }
      })
    }).$refs.checkbox

    this.el = this.checkbox.$el
    this.getModel().on('change:' + this.modelAttr, function (model, value) {
      that.props.value = value
    })
  }

  render () {}

  getModel() {
    if (this.getModelOuter) {
        return this.getModelOuter()
    } else {
        return this.model
    }
  }

  focus () {
    this.checkbox.focus()
  }

  disable () {
    this.props.disabled = true
  }

  isDisabledInFormBuilder () {
    return this.disabled
  }

  enable () {
    this.props.disabled = false
  }

  isEnabled () {
    return !this.props.disabled;
  }

  reset () {
    this.checkbox.$destroy()
  }

  undelegateEvents () {}

  off () {}

}
export default CheckboxInput;

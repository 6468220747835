/* @flow */

import type ZoneOffset from '../zoneOffset';
import type Instant from '../instant';

export default class ZoneRules {

	static of(offset: ZoneOffset): ZoneRules {
		return new (require('./offsetZoneRules').default)(offset);
	}

	getOffset(instant: Instant): ZoneOffset {
		throw new Error('abstract in this implementation');
	}
}

/* @flow */
import MetaObjectState from '../../common/enums/metaObjectState';
import BaseSelect from '../../common/components/baseSelect';

const MetaObjectStateSelect = Backbone.View.extend({

	events: {
		'change': 'changed'
	},

	initialize: function(options) {
		var that = this;
		this.model = options.model
		this.modelAttr = options.modelAttr;
		this.context = options.context;
		new BaseSelect({
			el:this.$el,
			allowClear: true,
			data:_.map(Object.keys(MetaObjectState), (value, index) => {
				return {
					text: value,
					id: value
				};
			})
		})
		if (this.model) {
			this.listenTo(this.model, 'change:' + this.modelAttr, this.render);
		}
	},

	render: function() {
		this.setValue(this.getDataFromModel());
	},

	setValue: function(value) {
		this.$el.val(value).trigger('change');
	},

	getValue: function() {
		return this.$el && this.$el.val();
	},

	getDataFromModel: function() {
		return this.model && this.model.get(this.modelAttr);
	},

	setDataToModel: function(obj){
		if (this.model && !_.isEqual(this.model.get(this.modelAttr) &&
				this.model.get(this.modelAttr), obj)) {
			this.model.set(this.modelAttr, obj);
		}
	},

	changed: function() {
		this.setDataToModel(this.getValue());
		if (this.model) {
			this.model.trigger('manualChange:' +  this.modelAttr, this.model);
		}
	},

	enable: function() {
		this.$el.removeAttr('disabled');
	},

	disable: function() {
		this.$el.attr('disabled', 'disabled');
	}

});

export default MetaObjectStateSelect;

/* @flow */

import type {
	TemporalAccessor
} from '../declarations';

export default class DateTimeFormatter {

	pattern: string;
	locale: string;

	constructor(pattern: string, locale: string) {
		this.pattern = DateTimeFormatter.javaPatternToMomentPattern(pattern);
		this.locale = locale;
	}

	static ofPattern(pattern: string, locale: string): DateTimeFormatter {
		return new DateTimeFormatter(pattern, locale);
	}

	static javaPatternToMomentPattern(pattern:string):string {
		if (pattern) {
			pattern = pattern.replace('D', 'DDD');
			pattern = pattern.replace('dd', 'DD');
			pattern = pattern.replace('d', 'D');
			pattern = pattern.replace('eeee', 'dddd');
			pattern = pattern.replace('eee', 'ddd');
			pattern = pattern.replace('e', 'E');
			pattern = pattern.replace('yyyy', 'YYYY');
			pattern = pattern.replace('yy', 'YY');
			pattern = pattern.replace('y', 'Y');
			pattern = pattern.replace('ZZZZZ', 'Z');
		}
		return pattern;
	}

	format(temporalAmount: TemporalAccessor): string {
		return temporalAmount.toMoment()
			.locale(this.locale).format(this.pattern);
	}
}

import BaseUpdateView from '../../common/views/baseUpdateView';

export default class UpdateView extends BaseUpdateView {

	initialize(options) {
		super.initialize(options);
	}

	getHTML() {
		return `<div id="blockNameDiv" class="modal-component">
							<label for="name">${app.getResource('name')}</label>
							<input class="form-control" type="text" data-is-string-multilingual="short" data-field="name"/>
						</div>`;
	}

	getCurrentConfiguration() {
		return this.model.toJSON();
	}
}

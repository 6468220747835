import MultilingualString from '../../common/models/multilingualString';
import Constants from '../../common/models/constants'

const ReferrersTree = Backbone.View.extend({

	el: '#referrersTree',

	getNodes: async function (data) {
		let nodes = [];
		let grouped = _.groupBy(data, e => e.objectType.id);
		let keys = Object.keys(grouped);
		app.entityManager.fetchManyStringViews(_.map(data, e => {
			return {id: e.id, viewId: null};
		}));
		for(let i = 0, l = keys.length; i < l; i++) {
			let childrenNodes = [];
			for(let j = 0, p = grouped[keys[i]].length; j < p; j++) {
				let metaObject = grouped[keys[i]][j];
				let stringView = null
				let link = null
				if (metaObject.objectType.id == Constants.ID_TYPE_VIEW){
					link = app.urls.home + 'entityView/edit/' + metaObject.id
					stringView = '#'+metaObject.id
				} else {
					stringView = await app.entityManager.fetchStringView(null, metaObject.id)
					link = app.urls.update(metaObject.objectType.id, metaObject.id)
				}
				childrenNodes.push({
					text: stringView,
					children: false,
					a_attr: {
						'href': link
					}
				});
			}
			if (keys[i] != Constants.ID_TYPE_VIEW){
				nodes.push({
					text: MultilingualString.getCurrentValue(app.types.get(keys[i]).name()),
					children: childrenNodes,
					state: {
						opened: true
					},
					a_attr: {
						'href': app.urls.indexPage(keys[i])
					}
				})
			} else {
				nodes.push({
					text: MultilingualString.getCurrentValue(app.types.get(keys[i]).name()),
					children: childrenNodes,
					state: {
						opened: true
					},
					state : {
						disabled: true
					}
				})
			}
		}
		return nodes;
	},

	render: async function (data) {
		var that = this;
		await this.$el.jstree()
		this.$el.jstree('destroy').empty();
		this.$el.jstree({
			'core': {
				data: function (node, callback) {
					that.getNodes(data).then(nodes => {
						callback.call(this, nodes);
					})
				},
				themes: {
					icons: false,
					name: 'proton',
					responsive: true
				}
			},
			'plugins': ['wholerow']
		}).bind('select_node.jstree', function (e, data) {
			 var href = data.node.a_attr.href;
			 window.open(href);
		});
	}
});

export default ReferrersTree;

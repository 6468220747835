import utils from '../components/utils'
import StringViewStatus from '../enums/StringViewStatus'
import { translate } from './stringResourceService'

class StringViewService {

  search(data){
    if (data.pattern.length < 3) {
      return Promise.resolve({
        items: [],
        totalSize: 0
      })
    }
    let url = app.urls.home + 'search/getPageOfRows';
    return utils.request(data, url, 'post');
  }

  getValue (o) {
    if (o.status === StringViewStatus.FOUND) {
      return o.value
    }
    if (o.status === StringViewStatus.NOT_FOUND) {
      return translate('not.found')
    }
    if (o.status === StringViewStatus.NOT_ACCESSIBLE) {
      return translate('not.accessible')
    }
    if (o.status === StringViewStatus.DELETED) {
      return translate('deleted')
    }
  }

  buildStringView(item, viewId, languageId) {
    return utils.postRequest(item.toJSON(),
      app.urls.buildStringView(item.get('entityTypeId') || item.entityTypeId, viewId, languageId))
  }
}

export let stringViewService = new StringViewService();

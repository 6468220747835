import PermissionFilterKind from '../../common/enums/permissionFilterKind';
import BaseModel from '../../common/models/baseModel';
import PermissionConstants from './permissionConstants';

export default class PermissionFilter extends BaseModel {

	initialize (o) {
		this.set('clientId', this.cid.substring(1));

		if (o && o.id) {
			this.set('id', o.id);
		}
		if (o && o.actionKind) {
			this.set('actionKind', o.actionKind);
		} else {
			this.set('actionKind', PermissionConstants.DEFAULT_USER_ACTION_KIND);
		}
		if (o && o.kind) {
			this.set('kind', o.kind);
		} else {
			if (this.get('actionKind') == PermissionConstants.DEFAULT_USER_ACTION_KIND) {
				this.set('kind', PermissionFilterKind.EVERYONE);
			} else {
				this.set('kind', PermissionConstants.DEFAULT_FILTER_KIND);
			}

		}
		if (o && o.roles) {
			this.set('roles', o.roles.map(r => r.id));
		} else {
			this.set('roles', []);
		}
		if (o && o.block) {
			this.set('blockId', o.block.id);
		}
		if (o && o.block) {
			this.set('blockName', o.block.name);
		}

		this.listenTo(this.get('kind'), 'change', (m) => {
			this.trigger('change:kind', m);
			this.trigger('change', m);
		});
		this.listenTo(this.get('roles'), 'change', (m) => {
			this.trigger('change:roles', m);
			this.trigger('change', m);
		});
		this.listenTo(this.get('blockName'), 'change', (m) => {
			this.trigger('change:blockName', m);
			this.trigger('change', m);
		});
	}

	toServerJSON () {

		if (this.get('actionKind') != PermissionConstants.DEFAULT_USER_ACTION_KIND
					&& this.get('kind') == PermissionConstants.DEFAULT_FILTER_KIND) {
			return null;
		}

		const json =  _.chain(this.attributes).clone()
			.omit('className', 'clientState', 'actionKind', 'roles', 'blockId', 'blockName')
			.mapObject(function (val, key) {
				if (_.isObject(val)) {
					if (key == 'metaObject') {
						return {
							id: val.id
						};
					}
					if (val.toServerJSON) {
						return val.toServerJSON();
					}
					if (val.toJSON) {
						return val.toJSON();
					}
				}
				return val;
			}).value();
		json.roles = this.get('roles').map(r => {return {id: r};});
		json.block = (!this.get('blockId') && !this.get('blockName') ? null : {
			id: this.get('blockId'),
			name: this.get('blockName')
		})
		json.id = this.get('id');
		return json;
	}

	static parseJSON(json) {
		return (!json ? {} :
			{
				id: json.id,
				kind: json.kind,
				roles: json.roles,
				block: json.block ? { id: json.block.id, name: json.block.name } : {}
			});
	}
}

/* @flow */
import  MultilingualString from '../models/multilingualString';

class MultilingualStringService {

  stringToModel(mls: any){
    if (_.isString(mls) || _.isNumber(mls) || _.isBoolean(mls)){
      return new MultilingualString({value: mls.toString()});
    }
    if (!(mls instanceof MultilingualString)){
      return new MultilingualString(mls);
    }
    return mls;
  }

  formatSystemString(inputString: string, args: string[]): string {
  	let formatted = inputString || '';
  	for (var i = 0; i < args.length; i++) {
  		var regexp = new RegExp('\\{'+i+'\\}', 'gi');
  		formatted = formatted.replace(regexp, args[i]);
  	}
  	return formatted;
  }

  format(inputString: MultilingualString, args): MultilingualString {

    inputString = this.stringToModel(inputString);
    args = args.map(arg => {
      return this.stringToModel(arg);
    });

  	let formatted = new MultilingualString();
  	formatted.setValue(this.formatSystemString(inputString.getValue(), args.map(string => string.getValue())));

  	let extractLanguages = (string) => {
  		return _.map(string.getTranslations().toJSON(), (v, k) => { return {languageTag: k}; })
  	}

  	let localesUnion = _.union(extractLanguages(inputString), ..._.map(args, arg => extractLanguages(arg)));

  	_.each(localesUnion, locale => {
  		formatted.setTranslation(locale,
  			 this.formatSystemString(
  				 inputString.getCurrentValue(locale.languageTag),
  				 args.map(string => string.getCurrentValue(locale.languageTag))));
  	});
  	return formatted;
  }

	render(inputString: string, values: array[]): string {
  	if (inputString == null || inputString == "") {
  		return "";
		}

		let formatted = inputString || '';
  	for (let value of values) {
			let regexp = new RegExp('\\{'+value[0]+'\\}', 'gi');
			formatted = formatted.replace(regexp, value[1]);
		}

		let regexp = new RegExp('\\{.[^\\{\\}]*\\}', 'gi');
		formatted = formatted.replace(regexp, "");

		return formatted;
	}

}

export let multilingualStringService = new MultilingualStringService();

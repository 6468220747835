/* @flow */
import { Service } from './service';

class CategoryService extends Service {

  prefix(){
    return 'entityCategory';
  }

}

export let categoryService = new CategoryService();

import { translate } from '../service/stringResourceService'
import utils from "../components/utils";

class UnsavedChangesModal extends Backbone.View {

	initialize () {
		let $el = $('#unsavedChangesModal')
		if ($el.length === 0) {
			let el = document.createElement('div')
			el.id = 'unsavedChangesModal'
			el.classList.add('modal')
			el.classList.add('fade')
			document.body.appendChild(el)
		}
		$el = $('#unsavedChangesModal')
		$el.html(this.getHTML())
		this.setElement($el)
	}

	getHTML () {
		return `
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header create-modal-header">
							<h4>
								<span id="headerText"></span>
							</h4>
						</div>
						<div class="modal-body">
							<span></span>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default pull-left" data-action="cancel">${translate('cancel')}</button>
							<button type="button" class="btn btn-primary" data-action="redirect">${translate('dont.save')}</button>
							<button type="button" class="btn btn-danger" data-action="save">${translate('save.and.continue')}</button>
						</div>
					</div>
				</div>`
	}

	show (options) {
		let continueCallback = options.continueCallback, saveCallback = options.saveCallback;
		let buttons = options.buttons || {"cancel":true, "save":true, "redirect": true};
		let saveText = options.saveText || app.getResource('save.and.continue');
		let headerResource = (options.headerResource && app.getResource(options.headerResource)) || app.getResource('unsaved.changes');
		let resource = (!options.onlyAdditionalNotResource && (
						(options.resource && options.resource.getCurrentValue && options.resource.getCurrentValue()) ||
						(options.resource && app.getResource(options.resource)) ||
						app.getResource('unsaved.changes.text')
					)) || "";
		if (options.additionalNotResourceText) {
			resource += ' ' + options.additionalNotResourceText;
		}

		this.$el.find('#headerText').html(headerResource);
		this.$el.find('.modal-body span').html(resource).css('text-align', 'left');
		this.$el.find(`.modal-footer > button[data-action="save"]`).text(saveText);
	
		this.$el.find('.modal-footer > button').hide();
		_.each(buttons, (func, btn) => {
			if (func === true) {
				if (btn == "cancel") {
					this.$el
						.find(`.modal-footer > button[data-action="cancel"]`)
						.off('click')
						.click(() => {
							this.$el.modal('hide');
						}).show();
				} else if (btn == "save") {
					this.$el
						.find(`.modal-footer > button[data-action="save"]`)
						.off('click')
						.click(() => {
							app.utils.preventPageLeave.save();
							app.tasksQueue.add((e) => {
								saveCallback && saveCallback(e);
								continueCallback && continueCallback(e);
							});
						}).show();
				} else if (btn == "redirect") {
					this.$el
						.find(`.modal-footer > button[data-action="redirect"]`)
						.off('click')
						.click((e) => {
							app.utils.preventPageLeave.removeEventHandler();
							continueCallback && continueCallback(e);
						}).show();
				}
			} else {
				this.$el.find(`.modal-footer > button[data-action="${btn}"]`)
				.off('click')
				.on('click', e => {	this.$el.modal('hide');	func(e);})
				.show();
			}
		});

		this.$el.modal({backdrop: 'static'});
	}

}

export default UnsavedChangesModal;

import utils from '../../common/components/utils.js';
import BaseForm from '../../common/views/baseForm.js';
import MultilingualString from '../../common/models/multilingualString.js';
import { categoryService } from '../../common/service/categoryService';
import CreateNamedEntityView from '../../common/views/createNamedEntityView';
import BaseUpdateView from '../../common/views/baseUpdateView';
import Constants from '../../common/models/constants'
import {initializeSortable} from './reorderInit'

app.categories = new Backbone.Collection(app.raw && app.raw.categories)

var EntityCategoryView = BaseForm.extend({

	el: '.categoriesPanel',

	events: {
		'click .entityCategory': 'select',
		'click #addCategory': 'add',
		'click .editCategory': 'edit',
		'click .removeCategory': 'remove'
	},
	checkNumbersOfCategories: function () {
		if (app.categories.isEmpty()){
			$(`[data-categoryId="${app.nocategory}"]`).hide()
		} else {
			$(`[data-categoryId="${app.nocategory}"]`).show()
			if (app.categories.length > 1) {
				$('#categories_reorder').show()
			} else {
				$('#categories_reorder').hide()
			}
		}
	},
	initialize: function () {
		EntityCategoryView.__super__.initialize.apply(this, arguments);
		this.checkNumbersOfCategories()
		var that = this;
		$('#removeSelectedCategories').confirm({
			confirm: that.remove
		});
		let orderedCategories = _.sortBy(app.raw.categories, 'relativeOrder')
		_.each(orderedCategories, category => this.addCategory(category));

		initializeSortable($('#categoryPanelHead'), $('.category-box-body'), 'categories', app.categories, 'data-categoryid', 'entityCategory', 'categoryTooltip')
	},

	select: function (event) {
		if ($('#categoriesBox').hasClass('ui-sortable-disabled')
					&& ($(event.target).hasClass('entityCategory') || $(event.target).hasClass('entityCategoryName'))) {
			$(event.currentTarget).toggleClass('active');
		}
	},

	add: function () {
		addNewCategory((category) => {
			this.addCategory(category);
			app.categories.add(new Backbone.Model(category));
			this.checkNumbersOfCategories()
		})
	},

	addCategory: function (category) {
		$('.category-box-body').append(`<div class="entityCategory ui-sortable-handle" data-categoryid="${category.id}">
				<span class="name entityCategoryName">
					${MultilingualString.getCurrentValue(category.name)}
				</span>
				<span class="pull-right categoryTooltip">
					${app.formatters.categoryTooltip(category)}
				</span>
			</div>`)
		$(`.entityCategory[data-categoryid=${category.id}]`).find('.editCategory').on('click', e => this.edit(e));
		$(`.entityCategory[data-categoryid=${category.id}]`).find('.removeCategory').on('click', e => this.remove(e));
	},

	remove: function (event) {
		let id = $(event.currentTarget).data('id');
		this.removedId = id;
		utils.confirmDelete([id], app.urls.dependencies(Constants.ID_TYPE_CATEGORY), app.urls.delete(Constants.ID_TYPE_CATEGORY),
			{}, () => {
				this.removeCategoryFromPanel();
				app.categories.remove(app.categories.get(id))
				this.checkNumbersOfCategories()
			 });
	},

	edit: function (event) {
		let id = $(event.currentTarget).data('id');
		let model = app.categories.get(id).clone();
		model.set('name', new MultilingualString(model.get('name')));
		app.editModal.show({
			url: `${app.urls.home}${categoryService.prefix()}/update`,
			headerResource: `edit.category`,
			model: model,
			view: BaseUpdateView,
			afterUpdateOnSuccess: (category) => { this.updateCategory(category); }
		});
	},

	updateCategory: function (category) {
		this.$el.find(`.entityCategory[data-categoryid=${category.id}]`).find('span.name')
			.text(MultilingualString.getCurrentValue(category.name));
		let model = app.categories.get(category.id);
		for (let key of Object.keys(category)) {
			model.set(key, category[key]);
		}
	},

	removeCategoryFromPanel: function () {
		this.$el.find(`.entityCategory[data-categoryid=${this.removedId}]`).remove();
	}
});

export function addNewCategory(onSuccess) {
	let model = new Backbone.Model();
	model.set('name', new MultilingualString({}));
	model.set('relativeOrder', app.raw.categories.length)
	app.createModal3.show({
		url: `${app.urls.home}${categoryService.prefix()}/create`,
		headerResource: `add.category`,
		model: model,
		afterCreateOnSuccess: onSuccess,
		createView: CreateNamedEntityView
	});
}

export default EntityCategoryView;

/* @flow */

function guessFieldName(fieldName: String, tableId: String): String {
  const tableType = app.fields.get(tableId).type()
  let field = tableType.fieldByName(fieldName)
  if (!field) {
    field = tableType.fields().find((f) => {return f.id == fieldName})
  }
  return field && field.fieldName()
}

export function hideColumn(fieldName: String, tableId: String) {
  const name = guessFieldName(fieldName, tableId)
  const table = this.control[tableId]
  if (name && table){
      const index = table.fieldsToShow.indexOf(name)
      if (index != -1){
        table.fieldsToShow.splice(index, 1)
        table.fillColumns()
      }
  }
}

export function showColumn(fieldName: String, tableId: String) {
  const name = guessFieldName(fieldName, tableId)
  const table = this.control[tableId]
  if (name && table){
    table.fieldsToShow.push(name)
    table.fieldsToShow = _.unique(table.fieldsToShow)
    table.fillColumns()
  }
}

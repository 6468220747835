import BaseModel from '../models/baseModel.js';

var BaseCollection = Backbone.Collection.extend({

	model: BaseModel,

	toServerJSON: function () {
		return this.models.map(i => i.toServerJSON ? i.toServerJSON() : i.toJSON());
	}

});

export default BaseCollection;

<template>
  <div style="position: relative">
    <div class="filters" style="display:inline;" ref="filter"></div>
    <div class="pull-right">
      <button class="btn btn-default" @click="showColumnList" ref="extended">
        <span class="glyphicon glyphicon-th-large"></span>
      </button>
    </div>
    <div class="clearfix"></div>
    <div class="newTableWrapper">
      <Table :table="{loadMore: loadMore, rowClasses: rowClasses}" :rows="rows" :columns="columns" v-on:rowClicked="onClickCell" ref="table" class="highViewport"/>
    </div>
  </div>
</template>

<script>
import { Table } from '@codejig/ui-components'
import { getRowViewItems, rowItemsToTableColumns, getOldTableData, getFieldsToShow } from '../../../utils/rowViewUtils'
import { initFiltersEntityTable, showColumnsList } from '../../../utils/tableUtils';
import { openLink, scaleDecimals } from '../../../common/components/utils'
import TableState from '../../../common/components/tableState';

export default {
    props: ['journalTypeId', 'referrerDocumentId'],
    data () {
        return {
            columns: [],
            rows: [],
            filters: [],
            table: {}
        }
    },
    methods: {
      loadMore(data, order) {
        return this.getMoreDataForTable({
          size: data.size,
          loadItems: data.loadItems,
          loadFilteredSize: data.loadTotal,
          loadTotalSize: false,
          loadDeleted: false,
          filters: this.filters && this.filters.filters && this.filters.filters.toServerJSON() || [],
          tags: null,
          lastValue: data.clear ? null : (this.rows && this.rows[this.rows.length-1] || null),
          metaObject: null,
          order: this.formatOrder(order),
        })
      },
      showColumnList(e) {
        showColumnsList.call(this.table, e)
      },
      formatOrder(order){
        let formattedOrder = {}
        if (order){
          if (order.order=="asc"){
            formattedOrder.ascendingOrder=true;
          }else if (order.order=="desc") {
            formattedOrder.ascendingOrder=false;
          }
          formattedOrder.field={id:this.getIdByFieldName(order.field, this.journalTypeId)}
          return formattedOrder
        }
      },
      getIdByFieldName(fieldName, typeId) {
        	return app.types.get(typeId).fieldByName(fieldName).id
      },
      getMoreDataForTable(moreStruct) {
        var that = this
          return new Promise(function (resolve, reject) {
            var onSuccess = function (data) {
              data.one && data.one.forEach((r) => {
                scaleDecimals(app.types.get(that.journalTypeId), r)
              })
              resolve({
                items: data.one,
                totalSize: data.two
              })
            }
              app.utils.ajaxRequest(moreStruct, app.urls.getJournalRecordsPage(that.referrerDocumentId, that.journalTypeId), 'post', onSuccess, reject, true)
          }).catch(function (response) {
            app.utils.error(response);
            return Promise.reject(response);
          });
        },
      onClickCell(row, e) {
        let target = $(e.target)
        if (!((target.parents('a')[0]) || e.target.nodeName.toLowerCase()=='a')) {
          let cell
          if (target.hasClass('table-cell')) {
            cell = target
          } else{
            cell = target.parents('div.table-cell')
          }
          if (target.is('.ref')) {
            openLink(target, e);
          }
        }
      },
      rowClasses(item, index) {
  			return {
  				clickable: 'clickable'
  			}
  		}
    },
    async mounted () {
        const rowViewItems = await getRowViewItems(null, this.journalTypeId)
        this.columns = rowItemsToTableColumns(rowViewItems)
        let fieldsToShow = getFieldsToShow(rowViewItems)
        let columnsFull = rowItemsToTableColumns(rowViewItems)
        const that = this
        let table = {
          oldTable: getOldTableData(rowViewItems, true),
          getStorageId: () => {
            return this.journalTypeId
          },
          filtersChanged: () => {
            this.filters = this.table.filters
            this.$refs.table.resetMore()
          },
          _get: () => {
            return $(this.$refs.extended)
          },
          columnsFull: columnsFull,
          fieldsToShow: fieldsToShow,
          fillColumns: () => {
            that.columns.splice(0, that.columns.length)
            columnsFull.forEach((c) => {
              if (that.table.fieldsToShow.includes(c.field)){
                that.columns.push(c)
              }
            })
          },
          filter: (filters) => {
            that.table.filters.loadFilters(filters)
            that.table.filtersChanged()
          },
          setExtendedMode: () => {},
          setFieldsToShow: (fields) => {
            that.table.fieldsToShow = fields
            that.table.fillColumns()
          }
        }
        this.table = table
        table.state = new TableState(table)
        initFiltersEntityTable({
          el: $(this.$refs.filter),
          table: table,
          fields: app.fields,
          types: app.types,
          model: null,
          typeId: this.journalTypeId
        })
        table.state.apply()
        this.$nextTick(function () {
          this.$refs.table.reinitPs()
        })
    },
    components: {
        Table
    }
}
</script>

import Constants from './constants';
import Entities from '../collections/entities';

export default class FieldViewOptions extends Backbone.Model {

	initialize(options) {
		super.initialize(options);
		if (!this.get('filters')) {
			this.set('filters', []);
		}
		this.setFilters(this.getFilters());
	}

	getFilters () {
		return this.get('filters');
	}

	setFilters (filters) {
		if (!(filters instanceof Backbone.Collection)) {
			filters = Entities.fromJSON(filters, Constants.ID_TYPE_FIELD_FILTER);
		}
		this.set('filters', filters);
	}

	getOrder () {
		return this.get('order');
	}

	setOrder (order) {
		this.set('order', order);
	}

	setIsExtendMode(isExtendMode) {
		this.set('isExtendMode', isExtendMode);
	}

	getIsExtendMode(field) {
		return this.get('isExtendMode');
	}

	toJSON() {
		return {
			order: this.getOrder(),
			filters: this.getFilters().map(filter => {
				return filter.toServerJSON();
			})
		};
	}

}

import utils from '../../common/components/utils.js'

export function initializeSortable($buttonsBox, $el, entities, models, idDefinder, entity, tooltipClass) {

	let reorder = $buttonsBox.find(`#${entities}_reorder`)
	let save = $buttonsBox.find(`#${entities}_saveOrder`)
	let cancel = $buttonsBox.find(`#${entities}_cancelReorder`)
	let oldOrder = []
	let isChanges = false

	reorder.on('click',(e)=>{
		reorder.hide()
		save.show()
		cancel.show()
		$(`.${tooltipClass}`).hide()
		Array.from(app[entities].models).forEach((model) => {
			oldOrder.push({id: model.get('id'), relativeOrder: model.get('relativeOrder')})
		})
		$el.sortable({
			stop: () => { updateRelativeOrder() },
			items: `.${entity}:not(.fixedPosition)`
		})
		$el.sortable('enable')
	})

	save.on('click',(e)=>{
		reorder.show()
		save.hide()
		cancel.hide()
		$(`.${tooltipClass}`).show()
		$el.sortable('disable')
		utils.postRequest(app[entities].models, app.urls.reorder(entity))
		isChanges = false
		oldOrder = []
		e.stopPropagation()
	})

	cancel.on('click',(e)=>{
		reorder.show()
		save.hide()
		cancel.hide()
		$(`.${tooltipClass}`).show()
		if (isChanges) {
			returnLastSavedOrder()
		}
		$el.sortable('disable')
		isChanges = false
		oldOrder = []
		e.stopPropagation()
	})

	function updateRelativeOrder(){
		let relativeOrder = 1
		isChanges = true
		$el.children('div').each(function (i, v) {
			let model = models.get($(v).attr(idDefinder))
			if(model && model.get('relativeOrder') != relativeOrder){
				model.set('relativeOrder', relativeOrder)
			}
			++relativeOrder
		});
	}

	function returnLastSavedOrder(){
		let sortedOldOrder = _.sortBy(oldOrder, 'relativeOrder')
		for (let i = 2; i <= sortedOldOrder[sortedOldOrder.length - 1].relativeOrder; ++i) {
			let findInOrder = sortedOldOrder.find((o) => { return o.relativeOrder == i })
			if (findInOrder) {
				let model = models.get(findInOrder.id)
				let $currentEl = $el.find(`[${idDefinder}="${model.get('id')}"]`).detach()
				$el.append($currentEl)
				model.set('relativeOrder', findInOrder.relativeOrder)
			}
		}
	}
}

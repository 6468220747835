import StateRecovery from './stateRecovery.js';
import TableState from './tableState.js';


export default class ReportedTableState extends TableState {

	apply() {
		const localStorageState = StateRecovery.get(this.localStorageKey) || {};
		localStorageState.columnsInfo && this.table.setColsInfo(localStorageState.columnsInfo);
	}

	mergeColumnSizes() {
		this.change((state) => {state.columnsInfo = this.table.getColsInfo();}, true);
	}
}

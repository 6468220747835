import TypeKind from '../../common/enums/typeKind';
import StringKind from '../../common/enums/stringKind';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import FieldKind from '../../common/enums/fieldKind';
import MultilingualString from '../../common/models/multilingualString';
import BaseCreateView from '../../common/views/baseCreateView';
import entityTypeService from '../../EntityType/service/entityTypeService';
import BaseSelect from '../../common/components/baseSelect';
import {addEntityType, newDataTypeDropdown, buildPopoversForAddType} from '../../EntityType/views/indexMainView'
import Types from '../../common/collections/types'
import Type, { getCurrentType } from '../../common/models/type'
import { translate } from '../../common/service/stringResourceService'
import { checkRequired, buildDefaultPopover } from '../../common/components/utils'

let CreateView = BaseCreateView.extend({

	events: {
		'click .addType': 'addType'
	},

	typeComparator: (a, b) => {
			if (a.isSystem < b.isSystem) {
				return -1;
			} else if (a.isSystem > b.isSystem){
				return 1;
			} else {
				return a.text.toLowerCase().localeCompare(b.text.toLowerCase());
			}
	},

	initialize: function (options) {
		this.options = options
		this.addShared = options.addShared;
		CreateView.__super__.initialize.apply(this, arguments);
		if (!this.addShared) {
			this.$fieldTypesDiv = $('#fieldTypesDiv');
			this.$fieldTypes = $('#fieldTypes');
			this.$isTransientDiv = $('#isTransientDiv');
			this.$isVirtualDiv = $('#isVirtualDiv');
			this.$isGdprSensitiveDiv = $('#isGdprSensitiveDiv');

			this.transientToggled = false
			entityTypeService.findAll()
				.then(data => {
					if (app.types) {
						// We need merging the types instead of simply replacing the list
						// The reason is that the service does not return the hidden types
						let typeIds = _.map(app.types.models, (t) => {return t.id});
						_.each(data, (item) => {
							if (_.indexOf(typeIds, item.id) == -1) {
								app.types.add(new Type(item));
							}
						})
					} else {
						app.types = new Types(data)
					}
					this.types = data;
					this.render();
				});
		} else {
			this.$sharedFields = $('#sharedFields');
			this.initSharedFieldsSelect();
		}
		this.model.on('change:isVirtual',(model, value) => {this.isFieldVirtualChanged.call(this, model, value)})
		if (getCurrentType().isExternal()) {
			this.model.on('change:isTransient', (model, value) => { this.checkIfShowMappedByForExternal.call(this, model, value) })
		}
		if (!getCurrentType().isTransient()) {
			this.model.on('change:isTransient',(model, value) => {this.onIsTransientChange.call(this, model, value)})
		}
		this.model.on('change:fieldType',(model, value) => {this.fieldTypeChanged.call(this, model, value)})
		$("#fieldNameInput").parent().find('input').on('input',() => {this.nameWasEdited = true;})
		if (getCurrentType().isExternal() && this.model.get('fieldKind') == FieldKind.REGULAR) {
			$('.dbName').show()
			$('#isDataSourceKeyDiv').show()
			$('#isAutoIncrementDiv').show()
			this.model.set('isDataSourceKey', false)
			this.model.set('isAutoIncrement', false)
		} else {
			$('#isDataSourceKeyDiv').hide()
			$('#isAutoIncrementDiv').hide()
		}
	},

	requiredCheck: function () {
		return checkRequired(this.model.toJSON(), this.$el)
	},

	render: function () {
		if (getCurrentType().isTransient()) {
			this.hideIsFieldTransient(true);
		}
		if (getCurrentType().isRegister()) {
			this.hideIsFieldTransient(false);
		}

		this.initDependOnKind();
		this.rebuildSelects();
		buildPopoversForAddType(this.$el)
	},

	isTypeTransient (typeId) {
		let type = app.types.get(typeId);
		return type && type.get('typeKind') === TypeKind.TRANSIENT
	},

	isTypeExternal (typeId) {
		let type = app.types.get(typeId);
		return type && type.get('typeKind') === TypeKind.EXTERNAL
	},

	isTransientToggle (state) {
		if (state) {
			this.inputs['isTransient'].disable()
			if (!this.model.get('isTransient')){
				this.model.set('isTransient', true)
				this.transientToggled = true
			}
		} else {
			this.inputs['isTransient'].enable()
			if (this.transientToggled){
				this.transientToggled = false
				this.model.set('isTransient', false)
			}
		}
	},

	onIsTransientChange() {
		this.rebuildSelect(this.$fieldTypes, this.createSelectDataForTypes(this.types))
	},

	checkIfShowMappedByForExternal() {
		if (this.model.get('fieldKind') == FieldKind.COLLECTION && !this.model.get('isTransient')
				&& this.model.get('fieldType') && app.types.get(this.model.get('fieldType')).isExternal()) {
			$('.mapedBy').show()
			buildMappedBySelect(this.model.get('fieldType'), this.model.get('name'), this.$el, this.model)
		} else {
			this.model.set('mappedById', null)
			$('.mapedBy').hide()
		}
	},

	isVirtualToggle (state) {
		if (!state) {
			this.inputs['isVirtual'].disable()
			if (this.model.get('isVirtual')){
				this.model.set('isVirtual', false)
			}
		} else {
			this.inputs['isVirtual'].enable()
		}
	},

	fieldTypeChanged (model, value) {
		if(!this.nameWasEdited) {
			let currentField = app.types.models.find(f => f.get('id') == value);
			if(currentField) {
				this.isVirtualToggle(currentField.get('typeKind') != TypeKind.EMBEDDED && currentField.get('typeKind') != TypeKind.TRANSIENT && currentField.get('typeKind') != TypeKind.EXTERNAL)
				model.set('name' , new MultilingualString(currentField.get('name')));
			}
		}
		let handleTypeId = (typeId) => {
			if (!model.get('isVirtual')) {
				if (this.isTypeTransient(typeId) || (this.isTypeExternal(typeId) && !getCurrentType().isExternal())){
					this.isTransientToggle(true)
				} else {
					this.isTransientToggle(false)
				}
			}
		}
		if (value) {
			handleTypeId(value)
		}
		if (!model.get('isTransient')) {
			handleTypeId(getCurrentType().id)
		}
		if (getCurrentType().isExternal()) {
			this.checkIfShowMappedByForExternal()
		}
	},

	isFieldVirtualChanged (model, value) {
		if (value) {
			this.isTransientToggle(true)
		} else {
			this.isTransientToggle(false)
		}
	},

	getType () {
		let type = app.model && app.types.get(app.model.id)
		if (!type){
			type = app.owner
		}
		return type || new Type()
	},

	initDependOnKind: function () {
		if (this.model.get('fieldKind') == FieldKind.DYNAMIC) {
			this.hideFieldType();
			this.hideIsFieldVirtual();
			this.hideIsGdprSensitive()
		} else {
			if (this.model.get('fieldKind') == FieldKind.COLLECTION && !this.options.model.get('hideVirtual') &&
					!(getCurrentType().isExternal())) {
				this.$isVirtualDiv.show();
				this.hideIsGdprSensitive()
			} else {
				this.hideIsFieldVirtual();
			}
			this.$fieldTypesDiv.show();
			this.rebuildSelect(this.$fieldTypes,
			this.createSelectDataForTypes(this.types));
		}
	},

	hideIsFieldTransient: function (value) {
		if (!value) {
			value = false;
		}
		this.model.set('isTransient', !!value)
		this.$isTransientDiv.hide();
	},

	addType (event) {
		addEntityType(event, {
			modalInstance: app.createModal2,
			afterCreateOnSuccess: (type) => {
				app.types.push(type)
				if (this.checkType(type)){
					this.types.unshift(type)
					this.rebuildSelects()
					this.model.set('fieldType',type.id)
				}
			}
		})
	},

	hideIsFieldVirtual: function (value) {
		if (!value) {
			value = false;
		}
		this.model.set('isVirtual', !!value, {silent:true})
		this.$isVirtualDiv.hide();
	},

	hideIsGdprSensitive: function () {
		this.model.set('isGdprSensitive', !true, {silent:true})
		this.$isGdprSensitiveDiv.hide();
	},

	hideFieldType: function () {
		this.$fieldTypes.val(null).trigger('change');
		this.$fieldTypesDiv.hide();
	},

	rebuildSelects: function () {
		this.rebuildSelect(this.$fieldTypes,
			this.createSelectDataForTypes(this.types))
	},

	createSelectDataForTypes: function (data) {
		return _.chain(data)
						.filter(item => this.checkType(item) && !item.isHidden)
						.map(item => {
							return {
								id: item.id,
								text: (new MultilingualString(item.name)).getCurrentValue(),
								isSystem: item.isSystem,
								primitiveEntityType: item.primitiveEntityType,
								primitiveTypeProperties: item.primitiveEntityType && item.primitiveTypeProperties,
							}
						}).sort(this.typeComparator).value()
	},

	optionExists: function (select, val) {
		return select.find('option[value="' + val + '"]').length !== 0;
	},

	rebuildSelect: function (select, data) {
		var val = select.val();
		select.parent().find('.container').remove();
		new BaseSelect({
			el:select,
			data: data.map((value) => {
				if (value.text && value.isSystem) {
					value.text += '<span class="label table-label label-default" style="margin-left: 5px;">System</span>';
				}
				if(value.primitiveEntityType == PrimitiveEntityType.STRING && !value.primitiveTypeProperties.stringHasTranslations && value.primitiveTypeProperties.stringKind != StringKind.HTML) {
					value.text += '<span class="label table-label label-default" style="margin-left: 5px;">Deprecated</span>';
				}
				return value;
			}),
			comparator: this.typeComparator
		}).render();
		if (this.optionExists(select, val)) {
			select.val(val).trigger('change');
		}
	},

	checkType: function (item) {
		var kind = this.model.get('fieldKind');
		var itemType = item.typeKind;
		var isTransient = this.model.get('isTransient')

		if (itemType == TypeKind.REGISTER &&
			!getCurrentType().isRegister()) {
			return false;
		}
		if (kind == FieldKind.DYNAMIC) {
			return false;
		}
		if (kind == FieldKind.COLLECTION && itemType == TypeKind.PRIMITIVE) {
			return false;
		}

		/*if ((!isTransient && !getCurrentType().isExternal()) && item.primitiveEntityType === PrimitiveEntityType.SYSTEM_STRING && item.isSystem) {
			return false;
		}*/

			switch (getCurrentType().get('typeKind')) {
				case TypeKind.EMBEDDED:
					if (itemType == TypeKind.EMBEDDED) {
						return false;
					} else if (itemType == TypeKind.TRANSIENT && !isTransient) {
						return false;
					} else if (itemType == TypeKind.EXTERNAL && !isTransient) {
						return false;
					} else {
						return true;
					}
					break;
				case TypeKind.REGISTER:
					if ((itemType == TypeKind.TRANSIENT ||
						itemType == TypeKind.EXTERNAL ||
						itemType == TypeKind.EMBEDDED) &&
						!isTransient) {
						return false;
					} else if (itemType == TypeKind.PRIMITIVE &&
						(item.primitiveEntityType == PrimitiveEntityType.BINARY ||
						item.primitiveEntityType == PrimitiveEntityType.STRING)) {
						return false;
					} else {
						return true;
					}
					break;
				case TypeKind.DICTIONARY:
				case TypeKind.DOCUMENT:
				case TypeKind.REPORT:
					return true;
					break;
				case TypeKind.EXTERNAL:
					if (PrimitiveEntityType.getNonStandard().includes(item.primitiveEntityType) && !isTransient) {
						return false
					}
					if ( (kind == FieldKind.COLLECTION && (itemType == TypeKind.EXTERNAL || itemType == TypeKind.TRANSIENT)) ||
								( kind == FieldKind.REGULAR && (itemType == TypeKind.PRIMITIVE || itemType == TypeKind.EXTERNAL || itemType == TypeKind.TRANSIENT)) ) {
						return true
					} else {
						return false
					}
					break
				case TypeKind.TRANSIENT:
					if (!isTransient) {
						return false;
					} else {
						return true;
					}
					break;
			}
		return true;
	},

	initSharedFieldsSelect: function () {
		let data = jQuery.grep(app.sharedFields, function (sharedField) {
			let result = !sharedField.isPredefined;
			_.each(app.data.sharedFields, function (item) {
				if (item.id === sharedField.id) {
					result = false;
				}
			});
			sharedField.text = (new MultilingualString(sharedField.name)).getCurrentValue();
			return result;
		});
		new BaseSelect({
			el:this.$sharedFields,
			data: data
		});
	},

	getHTML: function () {
		if (!this.addShared) {
		 return `<div class="grid-1x-padding">
							<label for="name">${app.getResource('name')}</label>
							<span class="asterisk"> *</span>
							<a help-popover="name-description"><span class="help-image"/></a>
						</div>
						<div class="grid-2x-padding">
							<input id = "fieldNameInput" class="form-control" type="text" data-is-string-multilingual="short" data-field="name" data-required="true"/>
						</div>

						<div id="fieldTypesDiv">
							<div class="grid-1x-padding">
								<label for="fieldType">${app.getResource('type')}</label>
								<span class="asterisk"> *</span>
							</div>
							<div class="grid-2x-padding" style="display:flex">
								<select class="form-control" id="fieldTypes" placeholder="${app.getResource('select.field.type')}" search-placeholder="${translate('type.to.search')}" data-field="fieldType"
								data-required="${this.model.get('fieldKind') != FieldKind.DYNAMIC}">
								</select>
								<div style="margin-left:10px;display:flex;">
								<button  style="margin-right:-4px;border-right:0px solid white" class="btn btn-primary dropdown-toggle addType no-popover cropped-button-right" data-kind="DICTIONARY">
									<span class="glyphicon glyphicon-plus"></span>
									<span th:text="">Add new data type</span>
								</button>
								<div class="dropdown">
								<button style="border-left:1px solid #204d74" class="btn btn-primary dropdown-toggle cropped-button-left"
												data-toggle="dropdown">
									<span style="color:white;" class="caret"></span>
								</button>
								${newDataTypeDropdown()}
								</div>

								</div>
							</div>
						</div>

						<div class="grid-1x-padding dbName" style="display: none;">
							<label for="name">${app.getResource('db.name')}</label>
							<a help-popover="database-name"><span class="help-image"/></a>
						</div>
						<div class="grid-2x-padding dbName" style="display: none;">
							<input class="form-control" type="text" data-field="columnName"></input>
						</div>

						${checkboxesForExternal(translate('primary.key'), translate('auto.increment'))}

						<div id="isVirtualDiv" class="grid-1x-padding">
							<div class="checkbox checkbox-without-margin">
								<label for="isVirtual">
									<input id="isFieldVirtual" type="checkbox"  data-primitive-type='BOOLEAN' data-field="isVirtual"/>
									${app.getResource('is.virtual')}
								</label>
							</div>
						</div>

						<div id="isTransientDiv" class="grid-1x-padding">
							<div class="checkbox checkbox-without-margin">
								<label for="isTransient">
									<input id="isFieldTransient" type="checkbox" data-primitive-type='BOOLEAN' data-field="isTransient"/>
									${app.getResource('is.transient')}
									<a help-popover="shared-field-transient"><span class="help-image"/></a>
								</label>
							</div>
						</div>
						<div id="isGdprSensitiveDiv" class="grid-1x-padding">
							<div class="checkbox checkbox-without-margin">
								<label for="isGdprSensitive">
									<input id="isGdprSensitive" type="checkbox" data-primitive-type='BOOLEAN' data-field="isGdprSensitive"/>
									${app.getResource('is.gdpr.sensitive')}
									<a help-popover="shared-field-transient"></a>
								</label>
							</div>
						</div>

						<div class="grid-1x-padding mapedBy" style="display: none;">
							<label for="name">${app.getResource('mapped.by')}</label>
							<span class="asterisk"> *</span>
							<a help-popover="mapped-by"><span class="help-image"/></a>
						</div>
						<div class="grid-2x-padding mapedBy" style="display: none;">
							<select class="form-control" id="mappedBySelect" data-field="mappedById"></select>
						</div>
						`;
		}
		return `<select class="form-control" id="sharedFields"></select>`;
	},

	saveConfiguration: function () {
		if (!this.addShared) {
			let data = this.model.toJSON();
			return data;
		} else {
			let val = this.$sharedFields.val();
			var data = null;
			_.each(app.sharedFields, function (item) {
				if (item.id == val) {
					data = item;
				}
			});
			data = {id: data.id, isShared: data.isShared};
			return data;
		}
	}
});

export function buildMappedBySelect(fieldTypeId, fieldTypeName, $modalEl, model) {
	let dataForMappedBy = []
	app.allFieldsOfExternals.forEach((f) => {
		if ( f.ownerId == fieldTypeId && f.fieldType.id == getCurrentType().id ) {
			dataForMappedBy.push({
				id: f.id,
				text: MultilingualString.getCurrentValue(fieldTypeName) + ': ' + MultilingualString.getCurrentValue(f.name)
			})
		}
	})
	let mappedBySelect = new BaseSelect({
		el: $modalEl.find('#mappedBySelect'),
		model: model,
		modelAttr: 'mappedById',
		data: dataForMappedBy
	})
	mappedBySelect.render()
}

export let checkboxesForExternal = (primaryKeyText, autoIncrementText) => {
	return `<div id="isDataSourceKeyDiv" class="grid-1x-padding">
			<div class="checkbox checkbox-without-margin">
				<label for="isDataSourceKey">
					<input id="isDataSourceKey" type="checkbox" data-primitive-type='BOOLEAN' data-field="isDataSourceKey"/>
					${primaryKeyText}
					<a help-popover="primary-key"><span class="help-image"/></a>
				</label>
			</div>
		</div>
		<div id="isAutoIncrementDiv" class="grid-1x-padding">
			<div class="checkbox checkbox-without-margin">
				<label for="isAutoIncrement">
					<input id="isAutoIncrement" type="checkbox" data-primitive-type='BOOLEAN' data-field="isAutoIncrement"/>
					${autoIncrementText}
					<a help-popover="auto-increment"><span class="help-image"/></a>
				</label>
			</div>
		</div>`
}

export default CreateView;

/* @flow */

import Entity from './entity';
import entityManager from '../components/entityManager';
import States from '../enums/states';

const EntityReference = Entity.extend({

	async _fetch() {
		const fullObject = await entityManager.fetchById(this.entityTypeId, this.id, this.get('version'));
		this.set(fullObject);
		this.unset('clientState', {silent: true});
		this.unset('isReference', {silent: true});
	},

	_initializeFields() {

	}
});

EntityReference.fromJSON = function (obj, entityTypeId): Entity {
	return new EntityReference(obj, {entityTypeId});
};

export default EntityReference;

/* @flow */

import Field from '../models/field';
import EntitiesWithOwner from './entitiesWithOwner';
import MultilingualString from '../models/multilingualString';

export default class Fields extends EntitiesWithOwner<Field> {

	initialize() {
		super.initialize.apply(this, arguments);
		this.model = Field;
		this.comparator = false;
	}

	fieldName(fieldId: string): string {
		const field = this.get(fieldId);
		return (field && (new MultilingualString(field.get('name'))).getCurrentValue()) || app.messages.missingFieldError;
	}

	fieldsPath(obj: any, idPath: string, language: any): string {
		let namesArray: any[] = [];
		if (obj) {
			namesArray.push(obj);
		}
		if (idPath) {
			let idArray: string[] = idPath.split('.');
			for (let i: number = 0, l: number = idArray.length; i < l; ++i) {
				let id: string = idArray[i];
				let field: Field = this.get(id);
				if (field) {
					let name: string = (new MultilingualString(field.get('name'))).getCurrentValue(language || null);
					if (field.isCollection()) {
						name = '@' + name;
					}
					namesArray.push(name);
				} else {
					return app.messages.missingFieldError;
				}
			}
		}
		return namesArray.join('.');
	}
}

import utils from '../components/utils';
import FieldInput from '../../common/components/fieldInput';
import MultilingualInputField from '../components/multilingualInputField.js';
import MultilingualString from '../models/multilingualString';
import CheckboxInput from '../../common/components/checkboxInput'
import {buildHelpPopover} from '../../help/docsResource' 

let BaseUpdateView = Backbone.View.extend({

	initialize: function (options) {
		this.setElement($(options.$el));
		this.html = options.html || '';
		this.$el.append(this.getHTML());
		this.inputs = {};
		this.selects = {};

		this.initializeMultilingualInputs();
		this.initializeInputs();
		this.initializeCheckboxes()
		_.each(this.inputs, input => input.render());
		this.$el.find('[help-popover]').each(function (a, elem) {
			buildHelpPopover((elem))
		})
	},


	initializeMultilingualInputs: function() {
		var that = this;
		this.$el.find('input[data-is-string-multilingual]')
			.each(function(event) {
				var model = utils.getChildModel(that.model, $(this));
				var options = that.model.get(model.attr) &&
					that.model.get(model.attr).toJSON();
				that.model.set(model.attr, new MultilingualString(options));
				var ModelType = that.models && that.models[model.attr] || MultilingualInputField;
				var input = new ModelType({
					model: model.model,
					modelAttr: model.attr,
					el: $(this)
				});
				that.inputs[model.attr] = input;
		});
	},

	initializeCheckboxes: function () {
		var that = this;
		this.$el.find('input[data-field][type=checkbox]').each(function (event) {
			var model = utils.getChildModel(that.model, $(this))
			var input = new CheckboxInput({
				model: model.model,
				modelAttr: model.attr,
				el: this
			})
			that.inputs[model.attr] = input
		})
	},

	initializeInputs: function () {
		var that = this;
		this.$el.find('input[data-field], textarea[data-field]')
			.not('.multilingual input, .multilingual textarea')
			.not('input[data-is-string-multilingual], textarea[data-is-string-multilingual]')
			.each(function (event) {
				var model = utils.getChildModel(that.model, $(this));
				var ModelType = that.models && that.models[model.attr] || FieldInput;
				var input = new ModelType({
					model: model.model,
					modelAttr: model.attr,
					el: this
				});
				that.inputs[model.attr] = input;
			});
	},

	getHTML: function () {
		return this.html || `<div class="modal-component">
							<label for="name">${app.getResource('name')}</label>
							<input class="form-control" type="text" data-is-string-multilingual="short" data-field="name"/>
						</div>`;
	},

	getCurrentConfiguration: function () {
		return this.model.toJSON();
	}
});

export default BaseUpdateView;

/* @flow */
import  utils from '../components/utils';
import { Service } from './service';

class EntityInstanceService {

  getPrefix(typeId){
    return app.builderMode ? 'builder' : `entity${typeId}`;
  }

  getDeleteSelectedUrl(typeId) {
    return app.urls.home + this.getPrefix(typeId) + '/deleteSelected';
  }

  getDependeciesUrl(typeId) {
    return app.urls.home + this.getPrefix(typeId) + '/dependencies' ;
  }
}

export let entityInstanceService = new EntityInstanceService();

/* @flow */

import ChronoField from '../enums/chronoField';
import ChronoUnit from '../enums/chronoUnit';
import LocalDateTime from './localDateTime';

import type { Temporal, TemporalAccessor } from '../declarations';
import type ZoneId from './zoneId';

import {
	DAYS_PER_WEEK,
	NANOS_PER_MILLI,
	NANOS_PER_SECOND,
	HOURS_PER_DAY,
	MINUTES_PER_HOUR,
	SECONDS_PER_MINUTE,
	SECONDS_PER_HOUR
} from '../constants';

import { checkValid, checkUnitIsSupported } from '../utils';

/**
 * Serilized form:
 *   hour: byte
 *   minute: byte
 *   second: byte
 *   nano: int
 */

export default class LocalTime {

	hour: number;
	minute: number;
	second: number;
	nano: number;

	/**
	 * Private constructor
	 */
	constructor(hour: number, minute: number, second: number, nano: number) {
		checkValid(HOURS_PER_DAY, hour);
		checkValid(MINUTES_PER_HOUR, minute);
		checkValid(SECONDS_PER_MINUTE, second);
		checkValid(NANOS_PER_SECOND, nano);
		this.hour = hour;
		this.minute = minute;
		this.second = second;
		this.nano = nano;
	}

	static of(hour: number, minute: number, second?: number = 0, nanoOfSecond?: number = 0): LocalTime {
		return new LocalTime(hour, minute, second, nanoOfSecond);
	}

	static ofNanoOfDay(nanoOfDay: number): LocalTime {
		const nano = nanoOfDay % NANOS_PER_SECOND;
		const secondOfDay = Math.floor(nanoOfDay / NANOS_PER_SECOND);
		const second = secondOfDay % SECONDS_PER_MINUTE;
		const minuteOfDay = Math.floor(secondOfDay / SECONDS_PER_MINUTE);
		const minute = minuteOfDay % MINUTES_PER_HOUR;
		const hour = Math.floor(minuteOfDay / MINUTES_PER_HOUR);
		return LocalTime.of(hour, minute, second, nano);
	}

	static ofSecondOfDay(secondOfDay: number): LocalTime {
		const second = secondOfDay % SECONDS_PER_MINUTE;
		const minuteOfDay = Math.floor(secondOfDay / SECONDS_PER_MINUTE);
		const minute = minuteOfDay % MINUTES_PER_HOUR;
		const hour = Math.floor(minuteOfDay / MINUTES_PER_HOUR);
		return LocalTime.of(hour, minute, second, 0);
	}

	static fromJSON(value: ?any): ?LocalTime {
		if (value == null) {
			return null;
		}
		return LocalTime.of(value.hour, value.minute, value.second, value.nano);
	}

	static from(temporal: TemporalAccessor): LocalTime {
		const h = temporal.get(ChronoField.HOUR_OF_DAY);
		const m = temporal.get(ChronoField.MINUTE_OF_HOUR);
		const s = temporal.get(ChronoField.SECOND_OF_MINUTE);
		const n = temporal.get(ChronoField.NANO_OF_SECOND);
		return LocalTime.of(h, m, s, n);
	}

	static now(zone: ZoneId): LocalTime {
		if (zone) {
			return LocalDateTime.now(zone).toLocalTime();
		}
		const date = new Date();
		return new LocalTime(
			date.getHours(),
			date.getMinutes(),
			date.getSeconds(),
			date.getMilliseconds() * NANOS_PER_MILLI);
	}

	toJSON() {
		return {
			hour: this.hour,
			minute: this.minute,
			second: this.second,
			nano: this.nano
		};
	}

	toMoment():moment$Moment {
		return global.moment.utc({
			hour: this.hour,
			minute: this.minute,
			second: this.second,
		});
	}

	toSecondOfDay(): number {
		const h = this.hour * SECONDS_PER_HOUR;
		const m = this.minute * SECONDS_PER_MINUTE;
		const s = this.second;
		return h + m + s;
	}

	toNanoOfDay(): number {
		const s = this.toSecondOfDay() * NANOS_PER_SECOND;
		return s + this.nano;
	}

	static fromMoment(moment: moment$Moment): LocalTime {
		return LocalTime.of(moment.hour(), moment.minute(), moment.second());
	}

	getHour(): number {
		return this.hour;
	}

	getMinute(): number {
		return this.minute;
	}

	getNano(): number {
		return this.nano;
	}

	getSecond(): number {
		return this.second;
	}

	get(field: ChronoField): number {
		switch (field) {
			case ChronoField.NANO_OF_SECOND: return this.nano;
			case ChronoField.SECOND_OF_MINUTE: return this.second;
			case ChronoField.MINUTE_OF_HOUR: return this.minute;
			case ChronoField.HOUR_OF_DAY: return this.hour;
		}
		throw new Error('Unsupported field');
	}

	withNano(nanoOfSecond: number): LocalTime {
		return new LocalTime(this.hour, this.minute, this.second, nanoOfSecond);
	}

	plus(amount: number, unit: ChronoUnit): LocalTime {
		checkUnitIsSupported(this, unit);
		if (unit == ChronoUnit.MILLIS) {
			throw new Error('Not implemented');
		}
		const moment = this.toMoment().add(amount, unit.toMoment());
		const time = LocalTime.fromMoment(moment);
		time.nano = this.nano;
		return time;
	}

	until(endExclusive: Temporal, unit: ChronoUnit): number {
		if (unit == ChronoUnit.NANOS) {
			const end = LocalTime.from(endExclusive);
			return end.toNanoOfDay() - this.toNanoOfDay();
		} else {
			throw new Error('Not implemented');
		}
	}

	compareTo(other: LocalTime): number {
		const h = this.hour - other.hour;
		if (h !== 0) {
			return h;
		}
		const m = this.minute - other.minute;
		if (m !== 0) {
			return m;
		}
		const s = this.second - other.second;
		if (s !== 0) {
			return s;
		}
		return this.nano - other.nano;
	}

	equals(other: ?any): boolean {
		if (other == null) {
			return false;
		}
		if (!(other instanceof LocalTime)) {
			return false;
		}
		return this.hour === other.hour &&
				this.minute === other.minute &&
				this.second === other.second &&
				this.nano === other.nano;
	}

	isAfter(other: LocalTime): boolean {
		return this.compareTo(other) > 0;
	}

	isBefore(other: LocalTime): boolean {
		return this.compareTo(other) < 0;
	}

	isSupported(field: ChronoField | ChronoUnit): boolean {
		if (field instanceof ChronoField) {
			return LocalTime.supportedFields.indexOf(field) > -1;
		}
		if (field instanceof ChronoUnit) {
			return LocalTime.supportedUnits.indexOf(field) > -1;
		}
		throw new Error('Not supported type of the argument');
	}

	static supportedFields = [
		ChronoField.NANO_OF_SECOND,
		ChronoField.NANO_OF_DAY,
		ChronoField.MICRO_OF_SECOND,
		ChronoField.MICRO_OF_DAY,
		ChronoField.MILLI_OF_SECOND,
		ChronoField.MILLI_OF_DAY,
		ChronoField.SECOND_OF_MINUTE,
		ChronoField.SECOND_OF_DAY,
		ChronoField.MINUTE_OF_HOUR,
		ChronoField.MINUTE_OF_DAY,
		ChronoField.HOUR_OF_AMPM,
		ChronoField.CLOCK_HOUR_OF_AMPM,
		ChronoField.HOUR_OF_DAY,
		ChronoField.CLOCK_HOUR_OF_DAY,
		ChronoField.AMPM_OF_DAY
	];

	static supportedUnits = [
		ChronoUnit.NANOS,
		ChronoUnit.MICROS,
		ChronoUnit.MILLIS,
		ChronoUnit.SECONDS,
		ChronoUnit.MINUTES,
		ChronoUnit.HOURS,
		ChronoUnit.HALF_DAYS
	];

	static getMethodSupportedEnumValues = LocalTime.supportedFields;
}

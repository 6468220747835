/* @flow */
import utils from '../../common/components/utils';
import { Service } from '../../common/service/service';

class EntityTypeService extends Service {

  prefix(){
    return 'entityType';
  }

}

export default new EntityTypeService();

import utils from './utils.js';
import StateRecovery from './stateRecovery.js';
import { applicationState } from '../components/applicationState';
import LocalStorageKeysService from '../service/localStorageKeysService'

export default class TableState {

	constructor(table) {
		this.table = table;
		this.localStorageKey = LocalStorageKeysService.buildCollectionKey(table.getStorageId())
		this.urlKey = table.getStorageId();
	}

	applicationState() {
		if (app.builderMode == true) {
			return applicationState
		} else {
			if (this.table.context) {
				return this.table.context.applicationState
			} else { 
				return applicationState
			}
		}
	}
	
	applyFieldsToShow() {
		const localStorageState = StateRecovery.get(this.localStorageKey) || {}
		if (localStorageState.fieldsToShow) {
			this.table.setFieldsToShow(localStorageState.fieldsToShow)
		}		
	}

	apply() {
		const localStorageState = StateRecovery.get(this.localStorageKey) || {};
		const urlState = this.applicationState().get(this.urlKey) || {};
		this.table.filter(urlState.filters || localStorageState.filters || [], urlState.filters && urlState.filters.length !== 0);
		urlState.filters && this.setFilters(urlState.filters);
		this.table.setExtendedMode(localStorageState.extendedmode || false);
		if (localStorageState.fieldsToShow) {
			this.table.setFieldsToShow(localStorageState.fieldsToShow)
		}
		if (this.table.setSortingToModel) {
			if (urlState.sort) {
				this.table.setSortingToModel(urlState.sort)
			}	else if (localStorageState.sort) {
				this.table.setSortingToModel(localStorageState.sort)
			}
		}
	}

	change(fun, onlyLocalStorage) {
		StateRecovery.change(this.localStorageKey, fun);
		!onlyLocalStorage && this.applicationState().change(this.urlKey, fun);
	}

	delete(field, onlyLocalStorage) {
		StateRecovery.delete(this.localStorageKey, field);
		!onlyLocalStorage && this.applicationState().delete(this.urlKey, field);
	}

	mergeColumnSizes(sizes) {
		this.change((state) => {state.columnSizes = sizes;}, true);
	}

	setFilters(filters) {
		if (!filters || filters.length === 0) {
			this.delete('filters');
		} else {
			this.change((state) => {state.filters = filters;});
		}
	}

	setExtendedMode(isExtended) {
		this.change((state) => {
			state.extendedmode = isExtended;
		})
	}

	addFilter(filter) {
		this.change((state) => {
			state.filters = state.filters || [];
			if (!_.find(state.filters, (f) => {return _.isEqual(f, filter);})) {
				state.filters.push(filter);
			}
		});
	}

	setSorting(sort) {
		this.change((state) => {
			state.sort = sort
		})
	}

	setFieldsToShow (fields) {
		this.change((state) => {
			state.fieldsToShow = fields
		})
	}

	removeFilter(filter) {
		this.change((state) => {
			state.filters = state.filters || [];
			state.filters = _.filter(state.filters, (f) => {
				return !_.isEqual(f, filter);
			});
			if (state.filters.length === 0) {
				this.delete('filters');
			}
		});
	}

	clearFiltersAndOrder() {
		this.delete('order');
		this.delete('search');
		this.delete('filters');
	}
}

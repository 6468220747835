<template>
  <span v-html="stringView"></span>
</template>

<script>
export default {
  props: ['value', 'viewId','showFunc'],
  data: function () {
    return {
      stringView: ''
    }
  },
  watch: {
    'value.id': function () {
      this.fetch()
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    fetch () {
      if (!this.value) {
        this.stringView = '-'
      } else {
        this.stringView = app.getResource('loading.string.view')
        app.entityManager.fetchStringView(this.viewId, this.value.id).then((result) => {
          if (!this.value){
            this.stringView = '-'
          }else{
            this.stringView = this.showFunc(result,this.value)
          }
        }).catch((e) => {
          console.error(e)
          this.stringView = app.getResource('failed.to.load.string.view')
        })
      }
    }
  }
}
</script>


var BaseModel = Backbone.Model.extend({

	bubbleMap: {
		'change': 'change'
	},

	initialize: function (o) {
		this.on('change', this._onChange, this);
		for (var prop in o) {
			if (o.hasOwnProperty(prop)) {
				var ref = o[prop];
				if (_.isArray(ref)) {
					if (ref.length && !_.isObject(ref[0])) {
						this.set(prop, ref);
					} else {
						let BaseCollection = require('../collections/baseCollection.js').default
						var collection = new BaseCollection(ref);
						this.set(prop, collection);
					}
				} else if (_.isObject(ref) && ref != null) {
					this.set(prop, new BaseModel(ref));
				}
			}
		}
	},

	_onChange: function () {
		var changedKeys = _.keys(this.changedAttributes());
		_.each(changedKeys, attr => {
			var oldValue = this.previous(attr);
			if (oldValue instanceof Backbone.Model ||
					oldValue instanceof Backbone.Collection) {
				oldValue.off('all', this.onChange);
			}
			var newValue = this.get(attr);
			if (newValue instanceof Backbone.Model ||
					newValue instanceof Backbone.Collection) {
				newValue.off('all', this.onChange);
				newValue.on('all', this.onChange, this);
			}
		})
	},

	onChange: function (eventName, model) {
		if (this.bubbleMap[eventName]) {
			this.trigger(this.bubbleMap[eventName], model);
		}
	},

	toJSON: function () {
		return _.chain(this.attributes).clone()
			.mapObject(function (val, key) {
				if (_.isObject(val)) {
					if (val.toJSON) {
						return val.toJSON();
					}
				}
				return val;
			}).value();
	},

	toServerJSON: function () {
		return _.chain(this.attributes).clone()
			.mapObject(function (val, key) {
				if (_.isObject(val)) {
					if (val.toServerJSON) {
						return val.toServerJSON();
					}
					if (val.toJSON) {
						return val.toJSON();
					}
				}
				return val;
			}).value();
	}

});

export default BaseModel;

export default class TaskState {

	static WAITING = 'WAITING';
	static RUNNING = 'RUNNING';
	static DONE = 'DONE';

	static max(s1, s2){
		let order = {};
		if (!s1 && !s2){
			return this.WAITING;
		}
		if (!s1) {
			return s2;
		}
		if (!s2){
			return s1;
		}
		order[this.WAITING] = 0;
		order[this.RUNNING] = 1;
		order[this.DONE] = 2;
		return order[s1] < order[s2] ? s2 : s1;
	}

	static getAll() {
		return [
			this.WAITING,
			this.RUNNING,
			this.DONE
		]
	}
};

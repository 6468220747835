import TypeKind from '../../common/enums/typeKind';
import FormItemKind from '../../common/enums/formItemKind';
import FieldKind from '../../common/enums/fieldKind';
import BaseUpdateView from '../../common/views/baseUpdateView';
import MultilingualString from '../../common/models/multilingualString';
import { multilingualStringService } from '../../common/service/multilingualStringService';
import Constants from '../../common/models/constants';
import BaseModel from '../../common/models/baseModel.js';
import {getPresets} from '../components/presets/presets'
import utils from '../../common/components/utils';
import EventKey from "../../common/enums/EventKey";
import {userPilotByDocsResource} from "../../help/docsResource";
var ToolBar = Backbone.View.extend({

	events: {
		'click .preset-group': '_togglePresetGroup',
		'click .removeField': 'removeField',
		'click .renameField': 'renameField',
		'keyup #search-field': 'searchField',
		'dblclick li.draggable:not(.preset-group)': 'showDraggingTooltip',
		'click li.draggable.focused': 'showDraggingTooltip',
		'click li.draggable:not(.preset-group)': 'updateDraggableFocus'
	},

	el: '#toolbar',

	initialize(options) {
		this.model = options.model;
		this.draggableOnce = '.draggable-once';
		this.draggableMultiple = '.draggable-multiple';
		this.attachedFields = options.attachedFields;

		this.listenTo(this.attachedFields, 'add', this.rebuildFields);
		this.listenTo(this.attachedFields, 'remove', this.rebuildFields);

		$('#attentionModal').on('hidden.bs.modal', () => {
			this.rebuildFields();
		});
		this.draggableOnceKinds = this.$('.draggable-once')
				.map((i, el) => {
					return $(el).data('kind');
				})
				.get();
	},

	render: function () {

		this.buildPresets();
		this.rebuildFields();
		this.toggleDraggableOnceKinds();
		this.openAccordion();
	},

	openAccordion() {
		let fields = this._getFields();
		let id = fields.find(f => !f.isSystem())
				? 'fields'
				: 'basicfields';
		this.$(`#${id}bar-wrapper`).addClass('in');
		this.$el.find(`#${id}-box .sidebar-header`).attr('aria-expanded', true);
	},


	toggleDraggableOnceKinds() {
		_.each(this.draggableOnceKinds, kind => {
			const item = this.model.findItemByKind(kind);
			if (item != null) {
				this.hideComponentWithKind(kind);
			} else {

			}
		});
	},

	hideComponentWithKind(kind) {
		this.$('#componentsBox')
				.find(`[data-kind="${kind}"]`)
				.hide();
	},

	showComponentWithKind(kind) {
		this.$('#componentsBox')
				.find(`[data-kind="${kind}"]`)
				.show();
	},

	_buildPresetDom(preset, idx, subIdx) {
		const li = $('<li class="draggable">')
							.attr('data-kind', 'PRESET')
							.attr('data-preset-idx', idx);

		if (subIdx !== undefined) {
			li.attr('data-preset-sub-idx', subIdx);
		}

		$('<a href="javascript:void(0);">').appendTo(li)
			.append(`<span class="${preset.icon}" />`)
			.append(`<span class="draggable_text">${preset.name}</span>`);

		return li;
	},

	_presetFilter(preset) {
		let viewKind = app.view.viewKind;
		let isExternal = app.types.get(app.view.ownerId).isExternal()
		return (!isExternal || (isExternal && preset.forExternals)) && (!preset.viewKinds || preset.viewKinds.indexOf(viewKind) > -1)
	},

	buildPresets () {
		const container = this.$('#basicfieldsbar');
		container.html('');
		_.each(getPresets(), (preset, idx) => {
			if (this._presetFilter(preset)) {
				const li = this._buildPresetDom(preset, idx, preset.isGroup ? (preset.customId || 0) : undefined)
					.appendTo(container);
				if (preset.isGroup) {
					li.addClass('preset-group');
					li.find('.draggable_text').append(`
						<span class="pull-right">
							<span class="closed-icon fa fa-angle-right" />
							<span class="open-icon fa fa-angle-down" />
						</span>
						`);
					const ul = $('<ul class="nav nav-dark nav-pills nav-stacked hidden" />');
					_.each(preset.items, (p, subIdx) => {
						if (this._presetFilter(p)) {
							const subLi = this._buildPresetDom(p, idx, subIdx);
							subLi.addClass('preset-option');
							ul.append(subLi);
						}
					});
					container.append(ul);
				}
			}
		});
	},

	_togglePresetGroup(e) {

			let clicked = $(e.currentTarget);
			clicked.toggleClass('open-preset-group');
			clicked.next().toggleClass('hidden');
	},

	_buildFieldOptions(element, field) {
		let fieldType = field.type();
		let isAttached = this.attachedFields.get(field.id) != null;

		element.append(`<span class="pull-right dropdown field-options">
								<a href="#" class="dropdown-toggle" data-toggle="dropdown">
									<span class="glyphicon glyphicon-option-horizontal expand">
								</a>
								<ul class="dropdown-menu">
									<li class="toggle-attach">
										<a href="javascript:void(0)">
											${app.getResource(isAttached ? 'detach.field' : 'attach.field')}
										</a>
									</li>
								</ul>
						</span>`);
		this.initHandlingFieldOptions(element);

		if (isAttached) {
				element.append(`<span class="label table-label label-default">
										${app.getResource('attached')}
									</span>`);
		}

		element.find('li.toggle-attach').click(() => {
			if (isAttached) {
				this.attachedFields.remove(field.id);
			} else {
				this.attachedFields.add({
						id : field.id
				});
			}
		});
		let text, url;
		if (!fieldType || fieldType.isPredefined()) {
			text = multilingualStringService.format(app.getResource('field.type'),
			[fieldType ?
				MultilingualString.fromJSON(fieldType.name()).getCurrentValue() :
				app.getResource('dynamic')
			])
			text = text.toHTML();
			url = '';
		} else {
			text = app.getResource('open.field.type');
			url = app.urls.open(Constants.ID_TYPE_TYPE, fieldType.id);
		}
		element.find('ul.dropdown-menu').append(`<li>
					<a ${url? ('href="' + url + '" '+'target="_blank"'):''}>
						${text}
					</a>
				</li>
				<li class="removeField" field-id=${field.id}><a>Delete</a></li>
				<li class="renameField" field-id=${field.id}><a>Rename</a></li>`);

	},

	initHandlingFieldOptions(element) {
		element.find('.field-options').click(e => {
			let target = $(e.currentTarget);
			target.closest('.draggable').removeClass('focused');
			target.on('show.bs.dropdown', () => {
				this.fieldOptionsOpened = true;
			});
			target.on('hidden.bs.dropdown', () => {
				this.fieldOptionsOpened = false;
			});
		})
	},

	removeField(event) {
		const fieldId = $(event.currentTarget).attr('field-id');
		utils.confirmDelete([fieldId], app.urls.dependencies(Constants.ID_FIELD_OWNER, app.ownerId),  app.urls.delete(Constants.ID_FIELD_OWNER, null, app.ownerId), null, () => {
			app.fields.remove(fieldId);
			this.$(`#fieldsbar li[data-field-id="${fieldId}"]`).remove();
			let amount = this._getFields().length;
			this.updateAmountOfFields(amount);
		});
	},

	renameField(event) {
		const fieldId = $(event.currentTarget).attr('field-id');
		let model = new BaseModel()
		model.set('id', fieldId);
		model.set('name', new MultilingualString(app.fields.get(fieldId).get('name')));
		app.editModal.show({
			url: app.urls.fieldUpdate + '/' + fieldId,
			headerResource: `edit.field`,
			model: model,
			view: BaseUpdateView,
			afterUpdateOnSuccess: (clientData) => {
				app.fields.get(fieldId).set('name' , clientData.name)
				this.rebuildFields();
			}
		});
	},

	rebuildFields: function (keyword) {
		this.$('#fieldsbar').html('');
		let fields = this._getFields();
		fields = _.sortBy(fields, field => field.get('relativeOrder'));
		if(typeof keyword != "string") {
			keyword = null
		}
		fields = _.filter(fields, field => {
			return keyword == null || keyword == "" || (new MultilingualString(field.name())).getCurrentValue().toLowerCase().indexOf(keyword.toLowerCase()) != -1
		})

		_.each(fields, field => {

			let fieldType = field.type();
			var li;
			let a;
			if (field.fieldInAncestorView) {
				li = $('<li class="disabled">');
			} else if (app.owner.kind() == TypeKind.EMBEDDED && field.kind() == FieldKind.COLLECTION){
				li = $('<li>')
			} else {
				li = $('<li class="draggable">');
			}
			li.attr('data-kind', FormItemKind.FIELD);
			li.attr('data-field-id', field.id);
			if (field.fieldInDescendantView) {
				a = $('<a href="javascript:void(0);">').appendTo(li);
					a.append($('<span class="draggable_text">')
					.text((new MultilingualString(field.name())).getCurrentValue() + ' <in descendant>')
					.attr('title', new MultilingualString(field.name()).getCurrentValue()));
			} else {
				a = $('<a href="javascript:void(0);">').appendTo(li);
				a.append($('<span class="draggable_text">')
							.text((new MultilingualString(field.name())).getCurrentValue())
							.attr('title', new MultilingualString(field.name()).getCurrentValue()));
			}
			this._buildFieldOptions(a, field);
			this.$('#fieldsbar').append(li);
		});
		this.makeDraggable();
		this.$('.scrollable').perfectScrollbar('update')
		this.updateAmountOfFields(fields.length);
	},

	updateAmountOfFields: function(amount) {
		if(amount > 0) {
			this.$('.amount-of-fields').show();
		} else {
			this.$('.amount-of-fields').hide();
		}
		if($("#search-field").length && $("#search-field").val().length == 0 && amount < 10) {
			$("#toolbar-search-fields").hide()
		} else {
			$("#toolbar-search-fields").show()
		}
		this.$('.amount-of-fields').text(amount);
	},

	showDraggingTooltip: function () {
		if (!this.fieldOptionsOpened) {
			userpilot && userpilot.trigger(userPilotByDocsResource('dragging-element-web-page-editor'));
		}
	},

	updateDraggableFocus: function (event) {
		let item = $(event.currentTarget);
		if (!item.hasClass('focused')) {
			this.$el.find('.draggable.focused').removeClass('focused');
			item.addClass('focused');
		}
	},

	searchField: function(event) {
		this._searchField(event.target.value);
	},
	_searchField: function(keyword) {
		this.rebuildFields(keyword);
	}

});

export default ToolBar;

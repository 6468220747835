import PropertyType from '../../common/enums/propertyType.js';
import PropertyKey from '../../common/enums/propertyKey.js';
import FormOption from '../../common/enums/formOption.js';
import BlockKind from '../../common/enums/blockKind.js';
import ViewKind from '../../common/enums/viewKind';
import BaseUpdateView from '../../common/views/baseUpdateView';
import BlockTrigger from '../../common/enums/blockTrigger.js';
import BlockType from '../../common/enums/blockType.js';
import FieldInput from '../../common/components/fieldInput.js';
import CheckboxInput from '../../common/components/checkboxInput.js';
import FieldTemporalAccesorInputProperties from '../../entity/components/fieldTemporalAccesorInputProperties.js';
import BaseSelect from '../../common/components/baseSelect.js';
import MultilingualInputField from '../../common/components/multilingualInputField.js';
import MultilingualHtmlInputField from '../../common/components/multilingualHtmlInputField.js';
import BaseForm from '../../common/views/baseForm.js';
import PropertyLabel from '../models/properties/propertyLabel.js';
import { PropertyGroups, PropertyGroupKeys } from '../../common/enums/propertyGroup.js';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import { initHelpPopovers } from '../../help/utils'
import PropertyDescription from '../models/properties/propertyDescription.js';
import {translate} from '../../common/service/stringResourceService'
import BaseModel from '../../common/models/baseModel.js';
import CreateBlock from '../../blocks/views/createView';
import CreateSharedStyle from '../../blocks/views/createSharedStyleModal';
import CreateTheme from '../../cssEditor/views/createTheme.js';
import CreateView from '../views/createView.js';
import utils from '../../common/components/utils.js';
import Constants from '../../common/models/constants';
import SharedStyle from '../../common/models/sharedStyle';
import MultilingualString from '../../common/models/multilingualString';
import DefaultProperties from '../models/properties/defaultProperties.js';
import { showConfirmModal } from '../../common/components/confirmModalVue'
import { buildBlockActions } from './fieldEventsUtils.js';
import TypeKind from '../../common/enums/typeKind.js';
import FieldKind from '../../common/enums/fieldKind.js';
import SeedInstancesList from './seedInstancesList';
import FormItemKind from '../../common/enums/formItemKind.js';
import ColorPicker from '@simonwep/pickr/dist/pickr.es5.min';
import ViewModifier from '../../common/enums/viewModifier';
import JsonViewItemFieldSerializeKind from '../../common/enums/jsonViewItemFieldSerializeKind.js';


var PropertiesBody = BaseForm.extend({

	initialize: function (options) {
		this.$el.empty()
		PropertiesBody.__super__.initialize.apply(this, arguments);
		this.model = options.model;
		this.fieldId = options.fieldId;
		this.instanceViewer = options.instanceViewer;
		this.elements = {};
		this.updateRenderType(options.primitiveEntityType)
		this.popoverPlacement = options.popoverPlacement;
		this.popoverContainer = options.popoverContainer;
		this.select2Container = options.select2Container;
		this.useGrouping = options.useGrouping;
		this.formItemKind = options.formItemKind
		this.viewKind = options.viewKind
		this.typeOfListView = options.typeOfListView
		if (this.typeOfListView) {
			this.viewKind = ViewKind.LIST
		}
	},

	onEnterKeyPressed(e) {
		let target = $(e.target);
		if (e.keyCode === 13) { // Enter
			if (target.data('is-string-multilingual') === 'short' ||
				target.data('type') === PropertyType.ICON ||
				target.data('type') === PropertyType.DOUBLE ||
				target.data('type') === PropertyType.DATETIME ||
				(target.data('type') === PropertyType.STRING && target.prop("tagName").toLowerCase() === 'input')) {
				const focusableElements = this.$el.find('input:not([data-locale], [type="range"]), ins, textarea, select');
				const currentIndex = [...focusableElements].findIndex(el => target.is(el));
				const newFocusIndex = (currentIndex + 1) % focusableElements.length;
				$(focusableElements[newFocusIndex]).focus();
			}
		}
	},

	render: function () {
		this.$el.addClass('panel-group');
		var that = this;
		let groups = {}
		this.rendering = true;
		if (this.useGrouping) {
			this.model.forEach(function (model) {
					if (model.get('visible') !== false) {
						if (model.get('group'))
							groups[model.get('group')] = 1;
						}
				});
			_.keys(groups).sort(function (a, b) {
				return PropertyGroups[a].order - PropertyGroups[b].order;
			}).forEach(function (group) {
				that.renderGroup(group);
			});
		}
		if (this.model) {
			this.model.models.sort(function (a, b) {
				return a.get('relativeOrder') - b.get('relativeOrder');
			}).forEach(function (model) {
				if (model.get('visible') !== false) {
					switch (model.get('type')) {
						case PropertyType.MLS:
							that.renderMLS(model);
							break;
						case PropertyType.HTML:
							that.renderHTML(model);
							break;
						case PropertyType.INT:
						case PropertyType.DOUBLE:
							that.renderPrimitiveNumber(model);
							break;
						case PropertyType.STRING:
							that.renderPrimitive(model);
							break;
						case PropertyType.SELECT:
						case PropertyType.MULTISELECT:
							that.renderSelect(model);
							break;
						case PropertyType.BOOLEAN:
							that.renderBoolean(model);
							break;
						case PropertyType.ICON:
							that.renderIconPicker(model);
							break;
						case PropertyType.SECTION:
							break;
						case PropertyType.BUTTON:
							that.renderButton(model);
							break;
						case PropertyType.DATETIME:
							that.renderDateTime(model);
							break;
						case PropertyType.COLOR:
							that.renderColorPicker(model);
							break;
						case PropertyType.FIELD_EVENTS:
							that.renderEvents(model);
							break;
						case PropertyType.TYPE_LINK:
							that.renderTypeLink(model);
							break;
						case PropertyType.SEED_INSTANCES:
							that.renderSeedInstaces(model);
					}
				}
			});
		}
		if (this.formItemKind == FormItemKind.BUTTON) {
			this.$el.find('#actionsgroupbox').addClass('in');
			this.$el.find('#actionspropertygroup').attr('aria-expanded', true);
		} else {
			this.$el.find('.group').first().addClass('in');
			this.$el.find('.sidebar-header').first().attr('aria-expanded', true);
		}
		this.rendering = false;
		initHelpPopovers()
		this.$el.parents('.scrollable').perfectScrollbar('update');
		if (this.elements[PropertyKey.FIELD_LABEL] && this.elements[PropertyKey.LABEL]) {
			this.listenTo(this.elements[PropertyKey.LABEL].msModel.get('translations'), 'change', this.changeLabelCheckboxState);
		}
		this.$el.keydown(this.onEnterKeyPressed.bind(this));
	},


	renderGroup: function(key) {
		let group = PropertyGroups[key];
		var card = $('<div>')
		.addClass('panel');
		if (app.owner.get('typeKind') == TypeKind.EMBEDDED && key == PropertyGroupKeys.EVENTS) {
			card.addClass('hidden');
		}
		let icon = group.icon ? `<span class="fa fa-${group.icon}"></span>` : '';
		var groupLabel = $('<div>')
		.addClass('sidebar-header cursor-pointer')
		.attr('id', key + 'propertygroup')
		.attr('data-toggle', 'collapse')
		.attr('href', `#` + key + `groupbox`)
		.attr('data-parent', '#item-properties')
		.attr('aria-expanded', false)
		.append(`<span class="group-header-text">
		${icon}
		<span>`+app.getResource(key.replace(/_/g, '.'))+`</span></span>`)
		.append('<span class="pull-right accordion-state fa fa-plus"></span>')
		.append('<span class="pull-right accordion-state fa fa-minus"></span>')
		.appendTo(card);
		if (key === PropertyGroupKeys.SEED_INSTANCES) {
			groupLabel.append(`<span class="sidebar-header-text amount-label amount-of-instances"></span>`)
		}
		var groupBox = $('<div>')
		.addClass('group collapse panel-collapse')
		.attr('id', `` + key + `groupbox`)
		.appendTo(card);
		if (key == PropertyGroupKeys.EVENTS && this.fieldId) {
			groupBox.addClass('fieldEvents');
		}
		this.$el.append(card);
	},

	setModel: function (model, primitiveEntityType, fieldId, formItemKind) {
		if (this.rendering) {
			console.warn('set model inside render !!!');
		} else {
			if (!_.isEqual(model.toJSON(), this.model.toJSON())) {
				this.save();
				this._destroyView();
				this.updateRenderType(primitiveEntityType)
				this.model = model;
				this.fieldId = fieldId;
				this.formItemKind = formItemKind
				this.render();
			} else if (!$('#item-properties').html().length) {
				this.render()
			}
		}
	},
	updateRenderType: function (primitiveEntityType) {
		if (primitiveEntityType){
			switch (primitiveEntityType) {
				case PrimitiveEntityType.DECIMAL:
				case PrimitiveEntityType.INTEGER:
				case PrimitiveEntityType.DOUBLE:
					this.renderType = 'number'
					break
				case PrimitiveEntityType.TIMESTAMP:
				case PrimitiveEntityType.LOCAL_DATE:
				case PrimitiveEntityType.LOCAL_TIME:
				case PrimitiveEntityType.LOCAL_DATE_TIME:
				case PrimitiveEntityType.DAY_OF_WEEK:
				case PrimitiveEntityType.MONTH:
				case PrimitiveEntityType.MONTH_DAY:
				case PrimitiveEntityType.YEAR:
				case PrimitiveEntityType.YEAR_MONTH:
				case PrimitiveEntityType.ZONE_OFFSET:
					this.renderType = 'time'
					break
				case PrimitiveEntityType.PERIOD:
				case PrimitiveEntityType.DURATION:
					this.renderType = 'period'
					break
				case PrimitiveEntityType.SYSTEM_STRING:
				case PrimitiveEntityType.STRING:
					this.renderType = 'string'
					break
				case PrimitiveEntityType.BINARY:
					this.renderType = 'binary'
					break
				case PrimitiveEntityType.BOOLEAN:
					this.renderType = 'boolean'
			}
		} else {
			this.renderType = null
		}
	},

	save: function () {
		_.each(this.elements, function (elem) {
			elem.reset();
		});
	},

	baseElementContainerRender: function (model,renderType) {
		var $div = $('<div>')
			.addClass('form-group')
			.attr('data-property-key', model.get('key'));
		if (model.get('group') && this.useGrouping) {
			$div.appendTo($(`#`+ model.get('group')+`groupbox`))
		}
		else {
			$div.appendTo(this.$el)
		}
		if (model.get('icon') )
				$div.append(`<span class="fa fa-${model.get('icon')}"></span> `);
		$('<label>')
			.appendTo($div)
			.attr('for', model.cid)
			.append("<span>"+PropertyLabel(model.get('key'), this.renderType, this.viewKind)+"</span>");
		var desc=PropertyDescription(model.get('key'), this.renderType, this.viewKind)
		if (desc){
			$('<a help-popover="'+desc+'"><span class="help-image"/></a>').appendTo($div)
		}
		return $div;
	},

	renderMLS: function (model) {
		var $div = this.baseElementContainerRender(model);
		var type = (model.get('size') == 'long') ? 'long' : 'short';
		var $el = $(type == 'long' ? '<textarea>' : '<input>').attr('type', 'text');
		$el.attr('data-is-string-multilingual', type) ;
		if (model.get('onlyButton') == 'true') {
			$el.attr('data-only-button', 'true');
		}
		if (model.get('disabled')) {
			$el.attr('disabled', 'disabled');
		}
		$el.appendTo($div)
			.attr('data-type', model.get('type'))
			.addClass('form-control');
		this.elements[model.get('key')] = new MultilingualInputField({
			el: $el,
			model: model,
			modelAttr: 'value'
		});
		this.elements[model.get('key')].render();
	},

	renderHTML: function (model) {
		var $div = this.baseElementContainerRender(model);
		let dontTranslate = this.model.get(PropertyKey.DONT_TRANSLATE)
		var type = 'long';
		if (dontTranslate && dontTranslate.get('value')){
			type = ''
		}
		this.elements[model.get('key')] = renderBaseHTML(model, $div, type, this.popoverPlacement, this.popoverContainer)
	},

	renderPrimitiveNumber: function (model) {
		var $div = this.baseElementContainerRender(model);
		var $el = $('<input>').attr('type', 'text');
		if (model.get('size') === 'long') {
			$el = $('<textarea>').attr('type', 'text');
		}
		$el.appendTo($div)
			.attr('data-type', model.get('type'))
			.addClass('numeric')
			.addClass('form-control');
		this.elements[model.get('key')] =	new FieldInput({
			el: $el,
			model: model,
			modelAttr: 'value'
		});
		this.elements[model.get('key')].render();
		const onInputNumeric = (e) => {
			if (e.target.value==""){
				e.target.value="";
			}
			var correctedValue="";
			var wasDot=false;
			for(let i=0;i<e.target.value.length;i++){
				let a=e.target.value[i];
				if (a>='0'&&a<='9'){
					correctedValue+=a;
				}else{
					if ((i==0)&&(a=='-')){
						correctedValue+=a;
					}
					if (!wasDot&&(a=='.')&&(i!=0)){
						if (!((i==1)&&(e.target.value[0]=='-'))){
							correctedValue+=a;
							wasDot=true;
						}
					}
				}
			}
			e.target.value=correctedValue;
		}
		$el.on('input',onInputNumeric);
	},

	renderPrimitive: function (model) {
		var $div = this.baseElementContainerRender(model);
		var $el = $('<input>').attr('type', 'text');
		if (model.get('size') === 'long') {
			$el = $('<textarea>').attr('type', 'text');
		}
		if (model.get('disabled')) {
			$el.attr('disabled', 'disabled');
		}
		var $div2 = $(`<div class="input-group-button-inside dropdown">`)
		$el.appendTo($div2).attr('data-type', model.get('type'))
			.addClass('form-control');
		$div2.appendTo($div)
		this.elements[model.get('key')] = new FieldInput({
			el: $el,
			model: model,
			modelAttr: 'value'
		});
		if(model.get('key') == PropertyKey.FIELD_NAME && this.viewKind != ViewKind.JSON) {
			$div2.append(`<div class="input-button"><button class = "btn btn-link soft-button dropdown-toggle">
				<span class = "fa fa-pencil"></span></button></div>`)
			let that = this
			let propertyModel = model
			$div2.find('button').on('click' , () => {
				let fieldId = this.fieldId;
				let modelClone = new BaseModel()
				modelClone.set('id', fieldId);
				modelClone.set('name', new MultilingualString(app.fields.get(fieldId).get('name')));
				app.editModal.show({
					url: app.urls.updateFieldName + fieldId,
					headerResource: `edit.field`,
					model: modelClone,
					view: BaseUpdateView,
					afterUpdateOnSuccess: (clientData) => {
						app.fields.get(fieldId).set('name' , clientData.name)
						propertyModel.set('value', new MultilingualString(clientData.name).getCurrentValue())
						app.updateTable && app.updateTable();
					}
				});
			})
		}
		this.elements[model.get('key')].render();
	},

	renderTypeLink: function (model) {
		var $div = this.baseElementContainerRender(model);
		let val = model.get('value');
		let wrap  = $('<div class="type-link"/>')
		let el = $('<span>');
		let text;
		if (val) {
			if (!Constants.isPredefined(val.id)) {
				let link = app.urls.open(Constants.ID_TYPE_TYPE, val.id);
				el = $('<a>', {
					target: '_blank',
					href: app.urls.open(Constants.ID_TYPE_TYPE, val.id)
				});
			}
			text = MultilingualString.getCurrentValue(val.name());
		} else {
			text = translate('dynamic');
		}
		el.text(text).appendTo(wrap.appendTo($div));
	},

	renderDateTime: function (model) {
		var $div = this.baseElementContainerRender(model);
		var $el = $('<input>').attr('data-primitive-type', PrimitiveEntityType.TIMESTAMP);
		$el.appendTo($div)
			.attr('data-type', model.get('type'))
			.addClass('form-control');
		this.elements[model.get('key')] = new FieldTemporalAccesorInputProperties({
			el: $el,
			model: model,
			modelAttr: 'value'
		});
		this.elements[model.get('key')].render();
	},


	renderIconPicker: function (model) {
		var $div = this.baseElementContainerRender(model);
		var $el = $('<input>')
					.attr('type', 'text')
					.attr('data-type', model.get('type'))
					.attr('data-placement', 'bottomLeft')
					.attr('data-container', '#iconpicker-container')
					.addClass('form-control icp icp-auto')
					.appendTo($div);
		this.elements[model.get('key')] = new FieldInput({
			el: $el,
			model: model,
			modelAttr: 'value'
		});

		$el.iconpicker({
			hideOnSelect: true,
			inputSearch: true,
			templates: {
				iconpicker: '<div class="iconpicker"><div class="iconpicker-items ps__child--consume"></div></div>'
			}
		});

		$el.data('iconpicker').setValue($el.val());
		$el.data('iconpicker').update();
		$el.on('iconpickerSetValue', e => {
			$el.val(e.iconpickerValue).trigger('change');
			model.set('value', $el.val());
		});

		this.elements[model.get('key')].render();
	},

	renderColorPicker: function (model) {
		var $div = this.baseElementContainerRender(model);
		var el = document.createElement("input");
		$(el).prependTo($div.find('label'))
		let localStorage = window.localStorage;
		let palette = JSON.parse(localStorage.getItem('colorPalette2') || '[]')
		let picker = ColorPicker.create({
			el: el,
			theme: 'monolith',
			components: {
					preview: true,
					opacity: true,
					hue: true,
					interaction: {
							input: true,
							save: true
					}
			},
			strings: {
				 save: app.getResource('save'),
			},
			default: model.get('value')
		});
		let val = model.get('value');
		picker.on('save', (color, instance) => {
			val = color.toHEXA().toString();
			model.set('value', val);
			if (!palette.includes(val)) {
				if (palette.length == 7) {
					palette.shift();
					picker.removeSwatch(0);
				}
				palette.push(val);
				picker.addSwatch(val)
				localStorage.setItem('colorPalette2', JSON.stringify(palette))
			}
		}).on('init', () => {
			for (let i = 0; i < palette.length; i++) {
				picker.addSwatch(palette[i]);
			}
		});
	},

	renderSelect: function (model) {
		var that = this;
		const propertyKey = model.get('key');
		const isButtonAction = _.contains([
			PropertyKey.CUSTOM_SERVER_EVENT,
			PropertyKey.BEFORE_CUSTOM_SERVER_EVENT,
			PropertyKey.AFTER_CUSTOM_SERVER_EVENT
		], propertyKey);
		let createNewAction = null;
		let typeModel = that.model.models.find((a) => a.get('key') == PropertyKey.FIELD_TYPE)
		let typeId = typeModel && typeModel.get('value') && typeModel.get('value').get('id') || this.typeOfListView && this.typeOfListView.get('id')
		let fieldModel = that.model.models.find((a) => a.get('key') == PropertyKey.FIELD_NAME);
		let fieldKind = fieldModel && fieldModel.get('field').get('fieldKind') || this.typeOfListView && FieldKind.COLLECTION
		let typeKind = typeModel && typeModel.get('value') && typeModel.get('value').get('typeKind') || this.typeOfListView && this.typeOfListView.get('typeKind')
		let field = fieldModel && fieldModel.get('field')

		if (isButtonAction) {
			createNewAction = function(event) {
				event.stopPropagation()
				that._addCustomBlock(propertyKey);
			}
		}
		if (propertyKey == PropertyKey.SHARED_STYLES) {
			createNewAction = function() {
				that._addSharedStyle();
			}
		}
		if (propertyKey == PropertyKey.CSS_THEME) {
			createNewAction = function() {
				that._addCssTheme();
			}
		}
		if (propertyKey == PropertyKey.LOADED_BLOCK_ID) {
			let val = model.get("value");
			let values = model.get("values");
			val = values.filter(v=> v.id == val)[0];

			if(val) {
				$($(".listMainActionBlock[data-trigger='LOADED'] span")[0]).html(app.getResource(`on.loaded`) + " - " + val.text);
				$("a[data-trigger='LOADED'][data-blockid='"+ val.id +"']").parent().addClass("selected");
			} else {
				$($(".listMainActionBlock[data-trigger='LOADED'] span")[0]).html(app.getResource(`on.loaded`) + `<span class="default-label">Default</span>`);
			}

			return null;
		}

		var templateProperty = that.model.models.find((a) => {return a.get('key') == PropertyKey.TEMPLATE})
		var viewProperty = that.model.models.find((a) => {return a.get('key') == PropertyKey.VIEW})
		var rowLoadProperty = that.model.models.find((a) => {return a.get('key') == PropertyKey.WHICH_ROW_LOAD})

		var updateCreateNewAction = () => {
			let viewKind
			if (that.viewKind == ViewKind.JSON) {
				const fieldSerializeKindModel = that.model.models.find((a) => a.get('key') == PropertyKey.JSON_VIEW_ITEM_FIELD_SERIALIZE_KIND);
				if (fieldSerializeKindModel && fieldSerializeKindModel.get('value') && fieldSerializeKindModel.get('value') == JsonViewItemFieldSerializeKind.STRING_VIEW) {
					viewKind = ViewKind.STRING;
				}
				else {
					viewKind = ViewKind.JSON;
				}
			}
			else if (fieldKind == FieldKind.REGULAR) {
				if (typeKind == TypeKind.EMBEDDED) {
					viewKind = ViewKind.FORM;
				} else {
					viewKind = ViewKind.STRING;
				}
			}
			else if (fieldKind == FieldKind.COLLECTION) {
				if (templateProperty && templateProperty.get('value') == 'card.grid') {
					viewKind = ViewKind.WIDGET
				} else {
					viewKind = ViewKind.ROW;
				}
			}
			createNewAction = function() {
				that._addView(typeId, viewKind);
			}
		}

		let updateViews = () => {
			const isWidget = templateProperty && templateProperty.get('value') == 'card.grid' || false

			let filteredViews = []
			if (this.typeOfListView && propertyKey == PropertyKey.VIEW) {
				filteredViews = this.typeOfListView.views().filter((view) => {
					return view.kind() == ViewKind.ROW && !view.isGenerated()
				})
			} else {
				filteredViews = field && field.type() && field.type().views().filter(function (view) {
					if (that.viewKind == ViewKind.JSON) {
						return view.kind() == ViewKind.JSON;
					}
					else if (field.kind() == FieldKind.COLLECTION) {
						if (isWidget) {
							return view.kind() == ViewKind.WIDGET
						} else {
							return view.kind() == ViewKind.ROW && !view.isGenerated()
						}
					} else if (field.type().kind() == TypeKind.EMBEDDED) {
						return view.kind() == ViewKind.FORM && !view.isGenerated();
					} else if (field.type().kind() == TypeKind.PRIMITIVE) {
						return view.kind() == ViewKind.PRIMITIVE && !view.isGenerated();
					} else {
						return view.kind() == ViewKind.STRING && !view.isGenerated();
					}
				})
			}

			if (that.viewKind != ViewKind.JSON) {
				viewProperty.set('values', filteredViews.map(function (view) {
					return {id: view.id, text: new MultilingualString(view.name()).getCurrentValue()}
				}));
			}
			updateCreateNewAction()
		}
		let updateRowLoad = () => {
			if (field.kind() == FieldKind.COLLECTION) {
				let filteredRowLoads = [];

				let tableViewId = viewProperty.get("value");
				if(!tableViewId) {
					let views = viewProperty.get("values").filter(view => app.views.get(view.id).get("viewModifier") == ViewModifier.DEFAULT);
					tableViewId = views && views.length && views[0].id;
				}

				if (field.get("fieldEvents") && field.get("fieldEvents")[BlockTrigger.ROW_LOAD]) {
					filteredRowLoads.push("row.load.from.field");
				}
				if (tableViewId && app.blocks.find(block => block.get("trigger") == BlockTrigger.ROW_LOAD && block.get("ownerId") == field.get("fieldTypeId") && block.get("viewId") == tableViewId)) {
					filteredRowLoads.push("row.load.from.index.table");
				}
				rowLoadProperty.set('values', filteredRowLoads.map(function (id) {
					return {id: id, text: translate(id)}
				}))
				let whichRowLoadValue = rowLoadProperty.get('value');
				if (whichRowLoadValue && !rowLoadProperty.get('values').find(value => value.id == whichRowLoadValue)) {
					rowLoadProperty.set('value', null);
				}
				this.elements[PropertyKey.WHICH_ROW_LOAD] && this.elements[PropertyKey.WHICH_ROW_LOAD].rebuildSelect(rowLoadProperty.get('values'), rowLoadProperty.get('value'));
			}
		}
		if (propertyKey == PropertyKey.TEMPLATE && fieldKind == FieldKind.COLLECTION) {
			if (field && !field.type().views().filter((v) => {return v.kind() == ViewKind.WIDGET }).length) {
				model.set('values', model.get('values').filter((v) => {return v.id !== 'card.grid'}))
			} else {
				model.on('change:value', () => {
					updateViews()
					this.elements[PropertyKey.VIEW].createNewAction = createNewAction
					this.elements[PropertyKey.VIEW].rebuildSelect(viewProperty.get('values'), viewProperty.get('value'))
				}, this)
			}
		}
		if (propertyKey == PropertyKey.VIEW) {
			updateViews()
			if (rowLoadProperty) {
				model.on('change:value', () => {
					updateRowLoad();
				}, this)
			}
		}
		if (propertyKey == PropertyKey.WHICH_ROW_LOAD) {
			updateRowLoad();
		}
		var $div = this.baseElementContainerRender(model);
		var $el = $('<select>');
		$el.appendTo($div)
			.attr('data-type', model.get('type'))
			.addClass('form-control');
		if (model.get('type') == PropertyType.MULTISELECT) {
			$el.attr('multiple', 'multiple');
		}
		const allowClear = !!model.get('allowClear');
		this.elements[propertyKey] =	new BaseSelect({
			withCustomTooltip: true,
			el: $el,
			model: model,
			modelAttr: 'value',
			createNewAction: createNewAction,
			data: model.get('values').map((value) => {
				let val = _.clone(value);
				if (app.isResourceDefined(value.text) && value.isSystem) {
					val.text = translate(value.text);
				}
				if (isButtonAction && _.contains(FormOption.getValues(), val.id)) {
					// Highlight system actions
					val.text += '<span class="label table-label label-default">System</span>';
				}
				if (model.get('needToBeTranslated')) {
					val.text = model.get('needToBeTranslated')(val);
				}
				return val;
			}),
			comparator: model.get('comparator'),
			darkMode: true,
			dropdownParent: this.select2Container,
			allowClear: allowClear,
			templateSelection: (state) => {
				if (model.get('link') && model.get('value') !== null) {
					const $link = $('<a class="select-open-item">')
					if (!allowClear) {
						$link.css('padding-right', '0px');
					}
					$link.on('mousedown',(e) => {
						e.stopPropagation()
						if (e.button == 1) {
							utils.redirectTo(model.get('link').url(model.get('value')), true)
						} else {
							if (app.view.viewKind === ViewKind.FORM) {
								app.formBuilder.isOurModalShown = true;
							}
							$.confirm({
								text: 'You have unsaved changes. Continue without saving?',
								confirm: function (button) {
									$('.confirmation-modal').attr('id', 'confirm-modal');
									$('.confirmation-modal .save').attr('data-redirect', model.get('link').url(model.get('value')));
									app.formBuilder.save(null, {
										redirectUrl: model.get('link').url(model.get('value'))
									});
								},
								cancel: function (button) {
									location.href = model.get('link').url(model.get('value'));
								},
								confirmButton: 'Save and continue',
								cancelButton: 'Continue',
								confirmButtonClass: 'btn-danger save',
								cancelButtonClass: 'btn-default'
							})
						}
					})
					.append('<i class="material-icons notranslate">open_in_new</i>');
					$('.confirmation-modal').on('dialogclose', function(e) {
						if (app.view.viewKind === ViewKind.FORM) {
							app.formBuilder.isOurModalShown = false;
						}
					})
					const $showDiv= $('<div>')
					$showDiv.append("<span>"+state.text+"</span>")
					$showDiv.append($link)
					return $showDiv
				} else if (isButtonAction && state.id) {
					var link = $('<a class="select-open-item">')
						.attr('target', '_blank')
						.attr('href', app.urls.blockConstruct(state.id))
						.append('<i class="material-icons notranslate">open_in_new</i>');
					return '<span>' + state.text + '</span>' + link.prop('outerHTML');
				} else {
					return state.html || state.text;
				}
			}
		})
		this.elements[propertyKey].render();
	},

	_addSharedStyle: function() {
		var that = this;
		let model = new BaseModel();
		app.createModal.show({
			url: app.urls.create(Constants.ID_TYPE_SHARED_STYLE),
			headerResource: `add.shared.style`,
			model: model,
			afterCreateOnSuccess: (data) => {
				that._updateList({
						id: data.id,
						text: MultilingualString.getCurrentValue(data.name)
					},
					PropertyKey.SHARED_STYLES, true);
				$("head style").append(new SharedStyle(data).toCSS());
			},
			createView: CreateSharedStyle,
		});
	},

	_addCssTheme: function() {
		var that = this;
		let model = new BaseModel();
		app.createModal.show({
			url: app.urls.create(Constants.ID_TYPE_CSS_THEME),
			headerResource: `add.theme`,
			model: model,
			afterCreateOnSuccess: (data) => {
				that._updateList({
						id: data.id,
						text: MultilingualString.getCurrentValue(data.name)
					},
					PropertyKey.CSS_THEME, true);
				utils.redirectTo(app.urls.update(Constants.ID_TYPE_CSS_THEME, data.id), true);
			},
			createView: CreateTheme,
		});
	},

	_addView: function(typeId, viewKind) {
		var that = this;
		let model = new BaseModel();
		let modalHeader;
		if (viewKind == ViewKind.STRING) {
			modalHeader = `add.string.view`;
		} else if (viewKind == ViewKind.ROW) {
			modalHeader = `add.row.view`;
		} else if (viewKind == ViewKind.FORM) {
			modalHeader = `add.form.view`;
		} else if (viewKind == ViewKind.WIDGET) {
			modalHeader = `add.widget.view`;
		} else if (viewKind == ViewKind.JSON) {
			modalHeader = `add.json.view`;
		}
		model.set('viewKind', viewKind);
		app.createModal.show({
			url: app.urls.createView(typeId),
			headerResource: modalHeader,
			model: model,
			afterCreateOnSuccess: (viewId) => {
				that._updateList({
						id: viewId,
						text: MultilingualString.getCurrentValue(model.get('name'))
					},
					PropertyKey.VIEW, true);
				utils.redirectTo(app.urls.editView(viewId, typeId), true);
			},
			createView: CreateView,
		});
	},

	_addCustomBlock: function (propertyKey) {
		let kind = BlockKind.ACTION;
		let initOptions = { blockTrigger: BlockTrigger.CUSTOM, blockType: this._getCustomBlockType(propertyKey) };
		let model = new BaseModel();
		model.set('blockKind', kind);
		let showCheckbox = app.formBuilder.checkIfShowPublishCheckbox()
		let modalText = showCheckbox ? app.formBuilder.getPublishCheckboxHtml() : ''
		app.createModal.show({
			url: app.urls.blockCreate,
			headerResource: `add.${kind.toLowerCase()}`,
			model: model,
			afterCreateOnSuccess: (data) => {
				this._updateCustomBlockLists({ id: data.id, text: data.name }, propertyKey);
				showConfirmModal({
					title: app.getResource('block.successfully.created'),
					text: modalText,
					confirmButtonText: app.getResource('save.and.edit'),
					cancelButtonText: app.getResource('close'),
					onConfirm: () => {
						app.formBuilder.save(null , {})
						app.formBuilder.afterSaveRedirectWithPublish(this.publishCheckbox, app.urls.blockConstruct(data.id))
					}
				})
				if (showCheckbox) {
					this.publishCheckbox = app.formBuilder.initPublishCheckboxOnModal()
				}
			},
			createView: CreateBlock,
			initOptions: initOptions
		});
	},

	_getCustomBlockType: function(propertyKey) {
		return propertyKey == PropertyKey.CUSTOM_SERVER_EVENT ? BlockType.SERVER : BlockType.CLIENT;
	},

	_updateCustomBlockLists: function(data, propertyKey) {
		const blockType = this._getCustomBlockType(propertyKey);
		_.each([
				{key:PropertyKey.CUSTOM_SERVER_EVENT, type: BlockType.SERVER},
				{key:PropertyKey.BEFORE_CUSTOM_SERVER_EVENT, type: BlockType.CLIENT},
				{key:PropertyKey.AFTER_CUSTOM_SERVER_EVENT, type: BlockType.CLIENT}
			], (o) => {
				if (o.type == blockType) {
					this._updateList(data, o.key, o.key == propertyKey);
				}
		})
	},

	_updateList: function(data, propertyKey, selectNewOption) {
		const property = this.model.findWhere({key: propertyKey});
		const optionList = property.get('values');
		optionList.push(data);
		const propertySelect = this.elements[propertyKey];
		propertySelect.rebuildSelect(optionList);
		if (selectNewOption) {
			if (property.get('type') == PropertyType.SELECT) {
				propertySelect.setValue(data.id);
			} else {
				let selected = propertySelect.getValue();
				selected.push(data.id);
				propertySelect.setValue(selected);
			}
		}
	},

	renderEvents: function(model) {
		let $ul = $('<ul>')
		.addClass('nav nav-dark nav-pills nav-stacked')
		.append(buildBlockActions(app.fields.get(this.fieldId)));
		let $div = $('<div>')
			.appendTo($('#' + model.get('group') + 'groupbox'))
			.attr('data-property-key', model.get('key'))
			.append($ul);

	},

	renderButton: function (model) {
		var $div = $('<div>')
			.appendTo($('#' + model.get('group') + 'groupbox'))
			.addClass('form-group')
			.attr('data-property-key', model.get('key'));
		var $btn = $('<button>').addClass('btn btn-md btn-link');
		$btn.append(`<span class="fa ${model.get('icon')}"/>`);
		$btn.appendTo($div);
		$("<label>"+PropertyLabel(model.get('key'), this.renderType)+"</label>").appendTo($div)
		$btn.click((e) => model.trigger('click', e));
	},

	renderBoolean: function (model) {
    var $div = this.baseElementContainerRender(model, 'boolean')
    $div.addClass('checkbox').removeClass('form-group')
    var $el = $('<input>').attr('data-primitive-type', 'BOOLEAN').attr('type', 'checkbox');
    $el.prependTo($div.find('label'))
      .attr('data-type', model.get('type'))
      .addClass('form-control');
    this.elements[model.get('key')] = {
      el:new CheckboxInput({
        el: $el[0],
        model: model,
        modelAttr: 'value'
      }),
      disable: function () {
        this.el.disable()
      },
      enable: function () {
        this.el.enable()
      },
      reset: function () {},
      off: function () {},
      undelegateEvents: function () {},
      popover: function (message) {
        $(this.el.checkbox.$el).parent().popover({
          content: message,
          trigger: 'hover'
        })
      }
    }
  },

	renderSeedInstaces: function(model) {
		let $ul = $('<ul class="nav nav-dark nav-pills nav-stacked"/>');
		let $div = $('<div />')
			.appendTo($('#' + model.get('group') + 'groupbox'))
			.attr('data-property-key', model.get('key'))
			.append($ul);
		model.seedInstancesList = new SeedInstancesList({
			type: app.fields.get(this.fieldId).type(),
			instancesViewer: this.instanceViewer,
			el: $ul
		})
		model.seedInstancesList.render()
	},

	changeLabelCheckboxState: function () {
			if (!this.elements[PropertyKey.LABEL].msModel.isBlank()) {
				this.elements[PropertyKey.FIELD_LABEL].el.checkbox.setChecked(false)
			}
	},

	getPropertyView(key) {
		return this.elements[key];
	},

	_destroyView: function () {
		_.each(this.elements, function (el) {
			el.undelegateEvents();
			el.off();
		});
		this.elements = {};
		this.undelegateEvents();
		this.$el.removeData().unbind();
		this.$el.empty();
	}
});

export function renderBaseHTML(model, $div, type, popoverPlacement, popoverContainer) {
	var $el = $('<textarea>').attr('type', 'text');
	$el.attr('data-is-string-multilingual', type) ;
	if (model.get('onlyButton') == 'true') {
		$el.attr('data-only-button', 'true');
	}
	$el.appendTo($div)
		.attr('data-type', model.get('type'))
		.addClass('form-control');
	let element =  new MultilingualHtmlInputField({
		el: $el,
		model: model,
		modelAttr: 'value',
		startCollapsed: true,
		popoverPlacement: popoverPlacement,
		popoverContainer: popoverContainer
	});
	element.render()
	return element
}


export default PropertiesBody;

import MultilingualString from './multilingualString';

var MultilingualStringResource = Backbone.Model.extend({

	initialize: function (options) {
		this.set('value', new MultilingualString(options.value));
	},

	key: function () {
		return this.get('key');
	},

	configuration: function() {
		return this.get('configuration');
	},

	defaultValue: function () {
		return this.get('value').defaultValue;
	},

	value: function () {
		return (new MultilingualString(this.get('value').toJSON())).getCurrentValue();
	}

});

export default MultilingualStringResource;

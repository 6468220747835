const TRANSFORMS = {
	'value': x => x,
	'Value': x => x && x.charAt(0).toUpperCase() + x.slice(1),
	'VALUE': x => x && x.toUpperCase(),
	'_value': x => x && x.toLowerCase()
};

const SPLIT_REGEX = /(%_?value(:[a-z]{2})?%)/ig;

function getValue(mls, lang, currentLanguage) {
	if (!mls) {
		return "";
	}
	if (!lang) {
		return mls.getCurrentValue(currentLanguage);
	}
	return mls.getCurrentValue(lang);
}

function constructPart(s) {
	if (s[0] == '%' && s[s.length-1] == '%') {
		let parts = s.substring(1, s.length-1).split(":");
		if (parts.length <= 2) {
			if(parts && parts.length == 2) {
				parts[0] = parts[0].substring(0, parts[0].length-1);
				parts[1] = parts[1].substring(1, parts[1].length);
			}
			let operator = TRANSFORMS[parts[0]];
			if (operator) {
				return (x, currentLanguage) => operator(_.escape((x.getTranslation && getValue(x, parts[1], currentLanguage)) || x));
			}
		}
	}
	return x => s;
}

export default class CodejigStringFormatter {
	constructor(pattern) {
		this.parts = pattern.split(SPLIT_REGEX)
			.filter(s => s)
			.map(constructPart);
	}

	static ofPattern(pattern) {
		return new CodejigStringFormatter(pattern);
	}

	format(value, lang) {
		return this.parts.map(p => p(value, lang)).join('');
	}
};

import PermissionFilterKind from '../../common/enums/permissionFilterKind';
import MenuItemTypeActionKind from '../../common/enums/menuItemTypeActionKind';
import BaseModel from '../../common/models/baseModel';
import MultilingualString from '../../common/models/multilingualString.js';
import EntityMenuItems from './entityMenuItems';
import UnavailableModuleModeKind from '../../common/enums/menuUnavailableModuleModeKind'

export default class EntityMenuItem extends BaseModel {

	initialize (o) {
		this.set('clientId', this.cid.substring(1));
		if (o.id && app && app.clientIds) {
			app.clientIds[o.id] = this.cid.substring(1);
		}
		if (o && o.view) {
			this.set('view', new BaseModel(o.view));
		} else {
			this.set('view', new BaseModel());
		}
		this.set('typeAction', (o.typeAction ? o.typeAction : MenuItemTypeActionKind.INDEX));
		this.set('children', new EntityMenuItems());
		this.set('text', o && o.text && new MultilingualString(o.text));
		if (o && o.permissionFilter) {
			this.set('permissionFilter', new BaseModel(o.permissionFilter));
			this.get('permissionFilter').set('roles',
				o.permissionFilter.roles.map(r => r.id));
		} else {
			this.set('permissionFilter', new BaseModel());
			this.get('permissionFilter').set('kind', PermissionFilterKind.EVERYONE);
		}
		this.set('modulesMask', _.reduce(o.modules && o.modules.map(m => m.accessMask) || [], (a, b) => {
			return a | Number.parseInt(b, 10)
		}, 0))
		this.set('modules', o.modules && o.modules.map(m => m.id) || [])
		this.set('unavailableModuleMode', o.unavailableModuleMode || UnavailableModuleModeKind.ACTIVE)
		this.listenTo(this, 'change:parent',
				(model) => {
					if (model.previous('parent') &&
						model.previous('parent') instanceof BaseModel) {
						model.previous('parent').get('children').remove(this);
					}
					if (model.get('parent')) {
						model.get('parent').get('children').add(this);
					}
				});
		this.listenTo(this.get('text'), 'change', (m) => {
			this.trigger('change:text', m);
			this.trigger('change', m);
		});

		if (this.get('text')) {
			this.listenTo(this.get('text').get('translations'), 'change', m => {
				this.trigger('change:text', m);
				this.trigger('change', m);
			});
		}
		this.listenTo(this.get('permissionFilter'), 'change', (m) => {
			this.trigger('change:permissionFilter', m);
			this.trigger('change', m);
		});
	}

	toServerJSON () {
		const json =  _.chain(this.attributes).clone()
			.omit('className', 'clientState', 'parent', 'children', 'permissionFilter', 'modulesMask')
			.mapObject(function (val, key) {
				if (_.isObject(val)) {
					if (key == 'metaObject') {
						return {
							id: val.id
						};
					}
					if (val.toServerJSON) {
						return val.toServerJSON();
					}
					if (val.toJSON) {
						return val.toJSON();
					}
				}
				return val;
			}).value();
		json.parent = this.get('parent') && {
			id: this.get('parent').get('id'),
			clientId: this.get('parent').get('clientId')
		};
		if (this.get('view') && this.get('view').id) {
			json.view = {
				id: this.get('view').id
			};
		} else {
			json.view = null;
		}
		json.modules = this.get('modules').map(m => {
			return {id: m}
		})
		json.permissionFilter = {
			id: this.get('permissionFilter').id,
			kind: this.get('permissionFilter').get('kind'),
			roles: this.get('permissionFilter').get('kind') ==
				PermissionFilterKind.IN_ANY_OF_ROLES ?
				this.get('permissionFilter').get('roles').map(r => {return {id: r};}) : []
		};
		return json;
	}
}

import TaskView from '../views/task/taskView';
import ProgressBarView from '../views/task/progressBarView';
import { TaskUtils } from '../components/taskUtils';
import TaskState from '../enums/taskState';
import TaskResult from '../enums/taskResult';
import TaskInfoKind from '../enums/taskInfoKind';
import ErrorCode from '../enums/errorCode';
import { timeSynchronizer } from '../../time/utils';
import utils from '../components/utils';
import TaskKind from "../enums/taskKind";
import {hideMergeLoader} from "../../help/utils";

export default class TaskManager {

    constructor() {
        this.tasks = {};
        this.taskView = new TaskView();
        this.progressBar = new ProgressBarView();
        this.waitingTasks = {};
    }

    merge(received) {
        let local = this.tasks[received.id] || {};
        let merged;
        if (received.infoKind === TaskInfoKind.NEW_MESSAGE){
          merged = local || {};
          merged.messages = merged.messages || [];
          merged.messages.push(received.message);
        } else {
          merged = _.extend(local, received);
        }
        merged.state = TaskState.max(local.state, received.state);
        return merged;
    }

    findTask(id){
        return this.tasks[id];
    }

    isTaskWithPromise(task) {
        return this.tasks[task.id] && (this.tasks[task.id].resolve || this.tasks[task.id].reject);
    }

    isTaskFromThisTab(task) {
      return this.tasks[task.id] && this.tasks[task.id].fromThisTab
    }

    onTaskInfoReceived(task) {
        let merged = this.merge(task);
        this.taskView.addTask({
            merged: merged,
            raw: task
        });
        this.tasks[task.id] = merged;
        if (app.builderMode &&
              (task.kind === TaskKind.POST_BUILD ||
                task.kind === TaskKind.DEV_DEPLOY ||
                task.kind === TaskKind.DEPLOY) &&
            (task.infoKind === TaskInfoKind.PROGRESS_CHANGED ||
             task.infoKind === TaskInfoKind.NEW_STATE ||
             task.state === TaskState.DONE) ||
             task.kind === TaskKind.SYSTEM_BUILD &&
              (task.infoKind === TaskInfoKind.PROGRESS_CHANGED ||
              task.infoKind === TaskInfoKind.NEW_STATE)
           ) {
            app.publishProgressBar.checkAnimation();
        }
        if (this.findTask(task.id)) {
            if (task.kind === TaskKind.MERGE && task.message && task.message.kind === 'ERROR') {
                hideMergeLoader();
            }
            if (task.infoKind === TaskInfoKind.PROGRESS_CHANGED){
              this.progressBar.set(TaskUtils.percentage(task.progress));
            }
            if (task.infoKind === TaskInfoKind.NEW_MESSAGE && this.isTaskFromThisTab(task)) {
              app.notificationManager.addMessage(task.message, task.id);
            }
            if (task.state === TaskState.DONE) {
                this.showResult(task);
                this.taskView.store(merged);
                if (task.kind != TaskKind.SYSTEM_BUILD){
                  this.progressBar.hide();
                }
                if (task.result === TaskResult.SUCCESS) {
                    if ((task.kind === TaskKind.DEPLOY || task.kind === TaskKind.DEV_DEPLOY) && this.isTaskFromThisTab(task)) {
                        app.buildView.proposeToOpenModal();
                    }

                    if(task.waitForChainedTasks && task.chainedTasksIds) {
                      this.waitingTasks[task.id] = task.chainedTasksIds;
                    } else {
                      this.tasks[task.id].resolve && this.tasks[task.id].resolve(task.responseObject);
                    }
                    for (var t in this.waitingTasks) {
                      if(this.waitingTasks[t].includes(task.id)) {
                        const index = this.waitingTasks[t].indexOf(task.id);
                        if (index > -1) {
                          this.waitingTasks[t].splice(index, 1);
                        }
                      }
                      if(this.waitingTasks[t].length == 0) {
                        this.tasks[t].resolve && this.tasks[t].resolve(this.tasks[t].responseObject);
                      }
                    }
                } else if (task.result === TaskResult.FAIL) {
                    this.tasks[task.id].reject && this.tasks[task.id].reject(task);
                }
            }
        }

    }

    showResult(task) {
      if (ErrorCode.hasOwnProperty(task.errorCode)) {
        switch (task.errorCode) {
          case ErrorCode.REMOVING_INSTANCE_WITH_REFERENCES:
            app.errorsModal.show({
              resource: task.errorCode.replace(/_/g, '.').toLowerCase(),
              referrersData: task.responseObject
            });
            break;
          case ErrorCode.LACK_THE_PROJECT_UPDATE_LICENSE:
            if (!app.currentUser && app.builderMode) {
              app.errorsModal.show({
                resource: 'forbidden.in.readonly.builder',
                loginOrRegister: true
              })
              break
            }
          default:
            app.errorsModal.show({
              resource: task.errorCode.replace(/_/g, '.').toLowerCase()
            });
        }
      }
    }

    fetchUncompletedTasks () {
        return utils.getRequest(app.urls.home + 'taskmanager/findUncompletedTasks')
    }

    loadUncompletedTasks () {
        if (app.builderMode || app.permissionChecks.showSystemJobs) {
          timeSynchronizer.initialize();
          this.fetchUncompletedTasks().then(tasks => {
              _.each(tasks, task => {
                  this.onTaskInfoReceived(task);
              })
          })
        }
    }

    fetchServerStatus() {
        utils.getRequest(app.urls.serverStatus)
            .then(response => {
                app.buildView.render(response.deploy);
                app.buildView.render(response.build);
            });
    }
}

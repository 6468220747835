/* @flow */

export default class FormOption {
	static DOCUMENT_POST = 'DOCUMENT_POST';
	static DOCUMENT_UNPOST = 'DOCUMENT_UNPOST';
	static SHOW_REGISTERS = 'SHOW_REGISTERS';
	static CLONE = 'CLONE';
	static SHOW_HISTORY = 'SHOW_HISTORY';
	static PRINT = 'PRINT';
	static SHARE = 'SHARE';
	static DOWNLOAD = 'DOWNLOAD';
	static DELETE = 'DELETE';
	static SAVE = 'SAVE';
	static LIST_LINK = 'LIST_LINK';
	static SAVE_AND_CLOSE = 'SAVE_AND_CLOSE';
	static CANCEL_AND_CLOSE = 'CANCEL_AND_CLOSE';
	static CANCEL = 'CANCEL';
	static DOWNLOAD_SPREADSHEET = 'DOWNLOAD_SPREADSHEET';
	static DOWNLOAD_XML = 'DOWNLOAD_XML';
	static DOWNLOAD_DOC = 'DOWNLOAD_DOC';
	static CUSTOMIZE = 'CUSTOMIZE';
	static BUILD = 'BUILD';
	static EXPORT_REPORT_TO_XLS = 'EXPORT_REPORT_TO_XLS';
	static DOWNLOAD_PDF = 'DOWNLOAD_PDF';

	static getTranslationKey = (_enum) => {
		return _enum.toLowerCase().split('_').join('.') + '.event';
	};
	static getButtonTranslationKey = (_enum) => {
		return _enum.toLowerCase().split('_').join('.');
	};
	static getValues = () => {
		return Object.keys(FormOption).filter((k) =>
			k !== 'getTranslationKey' &&
			k !== 'getButtonTranslationKey' &&
			k !== 'getValues');
	}
}

/* @flow */

import LocalDate from './localDate';
import LocalTime from './localTime';
import ChronoField from '../enums/chronoField';
import ChronoUnit from '../enums/chronoUnit';
import Instant from './instant';
import ZonedDateTime from './zonedDateTime';

import type ZoneId from './zoneId';
import ZoneOffset from './zoneOffset';
import type { Temporal } from '../declarations';
import * as constants from '../constants';

import {checkUnitIsSupported} from '../utils';

/**
 * Serilized form:
 *   year: int
 *   month: short
 *   day: short
 *   hour: byte
 *   minute: byte
 *   second: byte
 *   nano: int
 */

export default class LocalDateTime {

	date: LocalDate;
	time: LocalTime;

	constructor(date: LocalDate, time: LocalTime) {
		this.date = date;
		this.time = time;
	}

	static of(): LocalDateTime {
		if (arguments[0] instanceof LocalDate) {
			const [date: LocalDate, time: LocalTime] = arguments;
			return new LocalDateTime(date, time);
		}

		const [year: number, month: number, dayOfMonth: number,
			hour: number, minute: number, second: number, nanoOfSecond: number] = arguments;
		return new LocalDateTime(
			LocalDate.of(year, month, dayOfMonth),
			LocalTime.of(hour, minute, second, nanoOfSecond)
		);
	}

	static fromJSON(value: ?any): ?LocalDateTime {
		if (value == null) {
			return null;
		}
		return new LocalDateTime(
			LocalDate.of(value.year, value.month, value.day),
			LocalTime.of(value.hour, value.minute, value.second, value.nano)
		);
	}

	static ofInstant(instant: Instant, zone: ZoneId): LocalDateTime {
		const offset = zone.getRules().getOffset(instant);
		return LocalDateTime.ofEpochSecond(instant.getEpochSecond(), instant.getNano(), offset);
	}

	static ofEpochSecond(epochSecond: number, nanoOfSecond: number, offset: ZoneOffset): LocalDateTime {
		const seconds = epochSecond + offset.getTotalSeconds();
		const epochDay = Math.floor(seconds / constants.SECONDS_PER_DAY);
		const secondOfDay = seconds - (Math.floor(seconds /  constants.SECONDS_PER_DAY) *  constants.SECONDS_PER_DAY);
		const localTime = LocalTime.ofNanoOfDay(secondOfDay *  constants.NANOS_PER_SECOND + nanoOfSecond);
		const localDate = LocalDate.ofEpochDay(epochDay);
		return new LocalDateTime(localDate, localTime);
	}

	static now(zone: ZoneId): LocalDateTime {
		return ZonedDateTime.now(zone).toLocalDateTime();
	}

	toJSON() {
		return {
			year: this.date.getYear(),
			month: this.date.getMonthValue(),
			day: this.date.getDayOfMonth(),
			hour: this.time.getHour(),
			minute: this.time.getMinute(),
			second: this.time.getSecond(),
			nano: this.time.getNano()
		};
	}

	toMoment(): moment$Moment {
		return global.moment.utc({
			year: this.date.getYear(),
			month: this.date.getMonthValue() - 1,
			day: this.date.getDayOfMonth(),
			hour: this.time.getHour(),
			minute: this.time.getMinute(),
			second: this.time.getSecond()
		});
	}

	static fromMoment(moment: moment$Moment): LocalDateTime {
		return LocalDateTime.of(moment.year(), moment.month() + 1, moment.date(),
			moment.hour(), moment.minute(), moment.second());
	}

	toLocalDate(): LocalDate {
		return this.date;
	}

	toLocalTime(): LocalTime {
		return this.time;
	}

	toInstant(offset: ZoneOffset): Instant {
		return Instant.ofEpochSecond(this.toEpochSecond(offset), this.getNano());
	}

	toEpochSecond(offset: ZoneOffset): number {
		return this.date.toEpochDay() * constants.SECONDS_PER_DAY + this.time.toSecondOfDay() - offset.getTotalSeconds();
	}

	getDayOfMonth(): number {
		return this.date.getDayOfMonth();
	}

	getHour(): number {
		return this.time.getHour();
	}

	getMinute(): number {
		return this.time.getMinute();
	}

	getMonthValue(): number {
		return this.date.getMonthValue();
	}

	getNano(): number {
		return this.time.getNano();
	}

	getSecond(): number {
		return this.time.getSecond();
	}

	getYear(): number {
		return this.date.getYear();
	}

	get(field: ChronoField): number {
		switch (field) {
			case ChronoField.NANO_OF_SECOND:
				return this.time.getNano();
			case ChronoField.MICRO_OF_SECOND:
				return this.time.getNano() / constants.NANOS_PER_MICRO;
			case ChronoField.MILLI_OF_SECOND:
				return this.time.getNano() / constants.NANOS_PER_MILLI;
			case ChronoField.SECOND_OF_MINUTE:
				return this.time.getSecond();
			case ChronoField.MINUTE_OF_HOUR:
				return this.time.getMinute();
			case ChronoField.HOUR_OF_AMPM:
				let h = this.time.getHour();
				return h > 12 ? h - 12 : h;
			case ChronoField.HOUR_OF_DAY:
				return this.time.getHour();
			case ChronoField.AMPM_OF_DAY:
				return this.time.getHour() < 12 ? 0 : 1;
			case ChronoField.DAY_OF_WEEK:
				return this.date.getDayOfWeek();
			case ChronoField.DAY_OF_MONTH:
				return this.date.getDayOfMonth();
			case ChronoField.DAY_OF_YEAR:
				return this.date.getDayOfYear();
			case ChronoField.MONTH_OF_YEAR:
				return this.date.getMonthValue();
			case ChronoField.YEAR:
				return this.date.getYear();
			case ChronoField.INSTANT_SECONDS:
				return this.toMoment().utcOffset(app.classes.timeZoneService.getCurrentOffset().seconds / 60).unix();
			case ChronoField.OFFSET_SECONDS:
				return app.classes.timeZoneService.getCurrentOffset().seconds;
		}

		throw new Error('Not implemented');
	}

	set(field: ChronoField, value: number) {

		switch (field) {
			case ChronoField.NANO_OF_SECOND:
				this.time.nano = value;
				break;
			case ChronoField.MICRO_OF_SECOND:
				this.time.nano = value * constants.NANOS_PER_MICRO;
				break;
			case ChronoField.MILLI_OF_SECOND:
				this.time.nano = value * constants.NANOS_PER_MILLI;
				break;
			case ChronoField.SECOND_OF_MINUTE:
				this.time.second = value;
				break;
			case ChronoField.MINUTE_OF_HOUR:
				this.time.minute = value;
				break;
			case ChronoField.HOUR_OF_AMPM:
				if (value > 11) {
					throw new Error('Invalid value for HourOfAmPm (valid values 0 - 11): ', value);
				}
				this.time.hour = this.time.hour >= 12 ? value + 12 : value
				break;
			case ChronoField.HOUR_OF_DAY:
				this.time.hour = value;
				break;
			case ChronoField.AMPM_OF_DAY:
				this.time.hour = value == 0 ? (this.time.hour >= 12 ? this.time.hour - 12 : this.time.hour) : (this.time.hour >= 12 ? this.time.hour : this.time.hour + 12);
				break;
			case ChronoField.DAY_OF_WEEK:
				break;
			case ChronoField.DAY_OF_MONTH:
				this.date.month = value;
				break;
			case ChronoField.DAY_OF_YEAR:
				this.date.day = value;
				break;
			case ChronoField.MONTH_OF_YEAR:
				this.date.month = value;
				break;
			case ChronoField.YEAR:
				this.date.year = value;
				break;
			default:
				throw new Error('Not implemented, Unsupported field: ', field.toString());
		}

		return this;
	}

	with(field: ChronoField, value: number): LocalDateTime {
		let t = new LocalDateTime(new LocalDate(this.date.year, this.date.month, this.date.day), new LocalTime(this.time.hour, this.time.minute, this.time.second, this.time.nano));
		t.set(field, value);
		return t;
	}

	withNano(nanoOfSecond: number): LocalDateTime {
		return new LocalDateTime(this.date, this.time.withNano(nanoOfSecond));
	}

	plus(amount: number, unit: ChronoUnit): LocalDateTime {
		checkUnitIsSupported(this, unit);
		if (unit == ChronoUnit.MILLIS) {
			throw new Error('Not implemented');
		}
		const moment = this.toMoment().add(amount, unit.toMoment());
		let dateTime = LocalDateTime.fromMoment(moment);
		dateTime = dateTime.withNano(this.getNano());
		return dateTime;
	}

	until(endExclusive: Temporal, unit: ChronoUnit): number {
		throw new Error('Not implemented');
	}

	compareTo(other: LocalDateTime): number {
		const d = this.date.compareTo(other.date);
		if (d !== 0) {
			return d;
		}
		return this.time.compareTo(other.time);
	}

	equals(other: ?any): boolean {
		if (other == null) {
			return false;
		}
		if (!(other instanceof LocalDateTime)) {
			return false;
		}
		return this.date.equals(other.date) &&
				this.time.equals(other.time);
	}

	isAfter(other: LocalDateTime): boolean {
		return this.compareTo(other) > 0;
	}

	isBefore(other: LocalDateTime): boolean {
		return this.compareTo(other) < 0;
	}

	isSupported(field: ChronoField | ChronoUnit): boolean {
		if (field instanceof ChronoField) {
			return LocalDateTime.supportedFields.indexOf(field) > -1;
		}
		if (field instanceof ChronoUnit) {
			return LocalDateTime.supportedUnits.indexOf(field) > -1;
		}
		throw new Error('Not supported type of the argument');
	}

	static supportedFields = [
		ChronoField.NANO_OF_SECOND,
		ChronoField.NANO_OF_DAY,
		ChronoField.MICRO_OF_SECOND,
		ChronoField.MICRO_OF_DAY,
		ChronoField.MILLI_OF_SECOND,
		ChronoField.MILLI_OF_DAY,
		ChronoField.SECOND_OF_MINUTE,
		ChronoField.SECOND_OF_DAY,
		ChronoField.MINUTE_OF_HOUR,
		ChronoField.MINUTE_OF_DAY,
		ChronoField.HOUR_OF_AMPM,
		ChronoField.CLOCK_HOUR_OF_AMPM,
		ChronoField.HOUR_OF_DAY,
		ChronoField.CLOCK_HOUR_OF_DAY,
		ChronoField.AMPM_OF_DAY,
		ChronoField.DAY_OF_WEEK,
		ChronoField.ALIGNED_DAY_OF_WEEK_IN_MONTH,
		ChronoField.ALIGNED_DAY_OF_WEEK_IN_YEAR,
		ChronoField.DAY_OF_MONTH,
		ChronoField.DAY_OF_YEAR,
		ChronoField.EPOCH_DAY,
		ChronoField.ALIGNED_WEEK_OF_MONTH,
		ChronoField.ALIGNED_WEEK_OF_YEAR,
		ChronoField.MONTH_OF_YEAR,
		ChronoField.PROLEPTIC_MONTH,
		ChronoField.YEAR_OF_ERA,
		ChronoField.YEAR,
		ChronoField.ERA,
		ChronoField.INSTANT_SECONDS
	];

	static supportedUnits = [
		ChronoUnit.NANOS,
		ChronoUnit.MICROS,
		ChronoUnit.MILLIS,
		ChronoUnit.SECONDS,
		ChronoUnit.MINUTES,
		ChronoUnit.HOURS,
		ChronoUnit.HALF_DAYS,
		ChronoUnit.DAYS,
		ChronoUnit.WEEKS,
		ChronoUnit.MONTHS,
		ChronoUnit.YEARS,
		ChronoUnit.DECADES,
		ChronoUnit.CENTURIES,
		ChronoUnit.MILLENNIA,
		ChronoUnit.ERAS
	];

	static getMethodSupportedEnumValues = LocalDateTime.supportedFields;
}

import BaseModel from '../../common/models/baseModel'
import { translate } from '../../common/service/stringResourceService.js'

export default class ProposeToDeployModal extends Backbone.View {

	initialize () {
		let $el = $('#proposeToDeployModal')
		if ($el.length == 0) {
			let el = document.createElement('div')
			el.id = 'proposeToDeployModal'
			el.classList.add('modal')
			el.classList.add('fade')
			document.body.appendChild(el)
		}
		$el = $('#proposeToDeployModal')
		$el.html(this.getHTML())
		this.setElement($el)
	}

	getHTML () {
		return `
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header create-modal-header" style="padding-top: 10px; padding-bottom: 20px;">
							<h4 style="font-size: 16px; font-weight: 400;">
								<span id="headerText"></span>
								<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
								</button>
							</h4>
						</div>
						<div class="modal-footer">
							<button id="dontShow" type="button" class="btn btn-default pull-left" data-dismiss="modal">${app.getResource('do.not.ask.me.again')}</button>
							<button id="no" type="button" class="btn btn-danger pull-right" data-dismiss="modal">${app.getResource('no')}</button>
							<button id="yes" type="button" class="btn btn-success pull-right" data-dismiss="modal">${app.getResource('yes')}</button>
						</div>
					</div>
				</div>`
	}

	show (options: Object) {
		const that = this
		this.model = new BaseModel({})

		this.$el.find('.modal-header h4 #headerText').html(translate('deploy.propose.modal.text'))
		this.$el.find('#yes').click(() => {
			if (options.deployModal) {
				let onHidden = () => {
					options.deployModal()
					this.$el.off('hidden.bs.modal', onHidden)
				}
				this.$el.on('hidden.bs.modal', onHidden)
				this.$el.modal('hide')
			} else {
				options.deploy()
			}
		})
		this.$el.find('#no').click(() => {
			that.$el.modal('hide')
		})
		this.$el.find('#dontShow').click(() => {
			localStorage.setItem('dontShowProposeDeployModal', true)
		})

		this.$el.modal({backdrop: 'static'})
	}

}

import utils from '../common/components/utils.js';
import Constants from '../common/models/constants';
import Templates from './templates';
import ViewKind from '../common/enums/viewKind.js';

export default class Workspace {

	initialize() {
		$.holdReady(true);
	}
	getTemplates(templates) {
    const promises = []
    templates.forEach(template => {
      let promise = Promise.resolve()
			if (template.htmlForWidget) {
				promise = Promise.resolve(template.htmlForWidget)
			} else if (template.url) {
        promise = new Promise((resolve, reject) => {
          utils.ajaxRequest(null, template.url, 'get', resolve, reject, false, null, template.cache)
        })
      }
      promise.then(html => template.render(html))
      promises.push(promise)
    })
    return Promise.all(promises).then(() => {
      $.holdReady(false)
    })
	}
	createUpdateInstance(el, viewUrl, html) {
		var templates = [];
		const form = jQuery.extend({
			url: viewUrl,
			htmlForWidget: html
		}, Templates.clientFormTemplate(el));
		if (app.builderMode) {
				form.cache = false;
		} else {
			templates.push(Templates.main);
		}
		templates.push(form);
		return this.getTemplates(templates);
	}
	typeIndex(op, viewUrl) {
		var templates = [];
		const index = jQuery.extend({
			url: viewUrl,
		}, Templates.indexTemplate(op));
		if (app.builderMode) {
			index.cache = false;
		} else {
			templates.push(Templates.main);
		}
		templates.push(index);
		return this.getTemplates(templates);
	}
	timeline() {
		var templates = [Templates.main];
		return this.getTemplates(templates);
	}
}

import BaseCollection from './baseCollection';


class MapCollection extends BaseCollection {

	initialize() {
	}

	add(key, value) {
	}

	get(key) {
	}

	set(key, value) {
	}

	remove(key) {
	}

}

export default MapCollection;

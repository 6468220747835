import MultilingualString from "../common/models/multilingualString";
import Constants from "../common/models/constants";
import {translate} from "../common/service/stringResourceService";
import ViewKind from "../common/enums/viewKind";

export function renderAddTypeButton() {
	return `
		<li class="dropdown type-edit-controll" data-original-title="" title="">
			<a href="#" class="dropdown-toggle" data-toggle="dropdown">
				<i class="material-icons notranslate material-icons-size material-icons notranslate material-icons-size label tabel-label block-label" style="color: inherit;vertical-align: sub;padding:0px 10px;">add_box</i>
			</a>

			<ul style="left:auto; top:auto" class="dropdown-menu wide-tooltip">
				<li data-original-title="" title="">
					<a class="navigationAddType" data-kind="DICTIONARY">
						<span>${translate('dictionary')}</span>
					</a>
				</li>
				<li data-original-title="" title="">
					<a class="navigationAddType" data-kind="PRIMITIVE">
						<span>${translate('primitive')}</span>
					</a>
				</li>
				<li data-original-title="" title="">
					<a class="navigationAddType" data-kind="EMBEDDED">
						<span>${translate('embedded')}</span>
					</a>
				</li>
				<li data-original-title="" title="">
					<a class="navigationAddType" data-kind="DOCUMENT">
						<span>${translate('document')}</span>
					</a>
				</li>
				<li data-original-title="" title="">
					<a class="navigationAddType" data-kind="REPORT">
						<span>${translate('report')}</span>
					</a>
				</li>
				<li data-original-title="" title="">
					<a class="navigationAddType" data-kind="REGISTER">
						<span>${translate('register')}</span>
					</a>
				</li>
				<li data-original-title="" title="">
					<a class="navigationAddType" data-kind="TRANSIENT">
						<span>${translate('transient')}</span>
					</a>
				</li>
				<li data-original-title="" title="">
					<a class="navigationAddType" data-kind="EXTERNAL">
						<span>${translate('external')}</span>
					</a>
				</li>
			</ul>
		</li>`;
}

export function renderAddViewButton() {
		return `
				<li class="dropdown type-edit-controll" data-original-title="" title="">
					<a href="#" class="dropdown-toggle" data-toggle="dropdown">
						<i class="material-icons notranslate material-icons-size material-icons notranslate material-icons-size label tabel-label block-label" style="color: inherit;vertical-align: sub;padding:0px 10px;">add_box</i>
					</a>
					<ul class="dropdown-menu">
						<li>
							<a class="navigationAddView" data-kind="FORM">
								<span>${translate('web.page')}</span>
							</a>
						</li>
						<li>
							<a class="navigationAddView" data-kind="STRING">
								<span>${translate('string.view')}</span>
							</a>
						</li>
						<li>
							<a  class="navigationAddView" data-kind="ROW">
								<span>${translate('table.view')}</span>
							</a>
						</li>
						<li>
							<a  class="navigationAddView" data-kind="LIST">
								<span>${translate('list.view')}</span>
							</a>
						</li>
						<li>
							<a  class="navigationAddView" data-kind="SPREADSHEET" style="display: none;">
								<span>${translate('spreadsheet')}</span>
							</a>
						</li>
						<li>
							<a  class="navigationAddView" data-kind="DOC" style="display: none;">
								<span>${translate('doc.view')}</span>
							</a>
						</li>
						<li>
							<a  class="navigationAddView" data-kind="XML" style="display: none;">
								<span>${translate('xml.view')}</span>
							</a>
						</li>
						<li>
							<a  class="navigationAddView" data-kind="WIDGET">
								<span>${translate('widget.view')}</span>
							</a>
						</li>
					</ul>
				</li>`;
	}

export function renderAddBlockButton() {
		return `
			<li class="type-edit-controll dropdown additionButton" help-popover="blocks" data-original-title="" title="">
				<a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
					<i class="material-icons notranslate material-icons-size material-icons notranslate material-icons-size label tabel-label block-label" style="color: inherit;vertical-align: sub;padding:0px 10px;">add_box</i>
				</a>
				<ul class="dropdown-menu">
					<li>
						<a  class="navigationAddBlock" data-kind="ACTION">
							<span>${translate('action')}</span>
						</a>
					</li>
					<li>
						<a  class="navigationAddBlock" data-kind="FUNCTION">
							<span>${translate('function')}</span>
						</a>
					</li>
					<li>
						<a  class="navigationAddBlock" data-kind="ACTION" data-trigger="CUSTOM">
							<span>${translate('custom.action')}</span>
						</a>
					</li>
					<li>
						<a  class="navigationAddBlock" data-kind="ACTION" data-trigger="CREATE_FROM">
							<span>${translate('fill.function')}</span>
						</a>
					</li>
				</ul>
			</li>`;
	}

export function renderAddFieldButton() {
	return `
		<li class="dropdown type-edit-controll additionButton">
			<a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
				<i class="material-icons notranslate material-icons-size material-icons notranslate material-icons-size label tabel-label block-label" style="color: inherit;vertical-align: sub;padding:0px 10px;" help-popover="fields" data-original-title="" title="">add_box</i>
			</a>
			<ul class="dropdown-menu">
				<li data-original-title="" title="">
					<a  class="navigationAddField" data-kind="REGULAR">
						<span>${translate('regular.field')}</span>
					</a>
				</li>
				<li data-original-title="" title="">
					<a  class="navigationAddField" data-kind="COLLECTION">
						<span>${translate('collection')}</span>
					</a>
				</li>
				<li data-original-title="" title="">
					<a  class="navigationAddField" data-kind="DYNAMIC">
						<span>${translate('dynamic')}</span>
					</a>
				</li>
				<li>
					<a  class="navigationAddSharedField" data-kind="SHARED">
						<span>${translate('shared')}</span>
					</a>
				</li>
			</ul>
		</li>`;
}

export function recentlyTasks(tasks) {
	let resultTasks = []
	tasks.forEach((task) => {
		let target = task.mainTarget
		let title = ''
		let titleForLink = task.mainTarget.title ? MultilingualString.getCurrentValue(task.mainTarget.title) : ''
		if (!titleForLink) {
			titleForLink = target.targetId
		}
		switch (target.objectTypeId) {
			case Constants.ID_TYPE_TYPE:
				title += translate('system.field.type') + '; '
				break;
			case Constants.ID_TYPE_FIELD:
				title += translate('system.field.field.name') + '; '
				break;
			case Constants.ID_TYPE_BLOCK:
				title += translate('system.field.block') + '; '
				break;
			case Constants.ID_TYPE_VIEW:
				let view = target.viewKind
				if (view == ViewKind.FORM) {
					title += translate('web.page') + ' '
				} else {
					title += view + ' '
				}
				title += translate('view') + '; '
				break;
			case Constants.ID_TYPE_ENTITY_ROUTE:
				title += translate('system.type.entity.route.name') + '; '
				break;
			case Constants.ID_TYPE_ENTITY_MENU:
				title += translate('menu') + '; '
				break;
			case Constants.ID_TYPE_PERMISSION:
				title += translate('permission') + '; '
				break;
			case Constants.ID_TYPE_CONFIGURATION:
				titleForLink = translate('system.type.configuration.name')
				break;
			case Constants.ID_TYPE_CSS_THEME:
				title += translate('css.theme') + '; '
				break;
			default:
				title += MultilingualString.getCurrentValue(app.types.get(target.objectTypeId).get('name')) + '; '
		}
		title = title.toLowerCase() + target.action
		let link
		if (target.objectTypeId == Constants.ID_TYPE_FIELD) {
			link = app.urls.entityTypeDetails(null, app.configuration.id, 'SHARED_FIELDS_TABLE')
		} else if (target.objectTypeId == Constants.ID_TYPE_CONFIGURATION || target.objectTypeId == Constants.ID_TYPE_ENTITY_ROUTE
			|| target.objectTypeId == Constants.ID_TYPE_CUSTOM_LIBRARY || target.objectTypeId == Constants.ID_TYPE_ENTITY_GENERATOR) {
			link = app.urls.open(target.objectTypeId, null)
		} else {
			link = app.urls.open(target.objectTypeId, target.targetId)
		}
		resultTasks.push({
			title: title,
			link: link,
			titleForLink: titleForLink
		})
	})
	return resultTasks
}

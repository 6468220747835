 /* @flow */
import Instant from '../../time/models/instant'
import FieldTemporalAccesorInput from './fieldTemporalAccesorInput'
var FieldTemporalAccesorInputProperties = FieldTemporalAccesorInput.extend({

	getDataFromModel: function () {
		let string = this.model.get(this.modelAttr)
		let instant = null
		if (string){
			 let data = JSON.parse(string)
			 if (data){
				 instant=new Instant(data.seconds,data.nanos)
			 }
		}
		return instant
	},

	setDataToModel: function (obj) {
		if (this.model) {
				this.model.set(this.modelAttr, JSON.stringify(obj));
				this.model.trigger('manualChange:' +  this.modelAttr, this.model);
		}
	},
 });

export default FieldTemporalAccesorInputProperties;

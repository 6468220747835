/* @flow */

export default class PrimitiveEntityType {
	static STRING = 'STRING';
	static INTEGER = 'INTEGER';
	static DOUBLE = 'DOUBLE';
	static BOOLEAN = 'BOOLEAN';
	static BINARY = 'BINARY';
	static DECIMAL = 'DECIMAL';
	static SYSTEM_STRING = 'SYSTEM_STRING';
	static TIMESTAMP = 'TIMESTAMP';
	static ZONED_DATE_TIME = 'ZONED_DATE_TIME';
	static PERIOD = 'PERIOD';
	static DURATION = 'DURATION';
	static LOCAL_DATE = 'LOCAL_DATE';
	static LOCAL_TIME = 'LOCAL_TIME';
	static LOCAL_DATE_TIME = 'LOCAL_DATE_TIME';
	static DAY_OF_WEEK = 'DAY_OF_WEEK';
	static MONTH = 'MONTH';
	static MONTH_DAY = 'MONTH_DAY';
	static YEAR = 'YEAR';
	static YEAR_MONTH = 'YEAR_MONTH';
	static ZONE_OFFSET = 'ZONE_OFFSET';
	static DOCUMENT_REGISTER_STATE = 'DOCUMENT_REGISTER_STATE';
	static META_OBJECT_STATE = 'META_OBJECT_STATE';
	static LOCAL_DATE_RANGE = 'LOCAL_DATE_RANGE';
	static ENUM = 'ENUM';
	static JSON_NODE = 'JSON_NODE';

	static getAvailableToCreate() {
		return [
			this.STRING,
			this.INTEGER,
			this.DOUBLE,
			this.BOOLEAN,
			this.BINARY,
			this.DECIMAL,
			this.TIMESTAMP,
			this.LOCAL_DATE,
			this.LOCAL_TIME,
			this.LOCAL_DATE_TIME,
			this.LOCAL_DATE_RANGE,
			this.SYSTEM_STRING];
	}

	static getNullable() {
		return [
			this.INTEGER,
			this.BOOLEAN,
			this.DECIMAL,
			this.SYSTEM_STRING];
	}

	static getTypesThatSupportFormatters() {
		return [
			this.TIMESTAMP,
			this.LOCAL_DATE,
			this.LOCAL_TIME,
			this.LOCAL_DATE_TIME,
			this.MONTH,
			this.MONTH_DAY,
			this.YEAR,
			this.YEAR_MONTH,
			this.DAY_OF_WEEK,
			this.PERIOD,
			this.DURATION,
			this.ZONE_OFFSET,
			this.LOCAL_DATE_RANGE,
			this.STRING,
			this.INTEGER,
			this.DECIMAL,
			this.DOUBLE,
			this.SYSTEM_STRING];
	}

	static getNonStandard() {
		return [
			this.BINARY,
			this.DECIMAL,
			this.DURATION,
			this.LOCAL_DATE_RANGE,
			this.LOCAL_TIME,
			this.META_OBJECT_STATE,
			this.MONTH_DAY,
			this.STRING,
			this.PERIOD,
			this.YEAR,
			this.YEAR_MONTH,
			this.ZONE_OFFSET
		]
	}
}

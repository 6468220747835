import { translate } from '../service/stringResourceService'

class SingleSessionWarningModal extends Backbone.View {

	initialize () {
		let $el = $('#singleSessionWarningModal');
		if ($el.length === 0) {
			let el = document.createElement('div');
			el.id = 'singleSessionWarningModal';
			el.classList.add('modal');
			el.classList.add('fade');
			document.body.appendChild(el);
		}
		$el = $('#singleSessionWarningModal');
		$el.html(this.getHTML());
		this.setElement($el);
	}

	getHTML () {
		return `
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header create-modal-header">
							<h4>
								<span id="headerText">${translate('single.session.per.user.modal.header')}</span>
							</h4>
						</div>
						<div class="modal-body">
							<span>${translate('single.session.per.user.modal.text')}</span>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default closeAndRedirect" data-dismiss="modal">${translate('ok')}</button>
						</div>
					</div>
				</div>`
	}

	show (options) {
		this.$el
			.find('.closeAndRedirect')
			.click(() => {
				// TODO: or redirect to app.urls.login or app.urls.customLoginPage
				location.reload();
			});

		this.$el.modal({backdrop: 'static'});
	}

}

export default SingleSessionWarningModal;

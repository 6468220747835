import TypeKind from '../common/enums/typeKind';
import PropertyKey from '../common/enums/propertyKey';
import StringKind from '../common/enums/stringKind';
import PrimitiveEntityType from '../common/enums/primitiveEntityType';
import FieldKind from '../common/enums/fieldKind';
import ViewKind from '../common/enums/viewKind';
import ViewModifier from '../common/enums/viewModifier';
import MultilingualString from '../common/models/multilingualString';
import StringViewComponent from './StringViewComponent.vue';
import StringViewExternalComponent from './StringViewExternalComponent.vue';
import formattersAndStyles from '../common/components/formattersAndStyles'
import Formatter from '../common/components/formatter';
import {getView} from '../common/components/utils'
import {calcFixedTopOffset} from '../common/components/utils'
import {translate} from '../common/service/stringResourceService'
import LocalStorageKeysService from '../common/service/localStorageKeysService'
import stateRecovery from '../common/components/stateRecovery'
import Constants from '../common/models/constants'

export function formatOrder(order, typeId){
	let formattedOrder={}
	if (order){
		if (order.order=="asc"){
			formattedOrder.ascendingOrder=true;
		}else if (order.order=="desc") {
			formattedOrder.ascendingOrder=false;
		}
		formattedOrder.field={id:getIdByFieldName(order.field, typeId)}
		return formattedOrder
	}
}

function findVirtualScroller(elem) {
	const isElemVirtualScroller = function(elem) {
		if (!elem.classList) return false
		if (elem.classList.contains('virtual-scroller')) return true
		return false
	}
	let parent = elem
	while (parent && !isElemVirtualScroller(parent)) {
		parent = parent.parentElement
	}
	return parent
}

function formatCollectionInTable(object, options) {
	let badge = null
	let container = null
	let list = null
	let virtualScroller = null
	options.el.firstChild.childNodes.forEach((elem) => {
		if (!elem.classList) return
		if (elem.classList.contains('badge')) {
			badge = elem
		} else if (elem.classList.contains('list-container')) {
			container = elem
		}
	})
	if (!object || !object.length) {
		options.el.firstChild.classList.add('no-items')
	} else {
		options.el.firstChild.classList.remove('no-items')
		badge.text = object.length
		let handler = (e) => {
			if (!container.parentElement.contains(e.target)) {
				container.style.display = 'none'
				window.removeEventListener('click', handler)
			}
		}
		let show = () => {
			container.style.display = 'block'
			list.style.top = calcFixedTopOffset(
				virtualScroller.scrollTop + window.scrollY,
				badge.offsetTop,
				list.offsetHeight,
				30
			) + 'px'
			window.addEventListener('click', handler)
		}
		let initialized = false
		const onBadgeClick = (e) => {
			if (!initialized) {
				container.childNodes.forEach((elem) => {
					if (elem.tagName == 'UL') {
						list = elem
					}
				})
				list.innerHTML = ''
				virtualScroller = findVirtualScroller(options.el)
				const stringViews = object.map((instance) => {
					return app.entityManager.fetchStringView(null, instance.id).then((data) => {
						let a = document.createElement('a')
						a.setAttribute('href', app.urls.update(options.typeId, instance.id))
						a.text = data
						let li = document.createElement('li')
						li.appendChild(a)
						list.appendChild(li)
					})
				})
				app.entityManager.fetchStringViews()
				Promise.all(stringViews).then(() => {
					show()
					initialized = true
				})
			} else {
				show()
			}
		}
		badge.removeEventListener('click', onBadgeClick)
		badge.addEventListener('click', onBadgeClick)
	}
}

export function fromRowViewItemsToOptions(rowViewItems,table){
  let op={}
  op.rows=table.data
  let columns=[]
  let id = table.id
  if (id == 'index-table') {
    id += table.typeId
  }
  let lsKey = LocalStorageKeysService.buildKeyForComponent(id)
  _.each(rowViewItems.rowItems,(rowItem)=>{
		let environment = getOptions(rowItem, table)
		if (!environment.hidden){
			columns.push(generateColumnForOptions(rowItem, environment, lsKey))
		}
  })
  op.columns=columns
  return op
}

export function getOldTableData(rowViewItems, isIndexTable, typeId):object{
  let columns=[]
  if (isIndexTable && typeId && app.types.get(typeId).isDocument()) {
	let f = app.fields.get(Constants.ID_FIELD_IS_POSTED)
	columns.push({
		fieldId: f.id,
		field: f.name()
	})
  }
  _.each(rowViewItems.rowItems,(rowItem)=>{
    let viewId=""
    if (rowItem.owner){
      viewId=rowItem.owner.id
    }
		let column = {fieldId:rowItem.entityField.id,field:app.fields.get(rowItem.entityField.id).fieldName(),viewId:viewId}
		if (!rowItem.properties[PropertyKey.FIELD_LABEL]) {
			column.name = rowItem.properties[PropertyKey.HEADER]
		}
    columns.push(column)
  })
  let res={
    getTableFilterFields:function(){
      return columns;
    }
  }
	res.isIndexTable=isIndexTable;
  res.newTable=true;
  return res
}

export function getRowViewItems(viewId, typeId){
  return getView(viewId, typeId).then(rowView => {
		rowView.rowItems.sort((a, b)=> a.relativeOrder - b.relativeOrder)
		return rowView
	})
}

function generateColumnForOptions(rowItem, environment, lsKey){
  let col={}
  let appField=app.fields.get(rowItem.entityField.id)
  let field=appField.fieldName();
  col.field=field
  if (environment.beforeSort) {
    col.beforeSort = (col) => environment.beforeSort(col, environment)
  }
  if (environment.title){
    col.title= MultilingualString.toHTML(environment.title, null, true)
  } else {
    col.title= MultilingualString.toHTML(appField.get('name'), null, true)
  }
  if (environment.sortable){
    if (environment.sorter){
      let oldT={options:{sortName:field,columns:[[{primitiveType:environment.primitiveType,viewId:environment.viewId}]]},fields:[field]}
      col.sorter= function(b,a){ return environment.sorter.call(oldT,b,a)}
    }else{
      col.sorter=formattersAndStyles.predicate
    }
  }
  if (environment.isTransient){
    col.isTransient=true
  }else{
    col.isTransient=false
  }
  if (environment.isReadOnly){
    col.isReadOnly=true
  }else{
    col.isReadOnly=false
  }
	if (environment.readOnlyView && environment.readOnlyView == 'Plain text'){
		col.readOnlyViewPlainText = true
	}
  if (environment.extended){
    col.isHidden=true
  }else{
    col.isHidden=false
  }
	if (environment.klass){
		col.klass = environment.klass
	}
  if (environment.required) {
    col.required = true
  } else {
    col.required = false
  }
  col.definedOrder = environment.definedOrder
  if (environment.valign) {
    col.css = col.css || {}
		col.css['justify-content'] = {
			start: 'flex-start',
			center: 'center',
			end: 'flex-end'
		}[environment.valign]
		if (environment.primitiveType == 'BOOLEAN') {
			col.css['display'] = 'flex'
		}
	}
  col.component = environment.component

	if (environment.collection) {
		col.isCollection = true
		col.component = {
			implementation: 'cjg-custom',
			data: {
				func: (el, row, field) => {
					formatCollectionInTable(row[field], {
						el: el,
						typeId: environment.entityTypeId,
						formatterId: environment.formatterId
					})
				},
				html:`<div class="collection-in-table">
					<a title="${translate('press.to.see.list')}" class="badge"></a>
					<div class="list-container" style="display:none">
						<ul>
						</ul>
					</div>
					<span class="no-items">-</span>
				</div>
				`
			}
		}
	}
  if (environment.clientViewId) {
    col.clientViewId = environment.clientViewId
    col.component = {
      implementation: 'cjg-custom',
      data: {
        func: (el, row, field) => {
          Formatter.formatToHTMLWithClientView(row[field], {
            el: $(el),
            typeId: environment.entityTypeId,
            clientViewId: environment.clientViewId,
            formatterId: environment.formatterId
          })
        },
        html:""
      }
    }
  }
	if (environment.dataSource) {
		col.dataSource = environment.dataSource
	}
  if (environment.formatter) {
    col.formatter = (value, row, index) => {
      var res = environment.formatter.call(environment, value, row, index);
      return res
    }
  }
	if (environment.lockResize) {
		col.lockResize = true
	}
  if (environment.width) {
		const ls = stateRecovery.get(lsKey)
		if (col.lockResize || !(ls && ls.columsSizes && ls.columsSizes[col.field])){
			col.width = parseInt(environment.width)
		}
  }
  if (environment.comment) {
    const comment = new MultilingualString(environment.comment).getCurrentValue()
    if (comment)
    col.comment = {
      content: comment,
      placement: environment.commentPlacement
    }
  }
  return col
}

export function rowItemsToTableColumns (rowItems) {
	return fromRowViewItemsToOptions(rowItems, {}).columns
}

function getOptions(entity, table){
  let entityField =app.fields.get(entity.entityField.id)
  let options={}
  options.newTable=true
  options.footerFormatter=formattersAndStyles.footerFormatter
  options.fieldId=entityField.get('id')
  var fieldType=app.types.get(entityField.get('fieldTypeId'))

  if (entity.properties){
    if (entity.properties[PropertyKey.DEFAULT_SORTING] && entity.properties[PropertyKey.DEFAULT_SORTING] !== 'NONE') {
      options.definedOrder = entity.properties[PropertyKey.DEFAULT_SORTING].toLowerCase()
    }
    options.extended = entity.properties[PropertyKey.DONT_SHOW_BY_DEFAULT]
    options.hidden = entity.properties[PropertyKey.HIDDEN]
    if (!entity.properties[PropertyKey.FIELD_LABEL]) {
      options.title = entity.properties[PropertyKey.HEADER]
    }
    options.isReadOnly = entity.properties[PropertyKey.READ_ONLY]
		options.readOnlyView = entity.properties[PropertyKey.READ_ONLY_VIEW]
    options.required = entity.properties[PropertyKey.REQUIRED]
    options.width = entity.properties[PropertyKey.WIDTH]
		options.lockResize = entity.properties[PropertyKey.LOCK_RESIZE]
    options.comment = entity.properties[PropertyKey.COMMENT]
    options.commentPlacement = entity.properties[PropertyKey.COMMENT_PLACEMENT]
    options.valign = entity.properties[PropertyKey.ALIGN]
    options.klass = entity.properties[PropertyKey.CLASSES]
  }

  if (entityField.kind() == FieldKind.COLLECTION) {
    options.collection = true
  }
  if (entityField.get('fieldKind') != FieldKind.DYNAMIC &&
      fieldType.get('primitiveEntityType') != PrimitiveEntityType.BINARY &&
      fieldType.get('primitiveEntityType') != PrimitiveEntityType.PERIOD &&
    (!fieldType.get('primitiveTypeProperties') ||
      fieldType.get('primitiveTypeProperties').stringKind != StringKind.HTML)) {
        options.sortable=true;
  }

  options.isTransient=entityField.get('isTransient')
  if (entity.entityView && entity.entityView.id) {
    options.viewId=entity.entityView.id
  } else {
    options.viewId = null
  }

  options.entityTypeId=entityField.get('fieldTypeId')

  if (entityField.get('fieldKind') == FieldKind.REGULAR &&
    fieldType.get('typeKind') == TypeKind.PRIMITIVE) {
      if (fieldType.get('primitiveEntityType') == PrimitiveEntityType.DECIMAL){
        options.scale=fieldType.get('primitiveTypeProperties').decimalScale
      }
    options.clientViewId = entity.properties[PropertyKey.CLIENT_VIEW]
    if (fieldType.get('primitiveEntityType') == PrimitiveEntityType.STRING) {
      if (fieldType.get('primitiveTypeProperties').stringKind == StringKind.HTML ) {
      	if (entity.properties[PropertyKey.HTML_STRING_POPOVER]==true) {
      		options.formatter=formattersAndStyles.htmlStringFormatterWithLink
      	} else {
      		options.formatter=formattersAndStyles.htmlStringFormatter
      	}
      } else {
        options.sorter=formattersAndStyles.multilingualstringPredicate
      }
    } else {
      if (fieldType.get('primitiveEntityType') == PrimitiveEntityType.TIMESTAMP ||
      fieldType.get('primitiveEntityType') == PrimitiveEntityType.DURATION ||
      fieldType.get('primitiveEntityType') == PrimitiveEntityType.LOCAL_DATE ||
      fieldType.get('primitiveEntityType') == PrimitiveEntityType.LOCAL_TIME ||
      fieldType.get('primitiveEntityType') == PrimitiveEntityType.LOCAL_DATE_TIME ||
      fieldType.get('primitiveEntityType') == PrimitiveEntityType.DAY_OF_WEEK ||
      fieldType.get('primitiveEntityType') == PrimitiveEntityType.MONTH ||
      fieldType.get('primitiveEntityType') == PrimitiveEntityType.MONTH_DAY ||
      fieldType.get('primitiveEntityType') == PrimitiveEntityType.YEAR ||
      fieldType.get('primitiveEntityType') == PrimitiveEntityType.YEAR_MONTH) {
        options.sorter=formattersAndStyles.dateAndTimePredicate
      }
      options.formatterId = entity.formatter && entity.formatter.id
    }
		options.formatter = options.formatter || formattersAndStyles.primitiveFormatter({
			formatterId: entity.formatter && entity.formatter.id,
			type: fieldType
		});
    options.primitiveType=fieldType.get('primitiveEntityType')

    } else if (options.collection) {
      options.sortable = false
    } else {
    if (entity.properties){
      options.isLink = entity.properties[PropertyKey.IS_LINK] || (entity.properties[PropertyKey.OPEN] != 'dont.show.view.link');
      options.linkFormViewId = entity.properties[PropertyKey.LINK_FORM_VIEW]
			const dataSource = entity.properties[PropertyKey.DATA_SOURCE]
			if (dataSource != 'Database') {
				options.dataSource = dataSource
			}
    }
    let showFunc = function (result, value) {
      let stringView = result
      if (options.isLink === true) {
				const id = ( fieldType && fieldType.kind() == TypeKind.EXTERNAL) ? value.urlString : value.id
				stringView = `<span class="btn-link ref" data-kind="${entity.properties[PropertyKey.OPEN]}" data-objectId="${id}"
				data-type="${options.entityTypeId || value.objectType.id}" ${options.linkFormViewId ? 'data-linkForm="' + options.linkFormViewId + '"' : ''}>${result}</span>`
      }
      return stringView
    }
		if (fieldType && fieldType.kind() == TypeKind.EXTERNAL) {
			options.sortable = false
			options.component = {
				implementation: StringViewExternalComponent,
				data: {
					showFunc: showFunc,
					viewId: options.viewId,
					type: fieldType
				}
			}
		} else {
			options.component = {
	      implementation: StringViewComponent,
	      data: {
	        showFunc: showFunc,
	        viewId: options.viewId
	      }
	    }
		}

    options.sorter=formattersAndStyles.stringPredicate
    options.beforeSort = (col, env) => {
      _.each(table.data, row => {
        let cell = row[col.field]
        cell && app.entityManager.fetchStringView(env.viewId, cell.id)
      })
      return app.entityManager.fetchStringViews()
    }
  }
  return options
}


export function generateRowViewItems(entityTypeId,owner):array{
return  _.map(_.filter(_.filter(getAllFields(entityTypeId),(a)=>{
      return !a.get('isHidden')
    }),(f)=>{
      let typeKind=findType(f.get('fieldTypeId')).get('typeKind')
      return (f.get('fieldKind') == FieldKind.DYNAMIC || (
        f.get('fieldKind') != FieldKind.COLLECTION &&  typeKind != TypeKind.REGISTER && typeKind != TypeKind.EMBEDDED && typeKind != TypeKind.TRANSIENT && typeKind != TypeKind.EXTERNAL
      ))
    }),(o)=>{
      let item={owner:owner, entityField:o}
      if (findType(o.get('fieldTypeId'))!= null){
        if (findType(o.get('fieldTypeId')).get('typeKind') == TypeKind.PRIMITIVE){
          item.entityView=getDefaultView(findType(o.get('fieldTypeId')), ViewKind.PRIMITIVE)
        }
      }
      item.relativeOrder=o.relativeOrder;
      let field=""
      if (item.systemName){
        field=item.systemName
      }else{
        field='field'+item.id;
      }
      item.fieldName=field
      return item
    })
}

export function getFieldsToShow(rowViewItem) {
	return rowViewItem.rowItems.filter((rowItem) => {
		return !rowItem.properties.DONT_SHOW_BY_DEFAULT
	}).map((rowItem) => {
		return app.fields.get(rowItem.entityField.id).fieldName()
	})
}

export function getIsGdprSensitiveFields(rowViewItem) {
	return rowViewItem.rowItems.filter((rowItem) => {
		return app.fields.get(rowItem.entityField.id).isGdprSensitive()
	}).map((rowItem) => {
		return app.fields.get(rowItem.entityField.id).fieldName()
	})
}

export function getIdByFieldName(fieldName, typeId):string{
	return app.types.get(typeId).fieldByName(fieldName).id
}

function getAllFields(entityTypeId){
  let res= _.filter(app.fields.models,(a)=>{
    return a.get('ownerId')==entityTypeId
  })
  let entityType=_.find(app.types.models,(a)=>{
    return a.get('id')==entityTypeId
  })
  _.each(entityType.get('sharedFieldsIds'),(e)=>{
    _.find(app.fields.models,(a)=>{
      if (a.get('id')==e){
        res.push(a)
      }
    })
  })
  return res
}

function generateView(entityType,viewKind){
  let entityView={};
  entityView.isGenerated=true;
  entityView.viewKind=viewKind;
  entityView.owner=entityType;
  entityView.viewModifier=ViewModifier.DEFAULT;
  switch (viewKind){
    case ViewKind.ROW:
      entityView.rowItems=generateRowViewItems(entityType, entityView);
      break
    case ViewKind.PRIMITIVE:
      entityView.primitiveFormat = null
      break
  }
  return entityView;
}

function findType(typeId):object{
  return _.find(app.types.models,(a)=>{return a.get('id')==typeId})
}

function getDefaultView(entityType,viewKind){
  return generateView(entityType, viewKind)
}

/* @flow */

import MultilingualString from '../../common/models/multilingualString';
import Constants from '../../common/models/constants';
import utils from '../../common/components/utils.js';
import TableFilter from './tableFilter';
import FilterModal from './filterModal.js';
import FieldFilter from '../../common/models/fieldFilter'
import States from '../../common/enums/states'

export default TableFilter.extend({

	events() {
		return _.extend({}, TableFilter.prototype.events, {
			'filter-removed': 'onFilterRemoved'
		});
	},

	initialize(options) {
		this.fields = app.fields;
		this.types = app.types;
		this.typeId = options.context.typeId;
		this.context = options.context
		this.filters = options.model.get(options.modelAttr);
		this.filters.forEach((f) => {
			f.set('field', app.fields.get(f.get('field').get('id')))
			f.set('value', FieldFilter.valueFromJSON(f.get('field').type(), JSON.parse(f.get('value'))))
		})
		this.reloadFilters();

		this.listenTo(options.model, 'change:' + options.modelAttr, () => {
			this.filters = options.model.get(options.modelAttr);
			this.reloadFilters();
		});

		let that = this
		this.filters.toServerJSON = () => {
			let result = that.toServerJSON()
			that.context.data && that.context.data.filters.forEach((f) => {
				let inModel = result.find((i) => { return i.id == f.id })
				if (!inModel) {
					result.push({id: f.id,  clientState: States.DELETED})
				}
			})
			return result
		}

		this.modal = new FilterModal({
			el: this.$el,
			tableFilter: this,
			fields: () => this.getFieldsForFilterModal(),
			types: app.types,
			appFields: app.fields
		});

		const field = app.fields.get(Constants.ID_FIELD_FIELD_FILTERS);
		const fieldNameHTML = MultilingualString.toHTML(field.name());
		this.$('.box-title').html(fieldNameHTML);

		this.$el.on('filter-removed', () => this.onFilterRemoved());
	},

	getFieldsForFilterModal() {
		const dataType = app.types.get(this.typeId).reportDataType();
		const sharedFields = dataType.sharedFields().map(f => f.toJSON());
		const fields = dataType.fields().map(f => f.toJSON());
		return [].concat(sharedFields, fields);
	},

	renderHTML() {
		this.$('.box-tools').html(this.buttonGroupHtml());
	},

	_drawFilter(filter) {
		this.$('.placeholder').hide();
		TableFilter.prototype._drawFilter.apply(this, arguments);
	},

	_constructFilterViewShell(filterId) {
		return $('<div/>', {
			'data-filter-id': filterId,
			'class': 'box-header with-border'
		});
	},

	_fillFilterView($view, text) {
		const toolsDiv = $('<div class="report-list-tools pull-left">')
		const closeButton = $('<i class="material-icons notranslate remove-filter list-button secondary-button" role="button">close</i>');
		toolsDiv.append(closeButton);
		$view.append(toolsDiv);
		$view.append(text);
	},

	onFilterRemoved() {
		if (this.filters.length === 0) {
			this.$('.placeholder').show();
		}
	},

	_emptyContainer() {
		this.$('.filters-container').children().not('.placeholder').remove();
		this.$('.placeholder').show();
	}
});

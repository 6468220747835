/* @flow */
import { Service } from './service';
import Constants from '../models/constants';
import  Entity from '../models/entity';

class LanguageService {
	getCurrentLanguage() {
		const language = app.enabledLanguages.find(lang => lang.language === app.currentLanguage) || {};
		return new Entity.reference(language.id, Constants.ID_TYPE_LANGUAGE);
	}
}

export let languageService = new LanguageService();

import CreateExportView from '../baseExportViews/createExportView';
import utils from '../../../common/components/utils';

var CreateXmlView = CreateExportView.extend({

	initialize: function (options) {
		const that = this;

		options.acceptedFiles = '.xml';

		options.onSuccess = (file, response) => {
			console.log('SUCCESS');
			that.model.get('xmlProperties').template = response;
		}
		options.onRemovedFile = (file) => {
			console.log('REMOVED');
			that.model.get('xmlProperties').template = null;
		}

		CreateXmlView.__super__.initialize.apply(this, arguments);
	}
});

export default CreateXmlView;

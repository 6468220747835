import  BaseModel from '../../common/models/baseModel.js';
import  BaseCollection from '../../common/collections/baseCollection.js';
import  BaseSelect from './baseSelect.js';

var EntitySelect = BaseSelect.extend({

	modelType: BaseModel,

	modelCollectionType: BaseCollection,

	initialize: function (options) {
		EntitySelect.__super__.initialize.apply(this, arguments);
		if (options.modelType) {
			this.modelType = options.modelType;
			this.modelCollectionType = options.modelCollectionType;
		}
	},

	setValue: function (value) {
		if (this.$el.attr('multiple')) {
			if (value) {
				value = _.map(value, function (item) {
					return item.id;
				});
				this.$el.val(value).trigger('change');
			} else {
				this.$el.val([]).trigger('change');
			}
		} else {
			if (value && value.id) {
				this.$el.val(value.id).trigger('change');
			} else {
				this.$el.val(null).trigger('change');
			}
		}
	},

	getValue: function () {
		if (this.$el.attr('multiple')) {
			return _.map(this.$el.select2('data'), function (item) {
				return {id: item.id};
			});
		} else {
			return this.$el.select2('data').length === 0 ?
				null : {id: this.$el.select2('data')[0].id};
		}
	},

	getValueWithProperties: function (additionalProperties) {
		if (this.$el.attr('multiple')) {
			return _.map(this.$el.select2('data'), function (item) {
				let result = {id: item.id};
				_.each(additionalProperties, prop => result[prop] = item[prop]);
				return result;
			});
		} else {
			if (this.$el.select2('data').length === 0) {
				return null;
			}
			let data = this.$el.select2('data')[0];
			let result = {id: data.id};
			_.each(additionalProperties, prop => result[prop] = data[prop]);
			return result;
		}
	},

	getValueWithData: function () {
		if (this.$el.attr('multiple')) {
			return this.$el.select2('data');
		} else {
			return this.$el.select2('data').length === 0 ?
				null : this.$el.select2('data')[0];
		}
	},

	setDataToModel: function (value) {
		var oldValue = this.getDataFromModel();
		if (this.model) {
			var isEqual = true;
			if (this.$el.attr('multiple')) {
				if (!value && !oldValue) {
					isEqual = true;
				} else if (value && oldValue && oldValue.length === value.length) {
					_.sortBy(oldValue, 'id');
					_.sortBy(value, 'id');
					_.each(value, function (item, i) {
						if (oldValue[i].id != item.id) {
							isEqual = false;
						}
					});
				} else {
					isEqual = false;
				}
				if (!isEqual) {
					this.model.set(this.modelAttr, new this.modelCollectionType(value));
				}
			} else {
				if (!value && !oldValue) {
					isEqual = true;
				} else {
					isEqual = value && oldValue && value.id == oldValue.id;
				}

				if (!isEqual) {
					this.model.set(this.modelAttr, value && new this.modelType(value));
				}
			}
		}
	},

	getDataFromModel: function () {
		return this.model && this.model.get(this.modelAttr) &&
			this.model.get(this.modelAttr).toJSON();
	},
});

export default EntitySelect;

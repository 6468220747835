export let PropertyGroupKeys = {
	GENERAL: 'general',
	ADVANCED: 'advanced',
	VALIDATION: 'validation',
	APPEARANCE: 'appearance',
	EVENTS: 'actions',
	SEED_INSTANCES: 'seed_instances',
	TREE_VIEW: 'tree_view',
	MODAL_PROPERTIES: 'nested_window'
};

export let PropertyGroups = {
	[PropertyGroupKeys.GENERAL] : {
		order: 0
	},
	[PropertyGroupKeys.APPEARANCE] : {
		order: 2
	},
	[PropertyGroupKeys.VALIDATION] : {
		order: 3
	},
	[PropertyGroupKeys.ADVANCED] : {
		order: 4
	},
	[PropertyGroupKeys.EVENTS] : {
		order: 5,
		icon: 'bolt'
	},
	[PropertyGroupKeys.SEED_INSTANCES] : {
		order: 6
	},
	[PropertyGroupKeys.TREE_VIEW]: {
		order: 7
	},
	[PropertyGroupKeys.MODAL_PROPERTIES]: {
		order: 1
	}
}
